import { StepPageProps } from 'elements/core/contracts';

export enum ReEnrollDeviceStepsEnum {
    ConfirmReEnroll = 'ConfirmReEnroll',
    AppSetup = 'AppSetup',
    ReEnroll = 'ReEnroll',
    Success = 'Success',
}

export type ReEnrollDeviceScreenType = {
    [key: string | number]: React.ComponentType<StepPageProps<any>>;
};
