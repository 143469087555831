import { useParams } from '@hydrogen/elements.core.router';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';
import { useQuery } from 'react-query';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';

type PortfolioContact = {
    accessPolicyId: number
    displayName: string
    id: number
}

type InvestmentKeyIndices = {
    portfolioId: number,
    currentValue?: number,
    returnStartDate?: string | null,
    performanceTwr?: number | null,
    absoluteReturnPostGoLive: number,
    absoluteReturn: number,
    createDate?: string | null,
    portfolioContacts: PortfolioContact[]
}

export const useInvestmentKeyIndices = () => {
    const investmentId = useInvestmentId();
    const { investmentId: fromUrl } = useParams();

    const portfolioId = investmentId ?? fromUrl;

    const query = useQuery<InvestmentKeyIndices>(
        ['investment-key-indices', portfolioId],
        () => clientServiceApi().get(`v1.0/Plan/${portfolioId}/key-indices`).then(({ data }) => (data)),
        {
            enabled: !!portfolioId,
            keepPreviousData: true,

        },
    );

    return {
        ...query,
        isLoading: query.isIdle || query.isLoading || (query.isPreviousData && query.isFetching),
    };
};
