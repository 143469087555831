import { useMemo } from 'react';
import { productExternalIds } from '../../../../common/constants';

export function useProductType({
    externalId,
}) {
    const isAZP = useMemo(() => ([productExternalIds.azpNeu, productExternalIds.azpNeuSB, productExternalIds.azpAlt].includes(externalId)), [externalId]);
    const isAZPNeu = useMemo(() => (productExternalIds.azpNeu === externalId), [externalId]);
    const isAZPNeuSB = useMemo(() => (productExternalIds.azpNeuSB === externalId), [externalId]);
    const isAZPAlt = useMemo(() => (productExternalIds.azpAlt === externalId), [externalId]);
    const isPK = useMemo(() => ([productExternalIds.pk].includes(externalId)), [externalId]);
    const isThreeB = useMemo(() => [productExternalIds.azpNeu, productExternalIds.azpNeuSB, productExternalIds.azpAlt, productExternalIds.zic, productExternalIds.zifd, productExternalIds.zivv, productExternalIds.pk].includes(externalId), [externalId]);
    const isThreeA = useMemo(() => [productExternalIds.aaa, productExternalIds.fz, productExternalIds.fzp].includes(externalId), [externalId]);

    return {
        isAZP,
        isAZPNeu,
        isAZPNeuSB,
        isAZPAlt,
        isPK,
        isThreeB,
        isThreeA,
    };
}
