import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { Content } from '@hydrogen/elements.ui.layouts.base';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import Title from '@hydrogen/elements.ui.components.title';
import { SkeletonLine } from '@hydrogen/elements.ui.components.skeleton';
import { useAuthenticationData } from 'hydrogen/elements.auth.client-portal';

import { SubHeader } from 'elements/ui/components/header/sub-header';
import { ContactTile } from 'elements/ui/components/contact-tile';

export function Support() {
    const { t } = useLanguage();
    const { authData } = useAuthenticationData();

    const supportInfoData = [{
        contactName: t('support.contactName1'),
        openingHours: t('support.openingHours1'),
        email: t('support.email1'),
        phoneNumber1: t('support.phoneNumber11'),
    },
    {
        contactName: t('support.contactName2'),
        openingHours: t('support.openingHours1'),
        email: t('support.email2'),
        phoneNumber1: t('support.phoneNumber21'),
    }];

    return (
        <div className="flex-1 bg-white">
            <Content className="max-w-[75rem] !p-0 mx-auto mt-0 lg:mb-16 mb-10">
                <SubHeader title={t('support.title')} />
                <div className="max-w-[32.5rem] mx-auto px-[1rem]">
                    <Title as="h2" className="text-center !text-[1.75rem] !leading-[2.4375rem]">
                        {t('support.subTitle')}
                        &nbsp;
                        {
                            authData?.userInfo?.fullName
                            || <SkeletonLine className="inline-block mx-2 md:w-[30%]" height={9} width="full" />
                        }
                        ?
                    </Title>
                    <p className="py-8">
                        {t('support.text')}
                    </p>
                    <GridColumns columns={1} gap={4}>
                        {supportInfoData.map((props) => (<ContactTile {...props} key={uuidv4()} />))}
                    </GridColumns>
                </div>
            </Content>
        </div>
    );
}
