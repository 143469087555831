import React, {
    FC, ReactNode, useEffect, useState,
} from 'react';
import Base from '@hydrogen/elements.ui.layouts.base';
import Loader, { LoaderSizes } from '@hydrogen/elements.ui.components.loader';
import { useLocation, useNavigate } from '@hydrogen/elements.core.router';
import { useAuthenticationData } from '../hooks/use-authentication-data';
import { validateAppToken } from '../apis';
import { isAuthResponseError } from '../utils';

const ENROL_DEVICE_SCREEN = '/access-app';

type SecureProps = {
    children?: ReactNode;
    loading?: boolean
}

export const Secure:FC<SecureProps> = ({ children, loading = true }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { authData, logout } = useAuthenticationData() || {
        authData: {}, logout: () => {},
    };
    const [inProgress, setInProgress] = useState(loading);

    useEffect(() => {
        setInProgress(loading);
    }, [loading]);

    const checkToken = async (accessToken, appToken) => {
        try {
            if (!accessToken) {
                logout();

                return;
            }
            setInProgress(false);
            const { data } = await validateAppToken({ accessToken, appToken });

            isAuthResponseError(data);
        } catch {
            logout();
        }
    };

    useEffect(() => {
        if (authData?.accessToken && authData?.appToken) {
            checkToken(authData?.accessToken, authData?.appToken);
        }
    }, [authData?.accessToken, authData?.appToken]);

    useEffect(() => {
        if (!authData?.userInfo?.isTwoFactorEnabled && authData?.isAuthed && !pathname.startsWith(ENROL_DEVICE_SCREEN)) {
            navigate(ENROL_DEVICE_SCREEN);
        }
    }, [authData?.userInfo?.isTwoFactorEnabled, authData?.isAuthed, pathname]);

    if (inProgress) {
        return (
            <Base>
                <div style={{ margin: 'auto' }}>
                    <Loader size={LoaderSizes.extraLarge} />
                </div>
            </Base>
        );
    }

    return (
        <>{children}</>
    );
};
