import React from 'react';
import { container } from '@hydrogen/elements.core.di';

import { Overview } from 'elements/ui/widgets/overview';
import { useWithRundownWidget } from '../../../data/adapters/use-with-rundown-widget';

type InvestmentRundownProps = {
    columnsNumber?: number,
    dataSource?: typeof useWithRundownWidget
}

export function InvestmentRundown({
    columnsNumber,
    dataSource = container.get<typeof useWithRundownWidget>('useWithRundownWidget'),
}: InvestmentRundownProps) {
    const {
        data,
        isLoading,
    } = dataSource();

    return (
        <Overview
            title={data?.jaUsers}
            columnsNumber={columnsNumber}
            cards={data?.overviewRundownData}
            isLoading={isLoading}
        />
    );
}
