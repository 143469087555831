export enum ImageSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
}

export enum ImageVariants {
  round = "round",
  roundWithBorder = "roundWithBorder",
  border = "border",
  default = "default",
}
