import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isValid } from 'date-fns';
import { TFunction } from 'i18next';
import DateRangeOptions, { DateRangeType } from '@hydrogen/elements.ui.components.daterange-options';
import { formatDate, useLanguage } from '@hydrogen/elements.core.i18n';
import LineChart from '@hydrogen/elements.ui.charts.line-chart';
import { getContainer } from '@hydrogen/elements.core.di';
import { TimeIntervalEnum } from '@hydrogen/elements.core.types';
import { ContentBoxEmpty, ContentBoxError } from '@hydrogen/elements.ui.components.content-box';
import { useModifyPerformance } from '@hydrogen/elements.data.wealth.rest.use-modify-performance';
import { classNames } from '@hydrogen/elements.ui.utils';
import { TooltipComponentProps } from '@hydrogen/elements.ui.charts.core';

import { Tooltip } from 'elements/ui/components/tooltip';
import { HistoricalPerformanceProps, TFunctionKey } from './types';
import { getOptions } from './utils';
import { formatCurrency } from '../../../../common/formaters';

type ChartTooltipProps = TooltipComponentProps & {
    formatValue?: (value?: string | number | null) => string | null
    formatLabel?: (value?: string | number | null) => string | null
}
const ChartTooltip = (props: ChartTooltipProps) => (
    <Tooltip>
        <b className="mb-[0.25rem] text-various-blue">
            {props?.formatLabel ? props?.formatLabel(props?.x) : props?.x}
        </b>

        {props?.points?.map(({ y: value, color }) => (
            <div className="mb-0.5 last:mb-0 flex gap-1 items-center" key={uuidv4()}>
                <span className="w-3 h-0.5 rounded" style={{ backgroundColor: color as string }} />
                <span className="text-sm">
                    {props?.formatValue ? props?.formatValue(value) : value}
                </span>
            </div>
        ))}
    </Tooltip>
);

const getLegendTittle = (title: string | TFunctionKey, t: TFunction) => {
    if (typeof title === 'function') return title(t);

    return title;
};

const getUrlRangeOption = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const dateFrom = searchParams.get('dateFrom');
    const dateTo = searchParams.get('dateTo');

    if (!dateFrom
        || !dateTo
        || !isValid(new Date(dateFrom))
        || !isValid(new Date(dateTo))) return null;

    return ({
        from: dateFrom,
        key: 'urlRange',
        label: 'urlRange',
        to: dateTo,
    });
};
const HistoricalPerformance = ({
    dateRanges,
    dataSource = getContainer(useModifyPerformance),
    benchmark = null,
    withCustomDateRange = true,
    investmentPerformanceLegendTittle = '',
    newInvestmentPerformanceLegendTittle = '',
    requestPayload,
    inlineRange = false,
    checkGraphHeight = false,
    isThreeB,
}: HistoricalPerformanceProps) => {
    const { t, language } = useLanguage();

    const urlRange = getUrlRangeOption();
    const [
        dateRange, setDateRange,
    ] = React.useState<DateRangeType | null>(urlRange || dateRanges?.[0] || null);

    const {
        data, isLoading, isError, isRefetching,
    } = dataSource(requestPayload ? {
        ...requestPayload,
        dateFrom: dateRange?.from && formatDate(new Date(dateRange.from)),
        dateTo: dateRange?.to && formatDate(new Date(dateRange.to)),
    } : {
        language,
        backtracking: true,
        payload: {
            range: dateRange?.key || TimeIntervalEnum.CUSTOM_RANGE,
            dateFrom: dateRange?.from && formatDate(new Date(dateRange.from)),
            dateTo: dateRange?.to && formatDate(new Date(dateRange.to)),
        },
    });

    const onChange = (range: DateRangeType) => {
        setDateRange(range);
    };

    const chartData = useMemo(() => [
        ...((data?.investmentPerformance?.values?.length || 0)
        || (data?.values?.length || 0) > 0 ? [{
                name: getLegendTittle((investmentPerformanceLegendTittle), t) || t('common.current'),
                data: getOptions(data?.investmentPerformance
                    ? data?.investmentPerformance?.values : data?.values),
            }] : []),
        ...((data?.newInvestmentPerformance?.values?.length || 0) > 0 ? [{
            name: getLegendTittle(newInvestmentPerformanceLegendTittle, t) || t('common.new'),
            data: getOptions(data?.newInvestmentPerformance?.values),
        }] : []),
        ...(data?.benchmarks?.map?.((benchmarkItem) => ({
            name: benchmarkItem.name,
            data: getOptions(benchmarkItem?.values, dateRange),
        })) || []),
    ], [data, language]);

    return (
        <ContentBoxError
            isError={isError}
            errorMessage={(<>{t('common.something-wrong')}</>)}
        >
            <div>
                <div className="flex flex-wrap justify-between gap-4 mb-4 sm:mb-6">
                    <DateRangeOptions
                        withCustomDateRange={withCustomDateRange}
                        title={t('common.date-range')}
                        fromDateLabel={t('common.inception')}
                        onChange={onChange}
                        value={dateRange}
                        ranges={dateRanges}
                        formatDate={(date) => (date ? formatDate(new Date(date), 'dd.MM.yyyy') : '')}
                        maxDateTo={new Date()}
                        inlineRange={inlineRange}
                        disabledFrom={isThreeB}
                        disabledTo={isThreeB}
                    />
                    {benchmark}
                </div>
                <div className={classNames(isRefetching && 'animate-pulse')}>
                    <LineChart
                        checkGraphHeight={checkGraphHeight}
                        withLegend={!requestPayload}
                        withZoom
                        height="354px"
                        isLoading={isLoading}
                        yAxisFormatter={({ value }) => (!requestPayload
                            ? `${formatCurrency(+(value ?? 0), undefined)}%`
                            : (
                                t('{{val, number}}', {
                                    val: value ?? 0,
                                })
                            ))}
                        data={chartData}
                        TooltipComponent={(tooltipProps) => (
                            <ChartTooltip
                                {...tooltipProps}
                                formatLabel={(date) => (date ? formatDate(new Date(date), 'dd.MM.yyyy') : '')}
                                formatValue={(value) => (
                                    !requestPayload
                                        ? `${formatCurrency(+(value ?? 0), undefined)}%` : (
                                            t(`{{val, currency(${'usd'})}}`, {
                                                val: value ?? 0,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })
                                        )
                                )}
                            />
                        )}
                        EmptyDataComponent={(
                            <ContentBoxEmpty className="">
                                <>{t('common.data-empty')}</>
                            </ContentBoxEmpty>
                        )}
                    />
                </div>
            </div>
        </ContentBoxError>
    );
};

export default HistoricalPerformance;
