import React from 'react';

import { classNames } from '@hydrogen/elements.ui.utils';
import { useNavigate } from '@hydrogen/elements.core.router';
import { SkeletonLine } from '@hydrogen/elements.ui.components.skeleton';

import { LinkButton } from '../button';

export type DataTileProps = {
    className?: string,
    isLoading?: boolean,
    icon?: string,
    link?: string,
    title: string,
    value?: string | number | React.ReactNode
};

export const DataTile = ({
    className,
    icon = 'icon-edit-outline',
    isLoading = false,
    link,
    title,
    value,
}: DataTileProps) => {
    const navigate = useNavigate();

    const iconClasses = 'ml-3 icon inline-block text-[1.5rem] text-secondary-300';

    return (
        <div className={classNames(className, 'flex justify-between items-center px-4 py-3 text-base bg-various-gray text-primary-500 font-medium')}>
            <div>
                <p className="font-normal">
                    {title}
                </p>
                {isLoading ? <SkeletonLine height={6} width={24} /> : value}
            </div>
            {link && (
                <LinkButton className="flex flex-shrink-0 hover:no-underline" onClick={() => navigate(link)}>
                    <i className={classNames(iconClasses, icon)} />
                </LinkButton>
            )}
        </div>
    );
};
