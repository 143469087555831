import React, {
    Fragment, useCallback, useEffect, useMemo, useState,
} from 'react';
import { object, string } from 'yup';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import Grid from '@hydrogen/elements.ui.components.grid';
import { container } from '@hydrogen/elements.core.di';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { post, subscribe } from '@hydrogen/elements.core.event-bus';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';

import { FirstLoginClientDetails, FirstLoginTopics, StepPageProps } from 'elements/core/contracts';
import { Text } from 'elements/ui/components/text';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { usePasswordSetup } from 'elements/data/rest/use-password-setup';
import { Button } from 'elements/ui/components/button';
import { Controller, useForm } from 'elements/ui/components/use-form';
import { TextInput } from 'elements/ui/components/text-input';
import { useStepper } from 'elements/ui/components/stepper/components';
import { useNotifications } from '../../components/notification';
import { updateSessionStorageObject } from '../../../../common/helper';
import { PASSWORD_REGEX } from '../../../../common/validation';

type PasswordForm = {
    clientId: string;
    password: string;
    passwordConfirm: string;
};

const NUMBER_REGEX = /^[0-9]*$/;

const passwordCriteria = [
    {
        label: 'firstLogin.passwordSetup.passwordLength',
        callback: (_: (value: RegExp) => boolean, value: string) => value.length >= 8,
    },
    {
        label: 'firstLogin.passwordSetup.capitalLetters',
        callback: (validate: (value: RegExp) => boolean, _: string) => validate(/[A-Z\u00C0-\u00DF]/),
    },
    {
        label: 'firstLogin.passwordSetup.smallLetters',
        callback: (validate: (value: RegExp) => boolean, _: string) => validate(/[a-z\u00E0-\u00FF]/),
    },
    {
        label: 'firstLogin.passwordSetup.numbers',
        callback: (validate: (value: RegExp) => boolean, _: string) => validate(/[0-9]/),
    },
];

const inputClasses = 'h-12 md:text-lg px-6';

export function PasswordSetup({
    links: { nextLink, prevLink },
    dataSource = container.get<typeof usePasswordSetup>('usePasswordSetup'),
}: StepPageProps<typeof usePasswordSetup>) {
    const { inProgress, setPassword } = dataSource();
    const { negative } = useNotifications() || {};
    const { t } = useLanguage();
    const { onStepComplete } = useStepper() || {};
    const [clientData, setClientData] = useState<FirstLoginClientDetails>();

    useEffect(() => {
        const unsubscribe = subscribe(FirstLoginTopics.FirstLoginClientDetails, (payload) => {
            setClientData(payload);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    // #region Form setup

    const validationSchema = useMemo(
        () => object().shape({
            clientId: string()
                .matches(NUMBER_REGEX, t('validation.numbersOnly'))
                .required(t('validation.mandatoryField')),
            password: string()
                .matches(PASSWORD_REGEX, t('validation.passwordCriteria'))
                .required(t('validation.mandatoryField')),
            passwordConfirm: string()
                .test(
                    'is_password_same',
                    t('validation.passwordMatch'),
                    (passwordConfirm, testContext) => {
                        const { password } = testContext.parent;

                        return passwordConfirm === password;
                    },
                )
                .required(t('validation.mandatoryField')),
        }),
        [t],
    );

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<PasswordForm>({
        validationSchema,
        options: {
            defaultValues: {
                clientId: '',
                password: '',
                passwordConfirm: '',
            },
        },
    });

    const password = watch('password');

    const onFormSubmit = handleSubmit(async (values: PasswordForm) => {
        try {
            await setPassword({
                clientId: values.clientId,
                password: values.password,
                contactIdentityId: clientData?.contactIdentityId ?? '',
            });

            post(FirstLoginTopics.FirstLoginClientDetails, { ...clientData, userName: values.clientId });
            updateSessionStorageObject(FirstLoginTopics.FirstLoginClientDetails, { userName: values.clientId });

            if (typeof onStepComplete === 'function') {
                onStepComplete({ nextLink, prevLink });
            }
        } catch (err) {
            console.error(err);
            if (typeof negative === 'function') {
                negative(t('notifications.somethingWentWrong'));
            }
        }
    });

    // #endregion

    const isPasswordCriteriaSatisfied = useCallback(
        (criteria: RegExp) => !!password.match(criteria),
        [password],
    );

    const passwordCriteriaItems = useMemo(
        () => passwordCriteria.map((item) => {
            const isValid = item.callback(isPasswordCriteriaSatisfied, password);

            return (
                <Fragment key={item.label}>
                    <Icon
                        className={`w-4 h-4 ${isValid ? 'text-green-500' : ''}`}
                        size={IconsSizes.custom}
                        icon={isValid ? CheckCircleIcon : XCircleIcon}
                    />
                    <Text variant="stepTitle">{t(item.label)}</Text>
                </Fragment>
            );
        }),
        [isPasswordCriteriaSatisfied, password, t],
    );

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.passwordSetup.name')}
                stepTitle={t('firstLogin.passwordSetup.title')}
                stepDesc={t('firstLogin.passwordSetup.desc')}
                stepImage=""
                prevLink={prevLink}
                noImage
            />
            <form onSubmit={onFormSubmit} className="w-full">
                <div className="justify-self-start w-full text-start mt-2">
                    <Controller
                        name="clientId"
                        control={control}
                        render={({ field }) => (
                            <TextInput
                                tooltip={t('firstLogin.passwordSetup.titleTooltip')}
                                className={inputClasses}
                                error={errors.clientId?.message}
                                labelInline
                                label={t('firstLogin.passwordSetup.clientId')}
                                iconTrailing="info"
                                {...field}
                            />
                        )}
                    />
                </div>
                <div className="justify-self-start w-full text-start">
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                            <TextInput
                                type="password"
                                className={inputClasses}
                                error={errors.password?.message}
                                labelInline
                                label={t('firstLogin.passwordSetup.password')}
                                {...field}
                            />
                        )}
                    />
                </div>
                <div className="justify-self-start w-full text-start">
                    <Controller
                        name="passwordConfirm"
                        control={control}
                        render={({ field }) => (
                            <TextInput
                                type="password"
                                className={inputClasses}
                                error={errors.passwordConfirm?.message}
                                labelInline
                                label={t('firstLogin.passwordSetup.confirmPassword')}
                                {...field}
                            />
                        )}
                    />
                </div>
                <Grid
                    columns={2}
                    gap={2}
                    className="justify-items-start grid-cols-[16px_auto] items-center mb-[40px] w-full"
                >
                    <Text variant="normal" className="col-span-2 mb-1">
                        {t('firstLogin.passwordSetup.passwordCriteria')}
                    </Text>

                    {passwordCriteriaItems}
                </Grid>
                <Button
                    isSubmit
                    loading={inProgress}
                    onClick={onFormSubmit}
                    disabled={!password || inProgress}
                >
                    {t('firstLogin.passwordSetup.setPassword')}
                </Button>
            </form>
        </>
    );
}
