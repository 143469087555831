import React from 'react';
import { container } from '@hydrogen/elements.core.di';

import { formatDate, useLanguage } from '@hydrogen/elements.core.i18n';
import { Content } from '@hydrogen/elements.ui.layouts.base';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { useContactDetails } from 'elements/data/rest/use-contact-details';

import { SubHeader } from 'elements/ui/components/header/sub-header';
import { DataTile } from '../../components/data-tile';
import { ContentBox } from '../../components/content-box';
import { Button } from '../../components/button';
import { Secure } from '../../../../hydrogen/elements.auth.client-portal';

type PersonalDataProps = {
    dataSource?: typeof useContactDetails
}

export function PersonalData({
    dataSource = container.get<typeof useContactDetails>('useContactDetails'),
}: PersonalDataProps) {
    const { t } = useLanguage();

    const { data, isLoading } = dataSource();

    const categoryStyle = 'mb-[1rem] text-[1.125rem] leading-[1.5rem] font-medium text-primary-500';
    const supportLink = `mailto:invest@zurich.ch?subject=${t('personalData.subject')}`;

    const personalInformation = data?.generalInformation || {};
    const communicationMethods = data?.address || {};
    const contactInformation = data?.contactInformation || {};

    const residenceCity = [

        communicationMethods?.residenceAddress?.zipCode,
        communicationMethods?.residenceAddress?.city,
    ]
        ?.filter((line) => line !== null)
        ?.join(' ');

    const mailingCity = [

        communicationMethods?.mailingAddress?.zipCode,
        communicationMethods?.mailingAddress?.city,
    ]
        ?.filter((line) => line !== null)
        ?.join(' ');

    const jointAccountCity = [

        communicationMethods?.jointAccountAddress?.zipCode,
        communicationMethods?.jointAccountAddress?.city,
    ]
        ?.filter((line) => line !== null)
        ?.join(' ');

    const hasJointAccount = jointAccountCity || communicationMethods?.jointAccountAddress?.street
        || communicationMethods?.jointAccountAddress?.country || communicationMethods?.jointAccountAddress?.accountName;

    const hasMailingAddress = mailingCity || communicationMethods?.mailingAddress?.country || communicationMethods?.mailingAddress?.street;

    const onClick = () => {
        window.location.href = supportLink;
    };

    return (
        <Secure loading={isLoading}>
            <div className="flex-1 bg-white">
                <Content className="max-w-[75rem] !p-0 mx-auto mt-0 lg:mb-16 mb-10">
                    <SubHeader title={t('personalData.title')} />
                    <GridColumns className="max-w-[53.625rem] mx-auto lg:mt-4 px-[1rem]" gap={8} columns={1}>
                        <div>
                            <p className={categoryStyle}>{t('personalData.generalInfo')}</p>
                            <GridColumns className="gap-0.5 rounded-lg overflow-hidden" columns={{ lg: 2, default: 1 }}>
                                <DataTile
                                    isLoading={isLoading}
                                    title={t('personalData.name')}
                                    value={personalInformation?.name}
                                />
                                <DataTile
                                    isLoading={isLoading}
                                    title={t('personalData.surname')}
                                    value={personalInformation?.surname}
                                />
                                <DataTile
                                    isLoading={isLoading}
                                    title={t('personalData.gender')}
                                    value={t(`personalData.gender.${personalInformation?.gender}`)}
                                />
                                <DataTile
                                    isLoading={isLoading}
                                    title={t('personalData.dateOfBirth')}
                                    value={personalInformation?.dateOfBirth && formatDate(new Date(personalInformation?.dateOfBirth), 'dd.MM.yyyy')}
                                />
                            </GridColumns>
                        </div>
                        <div>
                            <p className={categoryStyle}>{t('personalData.address')}</p>
                            <GridColumns className="gap-0.5 rounded-lg overflow-hidden" columns={{ lg: 2, default: 1 }}>
                                <DataTile
                                    isLoading={isLoading}
                                    title={t('personalData.residenceAddress')}
                                    value={(
                                        <>
                                            <p>
                                                {communicationMethods?.residenceAddress?.street || ''}
                                            </p>
                                            <p>
                                                {residenceCity}
                                            </p>
                                            <p>
                                                {communicationMethods?.residenceAddress?.country || ''}
                                            </p>
                                        </>
                                    )}
                                />
                                {hasMailingAddress && (
                                    <DataTile
                                        isLoading={isLoading}
                                        title={t('personalData.mailingAddress')}
                                        value={(
                                            <>
                                                <p>
                                                    {communicationMethods?.mailingAddress?.street || ''}
                                                </p>
                                                <p>
                                                    {mailingCity}
                                                </p>
                                                <p>
                                                    {communicationMethods?.mailingAddress?.country || ''}
                                                </p>
                                            </>
                                        )}
                                    />
                                )}
                                {hasJointAccount && (
                                    <DataTile
                                        isLoading={isLoading}
                                        title={t('personalData.jointAccountAddress')}
                                        value={(
                                            <>
                                                <p>
                                                    {communicationMethods?.jointAccountAddress?.accountName || ''}
                                                </p>
                                                <p>
                                                    {communicationMethods?.jointAccountAddress?.street || ''}
                                                </p>
                                                <p>
                                                    {jointAccountCity}
                                                </p>
                                                <p>
                                                    {communicationMethods?.jointAccountAddress?.country || ''}
                                                </p>
                                            </>
                                        )}
                                    />
                                )}

                            </GridColumns>
                        </div>
                        <div>
                            <p className={categoryStyle}>{t('personalData.contactInformation')}</p>
                            <GridColumns className="gap-0.5 rounded-lg overflow-hidden" columns={{ lg: 2, default: 1 }}>
                                <DataTile
                                    isLoading={isLoading}
                                    title={t('personalData.email')}
                                    value={contactInformation?.email}
                                />
                                <DataTile
                                    isLoading={isLoading}
                                    title={t('personalData.phone')}
                                    value={contactInformation?.mobilePhone}
                                />
                            </GridColumns>
                        </div>
                        <ContentBox className="mt-2 lg:mb-10 p-4" as="section" noHeader variant="tabbed">
                            <GridColumns className="gap-4 items-center" columns={{ lg: 2, default: 1 }}>
                                <p className="text-primary-500">{t('personalData.modifyText')}</p>
                                <Button className="!lg:w-auto !h-12 px-6 text-white" onClick={onClick}>
                                    <i className="mr-2 icon icon-contact text-[1.5rem] text-white" />
                                    {t('personalData.requestChange')}
                                </Button>
                            </GridColumns>
                        </ContentBox>
                    </GridColumns>
                </Content>
            </div>
        </Secure>
    );
}
