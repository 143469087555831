import React from 'react';

import { Content } from '@hydrogen/elements.ui.layouts.base';

import { Secure } from 'hydrogen/elements.auth.client-portal';
import { InvestmentsList } from '../investments-list';
import { InvestmentOverview } from '../invetment-overview';
import { PersonalisedWelcome } from '../personalised-welcome/personalised-welcome';

export function Dashboard() {
    return (
        <Secure loading={false}>
            <div className="flex-1 bg-neutral-100">
                <Content className="max-w-[57.75rem] lg:pb-[4rem] mx-auto lg:mt-[6rem] mt-[1.5rem] lg:mb-[1.5rem]">
                    <PersonalisedWelcome />
                    <InvestmentOverview />
                    <InvestmentsList />
                </Content>
            </div>
        </Secure>
    );
}
