import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Chart, { ChartBaseProps, Point } from '@hydrogen/elements.ui.charts.core';
import { SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import { classNames } from '@hydrogen/elements.ui.utils';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

import { ContentBoxEmpty } from '@hydrogen/elements.ui.components.content-box';
import { getOptions } from './chart-options';
import { PieChartDataType } from './types';
import PieChartSkeleton from './pie-chart-skeleton';
import { LinkButton } from '../button';

type LegendPointType = Point & {
    currencyCode?: string
    amount?: string
}

type PieChartProps = ChartBaseProps & {
    onClickPoint?: () => void,
    height?: string,
    chartWidth?: string,
    legendType?: 'default' | 'custom',
    formatLegendValue?: (value?: string | number | null, point?: LegendPointType) => string | null
    formatLegendLabel?: (value?: string | number | null) => string | null
    data: PieChartDataType[]
    hasAllocations: boolean | undefined
}

export const PieChart = ({
    height = '280px',
    onClickPoint,
    data,
    chartWidth = '280px',
    options,
    legendType = 'default',
    TooltipComponent = null,
    isLoading = false,
    withLegend,
    hasAllocations,
    ...props
}: PieChartProps) => {
    const { t } = useLanguage();
    const [customLegend, setCustomLegend] = useState<Point[]>([]);
    const [showAllLabels, setShowAllLabels] = useState(false);
    const isCustomLegend = legendType === 'custom';

    const chartOptions = getOptions({
        onClickPoint,
        data,
        options,
        isCustomLegend,
        onLoad: (chart) => {
            if (chart?.legend?.allItems) {
                setCustomLegend(
                    chart.legend.allItems as Point[],
                );
            }
        },
    });

    return (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={<PieChartSkeleton height={height} />}
        >
            {hasAllocations ? (
                <>
                    <div
                        style={{ width: chartWidth }}
                        className="!w-full flex justify-center"
                    >
                        <Chart
                            {...props}
                            options={chartOptions}
                            withLegend={withLegend}
                            height={height}
                            TooltipComponent={TooltipComponent}
                        />
                    </div>
                    {isCustomLegend
                        && (
                            <div className="grid gap-y-[0.25rem]">
                                {customLegend?.map((item) => (
                                    <div
                                        className={classNames(Number(item?.index) > 1 && !showAllLabels && 'hidden', 'w-full flex')}
                                        key={item?.options?.id ?? uuidv4()}
                                    >
                                        <div
                                            style={{ backgroundColor: item.color as string }}
                                            className="flex-shrink-0 self-start w-2.5 h-2.5 mt-2 rounded-full"
                                        />
                                        <div className="ms-2 w-full">
                                            <p className="text-sm text-primary-500">
                                                {props?.formatLegendLabel
                                                    ? props?.formatLegendLabel(item.name)
                                                    : item.name}
                                            </p>
                                            <p className="text-xs text-brand-500">
                                                {props?.formatLegendValue
                                                    ? props?.formatLegendValue(item.y, item)
                                                    : item.y}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                                {customLegend?.length > 2 && (
                                    <div className="mt-2 text-center">
                                        <LinkButton
                                            className="inline-flex text-[0.875rem] leading-[1rem]"
                                            onClick={() => setShowAllLabels(!showAllLabels)}
                                        >
                                            {
                                                showAllLabels
                                                    ? t('pieChart.less')
                                                    : t('pieChart.more')
                                            }
                                            <Icon
                                                className="w-4 h-4 ml-2"
                                                icon={showAllLabels ? ChevronUpIcon : ChevronDownIcon}
                                                size={IconsSizes.custom}
                                            />
                                        </LinkButton>
                                    </div>
                                )}
                            </div>
                        )}
                </>
            ) : (
                <ContentBoxEmpty className="">
                    <>{t('common.data-empty')}</>
                </ContentBoxEmpty>
            )}
        </SkeletonWrapper>
    );
};
