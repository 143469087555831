import React, { ReactNode, useMemo } from 'react';

export type TextProps = {
  children: string | ReactNode;
  // weight?: "light" | "normal" | "medium" | "semibold" | "bold";
  className?: string;
  variant?:
    | 'stepTitle'
    | 'mainTitle'
    | 'desc'
    | 'error'
    | 'label'
    | 'normal'
    | 'emphasize'
    | 'disclaimer'
    | 'default'
    | 'alert';
};

const variants = {
    stepTitle: 'font-medium text-sm',
    normal: 'font-normal text-sm',
    mainTitle: 'font-light text-3xl',
    desc: 'font-normal text-base',
    disclaimer: 'font-normal text-xs',
    error: 'font-medium text-xs text-peachRed',
    label: 'font-normal text-lg',
    emphasize: 'font-semibold text-lg',
    default: '',
    alert: 'font-semibold text-base',
};

const HTML_TAG_REGEX = /<.*?>/g;

export function Text({
    children,
    // weight = "normal",
    className,
    variant = 'default',
}: TextProps) {
    const isHtml = useMemo(() => String(children).match(HTML_TAG_REGEX), [children]);

    return (
        <span
            className={`items-center relative ${variants[variant]} ${
                className ?? ''
            }`}
            dangerouslySetInnerHTML={
                typeof children === 'string' && isHtml
                    ? { __html: children }
                    : undefined
            }
        >
            {!isHtml ? children : null}
        </span>
    );
}
