import { useState, useCallback } from 'react';

export type UseNewPasswordSetupResult = {
    inProgress: boolean;
    setPassword: (payload: SetNewPasswordPayload) => Promise<boolean>;
};

export type SetNewPasswordPayload = {
    password: string;
};

export function useForgotPassword(): UseNewPasswordSetupResult {
    const [inProgress, setInProgress] = useState(false);

    const setPassword = useCallback(async (payload: SetNewPasswordPayload) => {
        try {
            setInProgress(true);

            // TODO: implement real api call
            return await new Promise<boolean>((resolve, reject) => {
                setTimeout(() => {
                    resolve(true);
                }, 1000);
            });
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            setInProgress(false);
        }
    }, []);

    return { inProgress, setPassword };
}
