import React from 'react';

import { container } from '@hydrogen/elements.core.di';
import { InvestmentWithdrawalPlan } from '../investment-withdrawal-plan';
import { useWithInvestmentTransferWidget } from '../../../data/adapters/use-with-investment-transfer-widget';

export type TransferProps<TDataSource> = {
    dataSource?: TDataSource;
}

export const InvestmentTransfer = ({
    dataSource = container.get<typeof useWithInvestmentTransferWidget>('useWithInvestmentTransferWidget'),
}: TransferProps<typeof useWithInvestmentTransferWidget>) => {
    const {
        data,
    } = dataSource();

    return (
        <>
            {data?.map((transfer) => (
                <InvestmentWithdrawalPlan
                    dataSource={() => transfer}
                />
            ))}
        </>
    );
};
