import React from 'react';

import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import { formatDate, t } from '@hydrogen/elements.core.i18n';
import { StarIcon } from '@heroicons/react/solid';
import { IconsSizes, ItemIconCircle } from '@hydrogen/elements.ui.icon';
import { formatCurrency } from '../../../../common/formaters';

export const customCells = {
    'Security.Name': ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width="full" height={3} />
                    <SkeletonLine width={32} className="mt-2" height={3} />
                </div>
            )}
        >
            <span className="flex items-center gap-2">
                <span className="font-medium text-primary-500">
                    {item['Security.Name']}
                </span>
                <>
                    {' '}
                    {item.IsFavourite ? (
                        <ItemIconCircle
                            icon={StarIcon}
                            size={IconsSizes.small}
                            colorClasses="text-brand-700 bg-brand-200"
                        />
                    ) : null}
                </>

            </span>
            <div>
                {item['Security.Isin']}
            </div>
        </SkeletonWrapper>
    ),
    Allocation: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width="full" height={3} />
                    <SkeletonLine width={32} className="mt-2" height={3} />
                </div>
            )}
        >
            <span>
                {t('{{val, number}}%', {
                    val: (item.Allocation || 0) * 100,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}
            </span>
        </SkeletonWrapper>
    ),
    // Performance: ({ item }, isLoading) => (
    //     <SkeletonWrapper
    //         isLoading={isLoading}
    //         Skeleton={(
    //             <div>
    //                 <SkeletonLine width="full" height={3} />
    //                 <SkeletonLine width={32} className="mt-2" height={3} />
    //             </div>
    //         )}
    //     >
    //         <span className={getValueTrend(item.Performance)}>
    //             {t('{{val, number}}%', {
    //                 val: (item.Performance || 0) * 100,
    //                 maximumFractionDigits: 2,
    //                 minimumFractionDigits: 2,

    //             })}
    //         </span>
    //     </SkeletonWrapper>
    // ),
    InvestmentValueSecurityCurrency: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width="full" height={3} />
                    <SkeletonLine width={32} className="mt-2" height={3} />
                </div>
            )}
        >
            <div>
                <span>
                    {formatCurrency(item.InvestmentValueSecurityCurrency, item['Security.Currency.CurrencyCode'])}
                </span>
            </div>

        </SkeletonWrapper>
    ),
    MonetaryPerformance: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width="full" height={3} />
                    <SkeletonLine width={32} className="mt-2" height={3} />
                </div>
            )}
        >
            <span>
                {formatCurrency(item.MonetaryPerformance, item['Security.Currency.CurrencyCode'])}
            </span>

        </SkeletonWrapper>
    ),
    ValuationPrice: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width="full" height={3} />
                    <SkeletonLine width={32} className="mt-2" height={3} />
                </div>
            )}
        >
            <span>
                {formatCurrency(item.ValuationPrice, item['Security.Currency.CurrencyCode'])}
            </span>
        </SkeletonWrapper>
    ),
    BuyPrice: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width="full" height={3} />
                    <SkeletonLine width={32} className="mt-2" height={3} />
                </div>
            )}
        >
            <span>
                {formatCurrency(item.BuyPrice, item['Security.Currency.CurrencyCode'])}
            </span>
        </SkeletonWrapper>
    ),
    'Security.TradingPrice': ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width="full" height={3} />
                    <SkeletonLine width={32} className="mt-2" height={3} />
                </div>
            )}
        >
            <span>
                {formatCurrency(item['Security.TradingPrice'], item['Security.Currency.CurrencyCode'])}
            </span>
        </SkeletonWrapper>
    ),
    calculatedCurrentPrice: ({ item }, isLoading) => {
        const calculatedPrice = item?.['Security.TradingPrice'] && item?.FxRate
            ? item['Security.TradingPrice'] / item.FxRate
            : null;

        return (
            <SkeletonWrapper
                isLoading={isLoading}
                Skeleton={(
                    <div>
                        <SkeletonLine width="full" height={3} />
                        <SkeletonLine width={32} className="mt-2" height={3} />
                    </div>
                )}
            >
                <span>
                    {
                        calculatedPrice !== null
                            ? formatCurrency(calculatedPrice, item['Security.Currency.CurrencyCode'])
                            : t('common.n-a')
                    }
                </span>
            </SkeletonWrapper>
        );
    },
    tradingPrice: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div className="min-h-[40px]">
                    <SkeletonLine width="full" height={3} />
                    <SkeletonLine width={32} className="mt-2" height={3} />
                </div>
            )}
        >
            <div className="font-medium text-primary-500">
                {formatCurrency(item.tradingPrice, item['currency.isoCode'])}
            </div>
            <div>
                {item.tradingPriceLastUpdateDate
                    && formatDate(new Date(item.tradingPriceLastUpdateDate), 'dd.MM.yyyy hh:mm')}
            </div>
        </SkeletonWrapper>
    ),
};

export const customMobileCells = {
    'Security.Name': ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div className="min-h-[24px] flex justify-end">
                    <SkeletonLine width="full" />
                </div>
            )}
        >
            <div className="font-medium truncate text-primary-500">
                {item['Security.Name']}
            </div>
        </SkeletonWrapper>
    ),
    tradingPrice: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div className="min-h-[24px] flex justify-end">
                    <SkeletonLine width={32} />
                </div>
            )}
        >
            <div className="text-primary-500">
                {formatCurrency(item.tradingPrice, item['currency.isoCode'])}
            </div>
        </SkeletonWrapper>
    ),
    InvestmentValueSecurityCurrency: customCells.InvestmentValueSecurityCurrency,
    // Performance: ({ item }, isLoading) => {
    //     const valueClasses = classNames(
    //         getValueTrend(item.Performance),
    //         'text-sm text-secondary-500',
    //     );

    //     return (
    //         <SkeletonWrapper
    //             isLoading={isLoading}
    //             Skeleton={(
    //                 <div>
    //                     <SkeletonLine width="full" height={3} />
    //                     <SkeletonLine width={32} className="mt-2" height={3} />
    //                 </div>
    //             )}
    //         >
    //             <span className={valueClasses}>
    //                 {t('{{val, number}}%', {
    //                     val: (item.Performance || 0) * 100,
    //                     maximumFractionDigits: 2,
    //                     minimumFractionDigits: 2,
    //                 })}
    //             </span>
    //         </SkeletonWrapper>
    //     );
    // },
};
