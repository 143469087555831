import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { customApi } from '@hydrogen/elements.core.rest.transport';
import { ListPayload, ListResponse } from '@hydrogen/elements.core.contracts';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';

export const useInvestmentTransactions = ({
    skip,
    take,
    fields = [],
    filters = [],
    orders = [],
    language = 'en',
}: ListPayload) => {
    const contactId = useContactId();
    const portfolioId = useInvestmentId();
    const query = useQuery<AxiosResponse<ListResponse>, unknown, ListResponse>(
        ['contact-investment-transactions', skip, take, fields, filters, orders, language, contactId, portfolioId],
        () => customApi().post(
            `/v1.0/investment-transactions/contacts/${contactId}/search/${language}?portfolioId=${portfolioId}`,
            {
                skip,
                take,
                fields,
                filters,
                orders,
            },
        ),
        {
            enabled: fields?.length > 0 && !!contactId && !!language && !!portfolioId,
            keepPreviousData: true,
            select({ data }) {
                return data;
            },

        },
    );

    return {
        ...query,
        isLoading: query.isIdle || query.isLoading || (query.isPreviousData && query.isFetching),
    };
};
