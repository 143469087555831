// Global constants
import { AuthErrors } from '../hydrogen/elements.auth.client-portal/types';

export const NA = '-';
export const ACTIVATION_CODE_LENGTH = 10;

export const THREEA = 6000;
export const FZ = 8000;

export const DEFAULT_CURRENCY = 'CHF';

export const FIRST_OF_JAN_23 = new Date('2023-01-01');
export const FIRST_OF_APR_24 = new Date('2024-04-01');

export enum PRODUCT_TYPE {
    'threeA',
    'VB'
}

export enum AccessPolicy {
    BeneficialOwner = 2,
    PowerOfAttorney = 3,
    Representative = 19,
    Owner = 20,
}

export const InvestmentCardColors = [
    'bg-[#91BFE3]',
    'bg-[#B6C6E8]',
    'bg-[#1fb1e6]/10',
    'bg-[#DAE2F4]',
    'bg-[#B6C6E8]',
    'bg-[#E2E1F5]',
    'bg-[#DAE2F4]',
    'bg-[#91BFE3]',
    'bg-[#B6C6E8]',
    'bg-[#1fb1e6]/10',
    'bg-[#DAE2F4]',
    'bg-[#B6C6E8]',
];

export const productExternalIds = {
    aaa: '3a',
    fz: 'FZ',
    fzp: 'FZ Premium',
    pk: 'PK',
    zifd: 'ZIFD',
    zic: 'ZIC',
    zivv: 'ZIVV',
    azpNeu: 'AZPD',
    azpNeuSB: 'AZPI',
    azpAlt: 'AZP alt',
};

export const envs = {
    LOCALHOST: 'localhost',
    DEV: 'dev',
    SIT: 'sit',
    UAT: 'uat',
    PROD: 'production',
};

export const AccessPolicyDescriptions = {
    [AccessPolicy.PowerOfAttorney]: 'common.powerOfAttorney',
    [AccessPolicy.Representative]: 'common.representative',
};

export const TIMEOUT_THRESHOLD = 300000; // 5min

export const ZIAG_FUTURAE = 'zurichinvest://enroll?activation_code=';
export const GLOBAL_FUTURAE = 'futurae://enroll?activation_code=';

export const OPTION_LIST = {
    country: 'country',
    gender: 'gender',
    maritalStatus: 'maritalStatus',
    language: 'language',
    salutation: 'salutation',
    WithdrawalPeriodicity: 'WithdrawalPeriodicity',
    degreeOfEducation: 'degreeOfEducation',
    pepStatus: 'pepStatus',
    inSwitzerlandSince: 'inSwitzerlandSince',
    plannedLengthOfStay: 'plannedLengthOfStay',
    professionalSituation: 'professionalSituation',
    originOfAssets: 'originOfAssets',
    industry2: 'industry2',
    beneficialOwner: 'beneficialOwner',
    preferenceStocks: 'preferenceStocks',
    preferenceExclusion: 'preferenceExclusion',
    exclusionSubstitute: 'substituteExclusion',
    preferenceInclusion: 'preferenceInclusion',
    fatfGreylist: 'fatfGreylist',
};

export const DOWNTIME = ['28.03.2024 00:00:00', '08.04.2024 23:59:59'];

export const LOGIN_AUTH_ERRORS = [AuthErrors.CredentialsInvalid, AuthErrors.ContactNotActivated, AuthErrors.SessionAlreadyActive, AuthErrors.AccessDenied, AuthErrors.TwoFactorDisabled, AuthErrors.DeviceNotEnrolled];
