import React, { useEffect, useMemo, useState } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { classNames } from '@hydrogen/elements.ui.utils';

export enum CircleSize {
    Large,
    Medium,
    Small,
}

export enum StrokeThickness {
    Large,
    Medium,
    Small,
}

export enum LabelSize {
    Large,
    Medium,
    Small,
}

type CounterProps = {
    seconds: number;
    circle?: {
        size?: CircleSize;
        className?: string;
        strokeThickness?: StrokeThickness;
    };
    label?: {
        text?: string;
        size?: LabelSize;
        className?: string;
    };
    onTimeUp?: () => void;
}

const INTERVAL_FREQUENCY = 1000; // in ms

export function Counter({
    seconds, circle, label, onTimeUp,
}: CounterProps) {
    const [timeLeft, setTimeLeft] = useState(seconds);
    const { t } = useLanguage();

    useEffect(() => {
        const fnId = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev === 0) {
                    onTimeUp?.();
                    clearInterval(fnId);

                    return 0;
                }

                return prev - 1;
            });
        }, INTERVAL_FREQUENCY);

        return () => clearInterval(fnId);
    }, []);

    const circleSize = useMemo(() => ({
        [CircleSize.Small]: 'h-12 w-12',
        [CircleSize.Medium]: 'h-16 w-16',
        [CircleSize.Large]: 'h-32 w-32',
    }[circle?.size || CircleSize.Large]), [circle?.size]);

    const labelSize = useMemo(() => ({
        [LabelSize.Small]: 'text-base',
        [LabelSize.Medium]: 'text-xl',
        [LabelSize.Large]: 'text-3xl',
    }[label?.size || LabelSize.Large]), [label?.size]);

    const strokeThickness = useMemo(() => ({
        [StrokeThickness.Small]: 'stroke-3',
        [StrokeThickness.Medium]: 'stroke-4',
        [StrokeThickness.Large]: 'stroke-5',
    }[circle?.strokeThickness || StrokeThickness.Large]), [circle?.strokeThickness]);

    const setCircleDasharray = () => {
        const FULL_DASH_ARRAY = 283; // cca 283 = 2 * PI * 45 (45 is the radius of the circle)

        const rawTimeFraction = timeLeft / seconds;
        const timeFraction = rawTimeFraction - (1 / seconds) * (1 - rawTimeFraction);

        const remainingDasharray = (FULL_DASH_ARRAY * timeFraction).toFixed(0);

        return `${remainingDasharray} ${FULL_DASH_ARRAY}`;
    };

    const isTimeUp = timeLeft === 0;

    return (
        <div className="flex flex-col justify-center items-center">
            <div className={classNames(circleSize, 'relative', circle?.className)}>
                <svg className="-scale-x-100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <g className="fill-none stroke-none">
                        <circle className={classNames(strokeThickness, 'stroke-disabled')} cx="50" cy="50" r="45" />
                        <path
                            strokeDasharray={setCircleDasharray()}
                            className={classNames(strokeThickness, isTimeUp ? 'stroke-disabled' : 'stroke-brand', '[stroke-linecap:square] rotate-90 origin-center transition duration-1000 ease-linear')}
                            d="
                            M 50, 50 m -45, 0
                            a 45,45 0 1,0 90,0
                            a 45,45 0 1,0 -90,0
                        "
                        />
                    </g>
                </svg>
                <span className={classNames(circleSize, labelSize, 'absolute top-0 flex justify-center items-center text-brand', label?.className)}>
                    {timeLeft}
                </span>
            </div>
            <span className="text-black mt-3 text-center text-xs font-medium">{label?.text || t('counter.remainingTime.label')}</span>
        </div>
    );
}
