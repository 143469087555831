import { mergeDeep } from '@hydrogen/elements.core.utils.base';
import { Chart, SeriesPieOptions } from 'highcharts';
import { Options } from '@hydrogen/elements.ui.charts.core';

import { PieChartDataType } from './types';

type ChartOptions = {
    data: PieChartDataType[],
    onClickPoint?: () => void
    onLoad?: (chart: Chart) => void
    options?: Options,
    isCustomLegend: boolean
}
export const getOptions = ({
    data,
    onClickPoint,
    options = {},
    onLoad,
    isCustomLegend = false,
}: ChartOptions): Options => (
    mergeDeep<Options>({
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        chart: {
            backgroundColor: 'transparent',
            type: 'pie',
            spacingTop: -5,
            events: {
                load() {
                    onLoad?.(this);
                },
            },
        },
        legend: {
            align: 'center',
            itemMarginBottom: 5,
            alignColumns: false,
            itemHoverStyle: {
                color: '',
            },
            itemStyle: {
                color: '',
            },
            ...(isCustomLegend ? {
                labelFormatter() {
                    return '';
                },
                symbolPadding: 0,
                symbolWidth: 0,
                symbolHeight: 0,
                squareSymbol: false,
                useHTML: true,
                labelFormat: '',
                symbolRadius: 0,
                itemMarginBottom: 0,
                padding: 0,
                margin: 0,
                itemDistance: 0,
                maxHeight: 0,
                itemStyle: {
                    color: '',
                    height: 0,
                },
            } : {}),
        },
        plotOptions: {
            series: {
                animation: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
                cursor: 'pointer',
                point: {
                    events: {
                        click: onClickPoint,
                    },
                },
            },
            pie: {
                borderWidth: 0,
                borderColor: '',
                allowPointSelect: false,
                center: ['50%', '50%'],
                cursor: 'pointer',
                thickness: 40,
                events: {
                    legendItemClick: () => false, // disable legend click
                },
                dataLabels: {
                    enabled: false,
                    borderColor: '',
                    style: {
                        textOutline: '',
                        color: '',
                        fontSize: '14px',
                    },
                },
            },
        },
        xAxis: {
            reversed: true,
        },
        series: data as SeriesPieOptions[],
    }, options)
);
