import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { object, string } from 'yup';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { post } from '@hydrogen/elements.core.event-bus';
import { container } from '@hydrogen/elements.core.di';
import { useSearchParams } from '@hydrogen/elements.core.router';
import { SkeletonLine } from '@hydrogen/elements.ui.components.skeleton';

import { useAccountActivation } from 'elements/data/rest/use-account-activation';
import { FirstLoginTopics, StepPageProps } from 'elements/core/contracts';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { Button } from 'elements/ui/components/button';
import { Controller, useForm } from 'elements/ui/components/use-form';
import { TextInput } from 'elements/ui/components/text-input';
import { useStepper } from 'elements/ui/components/stepper/components';
import { updateSessionStorageObject } from '../../../../common/helper';
import { ACTIVATION_CODE_LENGTH } from '../../../../common/constants';
import { SessionStoreManager } from '../../../core/utils';

type AccountActivationForm = {
    activationCode: string;
};

export function AccountActivation({
    links: { nextLink, skipLink = '', prevLink },
    dataSource = container.get<typeof useAccountActivation>(
        'useAccountActivation',
    ),
}: StepPageProps<typeof useAccountActivation>) {
    const { t, language } = useLanguage();
    const { onStepComplete, onStepSkip } = useStepper() || {};
    const { inProgress, validateActivationCode } = dataSource();
    const [searchParams, _] = useSearchParams();
    const [initalLoad, setInitialLoad] = useState(false);

    const storedCode = useMemo(() => {
        const code = SessionStoreManager('code').load()?.code;

        return (code === undefined ? '' : String(code));
    }, []);
    const isCodeStored = useMemo(() => (!(storedCode === '')), [storedCode]);

    const verifyCode = useCallback(async (code: string) => {
        try {
            const data = await validateActivationCode(code);

            if (data) {
                post(FirstLoginTopics.FirstLoginClientDetails, data);
                updateSessionStorageObject(FirstLoginTopics.FirstLoginClientDetails, data);
            }
            setInitialLoad(false);
            onStepComplete({ nextLink, prevLink });
        } catch (err:any) {
            if (String(err?.response?.data)?.includes('ActivationCode is expired')) {
                onStepSkip?.(skipLink);
            } else {
                setError('activationCode', { type: 'custom', message: t('firstLogin.activationCode.wrong.error') });
            }
        }
    }, [language]);

    useEffect(() => {
        try {
            const activationCode = searchParams.get('activationCode');

            if (activationCode) {
                setInitialLoad(true);

                (async () => {
                    verifyCode(activationCode);
                })();
            }
        } catch (err) {
            setInitialLoad(false);
        }
    }, [searchParams, nextLink]);

    // #region Form setup

    const validationSchema = useMemo(
        () => object().shape({
            activationCode: string()
                .required(t('validation.mandatoryField'))
                .min(
                    ACTIVATION_CODE_LENGTH,
                    t('validation.minLegth', { value: ACTIVATION_CODE_LENGTH }),
                )
                .max(
                    ACTIVATION_CODE_LENGTH,
                    t('validation.maxLength', { value: ACTIVATION_CODE_LENGTH }),
                ),
        }),
        [t],
    );

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setError,
    } = useForm<AccountActivationForm>({
        validationSchema,
        options: { defaultValues: { activationCode: storedCode } },
    });

    const code = watch('activationCode');

    const onFormSubmit = handleSubmit(
        async (values: AccountActivationForm) => {
            verifyCode(values.activationCode.toUpperCase());
        },
    );

    // #endregion

    if (inProgress && initalLoad) {
        return (
            <>
                <FirstLoginHeader
                    stepName=""
                    stepTitle=""
                    stepDesc=""
                    stepImage=""
                    isLoading
                />
                <SkeletonLine height={12} width="full" />
                <SkeletonLine height={14} width="full" className="mt-[40px]" />
            </>
        );
    }

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.activationCode.name')}
                stepTitle={t('firstLogin.activationCode.title')}
                stepDesc={t('firstLogin.activationCode.desc')}
                stepImage="/static/img/account_activation.svg"
                imgAlt={t('alt.accountActivation')}
            />
            <form onSubmit={onFormSubmit} className="w-full">
                <div className="justify-self-start w-full text-start mt-2">
                    <Controller
                        control={control}
                        name="activationCode"
                        render={({ field }) => (
                            <TextInput
                                {...field}
                                className="uppercase"
                                wrapperClassName={isCodeStored ? '!opacity-80' : ''}
                                label={t('firstLogin.activationCode.textInput.label')}
                                labelInline
                                disabled={isCodeStored}
                                error={errors.activationCode?.message}
                            />
                        )}
                    />
                </div>
                <Button
                    isSubmit
                    loading={inProgress}
                    disabled={inProgress || !code}
                    onClick={onFormSubmit}
                >
                    {t('firstLogin.button.continue')}
                </Button>
            </form>
        </>
    );
}
