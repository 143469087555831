import React from 'react';
import Table, {
    useTableControl,
    TableFilter,
    createTableState,
    TableEmpty,
} from '@hydrogen/elements.ui.components.table';
import { useFields } from '@hydrogen/elements.data.wealth.app.use-fields';
import { getTableOrders } from '@hydrogen/elements.core.utils.base';
import { getContainer } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { ContentBoxFunctionBar } from '@hydrogen/elements.ui.components.content-box';
import Pagination from '@hydrogen/elements.ui.components.pagination';
import { useInvestmentTransactions } from '@hydrogen/elements.data.wealth.rest.use-investment-transactions';
import { LinkListItem } from '@hydrogen/elements.ui.components.link-list';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';

import { ContentBox } from 'elements/ui/components/content-box';
import { customCells, customMobileCells } from './custom-cells';

export type InvestmentsTransactionsProps = {
    countOfPage?: number,
    dataSource?: typeof useInvestmentTransactions,
    tableSourceKey?: string
};

// TODO: this is copied from hydrogen - rethink if it's needed
// TODO: when BE is fixed, uncomment all commented lines
export function InvestmentTransactions({
    countOfPage = 10,
    tableSourceKey = 'contact-investment-transactions',
    dataSource = getContainer(useInvestmentTransactions),
}: InvestmentsTransactionsProps) {
    const { t, language } = useLanguage();
    const contactId = useContactId();
    const portfolioId = useInvestmentId();

    const { data = [], isLoading: isLoadingColumns } = useFields({
        sourceKey: tableSourceKey,
        language,
        parameters: {
            portfolioId,
            contactId,
        },
    });
    const {
        filters, onChangeFilter, page, setPage, sorting, onSort,
    } = useTableControl();

    // TODO: when BE is fixed and sending the correct values for
    // isMobileView and mobileOrder, remove this code
    const newData = data.map((item, index) => ({
        ...item,
        isMobileView: true,
        mobileOrder: index,
    }));

    const {
        data: transactions = {
            totalCount: 0,
            items: [],
        },
        isLoading, isError,
    } = dataSource({
        skip: (page - 1) * countOfPage,
        take: countOfPage,
        fields: newData.map(({ key }) => key),
        filters,
        orders: getTableOrders(sorting),
        language,
    });

    const tableState = createTableState({
        isLoading,
        columns: newData,
        data: transactions.items,
        isLoadingColumns,
        customCells,
        linkTo: (item) => item.href || `/transaction/details/${item.threadId}`,
        mobileCustomCells: customMobileCells,
        props: {
            skeleton: {
                className: 'min-h-[40px] sm:min-h-[24px]',
            },
        },
    });

    return (
        <ContentBox
            as="section"
            isMainBox
            title={t('investment-transactions.title')}
            variant="tabbed"
        >
            <ContentBoxFunctionBar position="top">
                <TableFilter
                    title={t('investments-transactions.search-title')}
                    filters={filters}
                    onChangeFilter={onChangeFilter}
                    fields={newData}
                    isLoading={isLoading}
                    isDisabled={isError && !filters.length}
                />
            </ContentBoxFunctionBar>
            <Table
                renderListItem={(listItem) => (
                    <LinkListItem>
                        {tableState.mobileItem(listItem)}
                    </LinkListItem>
                )}
                EmptySlot={(
                    <TableEmpty
                        withFilter={!!filters.length}
                        isError={isError}
                    />
                )}
                sorting={sorting}
                onSort={onSort as any} // TODO: fix type, probably due to different versions of lib
                rowKey="threadId"
                isEmpty={!isLoading && !tableState.data.length}
                columns={tableState.columns}
                data={tableState.data}
            />
            <Pagination
                total={transactions.totalCount}
                page={page}
                onChange={setPage}
                pageSize={countOfPage}
            />
        </ContentBox>
    );
}
