import { Outlet } from '@hydrogen/elements.core.router';
import React from 'react';
import { LoginForm } from '../../elements/ui/widgets/login-form';
import { AuthLayout } from '../../elements/ui/layouts/auth-layout';
import { IdentityVerificationForm } from '../../elements/ui/widgets/identity-verification-form';
import { IdentitySmsVerificationForm } from '../../elements/ui/widgets/identity-sms-verfication-form';

export const login = {
    Component: AuthLayout,
    url: '/',
    props: {
        children: <Outlet />,
    },
    children: [
        {
            url: '',
            Component: LoginForm,
            props: {
                links: {
                    nextLink: '/mfa',
                },
            },
        },
        {
            url: 'mfa',
            Component: IdentityVerificationForm,
            props: {
                links: {
                    nextLink: '/overview',
                },
            },
        },
        {
            url: 'auth-verification',
            Component: IdentitySmsVerificationForm,
            props: {
                links: {
                    nextLink: '/overview',
                },
            },
        },
    ],
};
