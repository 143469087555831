import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/outline';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { ContentBoxError } from '@hydrogen/elements.ui.components.content-box';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { ContentBox } from 'elements/ui/components/content-box';
import { v4 as uuidv4 } from 'uuid';

import Tooltip from '@hydrogen/elements.ui.components.tooltip';

export type WithdrawalItemProps = {
    label: string,
    value?: string | number,
    labelClassName?: string,
    valueClassName?: string,
    additionalData?: React.ReactNode
}

type WithdrawalProps = {
    dataSource: () => InvestmentWithdrawalPlanWidget;
}

type InvestmentWithdrawalPlanWidget = {
    data?: Array<WithdrawalItemProps>;
    hasWithdrawal: boolean;
    isError: boolean;
    isLoading: boolean;
    title: string;
    tooltip: string;
}

export const InvestmentWithdrawalPlan = ({
    dataSource,
}: WithdrawalProps) => {
    const { t } = useLanguage();

    const {
        data,
        hasWithdrawal,
        isError,
        isLoading,
        title,
        tooltip,
    } = dataSource();

    const WithdrawalItem = ({
        label,
        value,
        labelClassName = 'inline-block font-medium mb-1',
        valueClassName = 'float-right inline-block text-right text-secondary-300 mb-1',
        additionalData,
    }: WithdrawalItemProps) => (
        <div>
            <div className={labelClassName}>
                {label}
            </div>
            <div className={valueClassName}>
                {value}
            </div>
            {additionalData}
        </div>
    );

    if (!hasWithdrawal) {
        return null;
    }

    return (
        <ContentBox
            as="section"
            isMainBox
            title={(
                <div>
                    {title}
                    <div className="float-right inline-block pt-1">
                        <Tooltip
                            tooltip={(
                                <div
                                    className="text-darkBlue bg-ziagWhite p-3 rounded-2xl rounded-br-none font-medium text-xs max-w-[350px] text-left break-words"
                                >
                                    {tooltip}
                                </div>
                            )}
                            placement="top-end"
                            variant="pure"
                        >
                            <Icon
                                className="w-7 h-7"
                                size={IconsSizes.custom}
                                icon={InformationCircleIcon}
                            />
                        </Tooltip>
                    </div>
                </div>
            )}
            variant="tabbed"
        >
            <GridColumns
                columns={{
                    lg: 1,
                    default: 1,
                }}
                className="gap-y-[1rem] pt-[1rem] pb-[1.75rem] px-[1.5rem] text-base text-primary-500"
            >
                <ContentBoxError isError={isError} errorMessage={t('common.something-wrong')}>
                    <SkeletonWrapper
                        isLoading={isLoading}
                        Skeleton={(
                            <SkeletonLine
                                height={44}
                                width="full"
                            />
                        )}
                    >
                        {data?.map((item) => <WithdrawalItem key={uuidv4()} {...item} />)}
                    </SkeletonWrapper>
                </ContentBoxError>
            </GridColumns>
        </ContentBox>
    );
};
