import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
import Button, { ButtonLayouts, ButtonVariants, ButtonSizes } from '@hydrogen/elements.ui.components.button';

type ButtonProps = {
    isSubmit?: boolean,
    to?: string,
    className?: string,
    title?: string,
    disabled?: boolean,
    loading?: boolean,
    download?: boolean | string,
    size?: ButtonSizes,
    layout?: ButtonLayouts,
    variant?: ButtonVariants,
    as?: React.ElementType
    children?: React.ReactNode
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void
}

// The next classes are not configurable in hydrogen and needed to be overridden
// 1. font sizes and line height
// 2. border radius
// 3. disabled styles
export const SubmitButton = ({
    className,
    disabled,
    size,
    variant,
    ...props
}: ButtonProps) => (
    <Button
        {...props}
        disabled={disabled}
        size={size || ButtonSizes.large}
        variant={variant || ButtonVariants.brand}
        className={classNames(
            className,
            disabled && 'disabled:bg-disabled disabled:border-disabled disabled:text-buttonText disabled:hover:ring-0',
            'w-full md:h-14 h-12 rounded-full !text-[18px] !leading-[24px] font-normal',
        )}
    />
);
