import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { customApi } from '@hydrogen/elements.core.rest.transport';
import { ListPayload, ListResponse } from '@hydrogen/elements.core.contracts';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';

export const useInvestmentPositions = ({
    skip,
    take,
    fields = [],
    filters = [],
    orders = [],
    language = 'en',
}: ListPayload) => {
    const contactId = useContactId();
    const portfolioId = useInvestmentId();
    const latestPositionsObject = useQuery<AxiosResponse<ListResponse>, unknown, ListResponse>(
        ['contact-investment-positions', skip, take, fields, filters, orders, language, portfolioId, contactId],
        () => customApi().post(
            `/v1.0/investment-positions/contacts/${contactId}/search/${language}?portfolioId=${portfolioId}`,
            {
                skip,
                take,
                fields,
                filters,
                orders,
            },
        ),
        {
            enabled: fields?.length > 0 && !!contactId,
            keepPreviousData: true,
            select({ data }) {
                return data;
            },
        },
    );

    return {
        ...latestPositionsObject,
        isLoading:
        latestPositionsObject.isIdle
        || latestPositionsObject.isLoading
        || (latestPositionsObject.isPreviousData && latestPositionsObject.isFetching),
    };
};
