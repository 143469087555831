import React, { useCallback } from 'react';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { ButtonVariants } from '@hydrogen/elements.ui.components.button';
import { Secure } from 'hydrogen/elements.auth.client-portal';
import { FirstLoginHeader } from '../../components/first-login-header';
import { StepPageProps } from '../../../core/contracts';
import { useStepper } from '../../components/stepper/components';
import { Button } from '../../components/button';

export const ChangePasswordSuccess = ({ mainStep, totalSteps }: StepPageProps<any>) => {
    const { t } = useLanguage();
    const { onStepComplete } = useStepper();

    const onButtonClick = useCallback(() => {
        onStepComplete({ nextLink: '/profile', isLast: true });
    }, [onStepComplete]);

    return (
        <Secure>
            <FirstLoginHeader
                stepTitle={t('changePassword.success.title')}
                stepImage="/static/img/check.svg"
                imgAlt={t('alt.roundCheck')}
                imageBelowTitle
                stepName={`${mainStep}/${totalSteps}`}
                stepDesc={t('changePassword.success.desc')}
            />

            <Button
                variant={ButtonVariants.default}
                onClick={onButtonClick}
            >
                {t('changePassword.success.backToProfile')}
            </Button>
        </Secure>
    );
};
