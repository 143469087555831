import { setUiLibraryConfig } from '@hydrogen/elements.ui.configs';
import { Link, NavLink } from '@hydrogen/elements.core.router';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { ProgressBar, setDefaultProgressBar } from '@hydrogen/elements.ui.components.progress-bar';

setDefaultProgressBar(ProgressBar);
setUiLibraryConfig({
    Link,
    NavLink,
    translateFn: useLanguage,
    logo: {
        logoText: 'MyZurichInvest',
    },
    localKeys: {
        NEXT: 'common.next',
        PREVIOUS: 'common.previous',
        SHOWING: 'common.showing',
        TO: 'common.to',
        FROM: 'common.from',
        OF: 'common.of',
        OR: 'common.or',
        CONFIRM: 'common.confirm',
        CANCEL: 'common.cancel',
        AND: 'common.and',
        EQUAL: 'common.equal',
        RESULTS: 'common.results',
        CLOSE: 'common.close',
        APPLY: 'common.apply',
        ADD_FILTER: 'common.add-filter',
        CLEAR_FILTER: 'common.clear-filter',
        ADD_CONDITION: 'common.add-condition',
        TRUE: 'common.true',
        FALSE: 'common.false',
        SELECT_OPTIONS: 'common.select-options',
        REMOVE_CONDITION: 'common.remove-condition',
        SEARCH: 'common.search',
        FAVORITES: 'common.favorites',
        NEW: 'common.new',
        TODAY: 'common.today',
        CLEAR: 'common.clear',
        SOMETHING_WRONG: 'common.something-wrong',
        DATA_BY_FILTER_EMPTY: 'common.data-by-filter-empty',
        DATA_EMPTY: 'common.data-empty',
        N_A: 'common.n-a',
        ALL_DAY: 'common.all-day',
        OPEN: 'common.open',
        FILE_INPUT: 'common.file-input',
        TABLE_FILTER_DESCRIPTION: 'common.table-filter-description',
        STEP: 'common.step',
        SUGGESTED: 'common.suggested',
        INVALID_DATE: 'common.invalid-date',
        STEPS_TO_COMPLETE: 'common.steps-to-complete',
        LAST_STEP: 'common.last-step',
        DOWNLOAD: 'common.download',
        REMOVE: 'common.remove',
        OPTIONAL: 'common.optional',
        CONTACT: 'common.contact',
    },
    form: {
        withDraft: true,
        labelInline: true,
        formPrefix: 'form-store',
        storeType: 'sessionStorage',
    },
});
