import React, { useCallback, useState } from 'react';
import Title from '@hydrogen/elements.ui.components.title';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useNavigate } from '@hydrogen/elements.core.router';
import { OtpField } from '../../components/otp-field';
import { Button, LinkButton } from '../../components/button';
import { Text } from '../../components/text';
import { useAuthenticationData } from '../../../../hydrogen/elements.auth.client-portal';
import { useNotifications } from '../../components/notification';

export const IdentitySmsVerificationForm = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [smsCode, setSmsCode] = useState<string>('');
    const [error, setError] = useState<string | null>();
    const [confirmationInProgress, setConfirmationInProgress] = useState<boolean>(false);
    const [resendInProgress, setResendInProgress] = useState<boolean>(false);
    const { loginSms, sendSmsOtp, authData } = useAuthenticationData();
    const { action } = useNotifications();

    const onVerifyClick = useCallback(async () => {
        try {
            setConfirmationInProgress(true);
            if (smsCode?.length < 6) {
                setError(t('identitySmsVerification.otp.incorrectLength'));

                return;
            }
            await loginSms(smsCode);

            navigate('/access-app/set-device');
        } catch (err: any) {
            setError(t('identitySmsVerification.otp.invalidCode'));
        } finally {
            setConfirmationInProgress(false);
        }
    }, [loginSms, smsCode]);

    const onResendClick = useCallback(async () => {
        try {
            setResendInProgress(true);
            await sendSmsOtp();
            action(t('notifications.newCodeSent'));
        } finally {
            setResendInProgress(false);
        }
    }, [sendSmsOtp]);

    return (
        <div
            className="flex items-center justify-start md:justify-center flex-col text-darkBlue px-[1.75rem] md:px-0 h-full w-full"
        >
            <Title
                className="mt-16 mb-8"
            >
                {t('identityVerification.title')}
            </Title>
            <div className="text-left mb-[30px]">
                <Text
                    variant="desc"
                >
                    {t('identitySmsVerification.notification.intro', { phoneNumber: authData.userInfo.phoneNumber })}
                </Text>
            </div>
            <OtpField
                uppercase
                value={smsCode}
                onChange={(val: string) => setSmsCode(val)}
                onFullFill={() => {
                    setError(null);
                }}
                length={6}
                error={error}
            />
            <Button
                loading={confirmationInProgress}
                disabled={confirmationInProgress || !smsCode}
                onClick={onVerifyClick}
            >
                {t('firstLogin.phoneConfirmation.confirmButton')}
            </Button>
            <LinkButton
                disabled={resendInProgress}
                onClick={onResendClick}
                className="mt-[20px]"
            >
                {t('firstLogin.phoneConfirmation.resendCode')}
            </LinkButton>
            <LinkButton
                disabled={resendInProgress}
                onClick={() => navigate('/')}
                className="mt-[20px]"
            >
                {t('firstLogin.phoneConfirmation.backToLogin')}
            </LinkButton>
        </div>
    );
};
