import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { subscribe } from '@hydrogen/elements.core.event-bus';

import { FirstLoginClientDetails, FirstLoginTopics } from 'elements/core/contracts';
import { container } from '@hydrogen/elements.core.di';
import { useAppTokenValidate } from '../use-app-token-validate';

export type UseTermsAcceptResposne = {
    inProgress: boolean;
    clientData: FirstLoginClientDetails | undefined;
    acceptTerms: (data: AcceptTermsPayload) => Promise<boolean>;
};

export type AcceptTermsPayload = {
    contactIdentityId: string;
    portalTerms: boolean;
};

export function useTermsAccept(): UseTermsAcceptResposne {
    const dataSource = container.get<typeof useAppTokenValidate>('useAppTokenValidate');
    const { checkAppToken } = dataSource();
    const [inProgress, setInProgress] = useState(false);
    const [clientData, setClientData] = useState<FirstLoginClientDetails | undefined>();

    useEffect(() => {
        const unsubscribe = subscribe(FirstLoginTopics.FirstLoginClientDetails, (payload) => {
            setClientData(payload);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const acceptTerms = useCallback(
        async ({ contactIdentityId, portalTerms }: AcceptTermsPayload): Promise<boolean> => {
            if (!portalTerms) {
                return false;
            }
            try {
                setInProgress(true);

                await checkAppToken();

                const result: AxiosResponse<boolean> = await clientServiceApi().post(`/v1.0/Onboarding/${contactIdentityId}/terms-accepted`, {});

                return result.data;
            } catch (err) {
                console.error(err);
                throw err;
            } finally {
                setInProgress(false);
            }
        },
        [],
    );

    return { inProgress, acceptTerms, clientData };
}
