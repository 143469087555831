import React from 'react';

import { container } from '@hydrogen/elements.core.di';
import { Content } from '@hydrogen/elements.ui.layouts.base';

import { useInvestmentTransactions } from 'elements/data/rest/use-investment-transactions';
import { usePortfolioName } from 'elements/data/rest/use-portfolio-name';
import { InvestmentTransactions } from '../investment-transactions/investment-transactions';
import { SubHeader } from '../../components/header/sub-header';

type TransactionsOverviewProps<TDataSource> = {
    dataSource: TDataSource;
};

export function TransactionsOverview({
    dataSource = container.get<typeof useInvestmentTransactions>('useInvestmentTransactions'),
}: TransactionsOverviewProps<typeof useInvestmentTransactions>) {
    const name = usePortfolioName();

    return (
        <div className="flex-1 bg-neutral-100">
            <Content className="!p-0 mx-auto mt-0 mb-10">
                <SubHeader title={name} />
                <InvestmentTransactions dataSource={dataSource} />
            </Content>
        </div>

    );
}
