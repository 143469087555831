import { useParams } from '@hydrogen/elements.core.router';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';
import { useQuery } from 'react-query';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { useLanguage } from '@hydrogen/elements.core.i18n';

export type InvestmentRiskScore = {
    riskIndicator: string,
    risk: number,
    riskPercent: number,
    investmentStrategy: string
}

export const useInvestmentRiskScore = () => {
    const investmentId = useInvestmentId();
    const { language } = useLanguage();
    const contactId = useContactId();
    const { investmentId: fromUrl } = useParams();

    const portfolioId = investmentId ?? fromUrl;

    const query = useQuery<InvestmentRiskScore>(
        ['investment-risk-score', portfolioId, contactId, language],
        () => clientServiceApi().get(`v1.0/Plan/${portfolioId}/riskcategory/${contactId}?language=${language}`).then(({ data }) => data),
        {
            enabled: !!portfolioId && !!contactId,
            keepPreviousData: true,

        },
    );

    return {
        ...query,
        isLoading: query.isIdle || query.isLoading || (query.isPreviousData && query.isFetching),
    };
};
