import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import { ContentBoxError } from '@hydrogen/elements.ui.components.content-box';

import { AccordionSection } from 'elements/ui/components/accordion-section';
import { ContentBox } from 'elements/ui/components/content-box';
import { ContactBlock } from '../../components/contact-block';

export type CardType = {
    // eslint-disable-next-line react/no-unused-prop-types
    id: number,
    // eslint-disable-next-line react/no-unused-prop-types
    city: string,
    // eslint-disable-next-line react/no-unused-prop-types
    email?: string,
    // eslint-disable-next-line react/no-unused-prop-types
    name: string,
    // eslint-disable-next-line react/no-unused-prop-types
    phone?: string,
    // eslint-disable-next-line react/no-unused-prop-types
    street: string,
    // eslint-disable-next-line react/no-unused-prop-types
    title: string,
}

type ContactsProps = {
    cards?: CardType[],
    isLoading?: boolean,
    isError?: boolean,
}

export const Contacts = ({
    cards,
    isLoading = false,
    isError = false,
}: ContactsProps) => {
    const { t } = useLanguage();

    return (
        <ContentBox
            as="section"
            isMainBox
            title={(
                <>
                    {t('contacts.title')}
                    <p className="mt-1 text-base">{t('contacts.subtitle')}</p>
                </>
            )}
            variant="tabbed"
        >
            <GridColumns
                columns={{ default: 1 }}
                className="pt-[1rem] pb-[1.75rem] px-[1.5rem] text-base text-primary-500"
            >
                <SkeletonWrapper
                    isLoading={isLoading}
                    Skeleton={(
                        <SkeletonLine
                            height={60}
                            width="full"
                        />
                    )}
                >
                    <ContentBoxError
                        isError={isError}
                        errorMessage={(<>{t('common.something-wrong')}</>)}
                    >
                        <GridColumns columns={{ default: 1 }} gap={4}>
                            {cards?.map(({
                                id, title, name, ...props
                            }: CardType) => (
                                <div key={uuidv4()}>
                                    <AccordionSection title={title} opened={id === 1}>
                                        <ContactBlock {...props} name={name} />
                                    </AccordionSection>
                                </div>
                            ))}
                        </GridColumns>
                    </ContentBoxError>
                </SkeletonWrapper>
            </GridColumns>
        </ContentBox>
    );
};
