import React from 'react';
import { container } from '@hydrogen/elements.core.di';
import { ReEnrollDeviceScreenType, ReEnrollDeviceStepsEnum } from './types';
import { ReEnrollDeviceSuccess } from '../re-enroll-device-success';
import { ReEnrollDeviceConfirm } from '../re-enroll-device-confirm';
import { MfaSetup } from '../mfa-setup';
import { useReEnrollDevice } from '../../../data/rest/use-re-enroll-device';
import { MfaAppSetup } from '../mfa-app-setup';
import { Secure } from '../../../../hydrogen/elements.auth.client-portal';

export const ReEnrollDeviceScreen: ReEnrollDeviceScreenType = {
    [ReEnrollDeviceStepsEnum.ConfirmReEnroll]: (props) => (
        <ReEnrollDeviceConfirm {...props} />
    ),
    [ReEnrollDeviceStepsEnum.AppSetup]: (props) => (
        <Secure><MfaAppSetup {...props} /></Secure>
    ),
    [ReEnrollDeviceStepsEnum.ReEnroll]: (props) => (
        <MfaSetup {...props} dataSource={container.get<typeof useReEnrollDevice>('useReEnrollDevice')} isSecure />
    ),
    [ReEnrollDeviceStepsEnum.Success]: (props) => (
        <ReEnrollDeviceSuccess {...props} />
    ),
};
