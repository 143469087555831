import React from 'react';

import { formatDate, useLanguage } from '@hydrogen/elements.core.i18n';
import { container } from '@hydrogen/elements.core.di';
import { Content } from '@hydrogen/elements.ui.layouts.base';
import GridColumns from '@hydrogen/elements.ui.components.grid';

import { useAccountDetails } from 'elements/data/rest/use-account-details';
import { SubHeader } from 'elements/ui/components/header/sub-header';
import { SkeletonLine } from '@hydrogen/elements.ui.components.skeleton';
import { useParams } from '@hydrogen/elements.core.router';
import { DataTile } from '../../components/data-tile';
import { ContentBox } from '../../components/content-box';
import { NA } from '../../../../common/constants';
import { ContactBlock } from '../../components/contact-block';
import { useAgentAgencyInfo } from '../../../data/rest/use-agent-agency-info';
import { useInvestmentDetails } from '../../../data/rest/use-investment-details/use-investment-details';

export function AccountDetails() {
    const { t } = useLanguage();
    const { data: investmentDetails, isLoading: isInvestmentDetailsLoading } = useInvestmentDetails();
    const dataSet = container.get<typeof useAccountDetails>('useAccountDetails');
    const agentAgencyDataSet = container.get<typeof useAgentAgencyInfo>('useAgentAgencyInfo');
    const { investmentId } = useParams();

    const { data, isLoading } = dataSet();
    const { data: agentAgencyInfo, isLoading: isAgentInfoLoading } = agentAgencyDataSet();

    const contactsData = {
        phone: agentAgencyInfo?.agentInformation?.phone,
        email: agentAgencyInfo?.agentInformation?.email,
        name: `${agentAgencyInfo?.agentInformation?.firstName || ''} ${agentAgencyInfo?.agentInformation?.lastName || ''}`,
        street: agentAgencyInfo?.agentInformation?.address || '-',
        city: `${agentAgencyInfo?.agentInformation?.postalCode || ''} ${agentAgencyInfo?.agentInformation?.city || ''}`,
    };

    return (
        <div className="flex-1 bg-white">
            <Content className="max-w-[75rem] !p-0 mx-auto mt-0 lg:mb-16 mb-10">
                <SubHeader title={t('accountDetails.title')} />
                <GridColumns className="max-w-[53.625rem] mx-auto lg:mt-4 px-[1rem]" gap={8} columns={1}>
                    <div>
                        <p className="mb-4 text-[1.125rem] leading-[1.5rem] font-medium text-primary-500">
                            {isInvestmentDetailsLoading
                                ? (
                                    <SkeletonLine
                                        height={6}
                                        width={24}
                                    />
                                ) : investmentDetails?.portfolioName ?? `${t('positions.portfolio', 'Portfolio')} ${investmentId}`}
                        </p>
                        <GridColumns className="gap-0.5 rounded-lg overflow-hidden" columns={{ lg: 2, default: 1 }}>
                            <DataTile isLoading={isLoading} title={t('accountDetails.name')} value={data?.accountName} />
                            <DataTile
                                isLoading={isLoading}
                                title={t('accountDetails.referenceNumber')}
                                value={data?.lpzPlanId || NA}
                            />
                            <DataTile isLoading={isLoading} title={t('accountDetails.IBAN')} value={data?.iban || NA} />
                            <DataTile
                                isLoading={isLoading}
                                title={t('accountDetails.openingDate')}
                                value={data?.openingDate ? formatDate(new Date(data.openingDate), 'dd.MM.yyyy') : NA}
                            />
                        </GridColumns>
                    </div>
                    <ContentBox className="lg:mb-10 p-4" as="section" noHeader variant="tabbed">
                        <p className="mb-4 text-primary-500">{t('accountDetails.supportInfo')}</p>
                        <ContactBlock {...contactsData} isLoading={isAgentInfoLoading} />
                    </ContentBox>
                </GridColumns>
            </Content>
        </div>
    );
}
