import React, { useCallback, useMemo } from 'react';

import GridColumns from '@hydrogen/elements.ui.components.grid';
import { classNames } from '@hydrogen/elements.ui.utils';

import { getNavLink } from '@hydrogen/elements.ui.configs';
import { ContentBox } from 'elements/ui/components/content-box';
import { CardType } from '../../../data/adapters/use-with-investments-list-widget/use-with-investments-list-widget';
import { Image } from '../../components/image';

type InvestmentOverviewProps = {
    className?: string,
    data?: CardType,
    isImplemented?: boolean
}

export const InvestmentTile = ({
    className,
    data,
    isImplemented,
}: InvestmentOverviewProps) => {
    const NavLink = getNavLink();

    const WrapperComponent = useCallback(
        ({ children }) => (
            isImplemented && data?.href
                ? (
                    <NavLink
                        to={data?.href}
                        className="block mt-1 text-[1.125rem] leading-[1.5rem] !text-primary-500 hover:no-underline"
                    >
                        {children}
                    </NavLink>
                )
                : (<>{children}</>)
        ),
        [data?.href, isImplemented],
    );

    return (
        <WrapperComponent>
            <ContentBox as="section" noHeader>
                <GridColumns
                    columns={{
                        lg: 1,
                        default: 1,
                    }}
                    className={classNames(className, 'min-h-[190px] py-[1.1875rem] px-[1rem] rounded-lg text-primary-500 text-base')}
                >
                    <div className="min-h-[88px] flex justify-between">
                        <div>
                            <b className="font-medium">
                                {data?.title}
                            </b>
                            {
                                (
                                    data?.subTitle && data?.subTitle?.map((item) => (
                                        <p>{item}</p>
                                    ))
                                )
                            }
                            {
                                (
                                    data?.subSubtitle && data?.subSubtitle?.map((item) => (
                                        <p className="text-xs font-medium">{item}</p>
                                    ))
                                )
                            }
                        </div>
                        <div>
                            {
                                data?.isJAIconVisible && (
                                    <Image
                                        src="/static/img/joint-account.svg"
                                        alt="Joint Accouunt Icon"
                                        className="rounded-tl-[17px] rounded-tr-[17px] w-[18px] h-[18px]"
                                    />
                                )
                            }
                        </div>
                    </div>

                    <div className="flex flex-row items-end justify-between">

                        <div className="flex-col w-[2.0625rem] h-[1.5rem] text-accent-200">
                            {isImplemented && <i className="icon icon-line-chart text-[1.5rem]" />}
                        </div>
                        <div className="flex-col text-right">
                            <b className="font-medium">
                                {
                                    data?.performance
                                }
                            </b>
                            <p className="text-[1.125rem] leading-[1.25rem]">
                                {data?.value}
                            </p>
                        </div>
                    </div>
                </GridColumns>
            </ContentBox>
        </WrapperComponent>
    );
};
