import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { ButtonVariants } from '@hydrogen/elements.ui.components.button';

import { ContentBox } from 'elements/ui/components/content-box';
import { Button } from 'elements/ui/components/button';

export const AccountInfo = () => {
    const { t } = useLanguage();
    const navigate = useNavigate();

    return (
        <ContentBox as="section" isMainBox title={t('accountInfo.title')} variant="tabbed">
            <GridColumns
                columns={{ lg: 1 }}
                className="gap-y-[1rem] pt-[1rem] pb-[1.75rem] px-[1.5rem] text-base text-primary-500"
            >
                <p>
                    {t('accountInfo.text')}
                </p>
                <Button className="h-12" variant={ButtonVariants.default} onClick={() => navigate('account-details')}>{t('accountInfo.button')}</Button>
            </GridColumns>
        </ContentBox>
    );
};
