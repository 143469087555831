import React, { useCallback } from 'react';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { ButtonVariants } from '@hydrogen/elements.ui.components.button';
import { Secure } from 'hydrogen/elements.auth.client-portal';
import { FirstLoginHeader } from '../../components/first-login-header';
import { StepPageProps } from '../../../core/contracts';
import { useStepper } from '../../components/stepper/components';
import { Button } from '../../components/button';

export const ReEnrollDeviceConfirm = ({ mainStep, totalSteps, links: { nextLink, prevLink } }: StepPageProps<any>) => {
    const { t } = useLanguage();
    const { onStepComplete } = useStepper();

    const onButtonClick = useCallback(() => {
        onStepComplete({ nextLink, prevLink });
    }, [onStepComplete]);

    return (
        <Secure>
            <FirstLoginHeader
                stepTitle={t('reEnrollDevice.confirm.title')}
                stepImage="/static/img/terms-locked-pc.svg"
                imgAlt={t('alt.roundCheck')}
                imageBelowTitle
                stepName={`${mainStep}/${totalSteps}`}
                stepDesc={t('reEnrollDevice.confirm.desc')}
            />

            <Button
                variant={ButtonVariants.brand}
                onClick={onButtonClick}
            >
                {t('reEnrollDevice.confirm.proceed')}
            </Button>
        </Secure>
    );
};
