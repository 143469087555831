import React from 'react';

import { AccountActivation } from 'elements/ui/widgets/account-activation';
import { EmailConfirmation, EmailForm } from 'elements/ui/widgets/email-confirmation';
import { PhoneConfirmation, PhoneForm } from 'elements/ui/widgets/phone-confirmation';
import { TermsAndConditions } from 'elements/ui/widgets/terms-and-conditions';
import { PasswordSetup } from 'elements/ui/widgets/password-setup';
import { MfaAppSetup } from 'elements/ui/widgets/mfa-app-setup';
import { MfaSetup } from 'elements/ui/widgets/mfa-setup';
import { AccountActivationExpired } from 'elements/ui/widgets/account-activation-expired';
import { FirstLoginWelcome } from 'elements/ui/widgets/first-login-welcome';

import { FirstLoginScreenType, FirstLoginStepsEnum } from './types';

export const FirstLoginScreen: FirstLoginScreenType = {
    [FirstLoginStepsEnum.AccountActivation]: (props) => (
        <AccountActivation {...props} />
    ),
    [FirstLoginStepsEnum.AccountActivationExpired]: () => (
        <AccountActivationExpired />
    ),
    [FirstLoginStepsEnum.EmailConfirmation]: (props) => (
        <EmailConfirmation {...props} />
    ),
    [FirstLoginStepsEnum.EmailForm]: (props) => <EmailForm {...props} />,
    [FirstLoginStepsEnum.PhoneConfirmation]: (props) => (
        <PhoneConfirmation {...props} />
    ),
    [FirstLoginStepsEnum.PhoneForm]: (props) => <PhoneForm {...props} />,
    [FirstLoginStepsEnum.TermsAndConditions]: (props) => (
        <TermsAndConditions {...props} />
    ),
    [FirstLoginStepsEnum.PasswordSet]: (props) => <PasswordSetup {...props} />,
    [FirstLoginStepsEnum.Setup2FA1]: (props) => <MfaAppSetup {...props} />,
    [FirstLoginStepsEnum.Setup2FA2]: (props) => <MfaSetup {...props} />,
    [FirstLoginStepsEnum.Finish]: (props) => <FirstLoginWelcome {...props} />,
};
