import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { classNames } from '@hydrogen/elements.ui.utils';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';

import { ContentBox } from 'elements/ui/components/content-box';
import { container } from '@hydrogen/elements.core.di';
import { useTranslation } from 'react-i18next';
import { useInvestmentRiskScore } from '../../../data/rest/use-investment-risk-score/use-investment-risk-score';

const colorsMap = [
    {
        style: 'bg-various-blue',
        value: 0,
    },
    {
        style: 'bg-various-sky',
        value: 1,
    },
    {
        style: 'bg-various-yellow',
        value: 2,
    },
    {
        style: 'bg-various-orange',
        value: 3,
    },
    {
        style: 'bg-various-red',
        value: 4,
    },
];

export const InvestmentRisk = ({ dataSource = container.get<typeof useInvestmentRiskScore>('useInvestmentRiskScore') }) => {
    const { t } = useTranslation();
    const { data, isLoading } = dataSource();

    const riskScore = data?.risk || 0;

    return (
        <ContentBox as="section" isMainBox title={t('investmentRisk.title')} variant="tabbed">
            <GridColumns
                columns={{
                    sm: 1,
                    default: 1,
                }}
                className="gap-y-[0.25rem] pt-[1rem] pb-[1.75rem] px-[1.5rem] text-base text-primary-500"
            >
                <SkeletonWrapper
                    isLoading={isLoading}
                    Skeleton={(
                        <SkeletonLine
                            height={20}
                            width="full"
                        />
                    )}
                >
                    <b>
                        {data?.investmentStrategy}
                    </b>
                    <div className="flex flex-row justify-between text-secondary-300">
                        <span>{t('investmentRisk.low')}</span>
                        <span>{t('investmentRisk.medium')}</span>
                        <span>{t('investmentRisk.high')}</span>
                    </div>
                    <GridColumns
                        columns={{ default: 5 }}
                        className="gap-x-[0.25rem] w-full items-end"
                    >
                        {
                            colorsMap.map(({ style, value }) => (
                                <div
                                    key={uuidv4()}
                                    className={classNames(
                                        style,
                                        riskScore === value ? 'h-[1.5rem]' : 'h-[0.9375rem]',
                                        'rounded-[0.25rem]',
                                    )}
                                />
                            ))
                        }
                    </GridColumns>
                </SkeletonWrapper>
            </GridColumns>
        </ContentBox>
    );
};
