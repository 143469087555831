import React from 'react';

import { classNames } from '@hydrogen/elements.ui.utils';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { LinkContact } from '../link-contact';

export type ContactTileProps = {
    contactName: string,
    openingHours: string,
    email?: string,
    className?: string;
    phoneNumber1?: string;
    phoneNumber2?: string;
};

export const ContactTile = ({
    contactName,
    className,
    email,
    openingHours,
    phoneNumber1,
    phoneNumber2,
}: ContactTileProps) => (
    <GridColumns columns={1} gap={3} className={classNames(className, 'p-[1rem] rounded-lg border border-absolute-1000 text-base')}>
        <p className="flex align-middle text-[1.125rem] text-primary-500">
            <i className="icon icon-help inline-block text-[1.2rem] mr-3 text-[1.5rem]" />
            {contactName}
        </p>
        <p className="font-medium text-primary-500">{openingHours}</p>
        { phoneNumber1 && <LinkContact value={phoneNumber1} /> }
        { phoneNumber2 && <LinkContact value={phoneNumber2} /> }
        { email && <LinkContact value={email} variant="email" /> }
    </GridColumns>
);
