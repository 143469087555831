import React, { useState } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import Grid from '@hydrogen/elements.ui.components.grid';

import { Text } from 'elements/ui/components/text';

export type ContactInfoProps = {
    phoneNumber?: string;
    className?: string;
    expanded?: boolean;
};

const PHONE_NUMBER = '+41 (0)44 628 08 00';

export function ContactInfo({
    phoneNumber = PHONE_NUMBER,
    className,
    expanded = false,
}: ContactInfoProps) {
    const [visible, setVisible] = useState<boolean>(expanded);
    const { t } = useLanguage();

    return (
        <div className={`md:mb-6 mt-6 md:mt-20 md:border-none mx-[-1.75rem] border-b-[0.0625rem] border-content-background-hover flex flex-col justify-end ${
            className ?? ''
        }`}
        >
            <div
                className="flex justify-center w-full md:hidden hover:cursor-pointer"
                onClick={() => setVisible(!visible)}
                onKeyDown={(event) => {
                    if (event.key === 'h') {
                        setVisible(!visible);
                    }
                }}
                role="button"
                tabIndex={0}
            >
                <div
                    className="bg-content-background-hover px-8 py-1.5 flex items-center rounded-tl-[2.5rem] rounded-tr-[2.5rem] justify-center text-secondary-300 font-medium"
                >
                    <i className="icon icon-phone text-[1.25rem]" />
                    <Text className="pl-1 text-xs">{t('contactInfo.help')}</Text>
                </div>
            </div>
            <div
                className={`${visible ? 'block' : 'hidden'}  md:block md:border bg-content-background-hover md:bg-white px-[3.75rem] md:px-[1.9rem] py-[2rem] md:py-6 md:rounded-2xl border-dove -ml-[1.875rem] md:ml-0 !w-[calc(100%_+_60px)] md:!w-full`}
            >
                <h1 className="text-darkBlue text-[1rem] mb-5 md:mb-6 font-medium">
                    {t('contactInfo.title')}
                </h1>
                <Text className="text-darkBlue">{t('contactInfo.info')}</Text>
                <Grid
                    columns={2}
                    gap={3}
                    className="grid-cols-phoneWithIcon w-auto items-center text-secondary-300 mt-4"
                >
                    <i className="icon icon-phone text-[1.5rem]" />
                    <Text><a className="hover:underline" href={`tel:${phoneNumber}`}>{phoneNumber}</a></Text>
                </Grid>
            </div>
        </div>
    );
}
