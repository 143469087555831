export const prestige = {
    font: {
        header: 'Zurich Sans',
        default: 'Zurich Sans',
    },
    fontWeight: {
        light: '300',
        semibold: '600',
        header: 'light',
    },
    border: {
        'radius-input': '999rem',
    },
    boxShadow: {
        header: '0 none',
        content: '0 none',
    },
    gridTemplateColumns: {
        phoneWithIcon: '18px 1fr',
    },
    colors: {
        'intro-content-background': '182 198 232',
        'body-background': 'white',
        'content-background': 'white',
        'content-background-hover': '236 238 239',
        white: '255 255 255',
        'content-border': '223 227 228',
        'content-separator': 'accent-500',
        'header-background': 'white',
        'header-border': '223 227 228',
        'header-text': '72 112 198',
        'header-link': '33 103 174',
        'header-link-hover': 'primary-500',
        'header-link-active': 'header-link-hover',
        'header-text-hover-active': 'primary-500',
        'header-text-hover': 'primary-500',
        'header-text-active': 'primary-500',
        'sidebar-background': 'header-background',
        'sidebar-border': 'header-border',
        'sidebar-shadow': 'header-shadow',
        'sidebar-text': 'primary-500',
        'sidebar-category': 'secondary-500',
        'sidebar-link': 'primary-500',
        'sidebar-link-hover': 'primary-500',
        'sidebar-link-active': 'brand-700',
        'sidebar-background-active': 'brand-200',
        'sidebar-background-hover': 'neutral-100',
        'sidebar-badge-background': 'accent-200',
        'sidebar-badge-text': 'accent-700',
        'sidebar-badge-inverted-background': 'brand-700',
        'sidebar-badge-inverted-text': 'brand-200',
        'footer-background': '255 255 255',
        'footer-border': '223 227 228',
        'footer-text': '35 54 111',
        'footer-text-hover': 'absolute-200',
        'footer-text-active': 'footer-text-hover',
        'footer-appname': '35 54 111',
        'tabbar-background': 'content-background',
        'tabbar-border': 'tabbar-background',
        'tabbar-text': '33 103 174',
        'tabbar-link': '33 103 174',
        'tabbar-link-hover': 'primary-500',
        'tabbar-link-active': 'primary-500',
        'button-header-link': '33 103 174',
        'button-header-background': '255 255 255',
        'button-header-background-hover': 'button-header-background',
        'button-header-border': '255 255 255',
        'button-header-border-hover': 'button-header-border',
        'button-header-ring': '33 103 174',
        'button-sidebar-link': 'button-header-link',
        'button-sidebar-background': 'button-header-background',
        'button-sidebar-background-hover': 'button-header-background-hover',
        'button-sidebar-border': 'button-header-border',
        'button-sidebar-border-hover': 'button-header-border',
        'button-sidebar-ring': 'button-header-ring',
        'button-sidebar-shadow': 'button-header-shadow',
        'button-brand-background': '33 103 174',
        'button-brand-background-hover': 'button-brand-background',
        'button-brand-text': 'on-brand',
        'button-brand-border': 'button-brand-background',
        'button-brand-border-hover': 'button-brand-border',
        'button-brand-ring': 'brand-300',
        'button-default-background': 'white',
        'button-default-background-hover': 'button-default-background',
        'button-default-text': 'secondary-300',
        'button-default-border': 'secondary-300',
        'button-default-border-hover': 'button-default-border',
        'button-default-ring': 'accent-500',
        'button-accent-background': '72 112 198',
        'button-accent-background-hover': 'button-accent-background',
        'button-accent-text': '255 255 255',
        'button-accent-border': 'button-accent-background',
        'button-accent-border-hover': 'button-accent-border',
        'button-accent-ring': 'accent-300',
        'button-alternative-background': 'button-accent-background',
        'button-alternative-background-hover': 'button-accent-background-hover',
        'button-alternative-text': 'button-accent-text',
        'button-alternative-border': 'button-accent-border',
        'button-alternative-border-hover': 'button-accent-border-hover',
        'button-alternative-ring': 'button-accent-ring',
        'button-header-text': 'primary-500',
        'button-cta-background': 'primary-500',
        'button-cta-text': 'absolute-0',
        'button-cta-border': 'button-cta-background',
        'button-cta-ring': 'accent-300',
        'functionbar-background': 'neutral-50',
        'functionbar-border': 'accent-500',
        'tooltip-background': 'primary-500',
        'tooltip-text': 'content-background',
        'input-border-color': 'accent-500',
        focus: 'brand-300',
        'link-idle': '33 103 174',
        'link-hover': 'brand-700',
        'link-active': 'primary-500',
        'on-brand': 'absolute-0',
        'brand-200': '225 232 255',
        'brand-300': '192 210 255',
        'brand-500': '93 97 98',
        'brand-700': '45 65 166',
        'brand-overlay': 'primary-500',
        'on-accent': 'absolute-0',
        'accent-200': '72 112 198',
        'accent-300': '145 169 221',
        'accent-500': '236 238 239',
        'accent-700': '228 233 236',
        'on-primary': 'absolute-0',
        'primary-200': '145 191 227',
        'primary-300': 'white',
        'primary-500': '35 54 111',
        'primary-700': '182 198 232',
        'secondary-200': '245 246 250',
        'secondary-300': '33 103 174',
        'secondary-500': '105 113 130',
        'secondary-700': '201 208 210',
        'danger-200': '203 75 64',
        'danger-500': '203 75 64',
        'danger-700': 'white',
        'on-danger': 'white',
        'warn-200': '255 222 102',
        'warn-500': '249 175 22',
        'warn-700': 'white',
        'on-warn': 'white',
        'success-200': '66 131 81',
        'success-500': '9 133 88',
        'success-700': 'white',
        'on-success': 'white',
        'info-200': '199 223 252',
        'info-500': '88 155 237',
        'info-700': '10 70 143',
        'neutral-50': '249 250 254',
        'neutral-100': '245 246 252',
        'neutral-200': '218 226 244',
        'neutral-300': '190 197 212',
        'absolute-0': '255 255 255',
        'absolute-100': '245 246 250',
        'absolute-200': '228 232 238',
        'absolute-300': '190 197 208',
        'absolute-500': '37 40 46',
        'absolute-700': '20 21 25',
        'absolute-800': '13 17 22',
        'absolute-1000': '221 228 227',
        black: '0 0 0',
        'various-gray': '247 248 249',
        'various-red': '255 117 105',
        'various-orange': '255 134 64',
        'various-amber': '245 158 11',
        'various-yellow': '255 222 102',
        'various-lime': '152 224 29',
        'various-green': '34 197 94',
        'various-emerald': '16 185 129',
        'various-teal': '20 184 166',
        'various-cyan': '6 182 212',
        'various-sky': '145 200 242',
        'various-blue': '20 41 102',
        'various-indigo': '99 102 241',
        'various-violet': '139 92 246',
        'various-purple': '168 85 247',
        'various-fuchsia': '217 70 239',
        'various-pink': '236 72 153',
        'various-rose': '244 63 94',
        'fill-text': '116 121 122',
        'fill-front': 'primary-500',
        'fill-back': 'accent-300',
        'logo-text': '33 103 174',
        'logo-front': '33 103 174',
        'logo-back': 'accent-300',
        'chart-color-1': '84 149 207',
        'chart-color-2': '33 103 174',
        'chart-color-3': 'various-sky',
        'chart-color-4': '146 186 157',
        'chart-color-5': '202 242 205',
        'chart-color-6': '71 200 197',
        'chart-color-7': 'various-teal',
        'chart-color-8': 'various-yellow',
        'chart-color-9': 'various-fuchsia',
        'chart-color-10': 'various-orange',
        'chart-color-11': 'various-red',
        'chart-color-12': '15 103 191',
    },
};
