import { Dispatch } from 'react';

export type UserInfoType = {
    contactId: number | null,
    userName: string | null,
    isActive: boolean | null,
    isTwoFactorEnabled: boolean | null
    otpLength?: number | null
    firstName?: string | null
    lastName?: string | null
    fullName?: string | null
    phoneNumber?: string | null
}
export type AuthDataType = {
    appToken: string | null,
    isAuthed: boolean,
    userInfo: UserInfoType,
    accessToken: string | null,
    jwt: string | null
    preAuthenticateSessionId: string | null
}
export type StoreType = 'localStorage' | 'sessionStorage'

export type LinksConfigType = {
    login?: string,
    loginMtan?: string,
    loginSms?: string,
    forgotPassword?: string,
    forgotPasswordMtan?: string,
}

export type AuthenticationContextType = {
    authData: AuthDataType & Partial<AuthDataType>,
    login: (loginData: LoginBasePayload) => Promise<AuthDataType>
    logout: () => Promise<void>
    resetAuthData: () => void
    loginMtan: (passcode: string) => Promise<void>,
    loginSms: (key: string) => Promise<void>,
    sendSmsOtp: () => Promise<void>,
    forgotPassword: (username: string) => Promise<string | undefined>,
    forgotPasswordMtan: (passcode: string) => Promise<any>,
    resetPassword: (password: string, key: string) => Promise<any>,
    changePassword: (password: string, contactId: number) => Promise<any>,
    linksAction: { [key in keyof Required<LinksConfigType>]: () => void }
    mfaAuthError: boolean,
    setMfaAuthError: (value: boolean) => void,
    dispatchAuthData: Dispatch<Partial<AuthDataType>>
}

export interface StateStore {
    set(value: AuthDataType): void;

    get(): AuthDataType | null;

}

export enum TOKEN_TYPES {
    APP_TOKEN = 1,
    ACCESS_TOKEN = 2
}

export type LoginBasePayload = {
    username: string
    password: string
}

export type PreAuthPayload = LoginBasePayload & {
    trustedDeviceToken: string
    forceExpiringPreviousSession?: boolean
}

export type AuthPayload = {
    passcode: string
    preAuthenticateSessionId?: string | null
    setTrusted?: boolean
}

export type AuthStatusPayload = {
    userName: string | null,
    authStatusSessionId: string | null
}

export type SmsAuthenticatePayload = {
    clientId: number
}

export type VerifySmsCodePayload = {
    clientId: number,
    key: string
}

export type GetTokenPayload = {
    type: TOKEN_TYPES,
    accessToken?: string,
}

export type ValidateTokenPayload = {
    accessToken: string
    appToken: string
}
export type LogoutPayload = {
    contactsId: number | string,
    accessToken: string
}

export type UserDetailsPayload = {
    contactId: number | null,
    accessToken: string
}

export enum AuthErrors {
    CredentialsInvalid = 'CredentialsInvalid',
    PasscodeInvalid = 'PasscodeInvalid',
    SessionExpired = 'SessionExpired',
    SessionInvalid = 'SessionInvalid',
    MTanVerificationFailed = 'MTanVerificationFailed',
    PasswordPolicyValidationFailed = 'PasswordPolicyValidationFailed',
    PasswordSameAlreadySet = 'PasswordSameAlreadySet',
    ActivationKeyInvalid = 'ActivationKeyInvalid',
    OldPasswordInvalid = 'OldPasswordInvalid',
    AccessDenied = 'AccessDenied',
    AccessTokenInvalid = 'AccessTokenInvalid',
    ApplicationNotEnrolled = 'ApplicationNotEnrolled',
    ApplicationMultipleEnrolled = 'ApplicationMultipleEnrolled',
    AuditContextIdInvalid = 'AuditContextIdInvalid',
    ConfirmPasswordInvalid = 'ConfirmPasswordInvalid',
    ConfirmPasswordNotMatched = 'ConfirmPasswordNotMatched',
    ContactExternalInvalid = 'ContactExternalInvalid',
    ContactIdInvalid = 'ContactIdInvalid',
    ContactLocked = 'ContactLocked',
    ContactAlreadyUnlocked = 'ContactAlreadyUnlocked',
    ContactNotActivated = 'ContactNotActivated',
    ContactNotPasswordSet = 'ContactNotPasswordSet',
    DeviceAlreadyEnrolled = 'DeviceAlreadyEnrolled',
    DeviceMultipleEnrolled = 'DeviceMultipleEnrolled',
    DeviceNotEnrolled = 'DeviceNotEnrolled',
    EmailInvalid = 'EmailInvalid',
    EmailMultipleContacts = 'EmailMultipleContacts',
    EmailNotSet = 'EmailNotSet',
    Failed = 'Failed',
    IdentityIdInvalid = 'IdentityIdInvalid',
    IdentityProviderInvalid = 'IdentityProviderInvalid',
    JwtTokenExpired = 'JwtTokenExpired',
    JwtTokenInvalid = 'JwtTokenInvalid',
    MemberIdInvalid = 'MemberIdInvalid',
    MobileInvalid = 'MobileInvalid',
    MobileMultipleContacts = 'MobileMultipleContacts',
    MTanInvalid = 'MTanInvalid',
    PasswordSetActive = 'PasswordSetActive',
    PasswordInvalid = 'PasswordInvalid',
    SessionAlreadyActive = 'SessionAlreadyActive',
    TwoFactorDisabled = 'TwoFactorDisabled',
    UsernameInvalid = 'UsernameInvalid',
    UsernameAndEmailInvalid = 'UsernameAndEmailInvalid',
    Waiting = 'Waiting',
    NewPasswordInvalid = 'NewPasswordInvalid',
}

export type AuthErrorResponseType = {
    Result?: AuthErrors | string & { [key: string]: any },
    [key: string]: any
} & unknown
