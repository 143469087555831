export const getSteps = (stepsCount: number = 0): number[] => (
    Array(stepsCount)
        .fill(1)
        .map((step, index) => step + index)
);

export const parsePath = (path = '') => (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    path?.replaceAll?.('*', '') || ''
);
