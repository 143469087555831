import { StepsType } from 'elements/core/contracts';

import { ReEnrollDeviceScreen } from './re-enroll-device-screen';
import { ReEnrollDeviceStepsEnum } from './types';

const apiData: Array<StepsType> = [
    {
        url: '',
        links: {
            nextLink: '/app-setup',
            prevLink: '',
        },
        stepKey: ReEnrollDeviceStepsEnum.ConfirmReEnroll,
        Component: ReEnrollDeviceScreen[ReEnrollDeviceStepsEnum.ConfirmReEnroll],
        mainStep: 1,
        isMain: true,
        mainStepTitle: (t) => t('first-login.account-activation'),
    },
    {
        url: '/app-setup',
        links: {
            nextLink: '/set-device',
            prevLink: '',
        },
        stepKey: ReEnrollDeviceStepsEnum.AppSetup,
        Component: ReEnrollDeviceScreen[ReEnrollDeviceStepsEnum.AppSetup],
        mainStep: 2,
        isMain: true,
        mainStepTitle: (t) => t('first-login.account-activation'),
    },
    {
        url: '/set-device',
        links: {
            nextLink: '/success',
            prevLink: '/app-setup',
        },
        stepKey: ReEnrollDeviceStepsEnum.ReEnroll,
        Component: ReEnrollDeviceScreen[ReEnrollDeviceStepsEnum.ReEnroll],
        mainStep: 3,
        isMain: true,
        mainStepTitle: (t) => t('first-login.account-activation'),
    },
    {
        url: '/success',
        links: {
            prevLink: '',
        },
        stepKey: ReEnrollDeviceStepsEnum.Success,
        Component: ReEnrollDeviceScreen[ReEnrollDeviceStepsEnum.Success],
        mainStep: 4,
        isMain: true,
        mainStepTitle: (t) => t('first-login.account-activation'),
    },
];

export const fakeApi = () => new Promise((resolve) => {
    resolve(apiData);
});
