import { StepsType } from 'elements/core/contracts';

import { FirstLoginScreen } from './first-login-screen';
import { FirstLoginStepsEnum } from './types';

const apiData: Array<StepsType> = [
    {
        url: '/code-verification',
        links: {
            nextLink: '/email-confirmation',
            skipLink: '/code-expired',
        },
        stepKey: FirstLoginStepsEnum.AccountActivation,
        Component: FirstLoginScreen[FirstLoginStepsEnum.AccountActivation],
        mainStep: 1,
        isMain: true,
        mainStepTitle: (t) => t('first-login.account-activation'),
    },
    {
        url: '/code-expired',
        links: {
            prevLink: '/code-verification',
        },
        expandContactInfo: true,
        stepKey: FirstLoginStepsEnum.AccountActivationExpired,
        Component: FirstLoginScreen[FirstLoginStepsEnum.AccountActivationExpired],
        mainStep: 1,
        isMain: true,
        mainStepTitle: (t) => t('first-login.account-activation'),
    },
    {
        url: '/email-confirmation',
        links: {
            nextLink: '/phone-confirmation',
            skipLink: '/email-form',
            prevLink: '/code-verification',
        },
        stepKey: FirstLoginStepsEnum.EmailConfirmation,
        Component: FirstLoginScreen[FirstLoginStepsEnum.EmailConfirmation],
        mainStep: 2,
        isMain: true,
        mainStepTitle: (t) => t('first-login.email-confirmation'),
    },
    {
        url: '/email-form',
        links: {
            nextLink: '/email-confirmation',
            prevLink: '/email-confirmation',
        },
        stepKey: FirstLoginStepsEnum.EmailForm,
        Component: FirstLoginScreen[FirstLoginStepsEnum.EmailForm],
        mainStep: 2,
        isMain: true,
        mainStepTitle: (t) => t('first-login.email-confirmation'),
    },
    {
        url: '/phone-confirmation',
        links: {
            nextLink: '/terms',
            skipLink: '/phone-form',
            prevLink: '/email-confirmation',
        },
        stepKey: FirstLoginStepsEnum.PhoneConfirmation,
        Component: FirstLoginScreen[FirstLoginStepsEnum.PhoneConfirmation],
        mainStep: 3,
        isMain: true,
        mainStepTitle: (t) => t('first-login.phone-confirmation'),
    },
    {
        url: '/phone-form',
        links: {
            nextLink: '/phone-confirmation',
            prevLink: '/email-confirmation',
        },
        stepKey: FirstLoginStepsEnum.PhoneForm,
        Component: FirstLoginScreen[FirstLoginStepsEnum.PhoneForm],
        mainStep: 3,
        isMain: true,
        mainStepTitle: (t) => t('first-login.phone-form'),
    },
    {
        url: '/terms',
        links: {
            nextLink: '/password-setup',
            prevLink: '/phone-confirmation',
        },
        stepKey: FirstLoginStepsEnum.TermsAndConditions,
        Component: FirstLoginScreen[FirstLoginStepsEnum.TermsAndConditions],
        mainStep: 4,
        isMain: true,
        mainStepTitle: (t) => t('first-login.terms'),
    },
    {
        url: '/password-setup',
        links: {
            nextLink: '/auth-app',
            prevLink: '/terms',
        },
        stepKey: FirstLoginStepsEnum.PasswordSet,
        Component: FirstLoginScreen[FirstLoginStepsEnum.PasswordSet],
        mainStep: 5,
        isMain: true,
        mainStepTitle: (t) => t('first-login.password-setup'),
    },
    {
        url: '/auth-app',
        links: {
            nextLink: '/mfa-setup',
            prevLink: '/password-setup',
        },
        stepKey: FirstLoginStepsEnum.Setup2FA1,
        Component: FirstLoginScreen[FirstLoginStepsEnum.Setup2FA1],
        mainStep: 6,
        isMain: true,
        mainStepTitle: (t) => t('first-login.auth-app'),
    },
    {
        url: '/mfa-setup',
        links: {
            nextLink: '/finish',
            prevLink: '/auth-app',
        },
        stepKey: FirstLoginStepsEnum.Setup2FA2,
        Component: FirstLoginScreen[FirstLoginStepsEnum.Setup2FA2],
        mainStep: 7,
        isMain: true,
        mainStepTitle: (t) => t('first-login.mfa-setup'),
    },
    {
        url: '/finish',
        links: {
            prevLink: '/mfa-setup',
        },
        stepKey: FirstLoginStepsEnum.Finish,
        Component: FirstLoginScreen[FirstLoginStepsEnum.Finish],
        mainStep: 8,
        isMain: true,
        mainStepTitle: (t) => t('first-login.finish'),
    },
];

export const fakeApi = () => new Promise((resolve) => {
    resolve(apiData);
});
