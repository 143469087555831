import React, { useEffect, useMemo } from 'react';
import { AuthenticationContext } from '../context/authentication-context';
import { DefaultStore } from '../store/default-store';
import { useAuthenticationState } from '../hooks/use-authentication-state';
import {
    AuthDataType, LinksConfigType, StateStore, StoreType,
} from '../types';
import { initAuthData } from '../utils';
import { useAuthenticationActions } from '../hooks/use-authentication-actions';

type AuthenticationProviderProps = {
    children?: React.ReactNode | React.ReactNode[] | null,
    loadingComponent?: React.ReactNode | string | null,
    onLogin?: (authData: AuthDataType) => void
    onAppToken?: (authData: AuthDataType) => void
    store?: StateStore
    storeType?: StoreType
    prefixStore?: string
    onLogout?: () => void
    onRedirect?: () => void,
    links?: LinksConfigType
}

const AuthenticationProvider = ({
    children = null,
    onLogin,
    onAppToken,
    prefixStore = 'auth',
    storeType = 'sessionStorage',
    store = new DefaultStore(storeType, prefixStore),
    onLogout,
    onRedirect,
    loadingComponent = 'loading...',
    links = {
        login: '/auth/login',
        loginMtan: '/auth/login-mtan',
        forgotPassword: '/auth/forgot-password',
        forgotPasswordMtan: '/auth/forgot-password/mtan',
    },
}: AuthenticationProviderProps) => {
    const [authData, dispatchAuthData] = useAuthenticationState<AuthDataType>(
        store.get() || initAuthData,
        (payload) => store.set(payload),
    );
    const authActions = useAuthenticationActions({
        authData,
        onLogin,
        onLogout,
        onRedirect,
        dispatchAuthData,
        onAppToken,
        links,
    });

    useEffect(() => {
        if (authData.isAuthed) {
            onLogin?.(authData);
        }
    }, []);

    useEffect(() => {
        authActions.initApp(authData.appToken);
    }, []);

    const value = useMemo(() => ({
        authData,
        ...authActions,
    }), [authData.accessToken,
        authData.appToken,
        authActions,
    ]);

    return (
        <AuthenticationContext.Provider value={value}>
            {
                authActions.isLoadingAppToken
                    ? loadingComponent
                    : children
            }
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;
