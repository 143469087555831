import React, { useMemo } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { Link } from '@hydrogen/elements.core.router';
import { useAuthenticationData } from 'hydrogen/elements.auth.client-portal';
import logo from '../../../../assets/logo/logo.svg';
import { SessionTimeoutModal } from '../../widgets/session-timeout-modal';
import useIsMobile from '../../../core/utils/use-is-mobile';

type HeaderProps = {
    profileName?: string
}

export function Header(props: HeaderProps) {
    const { t } = useLanguage();
    const { authData } = useAuthenticationData();
    const isMobile = useIsMobile();

    const profileName = authData?.userInfo?.fullName || props.profileName;
    const nameInitials = useMemo(() => `${authData?.userInfo.firstName?.[0]}${authData?.userInfo.lastName?.[0]}`, [authData?.userInfo]);

    return (
        <header className="sticky top-0 shadow-header bg-header-background border-b border-header-border z-40">
            <nav className="max-w-page mx-auto px-4 sm:px-6">
                <div className="flex justify-between items-center h-14 sm:h-[5rem]">
                    <span className="max-h-6 sm:max-h-8 flex items-center md:justify-center lg:justify-start">
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <Link
                            className="flex items-center cursor-pointer"
                            to={authData.isAuthed ? '/overview' : '/'}
                        >
                            <img src={logo} alt={t('alt.smallLogo')} />
                        </Link>
                    </span>

                    {
                        authData.isAuthed && (
                            <Link className="flex items-center text-primary-500" to="/profile">
                                <span
                                    className="mr-2 hover:underline text-[1rem] leading-[2.5rem]"
                                >
                                    {isMobile ? nameInitials.toUpperCase() : profileName}
                                </span>
                                {/* <div */}
                                {/*    className="!w-10 !h-10 !bg-accent-300 !text-primary-500 rounded-full flex justify-center items-center" */}
                                {/* > */}
                                <i className="icon text-[1rem] text-primary-500 hover:none icon-user-male text-[30px]" />
                                {/* </div> */}
                            </Link>
                        )
                    }
                    <SessionTimeoutModal />
                </div>
            </nav>
        </header>
    );
}
