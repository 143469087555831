import React, { useState } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useNavigate, useParams } from '@hydrogen/elements.core.router';
import { useAuthenticationData } from 'hydrogen/elements.auth.client-portal';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { useNotifications } from '../../components/notification';
import { PasswordForm, PasswordFormType } from '../../components/password-form/password-form';

interface ForgotPasswordCreateNewPasswordFormProps {
    links: { nextLink: string }
}

export function ForgotPasswordCreateNewPasswordForm({ links: { nextLink } }: ForgotPasswordCreateNewPasswordFormProps) {
    const { t } = useLanguage();
    const { key } = useParams();
    const { resetPassword } = useAuthenticationData();
    const { negative } = useNotifications();
    const navigate = useNavigate();
    const [inProgress, setInProgress] = useState(false);

    const onFormSubmit = async (values: PasswordFormType) => {
        try {
            setInProgress(true);
            await resetPassword(values.password, key ?? '');

            navigate(nextLink);
        } catch (err: any) {
            if (err?.result === 'PasswordSameAlreadySet') {
                negative(t('notifications.samePasswordError'));
            } else {
                negative(t('notifications.somethingWentWrong'));
            }
        } finally {
            setInProgress(false);
        }
    };

    return (
        <>
            <FirstLoginHeader
                stepTitle={t('forgotPassword.passwordSetup.title')}
                stepImage=""
                noImage
            />

            <PasswordForm onFormSubmit={onFormSubmit} inProgress={inProgress} />
        </>
    );
}
