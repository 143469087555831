import { useQuery } from 'react-query';
import { customApi } from '@hydrogen/elements.core.rest.transport';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { IClientInvestRundown } from '@hydrogen/elements.core.contracts';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';

export function useClientInvestmentRundown() {
    const contactId = useContactId();
    const { language } = useLanguage();
    const investmentId = useInvestmentId();

    const query = useQuery<IClientInvestRundown>(
        ['client-invetment-rundown', contactId, investmentId],
        () => customApi()
            .get(
                `/v1.0/widgets/clients/${contactId}/investments/${investmentId}/rundown`,
                { params: { language } },
            ).then(({ data }) => data),
        {
            enabled: typeof contactId === 'number' && typeof investmentId === 'number',
            keepPreviousData: true,
        },
    );

    return {
        ...query,
        isLoading: query.isLoading || query.isIdle,
    };
}
