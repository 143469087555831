import React from 'react';
import { Navigate, Outlet } from '@hydrogen/elements.core.router';
import BaseLayout from '@hydrogen/elements.ui.layouts.base';

import { Header } from 'elements/ui/components/header';
import { Footer } from 'elements/ui/components/footer';

import { intro } from './routes/intro';
import { investment } from './routes/investment';
import { signup } from './routes/signup';
import { overview } from './routes/overview';
import { profile } from './routes/profile';
import { personalData } from './routes/personal-data';
import { support } from './routes/support';
import { terms } from './routes/terms';
import { login } from './routes/login';
import { forgotPassword } from './routes/forgot-password';
import { changePassword } from './routes/change-password';
import { documents } from './routes/documents';
import { reEnrollDevice } from './routes/re-enroll-device';
import { getDowntimeFlags } from '../common/helper';

const home = '/';

export const routingConfig = [
    {
        Component: BaseLayout,
        props: {
            header: <Header />,
            children: <Outlet />,
            footer: <Footer />,
        },
        url: '/',
        breadcrumb: 'dashboard',
        children: [
            login,
            ...(getDowntimeFlags()?.isDownTimeNow ? [] : [
                intro,
                documents,
                investment,
                overview,
                personalData,
                profile,
                changePassword,
                reEnrollDevice,
                support,
                signup,
                terms,
                ...forgotPassword,
            ]),
            {
                Component: () => (
                    <div className="flex-1">
                        <Navigate replace to={home} />
                    </div>
                ),
                url: '',
            },
        ],
    },
    {
        Component: () => <div>404</div>,
        url: '*',
    },
];
