import React, { useMemo } from 'react';
import { boolean, object } from 'yup';

import { container } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import Checkbox from '@hydrogen/elements.ui.components.checkbox';
import LinkButton from '@hydrogen/elements.ui.components.link-button';

import { StepPageProps } from 'elements/core/contracts';
import { useTermsAccept } from 'elements/data/rest/use-terms-accept';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { Button } from 'elements/ui/components/button';
import { Controller, useForm } from 'elements/ui/components/use-form';
import { Text } from 'elements/ui/components/text';
import { useStepper } from 'elements/ui/components/stepper/components';

export enum TermsFormFields {
    portalTerms = 'portalTerms',
}

type TermsForm = {
    [TermsFormFields.portalTerms]: boolean;
};

const termItems = [
    {
        key: TermsFormFields.portalTerms,
        label: 'firstLogin.terms.portal',
        url: '/signup/terms/portal-terms',
    },
];

export function TermsAndConditions({
    dataSource = container.get<typeof useTermsAccept>('useTermsAccept'),
    links: { nextLink, prevLink },
}: StepPageProps<typeof useTermsAccept>) {
    const { inProgress, acceptTerms, clientData } = dataSource();
    const { t } = useLanguage();
    const { onStepComplete } = useStepper() || {};

    // #region Form setup

    const validationSchema = useMemo(
        () => object().shape({
            [TermsFormFields.portalTerms]: boolean().oneOf(
                [true],
                t('validation.mandatoryField'),
            ),
        }),
        [t],
    );

    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit,
        watch,
    } = useForm<TermsForm>({
        validationSchema,
        options: {
            defaultValues: {
                [TermsFormFields.portalTerms]: false,
            },
        },
    });

    const values = watch();

    const onFormSubmit = handleSubmit(async (vals: TermsForm) => {
        if (!clientData) {
            return;
        }
        try {
            await acceptTerms({ contactIdentityId: clientData.contactIdentityId, ...vals });
            onStepComplete({ nextLink, prevLink });
        } catch (err) {
            console.error(err);
        }
    });

    const checkboxLabel = (item, error) => (
        <Text>
            {t('firstLogin.terms.accept')}
            <LinkButton
                as="a"
                href={item.url}
                className={`ml-1 hover:underline ${error ? 'text-danger-200' : 'text-secondary-300'}`}
            >
                {t(item.label)}
            </LinkButton>
        </Text>
    );

    const controllerFields = useMemo(
        () => termItems.map((i) => (
            <Controller
                name={i.key}
                key={i.key}
                control={control}
                render={({ field }) => (
                    <Checkbox
                        size="large"
                        className="mt-2 mb-8 [&>div>input]:border [&>div>input]:rounded-sm [&>div>input]:border-gray-400"
                        ref={field.ref}
                        onChange={(e) => {
                            setValue(i.key, e.target.checked, { shouldValidate: true });
                        }}
                        checked={values[i.key]}
                        label={checkboxLabel(i, !!errors[i.key]?.message)}
                        error={!!errors[i.key]?.message}
                    />
                )}
            />
        )),
        [control, errors, values, t],
    );

    // #endregion

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.terms.name')}
                stepTitle={t('firstLogin.terms.title')}
                stepDesc={t('firstLogin.terms.desc')}
                stepImage="/static/img/terms-locked-pc.svg"
                prevLink={prevLink}
                imgAlt={t('alt.termsLockedPc')}
            />
            <form onSubmit={onFormSubmit} className="w-full">
                <div className="text-left flex items-center w-full">
                    {controllerFields}
                </div>
                <div className="w-full">
                    <Button
                        isSubmit
                        onClick={onFormSubmit}
                        loading={inProgress}
                        disabled={!values[TermsFormFields.portalTerms] || inProgress}
                    >
                        {t('firstLogin.terms.acceptButton')}
                    </Button>

                </div>
            </form>
        </>
    );
}
