import React from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useNavigate } from '@hydrogen/elements.core.router';

import { Button } from 'elements/ui/components/button';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';

export function ForgotPasswordEmailSent() {
    const navigate = useNavigate();
    const { t } = useLanguage();

    return (
        <>
            <FirstLoginHeader
                stepTitle={t('forgotPassword.emailSent.title')}
                stepDesc={t('forgotPassword.emailSent.desc')}
                stepImage="/static/img/mail.svg"
                imgAlt={t('alt.accountActivation')}
            />
            <Button onClick={() => navigate('/')}>
                {t('forgotPassword.finish.goToLogin')}
            </Button>
        </>
    );
}
