import React, { useMemo } from 'react';

import {
    createRoutes, generateFlow, Route, Routes,
} from '@hydrogen/elements.core.router';
import Grid from '@hydrogen/elements.ui.components.grid';
import { BaseProgressProps } from '@hydrogen/elements.ui.components.progress-bar/dist/types';
import { classNames } from '@hydrogen/elements.ui.utils';

import { ProgressBars } from 'elements/ui/components/progress-bar';
import { LayoutProps, StepsType } from './types';
import { StepperLayout, StepperManager } from './components';

type StepperProps = {
    name: string;
    items: StepsType[];
    parentLinkPattern: string;
    entryPointUrl: string;
    allowedToGoBack?: boolean;
    layout?: React.ComponentType<LayoutProps>;
    progressBar?: React.ComponentType<BaseProgressProps>;
    className?: string;
    showContactInfo?: boolean;
    showBackButton?: boolean
    onCompleteUrl?: string;
    withCache?: boolean
};

export default function Stepper({
    name, items, parentLinkPattern, entryPointUrl, allowedToGoBack = true, layout, withCache = true,
    progressBar, className, showContactInfo, showBackButton, onCompleteUrl,
}: StepperProps) {
    const totalSteps = useMemo<number>(() => Math.max(...items.map((item) => item.mainStep)), [items]);

    const ProgressBar = useMemo(() => progressBar || ProgressBars, [progressBar]);

    const Layout = useMemo(() => layout || StepperLayout, [layout]);

    const routesFlow = createRoutes(
        generateFlow({
            totalSteps,
            parentLinkPattern,
            homePage: entryPointUrl,
            renderStepLayout: () => <div />,
            steps: items.map((item) => ({
                ...item,
                withLayout: false,
                Component: ({ parentLink, homePage: home }) => (
                    <Layout
                        entryPointUrl={entryPointUrl}
                        prevLink={`${parentLink}${item.links.prevLink}`}
                        showBackButton={showBackButton}
                        showContactInfo={showContactInfo}
                        expandContactInfo={item.expandContactInfo}
                    >
                        <ProgressBar
                            mainStep={item.mainStep}
                            totalSteps={totalSteps}
                            stepProgress={item.stepProgress}
                        />
                        <Grid
                            className={classNames('justify-items-center text-center text-darkBlue [&>div]:mt-4 [&>div]:mb-1 [&_h1]:text-xl', className)}
                        >
                            <item.Component
                                parentLinkPattern={parentLinkPattern}
                                url={item.url}
                                totalSteps={totalSteps}
                                mainStep={item.mainStep}
                                stepKey={item.stepKey}
                                links={{
                                    ...item.links,
                                    homePage: home,
                                    prevLink: item.links.prevLink ? `${parentLink}${item.links.prevLink}` : '',
                                    nextLink: `${parentLink}${item.links.nextLink}`,
                                    skipLink: item.links.skipLink
                                        ? `${parentLink}${item.links.skipLink}`
                                        : '',
                                }}
                            />
                        </Grid>
                    </Layout>
                ),
            })),
        }),
    );

    return (
        <StepperManager
            withCache={withCache}
            name={name}
            items={items}
            allowedToGoBack={allowedToGoBack}
            entryPointUrl={entryPointUrl}
            parentLinkPattern={parentLinkPattern}
            onCompleteUrl={onCompleteUrl}
        >
            <Routes>
                {routesFlow.map((route) => (
                    <Route path={route.path} key={route.path} element={route.element} />
                ))}
            </Routes>
        </StepperManager>
    );
}
