import React, { useRef, useEffect } from 'react';
import { TextInput } from '@hydrogen/elements.ui.components.text-input';
import { upperCase } from 'lodash';
import { generateInputKey, replaceAt } from './utils';

type OtpField = {
    length?: number
    value?: string
    disabled?: boolean
    uppercase?: boolean
    error?: string | null
    inputType?: 'text' | 'number'
    onChange?: (value: string) => void,
    onFullFill?: (code: string) => void
}
export const OtpField = ({
    length = 1,
    value = '',
    onChange,
    disabled = false,
    error = '',
    inputType = 'text',
    uppercase = false,
    onFullFill,
}: OtpField) => {
    const container = useRef<HTMLDivElement | null>(null);

    const onChangeHandler = (val: string) => {
        onChange?.(val);
    };

    const setInputFocus = (index: number) => {
        window.requestAnimationFrame(() => {
            container?.current?.querySelectorAll?.('input')?.[index]?.focus();
        });
    };
    const handleOnChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if (inputValue.length > 2) {
            const fullValue = replaceAt(value, index, inputValue);

            setTimeout(() => {
                setInputFocus(Math.min(fullValue.length, length - 1));
            }, 60);
            onChangeHandler(fullValue.slice(0, length));

            return;
        }
        const currentValue = inputValue?.[inputValue.length - 1] ?? '';
        const newValue = replaceAt(value, index, currentValue);
        const upperCaseIfNeeded = uppercase ? newValue.toUpperCase() : newValue;

        onChangeHandler(upperCaseIfNeeded);
        setInputFocus(index + 1);
    };

    useEffect(() => {
        if (value.length === length) {
            onFullFill?.(value);
        }
    }, [value, length]);
    const handleOnKeyUp = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
        const prevIndex = index - 1;
        const nextIndex = index + 1;
        const eventCode = event.code;
        const eventKeyCode = event.keyCode;

        if (['ArrowDown', 'ArrowUp'].includes(eventCode)) {
            event.preventDefault();

            return;
        }
        if (eventCode === 'Backspace' || eventKeyCode === 8) {
            setInputFocus(prevIndex);

            return;
        }
        if (eventCode === 'ArrowLeft') {
            setInputFocus(prevIndex);

            return;
        }
        if (eventCode === 'ArrowRight') {
            setInputFocus(nextIndex);
        }
    };

    const onFocusHandle = (event: React.FocusEvent<HTMLInputElement>) => {
        event?.target?.select?.();
    };

    return (
        <div
            className="min-h-[4.5rem] mt-[1rem] mb-[1.125rem] [&_p]:text-left [&_p]:text-[0.75rem] [&_p]:my-1.5 [&_p]:font-bold [&>div>div>div>div>div]:rounded-lg [&>div_input]:rounded-lg [&>div>div>div]:w-10 ![&>div>div>div_input]:h-[48px] [&>div>div>div_input]:text-2xl [&>div>div>div_input]:bg-ziagWhite [&>div>div>div>div>div]:bg-ziagWhite"
        >
            <div>
                <div
                    ref={container}
                    className="grid grid-rows-1 gap-2"
                    style={{ gridTemplateColumns: `repeat(${length}, minmax(0, 1fr))` }}
                >
                    {Array(length).fill(1).map((item, index) => (
                        <div key={generateInputKey(item + index)}>
                            <TextInput
                                type={inputType}
                                error={!!error}
                                className={`text-center ${uppercase ? 'uppercase' : false}`}
                                xPadding="px-0"
                                onChange={handleOnChange(index)}
                                onKeyDown={handleOnKeyUp(index)}
                                onFocus={onFocusHandle}
                                value={value?.[index] ?? ''}
                                name={`field${index}`}
                                disabled={disabled || (!value?.[index - 1] && index !== 0)}
                                autoFocus={index === 0}
                                ariaLabel="First"
                                autoComplete="none"
                            />
                        </div>
                    ))}
                </div>
                {error
                && (
                    <p className="mt-2 text-sm text-danger-500">
                        {error}
                    </p>
                )}
            </div>
        </div>
    );
};
