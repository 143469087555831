import { useCallback, useState } from 'react';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { AxiosResponse } from 'axios';
import { container } from '@hydrogen/elements.core.di';
import { FirstLoginClientDetails } from '../../../core/contracts';
import { useAppTokenValidate } from '../use-app-token-validate';

export type UsePhoneConfirmationResult = {
    confirmPhoneNumber: (key: string, contactIdentityId: string) => Promise<boolean>;
    resendVerificationCode: (contactIdentityId: string) => Promise<boolean>;
    updatePhoneNumber: (contactIdentityId: string, phoneNumber: string) => Promise<FirstLoginClientDetails>;
    confirmationInProgress: boolean;
    resendInProgress: boolean;
    updateInProgress: boolean;
};

export function usePhoneConfirmation(): UsePhoneConfirmationResult {
    const dataSource = container.get<typeof useAppTokenValidate>('useAppTokenValidate');
    const { checkAppToken } = dataSource();
    const [confirmationInProgress, setConfirmationInProgress] = useState(false);
    const [resendInProgress, setResendInProgress] = useState(false);
    const [updateInProgress, setUpdateInProgress] = useState(false);

    const confirmPhoneNumber = useCallback(async (key: string, contactIdentityId: string): Promise<boolean> => {
        try {
            setConfirmationInProgress(true);
            await checkAppToken();

            await clientServiceApi().post(`/v1.0/Onboarding/${contactIdentityId}/verify-phone-by-code`, { key });

            return true;
        } finally {
            setConfirmationInProgress(false);
        }
    }, []);

    const resendVerificationCode = useCallback(async (contactIdentityId: string): Promise<boolean> => {
        try {
            setResendInProgress(true);
            await checkAppToken();

            const response: AxiosResponse<boolean> = await clientServiceApi().post(`/v1.0/Onboarding/${contactIdentityId}/resend-phone-verification-otp`, {});

            return response.data;
        } finally {
            setResendInProgress(false);
        }
    }, []);

    const updatePhoneNumber = useCallback(async (contactIdentityId: string, phoneNumber: string) => {
        try {
            setUpdateInProgress(true);
            await checkAppToken();

            const response: AxiosResponse<FirstLoginClientDetails> = await clientServiceApi().patch(`/v1.0/Onboarding/${contactIdentityId}/update-contact-phone`, { phoneNumber });

            return response.data;
        } finally {
            setUpdateInProgress(false);
        }
    }, []);

    return {
        confirmationInProgress,
        updateInProgress,
        resendInProgress,
        confirmPhoneNumber,
        resendVerificationCode,
        updatePhoneNumber,
    };
}
