import { customApi, serviceApi } from '@hydrogen/elements.core.rest.transport';
import { useCallback } from 'react';

export const useAppToken = () => {
    const getAppToken = useCallback(
        async ({ type = 1, accessToken = '' }) => customApi().post(
            '/connect/token',
            { type, access_Token: accessToken },
            {
                Authorization: `Bearer ${accessToken}`,
            },
        ),
        [],
    );

    const validateAppToken = useCallback(
        async ({ accessToken, appToken }) => serviceApi().post(
            '/v1.0/authentication/tokens/validate',
            { accessToken },
            {
                Authorization: `Bearer ${appToken}`,
            },
        ),
        [],
    );

    return { getAppToken, validateAppToken };
};
