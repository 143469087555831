import React, { useCallback, useEffect, useState } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { container } from '@hydrogen/elements.core.di';
import { subscribe } from '@hydrogen/elements.core.event-bus';

import { FirstLoginClientDetails, FirstLoginTopics, StepPageProps } from 'elements/core/contracts';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { usePhoneConfirmation } from 'elements/data/rest/use-phone-confirmation';
import { Button, LinkButton } from 'elements/ui/components/button';
import { OtpField } from 'elements/ui/components/otp-field';
import { Text } from 'elements/ui/components/text';
import { useNotifications } from 'elements/ui/components/notification';
import { useStepper } from 'elements/ui/components/stepper/components';

export function PhoneConfirmation({
    links: { nextLink, prevLink, skipLink = '' },
    dataSource = container.get<typeof usePhoneConfirmation>(
        'usePhoneConfirmation',
    ),
}: StepPageProps<typeof usePhoneConfirmation>) {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [clientData, setClientData] = useState<FirstLoginClientDetails>();
    const { action, negative } = useNotifications();
    const { t } = useLanguage();
    const { onStepComplete, onStepSkip } = useStepper() || {};

    const {
        confirmPhoneNumber,
        confirmationInProgress,
        resendVerificationCode,
        resendInProgress,
    } = dataSource();

    useEffect(() => {
        const unsubscribe = subscribe(FirstLoginTopics.FirstLoginClientDetails, (payload) => {
            setClientData(payload);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const onConfirmClick = useCallback(async () => {
        try {
            if (code?.length < 6) {
                setError(t('validation.mandatoryField'));

                return;
            }
            const result = await confirmPhoneNumber(code, clientData?.contactIdentityId ?? '');

            if (!result) {
                setError(t('validation.invalidPhoneVerificationCode'));

                return;
            }

            onStepComplete({ nextLink, prevLink });
        } catch (err: any) {
            console.error(err);
            if (err?.response?.status === 409) {
                setError(t('validation.invalidPhoneVerificationCode'));

                return;
            }
            negative(t('notifications.somethingWentWrong'));
        }
    }, [confirmPhoneNumber, nextLink, code, clientData]);

    const onResendClick = useCallback(async () => {
        try {
            await resendVerificationCode(clientData?.contactIdentityId ?? '');
            action(t('notifications.newCodeSent'));
        } catch (err: any) {
            negative(t('notifications.errorSendingNewCode'));
        }
    }, [resendVerificationCode, clientData]);

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.phoneConfirmation.name')}
                stepTitle={t('firstLogin.phoneConfirmation.title')}
                stepDesc={t('firstLogin.phoneConfirmation.desc', { phone: clientData?.phoneNumber })}
                stepImage="/static/img/mobile.svg"
                prevLink={prevLink}
                imgAlt={t('alt.roundMobile')}
            />
            <OtpField
                uppercase
                value={code}
                onChange={(val: string) => setCode(val)}
                onFullFill={() => {
                    setError('');
                }}
                length={6}
                error={error}
            />
            <Button
                loading={confirmationInProgress}
                disabled={confirmationInProgress || !code}
                onClick={onConfirmClick}
            >
                {t('firstLogin.phoneConfirmation.confirmButton')}
            </Button>
            <LinkButton
                disabled={resendInProgress}
                onClick={onResendClick}
                className="mt-[20px]"
            >
                {t('firstLogin.phoneConfirmation.resendCode')}
            </LinkButton>

            {!clientData?.isNewClient && (
                <div className="mt-[74px] pt-[1.5rem] border-t w-full text-start">
                    <Text variant="label">
                        {t('firstLogin.phoneConfirmation.notYourPhone')}
                    </Text>
                    <LinkButton
                        className="mt-[4px] text-[16px] leading-[24px]"
                        onClick={() => onStepSkip?.(skipLink)}
                    >
                        {t('firstLogin.phoneConfirmation.updatePhone')}
                    </LinkButton>
                </div>
            )}
        </>
    );
}
