import { InputTextSize } from '@hydrogen/elements.ui.components.text-input';
import Grid from '@hydrogen/elements.ui.components.grid';
import React, { Fragment, useCallback, useMemo } from 'react';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { object, string } from 'yup';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { Button } from '../button';
import { Text } from '../text';
import { TextInput } from '../text-input';
import { Controller, useForm } from '../use-form';
import { PASSWORD_REGEX } from '../../../../common/validation';

export type PasswordFormType = {
    password: string;
    passwordConfirm: string;
};

type PasswordFormProps = {
    onFormSubmit: (values: PasswordFormType) => void,
    inProgress: boolean
}

const passwordCriteria = [
    {
        label: 'firstLogin.passwordSetup.passwordLength',
        callback: (_: (value: RegExp) => boolean, value: string) => value.length >= 8,
    },
    {
        label: 'firstLogin.passwordSetup.capitalLetters',
        callback: (validate: (value: RegExp) => boolean, _: string) => validate(/[A-Z]/),
    },
    {
        label: 'firstLogin.passwordSetup.smallLetters',
        callback: (validate: (value: RegExp) => boolean, _: string) => validate(/[a-z]/),
    },
    {
        label: 'firstLogin.passwordSetup.numbers',
        callback: (validate: (value: RegExp) => boolean, _: string) => validate(/[0-9]/),
    },
];

export const PasswordForm = ({ onFormSubmit, inProgress }: PasswordFormProps) => {
    const { t } = useLanguage();

    // #region Form setup

    const validationSchema = useMemo(
        () => object().shape({
            password: string()
                .matches(PASSWORD_REGEX, t('validation.passwordCriteria'))
                .required(t('validation.mandatoryField')),
            passwordConfirm: string()
                .test(
                    'is_password_same',
                    t('validation.passwordMatch'),
                    (passwordConfirm, testContext) => {
                        const { password } = testContext.parent;

                        return passwordConfirm === password;
                    },
                )
                .required(t('validation.mandatoryField')),
        }),
        [t],
    );

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<PasswordFormType>({
        validationSchema,
        options: {
            defaultValues: {
                password: '',
                passwordConfirm: '',
            },
        },
    });

    const password = watch('password');

    const onSubmit = handleSubmit(async (values: PasswordFormType) => {
        onFormSubmit(values);
    });

    // #endregion

    const isPasswordCriteriaSatisfied = useCallback(
        (criteria: RegExp) => !!password.match(criteria),
        [password],
    );

    const passwordCriteriaItems = useMemo(
        () => passwordCriteria.map((item) => {
            const isValid = item.callback(isPasswordCriteriaSatisfied, password);

            return (
                <Fragment key={item.label}>
                    <Icon
                        className={`w-4 h-4 ${isValid ? 'text-green-500' : ''}`}
                        size={IconsSizes.custom}
                        icon={isValid ? CheckCircleIcon : XCircleIcon}
                    />
                    <Text variant="stepTitle">{t(item.label)}</Text>
                </Fragment>
            );
        }),
        [isPasswordCriteriaSatisfied, password],
    );

    return (
        <form className="w-full" onSubmit={onSubmit}>
            <div className="justify-self-start w-full text-start">
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <TextInput
                            type="password"
                            textSize={InputTextSize.lg}
                            error={errors.password?.message}
                            labelInline
                            label={t('firstLogin.passwordSetup.password')}
                            {...field}
                        />
                    )}
                />
            </div>
            <div className="justify-self-start w-full text-start">
                <Controller
                    name="passwordConfirm"
                    control={control}
                    render={({ field }) => (
                        <TextInput
                            type="password"
                            textSize={InputTextSize.lg}
                            error={errors.passwordConfirm?.message}
                            labelInline
                            label={t('firstLogin.passwordSetup.confirmPassword')}
                            {...field}
                        />
                    )}
                />
            </div>
            <Grid
                columns={2}
                gap={2}
                className="justify-items-start grid-cols-[16px_auto] items-center mb-[40px] w-full"
            >
                <Text variant="normal" className="col-span-2 mb-1">
                    {t('firstLogin.passwordSetup.passwordCriteria')}
                </Text>

                {passwordCriteriaItems}
            </Grid>
            <Button
                isSubmit
                loading={inProgress}
                onClick={onSubmit}
                disabled={!password || inProgress}
            >
                {t('firstLogin.passwordSetup.setPassword')}
            </Button>
        </form>
    );
};
