import ModalDialog, { ModalDialogSizes } from '@hydrogen/elements.ui.components.modal-dialog';
import React, { useCallback, useEffect, useState } from 'react';
import { post, subscribe } from '@hydrogen/elements.core.event-bus';
import { useAuthenticationData } from 'hydrogen/elements.auth.client-portal';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { Button } from '../../components/button';
import { SessionTopics } from '../../../core/contracts';

const LOGOUT_THRESHOLD = 30;

export const SessionTimeoutModal = () => {
    const { t } = useLanguage();
    const { logout } = useAuthenticationData();
    const [showModal, setShowModal] = useState(false);
    const [countDown, setCountDown] = useState(LOGOUT_THRESHOLD);
    let timeout: NodeJS.Timeout | null = null;
    let interval: NodeJS.Timer | null = null;

    useEffect(() => {
        const unsubscribe = subscribe(SessionTopics.SessionExpired, (payload) => {
            setShowModal(payload);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (showModal) {
            timeout = setTimeout(() => {
                (async () => logout())();
                setCountDown(LOGOUT_THRESHOLD);
                post(SessionTopics.SessionExpired, false);
            }, LOGOUT_THRESHOLD * 1000);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [showModal]);

    useEffect(() => {
        if (showModal) {
            interval = setInterval(() => {
                setCountDown((prev) => prev - 1);
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [showModal]);

    const continueSession = useCallback(() => {
        if (timeout) {
            clearTimeout(timeout);
        }
        if (interval) {
            clearInterval(interval);
        }
        setCountDown(LOGOUT_THRESHOLD);
        post(SessionTopics.SessionExpired, false);
    }, []);

    return (
        <ModalDialog
            size={ModalDialogSizes.small}
            withCloseIcon
            show={showModal}
            onClose={continueSession}
            footer={(
                <div className="block mx-auto">
                    <div className="pb-2 w-[240px]">
                        <Button
                            isSubmit
                            onClick={continueSession}
                        >
                            {t('sessionExpire.modal.button')}
                        </Button>
                    </div>
                </div>
            )}
            title={(
                <div
                    className="text-center pt-[1.1rem] md:text-[28px] text-[24px] md:leading-[39px] leading-[33px] font-normal"
                >
                    {t('sessionExpire.modal.title')}
                </div>
            )}
        >
            <div className="md:pt-14 md:pb-8 md:px-10 pt-[1.5rem]">
                {t('sessionExpire.modal.body', { time: countDown })}
            </div>
        </ModalDialog>
    );
};
