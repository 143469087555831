import { useQuery } from 'react-query';
import { customApi } from '@hydrogen/elements.core.rest.transport';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';
import { IAnalysisAssetDevelopmentReqParams, TAnalysisAssetDevelopmentResponse } from '@hydrogen/elements.core.contracts';

export function useAnalysisAssetDevelopment(params: IAnalysisAssetDevelopmentReqParams = {}) {
    const contactId = params.contactId || useContactId();
    const portfolioId = params.portfolioId || useInvestmentId();

    const query = useQuery<TAnalysisAssetDevelopmentResponse>(
        ['analysis-asset-development', contactId, portfolioId, params.period, params.dateFrom, params.dateTo],
        () => customApi()
            .post(
                `/v1.0/widgets/clients/${contactId}/portfolios/${portfolioId}/investments/analysis/asset-development`,
                {
                    period: params.period, dateFrom: params.dateFrom, dateTo: params.dateTo,
                },
            )
            .then(({ data }) => data),
        {
            keepPreviousData: true,
            enabled: !!contactId && !!portfolioId,
        },
    );

    return {
        ...query,
        isLoading: query.isIdle || query.isLoading,
    };
}
