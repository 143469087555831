import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { classNames } from '@hydrogen/elements.ui.utils';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { ContentBoxEmpty, ContentBoxError } from '@hydrogen/elements.ui.components.content-box';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';

import { NA } from 'common/constants';
import { formatCurrency, formatPercentage } from 'common/formaters';
import { ContentBox } from 'elements/ui/components/content-box';
import { Secure } from 'hydrogen/elements.auth.client-portal';
import Tooltip from '@hydrogen/elements.ui.components.tooltip';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { InformationCircleIcon } from '@heroicons/react/outline';

export type CardType = {
    date?: string | null,
    label: string,
    unit: string,
    value?: number,
    showNegative?: boolean,
    tooltip?: string
    order?: number
}

type OverviewProps = {
    title?: string[],
    columnsNumber?: number,
    cards?: CardType[],
    isError?: boolean,
    isLoading?: boolean
}

export const Overview = ({
    title,
    columnsNumber = 2,
    cards,
    isError = false,
    isLoading = false,
}: OverviewProps) => {
    const { t } = useLanguage();

    const renderCards = () => (
        <>
            {cards?.map((item) => (
                <div key={uuidv4()}>
                    <div className="inline-block">
                        <p className="flex text-primary-500 font-medium">
                            {item.label}
                            <span className="font-light flex items-center">
                                    &nbsp;
                                {t('investment-overview.in')}
                                &nbsp;
                                {item.unit}
                                {item.tooltip && (
                                    <Tooltip
                                        tooltip={(
                                            <div
                                                className="text-darkBlue bg-ziagWhite p-3 rounded-2xl rounded-br-none font-medium text-xs max-w-[350px] text-left break-words"
                                            >
                                                {item.tooltip}
                                            </div>
                                        )}
                                        placement="top-end"
                                        variant="pure"
                                    >
                                        <Icon
                                            className="w-5 h-5 ml-2"
                                            size={IconsSizes.custom}
                                            icon={InformationCircleIcon}
                                        />
                                    </Tooltip>
                                )}
                            </span>
                        </p>
                        {item.date && (
                            <p className="text-sm text-primary-500">
                                {t('investment-overview.since')}
                                &nbsp;
                                {item.date}
                            </p>
                        )}
                    </div>
                    <div
                        className="float-right inline-block text-right text-[1.125rem] text-secondary-300 tracking-[0.04rem]"
                    >
                        {
                            // eslint-disable-next-line no-nested-ternary
                            item.value !== undefined ? (item.unit === '%' ? formatPercentage(item.value, item.showNegative) : formatCurrency(item.value)) : NA
                        }
                    </div>
                </div>
            ))}
        </>
    );

    return (
        <Secure loading={false}>
            <ContentBox as="section" noHeader>
                {
                    isError ? (
                        <div className="pb-[1.75rem] p-[1.5rem]">
                            <ContentBoxError isError={isError} errorMessage={t('common.something-wrong')}>
                                <></>
                            </ContentBoxError>
                        </div>
                    ) : (
                        <div className="pb-[1.75rem] p-[1.5rem]">
                            {title && (
                                <div className="flex justify-center sm:flex-row flex-col gap-x-[0.5rem] pb-[1.0rem]">
                                    <p className="text-[1.125rem] text-primary-500 text-nowrap">{title[0]}</p>
                                    <p className="sm:block hidden text-[1.125rem] text-primary-500 text-nowrap">&</p>
                                    <p className="text-[1.125rem] text-primary-500 text-nowrap">{title[1]}</p>
                                </div>
                            )}
                            <GridColumns
                                columns={{
                                    lg: columnsNumber,
                                    default: 1,
                                }}
                                className="gap-y-[1rem] gap-x-[1rem] text-base"
                            >
                                <SkeletonWrapper
                                    isLoading={isLoading}
                                    Skeleton={(
                                        <>
                                            {
                                                [...Array(columnsNumber)].map(
                                                    () => (
                                                        <SkeletonLine
                                                            className={classNames(columnsNumber === 2 ? 'h-[1.5rem]' : 'h-[2.8125rem]')}
                                                            key={uuidv4()}
                                                            height={11}
                                                            width="full"
                                                        />
                                                    ),
                                                )
                                            }
                                        </>
                                    )}
                                >
                                    {cards?.length ? renderCards() : (
                                        <ContentBoxEmpty className="">
                                            <>{t('common.data-empty')}</>
                                        </ContentBoxEmpty>
                                    )}
                                </SkeletonWrapper>
                            </GridColumns>
                        </div>
                    )
                }
            </ContentBox>
        </Secure>
    );
};
