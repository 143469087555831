import React, { useEffect, useMemo, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { object, string } from 'yup';

import Grid from '@hydrogen/elements.ui.components.grid';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import Tooltip from '@hydrogen/elements.ui.components.tooltip';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { container } from '@hydrogen/elements.core.di';
import { post, subscribe } from '@hydrogen/elements.core.event-bus';

import { FirstLoginClientDetails, FirstLoginTopics, StepPageProps } from 'elements/core/contracts';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { useEmailConfirmation } from 'elements/data/rest/use-email-confirmation';
import { Text } from 'elements/ui/components/text';
import { Button } from 'elements/ui/components/button';
import { Controller, useForm } from 'elements/ui/components/use-form';
import { TextInput } from 'elements/ui/components/text-input';
import { Infobox, InfoboxTypes } from 'elements/ui/components/info-box';
import { useNotifications } from 'elements/ui/components/notification';
import { useStepper } from 'elements/ui/components/stepper/components';
import { updateSessionStorageObject } from '../../../../common/helper';

type EmailForm = {
    email: string;
};

// eslint-disable-next-line
const EMAIL_REGEX = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;

export function EmailForm({
    links: { nextLink, prevLink },
    dataSource = container.get<typeof useEmailConfirmation>(
        'useEmailConfirmation',
    ),
}: StepPageProps<typeof useEmailConfirmation>) {
    const {
        updateEmail,
        inProgress,
    } = dataSource();
    const { t } = useLanguage();
    const [clientData, setClientData] = useState<FirstLoginClientDetails>();
    const { negative } = useNotifications();
    const { onStepComplete } = useStepper() || { onStepComplete: () => {} };

    useEffect(() => {
        const unsubscribe = subscribe(FirstLoginTopics.FirstLoginClientDetails, (payload) => {
            setClientData(payload);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    // #region Form setup

    const validationSchema = useMemo(
        () => object().shape({
            email: string()
                .matches(EMAIL_REGEX, t('validation.invalidEmail'))
                .required(t('validation.mandatoryField')),
        }),
        [t],
    );

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        setError,
    } = useForm<EmailForm>({
        validationSchema,
        options: { defaultValues: { email: '' } },
    });

    const email = watch('email');

    const onFormSubmit = handleSubmit(async (values: EmailForm) => {
        try {
            const data = await updateEmail(values.email, clientData?.contactIdentityId ?? '');

            post(FirstLoginTopics.FirstLoginClientDetails, data);
            updateSessionStorageObject(FirstLoginTopics.FirstLoginClientDetails, data);

            onStepComplete({ nextLink, prevLink });
        } catch (err: any) {
            console.error(err);
            if (err?.response?.status === 400) {
                setError('email', { message: t('validation.emailTaken') });
            }
        }
    });

    // #endregion

    const title = useMemo(() => (
        <Grid columns={2} gap={3} className="grid-cols-[auto_auto] items-center">
            <Text>{t('firstLogin.emailConfirmation.form.title')}</Text>
            <Tooltip
                tooltip={(
                    <div
                        className="text-darkBlue bg-ziagWhite p-3 rounded-2xl rounded-br-none font-medium text-xs max-w-[170px] text-left break-words"
                    >
                        {t('firstLogin.emailConfirmation.form.titleTooltip')}
                    </div>
                )}
                placement="top-end"
                variant="pure"
            >
                <Icon
                    className="w-6 h-6"
                    size={IconsSizes.custom}
                    icon={InformationCircleIcon}
                />
            </Tooltip>
        </Grid>
    ), [t]);

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.emailConfirmation.name')}
                stepTitle={title}
                stepDesc={t('firstLogin.emailConfirmation.form.desc')}
                stepImage="/static/img/mail.svg"
                prevLink={prevLink}
                imgAlt={t('alt.accountActivation')}
            />
            {errors.email?.message === t('validation.emailTaken') && (
                <Infobox
                    title={t('firstLogin.emailConfirmation.form.error')}
                    type={InfoboxTypes.error}
                    noIcon
                />
            )}

            <form onSubmit={onFormSubmit} className="w-full">
                <div className="justify-self-start w-full text-start mt-2">
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <TextInput
                                {...field}
                                className="justify-self-start text-start h-12 md:text-lg px-6"
                                error={errors.email?.message !== t('validation.emailTaken') && errors.email?.message}
                                labelInline
                                label={t('firstLogin.emailConfirmation.form.emailLabel')}
                            />
                        )}
                    />
                </div>
                <Button
                    isSubmit
                    loading={inProgress}
                    disabled={inProgress || !email}
                    onClick={onFormSubmit}
                >
                    {t('firstLogin.button.continue')}
                </Button>
            </form>
        </>
    );
}
