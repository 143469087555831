import React from 'react';
import { getContainer } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useClientPerformance } from '@hydrogen/elements.data.wealth.rest.use-client-performance';
import { ContentBox } from 'elements/ui/components/content-box';
import HistoricalPerformance from './historical-performance';
import { HistoricalPerformanceProps } from './types';
import { ChartInfo } from '../../components/chart-info';

export const Performance = ({
    dateRanges,
    dataSource = getContainer(useClientPerformance),
    benchmark = null,
    investmentPerformanceLegendTittle = '',
    newInvestmentPerformanceLegendTittle = '',
    requestPayload,
    isThreeB,
}: HistoricalPerformanceProps) => {
    const { t } = useLanguage();

    return (
        <ContentBox as="section" isMainBox title={t('common.performance')} variant="tabbed">
            <div className="pt-4 pb-7 px-6">
                <ChartInfo />
                <HistoricalPerformance
                    dateRanges={dateRanges}
                    dataSource={dataSource}
                    benchmark={benchmark}
                    investmentPerformanceLegendTittle={investmentPerformanceLegendTittle}
                    newInvestmentPerformanceLegendTittle={newInvestmentPerformanceLegendTittle}
                    requestPayload={requestPayload}
                    chartValueFormatted
                    isThreeB={isThreeB}
                />
            </div>
        </ContentBox>
    );
};
