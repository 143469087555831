import React, { useCallback, useState } from 'react';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import Grid from '@hydrogen/elements.ui.components.grid';
import { Secure, useAuthenticationData } from 'hydrogen/elements.auth.client-portal';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { FirstLoginHeader } from '../../components/first-login-header';
import { PasswordForm } from '../../components/password-form';
import { useNotifications } from '../../components/notification';
import { StepPageProps } from '../../../core/contracts';
import { useStepper } from '../../components/stepper/components';
import { Text } from '../../components/text';
import { PasswordFormType } from '../../components/password-form/password-form';

export const ChangePasswordNewPassword = ({
    links: { nextLink, prevLink },
    mainStep,
    totalSteps,
}: StepPageProps<any>) => {
    const { t } = useLanguage();
    const { negative } = useNotifications();
    const { onStepComplete } = useStepper();
    const [inProgress, setInProgress] = useState(false);
    const { changePassword } = useAuthenticationData();
    const contactId = useContactId();

    const onPasswordChangeClick = useCallback(async (values: PasswordFormType) => {
        try {
            if (!contactId) {
                negative(t('notifications.somethingWentWrong'));

                return;
            }

            setInProgress(true);
            await changePassword(values.password, contactId);
            onStepComplete({ nextLink, prevLink });
        } catch (err:any) {
            if (err?.response?.data?.code === 'NewPasswordSameAsOld') {
                negative(t('notifications.samePasswordError'));
            } else {
                negative(t('notifications.somethingWentWrong'));
            }
        } finally {
            setInProgress(false);
        }
    }, [contactId]);

    return (
        <Secure>
            <FirstLoginHeader
                stepTitle={t('changePassword.setPassword.title')}
                noImage
                stepName={`${mainStep}/${totalSteps}`}
            />
            <Grid className="text-left w-full" gap={6} columns={1}>
                <Text variant="label">{t('changePassword.setPassword.label')}</Text>
                <PasswordForm onFormSubmit={onPasswordChangeClick} inProgress={inProgress} />
            </Grid>
        </Secure>
    );
};
