import React, { useMemo } from 'react';
import { container } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import GridColumns from '@hydrogen/elements.ui.components.grid';

import { CardType, Overview } from 'elements/ui/widgets/overview';
import {
    useClientInvestmentOverview,
} from 'elements/data/rest/use-client-investment-overview';

 type InvestmentOverviewProps ={
    dataSource? : typeof useClientInvestmentOverview
 }
export function InvestmentOverview({
    dataSource = container.get<typeof useClientInvestmentOverview>('useClientInvestmentOverview'),
}: InvestmentOverviewProps) {
    const { t } = useLanguage();

    // uncomment after jwt integration
    const {
        data,
        isLoading,
        isError,
    } = dataSource({ showClosed: true });

    const overviewData: CardType[] | undefined = useMemo(() => (data ? [
        {
            label: t('overview.totalValue'),
            unit: data?.BaseCurrency?.CurrencyCode || '',
            value: data?.CurrentValue,
        },
        {
            label: t('client-dashboard.overview.total-return'),
            unit: data?.BaseCurrency?.CurrencyCode || '',
            value: data?.AbsoluteReturn,
        },
    ] : undefined), [data, t]);

    return (
        <GridColumns gap={6} columns={1}>
            <div className="mt-8">
                <Overview
                    cards={overviewData}
                    isLoading={isLoading}
                    isError={isError}
                />
            </div>
        </GridColumns>
    );
}
