import { container } from '@hydrogen/elements.core.di';
import { useSchema } from '@hydrogen/elements.data.wealth.rest.use-schema';
import { useAnalysisPerformance } from '@hydrogen/elements.data.wealth.rest.use-analysis-performance';
import { useAnalysisInvestmentAllocation } from '@hydrogen/elements.data.wealth.rest.use-analysis-investment-allocation';
import { useAccountActivation } from 'elements/data/rest/use-account-activation';
import { useEmailConfirmation } from 'elements/data/rest/use-email-confirmation';
import { usePasswordSetup } from 'elements/data/rest/use-password-setup';
import { useTermsAccept } from 'elements/data/rest/use-terms-accept';
import { useLogin } from 'elements/data/rest/use-login/use-login';
import { usePassCodeConfirmation } from 'elements/data/rest/use-passcode-confirmation/use-passcode-confirmation';
import { usePhoneConfirmation } from 'elements/data/rest/use-phone-confirmation';
import { useMfaSetup } from 'elements/data/rest/use-mfa-setup';

import { useCookiesAccept } from 'elements/data/rest/use-cookies-accept';
import { useContactDetails } from 'elements/data/rest/use-contact-details';
import { useInvestmentPositions } from 'elements/data/rest/use-investment-positions';
import { useInvestmentTransactions } from 'elements/data/rest/use-investment-transactions';
import { useAgentAgencyInfo } from 'elements/data/rest/use-agent-agency-info';
import { useAnalysisAssetDevelopment } from 'elements/data/rest/use-analysis-asset-development';
import { useInvestmentWithdrawal } from 'elements/data/rest/use-investment-withdrawal';
import { useInvestmentTransfer } from 'elements/data/rest/use-investment-transfer';
import { useOptionList } from 'elements/data/rest/use-option-list';
import { useForgotPassword } from '../elements/data/rest/use-password-setup/use-forgot-password';
import { useDocuments } from '../elements/data/rest/use-documents';
import { useDeposits } from '../elements/data/rest/use-deposits';
import { useAccountDetails } from '../elements/data/rest/use-account-details';
import { useReEnrollDevice } from '../elements/data/rest/use-re-enroll-device';
import { useClientInvestmentRundown } from '../elements/data/rest/use-client-investment-rundown';
import { useClientsOverviewInvestments } from '../elements/data/rest/use-clients-overview-investments';
import { useInvestmentRiskScore } from '../elements/data/rest/use-investment-risk-score';
import { useInvestmentKeyIndices } from '../elements/data/rest/use-investment-key-indices';
import { useClientInvestmentOverview } from '../elements/data/rest/use-client-investment-overview';
import { useAppToken } from '../elements/data/rest/use-app-token';
import { useAppTokenValidate } from '../elements/data/rest/use-app-token-validate';
import { useWithInvestmentsListWidget } from '../elements/data/adapters/use-with-investments-list-widget';
import { useWithInvestmentTransferWidget } from '../elements/data/adapters/use-with-investment-transfer-widget';
import { useWithInvestmentWithdrawalWidget } from '../elements/data/adapters/use-with-investment-withdrawal-widget';
import { useWithRundownWidget } from '../elements/data/adapters/use-with-rundown-widget';

container.bind<typeof useSchema>('useSchema').toFunction(useSchema);
container.bind<typeof useAccountActivation>('useAccountActivation').toFunction(useAccountActivation);
container.bind<typeof useEmailConfirmation>('useEmailConfirmation').toFunction(useEmailConfirmation);
container.bind<typeof usePasswordSetup>('usePasswordSetup').toFunction(usePasswordSetup);
container.bind<typeof useTermsAccept>('useTermsAccept').toFunction(useTermsAccept);
container.bind<typeof useLogin>('useLogin').toFunction(useLogin);
container.bind<typeof usePassCodeConfirmation>('usePassCodeConfirmation').toFunction(usePassCodeConfirmation);
container.bind<typeof usePhoneConfirmation>('usePhoneConfirmation').toFunction(usePhoneConfirmation);
container.bind<typeof useMfaSetup>('useMfaSetup').toFunction(useMfaSetup);
container.bind<typeof useForgotPassword>('useForgotPassword').toFunction(useForgotPassword);
container.bind<typeof useCookiesAccept>('useCookiesAccept').toFunction(useCookiesAccept);
container.bind<typeof useClientInvestmentOverview>('useClientInvestmentOverview').toFunction(useClientInvestmentOverview);
container.bind<typeof useClientsOverviewInvestments>('useClientsOverviewInvestments').toFunction(useClientsOverviewInvestments);
container.bind<typeof useClientInvestmentRundown>('useClientInvestmentRundown').toFunction(useClientInvestmentRundown);
container.bind<typeof useAnalysisAssetDevelopment>('useAnalysisAssetDevelopment').toFunction(useAnalysisAssetDevelopment);
container.bind<typeof useAnalysisPerformance>('useAnalysisPerformance').toFunction(useAnalysisPerformance);
container.bind<typeof useContactDetails>('useContactDetails').toFunction(useContactDetails);
container.bind<typeof useAnalysisInvestmentAllocation>('useAnalysisInvestmentAllocation').toFunction(useAnalysisInvestmentAllocation);
container.bind<typeof useDocuments>('useDocuments').toFunction(useDocuments);
container.bind<typeof useDeposits>('useDeposits').toFunction(useDeposits);
container.bind<typeof useAccountDetails>('useAccountDetails').toFunction(useAccountDetails);
container.bind<typeof useInvestmentPositions>('useInvestmentPositions').toFunction(useInvestmentPositions);
container.bind<typeof useInvestmentWithdrawal>('useInvestmentWithdrawal').toFunction(useInvestmentWithdrawal);
container.bind<typeof useInvestmentTransfer>('useInvestmentTransfer').toFunction(useInvestmentTransfer);
container.bind<typeof useOptionList>('useOptionList').toFunction(useOptionList);
container.bind<typeof useInvestmentTransactions>('useInvestmentTransactions').toFunction(useInvestmentTransactions);
container.bind<typeof useAgentAgencyInfo>('useAgentAgencyInfo').toFunction(useAgentAgencyInfo);
container.bind<typeof useReEnrollDevice>('useReEnrollDevice').toFunction(useReEnrollDevice);
container.bind<typeof useInvestmentRiskScore>('useInvestmentRiskScore').toFunction(useInvestmentRiskScore);
container.bind<typeof useInvestmentKeyIndices>('useInvestmentKeyIndices').toFunction(useInvestmentKeyIndices);
container.bind <typeof useAppToken>('useAppToken').toFunction(useAppToken);
container.bind <typeof useAppTokenValidate>('useAppTokenValidate').toFunction(useAppTokenValidate);
container.bind <typeof useWithInvestmentsListWidget>('useInvestmentsList').toFunction(useWithInvestmentsListWidget);
container.bind <typeof useWithInvestmentTransferWidget>('useWithInvestmentTransferWidget').toFunction(useWithInvestmentTransferWidget);
container.bind <typeof useWithInvestmentWithdrawalWidget>('useWithInvestmentWithdrawalWidget').toFunction(useWithInvestmentWithdrawalWidget);
container.bind <typeof useWithRundownWidget>('useWithRundownWidget').toFunction(useWithRundownWidget);
