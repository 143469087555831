import React from 'react';
import { useQuery } from 'react-query';

import Base, { Content } from '@hydrogen/elements.ui.layouts.base';
import Loader, { LoaderSizes } from '@hydrogen/elements.ui.components.loader';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { Stepper } from 'elements/ui/components/stepper';
import { fakeApi } from './api-data';
import { SubHeader } from '../../components/header/sub-header';

export type ReEnrollDeviceProps = {
    parentLinkPattern: string;
};

const DISABLE_BACK_ON_ROUTE = '/access-app/success';

export function ReEnrollDevice({
    parentLinkPattern,
}: ReEnrollDeviceProps) {
    const { t } = useLanguage();
    const { data, isLoading } = useQuery<any>(['re-enroll-device-mock'], fakeApi, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });

    if (isLoading) {
        return (
            <Base>
                <div style={{ margin: 'auto' }}>
                    <Loader size={LoaderSizes.extraLarge} />
                </div>
            </Base>
        );
    }

    return (
        <div className="flex-1 bg-white">
            <Content className="max-w-[75rem] !p-0 mx-auto mt-0 lg:mb-16">
                <SubHeader
                    title={t('reEnrollDevice.title')}
                    showBack={global.window.location.pathname !== DISABLE_BACK_ON_ROUTE}
                />
                <Stepper
                    withCache={false}
                    name="ReEnrollDevice"
                    items={data}
                    parentLinkPattern={parentLinkPattern}
                    entryPointUrl="/access-app"
                    showBackButton={false}
                    showContactInfo={false}
                    onCompleteUrl="/overview"
                />
            </Content>
        </div>
    );
}
