import { t } from '@hydrogen/elements.core.i18n';
import { FZ, THREEA } from './constants';

const MAX_FRACTION_DIGITS = 2;
const MIN_FRACTION_DIGITS = 2;

export const formatPercentage = (value?: number | null, showNegative: boolean = false) => {
    if (!value) {
        return '-';
    }

    /**
     *   Example:
     *   if showNegative = true && value is 3  output = `+3%`
     *   if showNegative =  true  && value is -3 output = `-3%`
     *   if showNegative = false && value is 3 output = `3%`
     *   if showNegative = false && value is -3 output = `-3%`
     */
    // eslint-disable-next-line no-nested-ternary
    const symbol = showNegative ? (value >= 0 ? '+' : '-') : '';

    return t(
        `${symbol} {{val, number}}%`,
        {
            val: showNegative ? Math.abs(value) : value,
            maximumFractionDigits: MAX_FRACTION_DIGITS,
            minimumFractionDigits: MIN_FRACTION_DIGITS,
        },
    );
};

export const formatCurrency = (
    value: number,
    currency?: string,
    isCurrencyPositionedAfter: boolean = false,
) => {
    const formatSettings = {
        val: value,
        maximumFractionDigits: MAX_FRACTION_DIGITS,
        minimumFractionDigits: MIN_FRACTION_DIGITS,
        lng: 'de-CH',
    };

    if (!currency) {
        return t('{{val, number}}', formatSettings);
    }

    return isCurrencyPositionedAfter
        ? t(`{{val, number}} ${currency}`, formatSettings)
        : t(`${currency} {{val, number}}`, formatSettings);
};

export const formatInvestmentType = (name: string | undefined) => {
    if (!name) {
        return '-';
    }

    const type = parseInt(name.split('.')[1], 10);

    if (type < THREEA) {
        return t('product.3a');
    }
    if (type < FZ) {
        return t('product.fz');
    }

    return '-';
};

export const DAY_MONTH_YEAR_FORMAT = 'dd.MM.yyyy';
