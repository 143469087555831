import React, { useCallback, useEffect, useMemo } from 'react';

import { setAccessToken, setRestConfig } from '@hydrogen/elements.core.rest.transport';
import { InitLanguage } from '@hydrogen/elements.core.i18n';
import { setGQLAuthToken, setGqlConfig, setGQLConfigUrl } from '@hydrogen/elements.core.gql.transport';

import Router, {
    setRouterConfigs, useLocation, useNavigate, withBrowserRouter,
} from '@hydrogen/elements.core.router';

import Loader, { LoaderSizes } from '@hydrogen/elements.ui.components.loader';
import Base from '@hydrogen/elements.ui.layouts.base';
import { useMode } from '@hydrogen/elements.ui.theme';

import { dispatchContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { AuthDataType } from 'hydrogen/elements.auth.client-portal/types';
import { AuthenticationProvider } from 'hydrogen/elements.auth.client-portal';
import { availableLanguages } from 'elements/core/i18n/constants';
import { NotificationManager } from 'elements/ui/components/notification';
import { serviceConfig } from './configs/service.config';
import { routingConfig } from './configs/routing.config';
import { dispatchIsAuthed, useIsAuthed } from './elements/data/app/use-is-authed';
import { DowntimeModal } from './elements/ui/widgets/downtime-modal';

const resourceKey = 'ClientCockpit';

const setSWAccessToken = (accessToken) => {
    window?.navigator?.serviceWorker?.controller?.postMessage({
        type: 'ACCESS_TOKEN',
        payload: accessToken,
    });
};

setRestConfig(serviceConfig);
setGQLConfigUrl(serviceConfig?.GATEWAY_API_URL || '');
setGqlConfig({
    urlSuffix: serviceConfig?.GQL_URL_SUFFIX,
});

setRouterConfigs(routingConfig);

const LoaderBase = () => (
    <Base>
        <div style={{ margin: 'auto' }}>
            <Loader size={LoaderSizes.extraLarge} />
        </div>
    </Base>
);

const PUBLIC_ROUTES = ['/', '/mfa', '/forgot-password', '/signup', '/registration', '/auth-verification'];

function App() {
    const isAuthed = useIsAuthed();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isApplicationAuthComplete, setApplicationAuthComplete] = React.useState(false);
    const onPublicAccessToken = useCallback((authData: AuthDataType) => {
        setAccessToken(authData.accessToken || authData.appToken || '');
        setSWAccessToken(authData.accessToken || authData.appToken);
        InitLanguage(resourceKey, {
            supportedLngs: availableLanguages.map(({ value }) => value),
            fallbackLng: 'de',
        });
        setApplicationAuthComplete(true);

        if (authData.userInfo.contactId) {
            dispatchContactId(authData.userInfo.contactId);
        }
    }, []);

    const onLogin = useCallback((authData: AuthDataType) => {
        setAccessToken(authData.accessToken ?? '');
        setSWAccessToken(authData.accessToken);
        if (authData.accessToken) {
            setGQLAuthToken(authData.accessToken);
        }
        if (authData.userInfo.contactId) {
            dispatchContactId(authData.userInfo.contactId);
        }

        if (authData.isAuthed) {
            dispatchIsAuthed(authData.isAuthed);
        }
    }, []);

    useMode();

    const isPublicRoute = useMemo(() => PUBLIC_ROUTES.some((s) => (pathname.startsWith(s) && s !== '/') || pathname === '/'), [pathname]);

    useEffect(() => {
        if (isAuthed && isPublicRoute) {
            navigate('/overview');
        } else if (!isAuthed && !isPublicRoute) {
            navigate('/');
        }
    }, [isAuthed, isPublicRoute]);

    return (
        <AuthenticationProvider
            onAppToken={onPublicAccessToken}
            onLogin={onLogin}
            loadingComponent={(
                <LoaderBase />
            )}
            links={{
                login: '/',
                loginMtan: '/mfa',
                forgotPassword: '/forgot-password',
                forgotPasswordMtan: '/forgot-password/mfa',
                loginSms: '/auth-verification',
            }}
        >
            <NotificationManager>
                <>
                    {isApplicationAuthComplete ? (
                        <>
                            <DowntimeModal />
                            <Router />
                        </>
                    ) : <LoaderBase />}
                </>
            </NotificationManager>
        </AuthenticationProvider>
    );
}

export default withBrowserRouter(App);
