import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { container } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import { ContentBoxEmpty, ContentBoxError } from '@hydrogen/elements.ui.components.content-box';

import { useNavigate } from '@hydrogen/elements.core.router';
import { NA } from 'common/constants';
import { formatCurrency, formatPercentage } from 'common/formaters';
import { ContentBox } from 'elements/ui/components/content-box';
import { LinkButton } from 'elements/ui/components/button';
import { useInvestmentPositions } from 'elements/data/rest/use-investment-positions';

export type Position = {
    'Security.Name': string,
    Allocation?: number,
    InvestmentValueSecurityCurrency?: number,
    'Security.Currency.CurrencyCode': string
}

type PositionsProps<TDataSource> = {
    dataSource?: TDataSource;
}

export const Positions = ({
    dataSource = container.get<typeof useInvestmentPositions>('useInvestmentPositions'),
}: PositionsProps<typeof useInvestmentPositions>) => {
    const { t, language } = useLanguage();
    const navigate = useNavigate();

    // TODO: set items to empty array after BE fixes their issues
    const {
        data,
        isLoading,
        isError,
    } = dataSource({
        skip: 0,
        take: 3,
        fields: ['Security.Name',
            'Allocation',
            'InvestmentValueSecurityCurrency',
            'Security.Currency.CurrencyCode'],
        language,
    });

    const positions = data?.items as Position[];

    const hasPositions = useMemo(() => positions?.length > 0, [positions]);

    return (
        <ContentBox as="section" isMainBox title={t('positions.title')} variant="tabbed">
            <GridColumns
                columns={{
                    lg: 1,
                    default: 1,
                }}
                className="gap-y-[1rem] pt-[1rem] pb-[1.75rem] px-[1.5rem] text-base text-primary-500"
            >
                <ContentBoxError isError={isError} errorMessage={t('common.something-wrong')}>
                    <SkeletonWrapper
                        isLoading={isLoading}
                        Skeleton={(
                            <SkeletonLine
                                height={44}
                                width="full"
                            />
                        )}
                    >

                        <>
                            {hasPositions ? positions?.map((item) => (
                                <div key={uuidv4()}>
                                    <p className="font-medium">
                                        {item['Security.Name']}
                                    </p>
                                    <div className="inline-block text-[1.125rem]">
                                        {item?.Allocation ? formatPercentage(item.Allocation * 100) : NA}
                                    </div>
                                    <div className="float-right inline-block text-right">
                                        {item?.InvestmentValueSecurityCurrency ? formatCurrency(item.InvestmentValueSecurityCurrency, item['Security.Currency.CurrencyCode'], true) : NA}
                                    </div>
                                </div>
                            )) : (
                                <ContentBoxEmpty className="">
                                    <>{t('common.data-empty')}</>
                                </ContentBoxEmpty>
                            )}
                        </>
                    </SkeletonWrapper>
                    {hasPositions && (
                        <div className="py-2 inline-block text-right">
                            <LinkButton onClick={() => navigate('positions')} className="float-right">
                                <div className="flex text-[1.125rem] leading-[1.5rem]">
                                    {t('positions.show-all')}
                                    <i className="ml-2 icon icon-long-arrow-right text-[1.2rem] decoration-none" />
                                </div>
                            </LinkButton>
                        </div>
                    )}
                </ContentBoxError>
            </GridColumns>
        </ContentBox>
    );
};
