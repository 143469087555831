import { useCallback, useState } from 'react';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { AxiosResponse } from 'axios';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { isAuthResponseError } from '../../../../hydrogen/elements.auth.client-portal/utils';
import { AuthErrorResponseType } from '../../../../hydrogen/elements.auth.client-portal/types';
import { MfaAppDetails, UseMfaSetupResult } from '../use-mfa-setup/use-mfa-setup';
import { useAuthenticationData } from '../../../../hydrogen/elements.auth.client-portal';

export enum EnrolmentStatus {
    Pending = 'Pending',
    Success = 'Success'
}

type EnrolmentStatusResponse = {
    Result: string,
    Status: EnrolmentStatus
}

export function useReEnrollDevice(): UseMfaSetupResult {
    const [inProgress, setInProgress] = useState(false);
    const [appData, setAppData] = useState<MfaAppDetails>();
    const [enrolmentStatus, setEnrolmentStatus] = useState<EnrolmentStatus>();
    const contactId = useContactId();
    const { authData, dispatchAuthData } = useAuthenticationData();

    const getAppCode = useCallback(async () => {
        try {
            if (!contactId || appData) {
                return;
            }

            setInProgress(true);

            const response: AxiosResponse<MfaAppDetails> = await clientServiceApi().post(`/v1.0/Contact/${contactId}/add-new-device`, { disableTwoFactor: authData.userInfo.isTwoFactorEnabled });

            dispatchAuthData({ ...authData, userInfo: { ...authData.userInfo, isTwoFactorEnabled: false } });

            setAppData(response.data);
        } finally {
            setInProgress(false);
        }
    }, [contactId, authData, dispatchAuthData]);

    const getEnrolmentStatus = useCallback(async (clearInterval) => {
        try {
            if (!authData?.userInfo?.userName) {
                return;
            }

            const response: AxiosResponse<EnrolmentStatusResponse> = await clientServiceApi().post('/v1.0/Onboarding/enrollment-status', {
                userName: authData?.userInfo?.userName,
                enrollmentSessionId: appData?.EnrollmentSessionId,
            });

            isAuthResponseError(response.data as AuthErrorResponseType);

            if (response.data.Status === EnrolmentStatus.Success) {
                clearInterval();
                dispatchAuthData({ ...authData, userInfo: { ...authData.userInfo, isTwoFactorEnabled: true } });
            }
            setEnrolmentStatus(response.data.Status);
        } catch (err: any) {
            clearInterval();
            setEnrolmentStatus(err.Result);
        }
    }, [authData, appData, dispatchAuthData]);

    return {
        inProgress, getAppCode, appData, getEnrolmentStatus, enrolmentStatus,
    };
}
