import React from 'react';
import ReactDOM from 'react-dom/client';
import './configs/inversify.config';
import './configs/ui-library.config';
import { setColorSchemas } from '@hydrogen/elements.ui.theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import Highcharts from 'highcharts';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './sw';

import App from './App';

import '@hydrogen/elements.tailwind-styles/styles.css';
import './index.css';

import { prestige } from './theme';

window.Highcharts = Highcharts;
serviceWorker.register();

const queryClient = new QueryClient();

setColorSchemas(prestige);
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
