import React from 'react';

import { container } from '@hydrogen/elements.core.di';
import { Outlet } from '@hydrogen/elements.core.router';
import BaseLayout from '@hydrogen/elements.ui.layouts.base';

import { AccountActivation } from 'elements/ui/widgets/account-activation';
import { FirstLogin } from 'elements/ui/widgets/first-login';
import { useAccountActivation } from 'elements/data/rest/use-account-activation';
import { ReadTermsDetails } from 'elements/ui/widgets/read-terms-details';
import { TermsFormFields } from 'elements/ui/widgets/terms-and-conditions/terms-and-conditions';

const parentRoute = '';

export const signup = {
    url: `${parentRoute}/`,
    Component: BaseLayout,
    index: true,
    props: {
        children: <Outlet />,
        //     footer: <Footer />,
    },
    children: [
        {
            url: 'email-confirmation',
            Component: AccountActivation,
            props: {
                dataSource: container.get<typeof useAccountActivation>(
                    'useAccountActivation',
                ),
                links: {
                    prevLink: '',
                    homePage: '/registration',
                    nextLink: '/email-confirmation',
                },
            },
        },
        {
            url: 'signup/terms/portal-terms',
            Component: ReadTermsDetails,
            props: {
                termKey: TermsFormFields.portalTerms,
            },
        },
        {
            url: 'signup/*',
            Component: FirstLogin,
            props: {
                parentLinkPattern: 'signup',
            },
        },
    ],
};
