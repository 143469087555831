import React from 'react';
import { SkeletonLine } from '@hydrogen/elements.ui.components.skeleton';

export const SkeletonCard = () => (
    <div className="bg-white relative rounded-box border-0">
        <div className="h-52 flex flex-col justify-between p-4 sm:p-5">
            <div>
                <div className="font-medium mb-2">
                    <SkeletonLine height={6} width={24} />
                </div>
                <SkeletonLine height={4} width={20} />
            </div>
            <div>
                <div className="flex justify-end items-end">
                    <div className="items-end">
                        <SkeletonLine height={8} width={32} />
                    </div>
                </div>
                <div className="mt-3 w-full h-2 flex items-center rounded-box">
                    <SkeletonLine height={2} width="full" />
                </div>
            </div>
        </div>
    </div>
);
