import React from 'react';
import { container } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { SkeletonLine } from '@hydrogen/elements.ui.components.skeleton';
import Title from '@hydrogen/elements.ui.components.title';
import { useContactDetails } from 'elements/data/rest/use-contact-details';

 type PersonalisedWelcomeProps ={
    dataSource? : typeof useContactDetails
 }
export function PersonalisedWelcome({
    dataSource = container.get<typeof useContactDetails>('useContactDetails'),
}: PersonalisedWelcomeProps) {
    const { t } = useLanguage();
    const { data } = dataSource();

    const firstName = data?.generalInformation?.name;
    const lastName = data?.generalInformation?.surname;
    const clientFullName = firstName && lastName ? `${firstName} ${lastName}` : '';

    return (
        <Title className="text-[1.875rem] leading-[2.6875rem]">
            <p className="font-ogg">{t('overview.hello')}</p>
            {clientFullName || <SkeletonLine className="md:w-[30%]" height={9} width="full" />}
        </Title>
    );
}
