import React from 'react';

import { Disclosure } from '@headlessui/react';
import { classNames } from '@hydrogen/elements.ui.utils';
import Title from '@hydrogen/elements.ui.components.title';

type AccordionSectionProps = {
    className?: string,
    opened?: boolean,
    title?: string | React.ReactNode,
    children: string | number | React.ReactNode | null
}

export const AccordionSection = ({
    children, className, opened, title,
}: AccordionSectionProps) => (
    <dl className={className}>
        <Disclosure defaultOpen={opened}>
            {({ open }) => (
                <>
                    <dt>
                        <Disclosure.Button className="w-full flex items-start justify-between gap-6">
                            <Title as="h3" className="!text-lg !leading-6 font-medium">{title}</Title>
                            <span className="flex flex-shrink-0">
                                <i className={classNames(open ? 'icon-arrow-up' : 'icon-arrow-down', '-mt-[0.4rem] text-[1.9rem]')} />
                            </span>
                        </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-4 mb-4">
                        {children}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    </dl>
);
