import React, { useMemo } from 'react';
import { container } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useAgentAgencyInfo } from 'elements/data/rest/use-agent-agency-info';
import { CardType, Contacts } from '../contacts';

 type InvestmentRundownProps = {
    dataSource? : typeof useAgentAgencyInfo
 }
export function InvestmentContacts({
    dataSource = container.get<typeof useAgentAgencyInfo>('useAgentAgencyInfo'),
}: InvestmentRundownProps) {
    const { t } = useLanguage();

    // uncomment after jwt integration
    const {
        data,
        error,
        isLoading,
    } = dataSource();

    const agencyContactsData: CardType[] | undefined = useMemo(() => {
        const contacts: CardType[] = [];

        if (data?.agentInformation) {
            contacts.push({
                id: 1,
                ...data?.agentInformation,
                name: `${data?.agentInformation?.firstName || ''} ${data?.agentInformation?.lastName || ''}`,
                street: data?.agentInformation?.address || '-',
                city: `${data?.agentInformation?.postalCode || ''} ${data?.agentInformation?.city || ''}`,
                title: t('contacts.yourAdvisor'),
            });
        }

        if (data?.agencyInformation) {
            contacts.push({
                id: 2,
                ...data?.agencyInformation,
                street: data?.agencyInformation?.address || '-',
                city: `${data?.agencyInformation?.postalCode || ''} ${data?.agencyInformation?.city || ''}`,
                title: t('contacts.yourAgency'),
            });
        }

        return (contacts.length ? contacts : undefined);
    }, [data, t]);

    return (<Contacts cards={agencyContactsData} isLoading={isLoading} isError={!!error} />);
}
