export interface IDocument {
    id: string
    documentSource: number
    document: string
    productId?: number | null
    businessUnit: string
    portfolioId?: string | null
    portfolioName?: string | null
    createDate: string
    type: string
    lpzCustomerId?: number | null
    lpzPlanId?: string | null
    category: string
}

export enum DOCUMENT_SOURCE {
    dmsDocument = 1,
    lpzDocument = 2,
}

export interface UseDocumentsReturnType {
    isLoading: boolean;
    isError: boolean;
    data: IDocument[];
    downloadDocument: ({
        id, documentSource, document, productId, lpzCustomerId,
    }: {
        id: any;
        documentSource: any;
        document: any;
        productId: any;
        lpzCustomerId: any;
    }) => Promise<void>;
}
