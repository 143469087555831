import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { Content } from '@hydrogen/elements.ui.layouts.base';
import { SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';

import { container } from '@hydrogen/elements.core.di';
import { ContentBoxEmpty, ContentBoxError } from '@hydrogen/elements.ui.components.content-box';
import { ContentBox } from 'elements/ui/components/content-box';
import { SubHeader } from 'elements/ui/components/header/sub-header';
import { Document } from 'elements/ui/components/document';
import { SkeletonCard } from './skeleton-card';
import { useDocuments } from '../../../data/rest/use-documents';

export const Documents = ({
    dataSource = container.get<typeof useDocuments>('useDocuments'),
}) => {
    const { t } = useLanguage();

    const {
        data,
        isLoading,
        isError,
        downloadDocument,
    } = dataSource();

    return (
        <div className="flex-1 bg-white">
            <Content className="max-w-[40rem] !lg:pb-16 mx-auto lg:mt-10 mt-[1.5rem] lg:mb-[1.5rem]">
                <SubHeader title={t('documents.title')} />
                <ContentBoxError isError={isError} errorMessage={t('common.something-wrong')}>
                    <ContentBox
                        as="section"
                        isMainBox
                        title={(
                            <>
                                <div>{t('documents.title')}</div>
                                {/*  <Button className="!w-auto !h-10 mt-3 px-4" variant={ButtonVariants.default} onClick={onFilter}>
                                <i className="mr-3 icon icon-filter inline-block text-[1.5rem] text-secondary-300" />
                                {t('documents.filter')}
                            </Button> */}
                            </>
                        )}
                        variant="tabbed"
                    >
                        <SkeletonWrapper
                            isLoading={isLoading}
                            Skeleton={(
                                <>
                                    <SkeletonCard />
                                    <SkeletonCard />
                                </>
                            )}
                        >
                            {data?.length ? data?.sort((a, b) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime()).map((doc) => (
                                <Document
                                    document={doc}
                                    downloadDocument={downloadDocument}
                                    key={uuidv4()}
                                />
                            )) : (
                                <ContentBoxEmpty className="">
                                    <>{t('common.data-empty')}</>
                                </ContentBoxEmpty>
                            )}
                            {/* <p className="py-4 border-t-[0.0625rem] border-absolute-1000 text-[0.75rem] leading-[1.125rem] text-center text-fill-text">
                            {t('documents.showResults', 'Showing {{number}} of {{total}} results', { number: documents.length, total })}
                        </p> */}
                        </SkeletonWrapper>
                    </ContentBox>
                </ContentBoxError>
            </Content>
        </div>
    );
};
