import { baseApi } from '@hydrogen/elements.core.rest.transport';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';
import { useEffect, useState } from 'react';
import { serviceConfig } from 'configs/service.config';
import { AgencyInformationData } from './types';

const { CLIENT_SERVICE_URL_SUFFIX } = serviceConfig;

export type UseAgentAgencyInfoResponse = {
    isLoading: boolean,
    data: any,
    error: string | boolean
};

export function useAgentAgencyInfo(): UseAgentAgencyInfoResponse {
    const [isLoading, setIsLoading] = useState(true);
    const investmentId = useInvestmentId();
    const [error, setError] = useState<string | boolean>(false);
    const [data, setData] = useState<AgencyInformationData>();

    useEffect(() => {
        if (investmentId) {
            setIsLoading(true);
            baseApi(CLIENT_SERVICE_URL_SUFFIX)
                .get('v1.0/Plan/agent-agency-information', {
                    params: {
                        portfolioId: investmentId,
                    },
                })
                .then((response) => {
                    if (response.data) {
                        setError(false);
                        setData(response.data);
                    }
                })
                .catch((e) => setError(e.message))
                .finally(() => setIsLoading(false));
        }
    }, [investmentId]);

    return { data, isLoading, error };
}
