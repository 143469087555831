import React from 'react';

import { Outlet } from '@hydrogen/elements.core.router';
import { container } from '@hydrogen/elements.core.di';

import { Investment } from 'elements/ui/widgets/investment';
import { TransactionsOverview } from 'elements/ui/widgets/transactions-overview';
import { useInvestmentTransactions } from 'elements/data/rest/use-investment-transactions';
import { PositionsOverview } from 'elements/ui/widgets/positions-overview';
import { useInvestmentPositions } from 'elements/data/rest/use-investment-positions';
import { AccountDetails } from '../../elements/ui/widgets/account-details';

export const investment = {
    Component: ({ children }) => (<>{children}</>),
    url: '/investment/:investmentId/',
    props: {
        children: <Outlet />,
    },
    children: [
        {
            url: '',
            Component: Investment,
        },
        {
            url: 'account-details',
            Component: AccountDetails,
        },
        {
            url: 'transactions',
            Component: TransactionsOverview,
            props: {
                dataSource: container.get<typeof useInvestmentTransactions>('useInvestmentTransactions'),
            },
        },
        {
            url: 'positions',
            Component: PositionsOverview,
            props: {
                dataSource: container.get<typeof useInvestmentPositions>('useInvestmentPositions'),
            },
        },
    ],
};
