export const theme = {
    shared: 'w-full min-h-12 px-4 py-3 lg:w-64 flex rounded-2xl items-start justify-between transition-transform duration-300 ease-out text-white shadow-lg',
    types: {
        base: 'bg-black',
        action: 'bg-mossGreen',
        alert: 'text-darkBlue bg-lemonYellow',
        negative: 'bg-peachRed',
        info: 'bg-skyBlue',
    },
};
