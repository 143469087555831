import React, { useCallback } from 'react';

import Grid from '@hydrogen/elements.ui.components.grid';
import LinkButton from '@hydrogen/elements.ui.components.link-button';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useNavigate, useParams } from '@hydrogen/elements.core.router';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';

import { ContactInfo } from 'elements/ui/widgets/contact-info';
import { LayoutProps } from '../types';

export default function StepperLayout({
    prevLink = '',
    entryPointUrl,
    children,
    withMobileBackButton = false,
    minContent = 'min-content_min-content_1fr',
    showBackButton = true,
    showContactInfo = true,
    expandContactInfo = false,
}: LayoutProps) {
    const navigate = useNavigate();
    const { t } = useLanguage();
    const route = useParams();

    const goBack = useCallback(() => {
        if (prevLink.includes('undefined')) {
            navigate(entryPointUrl);
        } else {
            navigate(prevLink);
        }
    }, [prevLink]);

    return (
        <Grid
            className="xs:justify-items-left md:justify-items-center grid-cols-1 px-[1.75rem] md:px-0 md:grid-cols-[1fr_350px_1fr] mt-0 md:mt-[3rem]"
        >
            <LinkButton
                className={`self-baseline ${withMobileBackButton ? 'flex' : 'hidden'} z-[1] md:flex text-primary-500 w-[100px] align-center`}
                href={prevLink}
                onClick={showBackButton ? goBack : undefined}
            >
                {showBackButton && (
                    <>
                        <ArrowNarrowLeftIcon className="text-primary-500 w-[1.5rem] mr-[0.625rem]" />
                        {` ${t('navigation.back')}`}
                    </>
                )}
            </LinkButton>
            <Grid
                className={`items-start [&>nav]:mb-0 [&>nav]:px-0 [&>nav>ol]:!mt-3 grid-rows-[${minContent}] min-h-[calc(100vh_-_114px)] grid-rows-[auto_1fr_auto] md:min-h-fit`}
            >
                {children}
                {(showContactInfo && route?.['*'] !== 'phone-form') && <ContactInfo className="mt-[24px] grid-row-3" expanded={expandContactInfo} />}
            </Grid>
            <div />
        </Grid>
    );
}
