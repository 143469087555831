import { Dispatch, useCallback, useState } from 'react';
import { useNavigate } from '@hydrogen/elements.core.router';
import { AxiosResponse } from 'axios';
import {
    authenticateApi,
    authenticateStatusApi,
    changePasswordApi,
    getAppToken,
    getAuthenticateStatusApi,
    getUserDetailsApi,
    logoffApi,
    preAuthApi,
    resetPasswordApi,
    sendPreliminaryKeyApi,
    sendResetKeyApi,
    sendSmsCodeApi,
    validateAppToken,
    verifySmsCodeAPi,
} from '../apis';
import {
    AuthDataType, AuthErrors, LinksConfigType, LoginBasePayload, TOKEN_TYPES,
} from '../types';
import {
    generateJWT, getUserInfo, initAuthData, isAuthResponseError,
} from '../utils';
import { useAuthenticationNavigation } from './use-authentication-navigation';
import { useIdleUserTime } from './use-idle-user-time';
import { useInterval } from '../../../common/helper';
import { dispatchIsAuthed } from '../../../elements/data/app/use-is-authed';

type AuthenticationActionsPayload = {
    authData: AuthDataType
    dispatchAuthData: Dispatch<Partial<AuthDataType>>
    onLogin?: (authData: AuthDataType) => void
    onAppToken?: (authData: AuthDataType) => void
    onLogout?: () => void
    onRedirect?: () => void,
    links: LinksConfigType
}

export const useAuthenticationActions = ({
    authData,
    onLogin,
    onAppToken,
    onLogout,
    onRedirect,
    dispatchAuthData,
    links,
}: AuthenticationActionsPayload) => {
    const [isLoadingAppToken, setLoadingAppToken] = useState(false);
    const [mfaAuthError, setMfaAuthError] = useState(false);
    const [startPolling, setStartPolling] = useState<any>();
    const linksAction = useAuthenticationNavigation(links);
    const navigate = useNavigate();

    useIdleUserTime(authData);

    const checkAppToken = useCallback(async (appToken) => {
        try {
            if (!appToken) {
                const {
                    data: { result: { accessToken } },
                } = await getAppToken({ type: TOKEN_TYPES.APP_TOKEN });

                return accessToken;
            }
            await validateAppToken({ accessToken: appToken, appToken });

            return appToken;
        } catch {
            const {
                data: { result: { accessToken } },
            } = await getAppToken({ type: TOKEN_TYPES.APP_TOKEN });

            return accessToken;
        }
    }, []);

    const forgotPassword = useCallback(async (username: string) => {
        const appToken = await checkAppToken(authData.appToken);

        const { data } = await sendPreliminaryKeyApi({
            accessToken: appToken,
            username,
        });

        dispatchAuthData({
            preAuthenticateSessionId: data?.GeneratedSessionId,
        });

        return data?.GeneratedSessionId;
    }, [authData.appToken]);

    const forgotPasswordMtan = useCallback(async (passcode: string) => {
        const appToken = await checkAppToken(authData.appToken);

        if (!authData.preAuthenticateSessionId) return;
        const { data } = await sendResetKeyApi({
            accessToken: appToken,
            preliminaryKey: passcode,
            redirectUrl: `${window.location.origin}/forgot-password/set-new-password`,
            sessionId: authData.preAuthenticateSessionId,
        });

        if ([AuthErrors.MTanVerificationFailed, AuthErrors.MTanInvalid].includes(data?.Result)) {
            throw data;
        }
    }, [authData.appToken, authData.preAuthenticateSessionId]);

    const login = useCallback(async (loginData: LoginBasePayload) => {
        const appToken = await checkAppToken(authData.appToken);
        const { data } = await preAuthApi({
            ...loginData,
            trustedDeviceToken: appToken,
            forceExpiringPreviousSession: true,
        });

        isAuthResponseError(data);

        const baseAuthData = {
            ...authData,
            appToken,
            userInfo: getUserInfo(data?.CampaignContact, data?.Length),
        };

        if (data?.CampaignContact?.IsTwoFactorEnabled) {
            const mtanAuthData = {
                ...baseAuthData,
                preAuthenticateSessionId: data?.PreAuthenticateSessionId,
                isAuthed: false,
            };

            dispatchAuthData(mtanAuthData);
            linksAction.loginMtan();

            return mtanAuthData;
        }
        const newAuthData = {
            ...baseAuthData,
            preAuthenticateSessionId: data?.PreAuthenticateSessionId,
            accessToken: data?.Session?.JwtAccessToken,
            jwt: generateJWT(data?.Session?.SessionId, data?.Session?.JwtAccessToken),
            isAuthed: false,
        };

        await sendSmsCodeApi({ clientId: newAuthData?.userInfo?.contactId ?? 0 }, newAuthData.accessToken);

        dispatchAuthData(newAuthData);

        linksAction.loginSms();

        return newAuthData;
    }, [authData, onLogin, onRedirect, dispatchAuthData]);

    const resetPassword = useCallback(async (password: string, key: string) => {
        const appToken = await checkAppToken(authData.appToken);

        const { data } = await resetPasswordApi({
            accessToken: appToken,
            password,
            key,
        });

        isAuthResponseError(data);
    }, [authData.appToken]);

    const changePassword = useCallback(async (password: string, contactId: number) => {
        const { data } = await changePasswordApi({
            accessToken: authData.accessToken || '',
            contactId,
            password,
        });

        isAuthResponseError(data);
    }, [authData.accessToken]);

    const sendSmsOtp = useCallback(async () => {
        await sendSmsCodeApi({ clientId: authData?.userInfo?.contactId || 0 }, authData.accessToken);
    }, [authData]);

    const loginSms = useCallback(async (key: string) => {
        await verifySmsCodeAPi({ key, clientId: authData.userInfo.contactId! }, authData.accessToken);
        const newAuthData = { ...authData, isAuthed: true };

        dispatchAuthData(newAuthData);

        onLoginSuccessful(newAuthData);
    }, [authData]);

    const getAuthStatus = useCallback(async (clearInterval) => {
        try {
            const { data, status }: AxiosResponse<any> = await getAuthenticateStatusApi({
                userName: startPolling.CampaignContact.UserName,
                authStatusSessionId: startPolling.AuthStatusSessionId,
            }, authData.appToken);

            if (status === 200) {
                const newAuthData = {
                    ...authData,
                    userInfo: {
                        ...authData.userInfo,
                        userName: startPolling.CampaignContact.UserName,
                    },
                    accessToken: data?.Session?.JwtAccessToken,
                    preAuthenticateSessionId: null,
                    jwt: generateJWT(data?.Session?.SessionId, data?.Session?.JwtAccessToken),
                    isAuthed: true,
                };

                dispatchAuthData(newAuthData);
                onLoginSuccessful(newAuthData);
                setStartPolling(null);
                clearInterval();
            }
        } catch (err: any) {
            clearInterval();
            setStartPolling(null);
        }
    }, [startPolling, authData]);

    useInterval(getAuthStatus, startPolling ? 3000 : null, 0);

    const loginMtan = useCallback(async (passcode: string) => {
        const authResponse = await new Promise<any>((resolve) => {
            setTimeout(() => {
                (async () => {
                    const { data } = await authenticateApi(
                        {
                            passcode,
                            setTrusted: true,
                            preAuthenticateSessionId: authData?.preAuthenticateSessionId,
                        },
                        authData.userInfo.contactId,
                        authData.appToken,
                    );

                    return resolve(data);
                })();
            }, 2000);
        });

        isAuthResponseError(authResponse);

        setTimeout(async () => {
            try {
                const { data } = await authenticateStatusApi({
                    userName: authResponse.CampaignContact.UserName,
                    authStatusSessionId: authResponse.AuthStatusSessionId,
                }, authData.appToken);

                setStartPolling(authResponse);
            } catch (err) {
                console.log(err);
            }
        }, 2000);

        // return new Promise<AuthDataType>((resolve) => {
        //     setTimeout(() => {
        //         (async () => {
        //             const { data: statusData } = await authenticateStatusApi({
        //                 userName: authResponse.CampaignContact.UserName,
        //                 authStatusSessionId: authResponse.AuthStatusSessionId,
        //             }, authData.appToken);
        //
        //             isAuthResponseError(statusData);
        //
        //             const newAuthData = {
        //                 ...authData,
        //                 userInfo: {
        //                     ...authData.userInfo,
        //                     userName: authResponse.CampaignContact.UserName,
        //                 },
        //                 accessToken: statusData?.Session?.JwtAccessToken,
        //                 preAuthenticateSessionId: null,
        //                 jwt: generateJWT(statusData?.Session?.SessionId, statusData?.Session?.JwtAccessToken),
        //                 isAuthed: true,
        //             };
        //
        //             dispatchAuthData(newAuthData);
        //             onLoginSuccessful(newAuthData);
        //
        //             return resolve(newAuthData);
        //         })();
        //     }, 2000);
        // });
    }, [authData, onLogin, onRedirect, dispatchAuthData]);

    const initAppToken = useCallback(async (appToken) => {
        setLoadingAppToken(true);

        try {
            if (!appToken) {
                const { data } = await getAppToken({ type: TOKEN_TYPES.APP_TOKEN });

                const newAuthData = {
                    ...authData,
                    appToken: data.result.accessToken,
                };

                dispatchAuthData(newAuthData);
                onAppToken?.(newAuthData);

                return;
            }
            onAppToken?.(authData);
        } catch (err) {
            console.log('getting App Token error', err);
        } finally {
            setLoadingAppToken(false);
        }
    }, []);

    const resetAuthData = useCallback(() => {
        dispatchAuthData(initAuthData);
    }, [dispatchAuthData]);

    const logout = useCallback(async () => {
        try {
            if (authData?.userInfo?.contactId && authData?.accessToken) {
                await logoffApi({
                    contactsId: authData?.userInfo?.contactId,
                    accessToken: authData?.accessToken,
                });
            }
        } catch (err) {
            console.log('logout error', err);
        } finally {
            resetAuthData();
            dispatchIsAuthed(false);
            onLogout?.();
        }
    }, [authData, onLogout, resetAuthData]);

    const initApp = useCallback(async (appToken) => {
        if (authData.isAuthed && (['/', '/registration'].includes(window.location.pathname) || window.location.pathname.includes('/signup'))) {
            navigate('/overview');
        }

        await initAppToken(appToken);
    }, []);

    const getUserDetails = useCallback(async (payload: AuthDataType) => {
        const { data } = await getUserDetailsApi({
            contactId: payload.userInfo.contactId,
            accessToken: payload.accessToken ?? '',
        });

        dispatchAuthData({
            ...payload,
            userInfo: {
                ...payload.userInfo,
                firstName: data?.details?.personalInformation?.firstName,
                lastName: data.details.personalInformation?.lastName,
                fullName: `${data?.details?.personalInformation?.firstName} ${data.details.personalInformation?.lastName}`,
            },
        });
    }, [authData]);

    const onLoginSuccessful = useCallback((data: AuthDataType) => {
        dispatchAuthData(data);
        onLogin?.(data);
        onRedirect?.();
        getUserDetails(data);
    }, [getUserDetails]);

    return ({
        login,
        logout,
        resetAuthData,
        checkAppToken,
        forgotPassword,
        forgotPasswordMtan,
        initApp,
        isLoadingAppToken,
        loginMtan,
        linksAction,
        resetPassword,
        changePassword,
        setMfaAuthError,
        mfaAuthError,
        initAppToken,
        loginSms,
        sendSmsOtp,
        dispatchAuthData,
    });
};
