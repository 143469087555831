import React, { ReactNode } from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
import ContentBox from '@hydrogen/elements.ui.components.content-box';

type ContentBoxProps = {
    layout?: 'card' | 'no-card'
    as?: React.ElementType
    variant?: 'default' | 'tabbed' | 'performances'
    className?: string
    href?: string
    resizable?: boolean
    isMainBox?: boolean
    title?: string | React.ReactElement | null
    subTitle?: ReactNode | null
    optionsAlign?: string | false
    options?: React.ReactElement | null
    backOption?: string
    children: React.ReactNode | React.ReactNode[] | null,
    noHeader?: boolean
    state?: any
    arrowIconClassName?: string
    backToTop?: boolean
    titleAlign?: string | false
}
export const CContentBox = ({
    className,
    isMainBox,
    ...props
}: ContentBoxProps) => (
    <ContentBox
        {...props}
        className={classNames(className, isMainBox && '[&>div:first-child]:py-[1rem] [&>div:first-child]:px-[1.5rem] [&_h2]:text-[1.375rem] [&_h2]:leading-[2.0625rem]', 'border border-secondary-700')}
    />
);
