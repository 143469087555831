import React, { useEffect, useMemo } from 'react';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { container } from '@hydrogen/elements.core.di';
import { SkeletonLine } from '@hydrogen/elements.ui.components.skeleton';
import { useNavigate } from '@hydrogen/elements.core.router';
import { ButtonVariants } from '@hydrogen/elements.ui.components.button';

import { StepPageProps } from 'elements/core/contracts';
import { Button } from 'elements/ui/components/button';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { useMfaSetup } from 'elements/data/rest/use-mfa-setup';
import { useStepper } from 'elements/ui/components/stepper/components';
import { Image } from '../../components/image';
import { useInterval } from '../../../../common/helper';
import { EnrolmentStatus } from '../../../data/rest/use-mfa-setup/use-mfa-setup';
import { useNotifications } from '../../components/notification';
import { AuthErrors } from '../../../../hydrogen/elements.auth.client-portal/types';
import { isDev } from '../../../../common/utils';
import { GLOBAL_FUTURAE, ZIAG_FUTURAE } from '../../../../common/constants';
import useIsMobile from '../../../core/utils/use-is-mobile';
import { Secure } from '../../../../hydrogen/elements.auth.client-portal';

export function MfaSetup({
    dataSource = container.get<typeof useMfaSetup>('useMfaSetup'),
    links: { prevLink, nextLink }, isSecure,
}: StepPageProps<typeof useMfaSetup>) {
    const {
        inProgress, getAppCode, appData, getEnrolmentStatus, enrolmentStatus,
    } = dataSource();
    const navigate = useNavigate();
    const { t } = useLanguage();
    const { onStepComplete } = useStepper() || {};
    const { negative } = useNotifications() || {};
    const isMobile = useIsMobile();

    useEffect(() => {
        (async () => getAppCode())();
    }, [getAppCode]);

    useInterval(getEnrolmentStatus, appData?.EnrollmentSessionId ? 3000 : null, 3000);

    useEffect(() => {
        if (enrolmentStatus === EnrolmentStatus.Success) {
            if (typeof onStepComplete === 'function') {
                onStepComplete({ nextLink });
            }
        } else if (enrolmentStatus && AuthErrors[enrolmentStatus]) {
            if (typeof negative === 'function') {
                negative(t('notifications.somethingWentWrong'));
                navigate(prevLink ?? '');
            }
        }
    }, [enrolmentStatus]);

    const activationCode = useMemo(() => {
        const data = {
            imageUrl: appData?.AsynchronousParameters?.ActivationQrCodeUrl || '',
            appUrl: '',
        };

        if (appData?.AsynchronousParameters?.ActivationQrCodeUrl) {
            const splitted = appData?.AsynchronousParameters?.ActivationQrCodeUrl?.split('=');

            data.appUrl = `${isDev() ? GLOBAL_FUTURAE : ZIAG_FUTURAE}${splitted[1]}`;
        }

        return data;
    }, [appData?.AsynchronousParameters?.ActivationQrCodeUrl]);

    const qrData = useMemo(
        () => (
            <>
                <div className="flex justify-center md:hidden w-full pb-4">
                    <Button
                        onClick={() => global.open(activationCode.appUrl)}
                        variant={ButtonVariants.brand}
                    >
                        {t('firstLogin.mfaSetup.openAuthApp')}
                    </Button>
                </div>
                <Image
                    src={activationCode.imageUrl}
                    alt={t('alt.mfaAppQrCode')}
                    className="w-[200px] h-[200px]"
                />
            </>
        ),
        [appData, t],
    );

    const loadingSkeleton = useMemo(
        () => (
            <>
                <SkeletonLine width="48" height="48" className="mt-6" />
                <SkeletonLine className="mt-6 mb-2" height={5} />
                <SkeletonLine />
            </>
        ),
        [],
    );

    const content = useMemo(() => (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.mfaSetup.name')}
                stepTitle={t('firstLogin.mfaSetup.title')}
                stepDesc={t(isMobile ? 'firstLogin.mfaSetup.descMobile' : 'firstLogin.mfaSetup.desc')}
                noImage
                isLoading={inProgress}
                prevLink={prevLink}
            />
            {inProgress ? loadingSkeleton : qrData}
        </>
    ), [loadingSkeleton, inProgress, qrData]);

    return (
        isSecure ? <Secure>{content}</Secure> : content
    );
}
