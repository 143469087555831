import { OPTION_LIST } from 'common/constants';
import React, { useMemo } from 'react';
import { formatDate, useLanguage } from '@hydrogen/elements.core.i18n';
import { formatCurrency } from '../../../../common/formaters';
import { useInvestmentWithdrawal } from '../../rest/use-investment-withdrawal';
import { useSelectedPortfolio } from '../../helpers/use-selected-portfolio';
import { useProductType } from '../../helpers/use-product-type';
import { useOptionList } from '../../rest/use-option-list';

export const useWithInvestmentWithdrawalWidget = () => {
    const { t, language } = useLanguage();
    const {
        data: frequencyOptions,
        ...optionListQuery
    } = useOptionList({
        listKey: OPTION_LIST.WithdrawalPeriodicity,
    });

    const {
        data: selectedPortfolio,
        isLoading: isSelectedPortfolioLoading,
        isError: isSelectedPortfolioError,
    } = useSelectedPortfolio();

    const {
        isAZP,
        isAZPNeuSB,
    } = useProductType({ externalId: selectedPortfolio?.product?.externalId });

    const {
        data,
        isLoading,
        isError,
    } = useInvestmentWithdrawal();

    const withdrawalData = useMemo(() => [
        {
            label: t('withdrawal.bankDetails'),
            value: data?.bankName,
            valueClassName: 'inline-block text-[1.125rem] text-secondary-300',
            labelClassName: 'font-medium pb-[0.5rem]',
            additionalData: <div className="text-[1.125rem] text-secondary-300 mb-1">{data?.bankIban}</div>,
        },
        {
            label: t('withdrawal.withdrawalAmount'),
            value: data?.withdrawalAmount && formatCurrency(data?.withdrawalAmount, 'CHF'),
            valueClassName: 'float-right inline-block text-right font-medium text-secondary-300',
        },
        ...(isAZP
            ? [
                {
                    label: t('withdrawal.withdrawalType'),
                    value: data?.withdrawalType,
                },
            ]
            : []),
        {
            label: t('withdrawal.frequency'),
            value: data?.frequency && frequencyOptions?.find((i) => i.id === data?.frequency)?.label,
        },
        ...(isAZPNeuSB
            ? [] : [{
                label: t('withdrawal.withdrawalsStart'),
                value: data?.startOfWithdrawal && formatDate(new Date(data?.startOfWithdrawal), 'MM/yyyy'),
            }]
        ),
        ...(isAZP
            ? [
                {
                    label: t('withdrawal.duration'),
                    value: data?.durationInYears && `${data?.durationInYears} ${t('common.years')}`,
                },
                {
                    label: t('withdrawal.plannedEndDate'),
                    value: data?.plannedEndDate && formatDate(new Date(data?.plannedEndDate), 'dd/MM/yyyy'),
                    additionalData:
                <div className="text-[0.875rem] pt-[0.5rem]">
                    {t('withdrawal.tooltip.plannedEndDate')}
                </div>,
                },
            ]
            : []),
    ], [data, language, isAZP]);

    const hasWithdrawal = useMemo(() => (!!data?.bankIban), [
        data?.bankIban,
    ]);

    return {
        data: withdrawalData,
        isLoading: isLoading || isSelectedPortfolioLoading || optionListQuery.isLoading,
        isError: isError || isSelectedPortfolioError || optionListQuery.isError,
        hasWithdrawal,
        title: t('withdrawal.title'),
        tooltip: t('withdrawal.tooltip.info'),
    };
};
