import React from 'react';

import { t } from '@hydrogen/elements.core.i18n';
import Grid from '@hydrogen/elements.ui.components.grid';

import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { usePhoneConfirmation } from 'elements/data/rest/use-phone-confirmation';
import { StepPageProps } from 'elements/core/contracts';
import { Text } from 'elements/ui/components/text';

type PhoneForm = {
    phoneNumber: string;
};

export function PhoneForm({
    links: { prevLink },
}: StepPageProps<typeof usePhoneConfirmation>) {
    const phoneNumber = '+41 (0)44 628 08 00';

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.phoneConfirmation.name')}
                stepTitle={t('firstLogin.newPhoneInput.form.title')}
                stepDesc=""
                stepImage="/static/img/mobile.svg"
                prevLink={prevLink}
                imgAlt={t('alt.roundMobile')}
            />

            <Grid
                columns={2}
                gap={3}
                className="grid-cols-phoneWithIcon w-auto items-center text-secondary-300 mt-4"
            >
                <i className="icon icon-phone text-[1.5rem]" />
                <Text><a className="hover:underline" href={`tel:${phoneNumber}`}>{phoneNumber}</a></Text>
            </Grid>
        </>
    );
}
