import { StoreManager, SessionStoreObject } from './types';

export const SessionStoreManager = (storeName: string): StoreManager => {
    const save = (data: SessionStoreObject) => {
        try {
            global.sessionStorage.setItem(storeName, JSON.stringify(data));
        } catch (e) {
            /* eslint-disable no-console */
            console.error(e);
        }
    };

    const load = (): SessionStoreObject => {
        const sessionData = global.sessionStorage.getItem(storeName);

        return sessionData ? JSON.parse(sessionData) : {};
    };

    return { save, load };
};
