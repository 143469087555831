import React, { useMemo } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { container } from '@hydrogen/elements.core.di';

import { useCookiesAccept } from 'elements/data/rest/use-cookies-accept';
import { Text } from 'elements/ui/components/text';

type TCookiesBanner = {
    dataSource?: typeof useCookiesAccept
}

export function CookiesBanner({
    dataSource = container.get<typeof useCookiesAccept>('useCookiesAccept'),
}: TCookiesBanner) {
    const { cookiesAccepted, acceptCookies } = dataSource();
    const { t, language } = useLanguage();

    const cookiesLink = useMemo(() => {
        switch (language) {
            case 'de':
                return 'https://zurich.ch/de/datenschutz/';
            case 'fr':
                return 'https://zurich.ch/fr/protection-des-donnees/';
            case 'it':
                return 'https://zurich.ch/it/protezione-dei-dati/';
            default:
                return 'https://zurich.ch/en/data-protection/';
        }
    }, [language]);

    if (cookiesAccepted) return null;

    return (
        <div className="flex px-[1.5rem] py-2 text-primary-500 bg-neutral-200">
            <i className="-mt-0.5 -mb-1.5 icon icon-information-point flex-shrink-0 self-start text-[1.25rem] text-primary-500" />
            <div className="ms-3 w-full">
                <div className="flex items-start justify-between">
                    <Text variant="normal" className="self-center">
                        <>
                            {t('cookiesBanner.message')}
                            <a href={cookiesLink} className="ml-1 hover:underline font-bold" target="_blank" rel="noreferrer">
                                {t('cookiesBanner.link')}
                            </a>
                        </>
                    </Text>
                    <button type="button" className="flex flex-shrink-0 ms-3 w-[1rem] h-[1.25rem] text-primary-500" onClick={() => acceptCookies({ accepted: true })}>
                        <i className="-mt-0.5 -mb-1.5 icon icon-close text-[1.25rem] text-primary-500" />
                    </button>
                </div>
            </div>
        </div>

    );
}
