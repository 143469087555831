import React from 'react';

import { container } from '@hydrogen/elements.core.di';
import { Content } from '@hydrogen/elements.ui.layouts.base';

import { SubHeader } from 'elements/ui/components/header/sub-header';
import { useInvestmentPositions } from 'elements/data/rest/use-investment-positions';
import { usePortfolioName } from 'elements/data/rest/use-portfolio-name';
import { InvestmentPositions } from '../investment-positions/investment-positions';

type PositionsOverviewProps<TDataSource> = {
    dataSource: TDataSource;
};

export function PositionsOverview({
    dataSource = container.get<typeof useInvestmentPositions>('useInvestmentPositions'),
}: PositionsOverviewProps<typeof useInvestmentPositions>) {
    const name = usePortfolioName();

    return (
        <div className="flex-1 bg-neutral-100">
            <Content className="!p-0 mx-auto mt-0 mb-10">
                <SubHeader title={name} />
                <InvestmentPositions dataSource={dataSource} />
            </Content>
        </div>

    );
}
