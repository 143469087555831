import { StateStore, StoreType } from '../types';

export class DefaultStore implements StateStore {
    storeType = 'localStorage';

    prefix = 'auth';

    constructor(type: StoreType = 'localStorage', prefix = 'auth') {
        this.storeType = type;
        this.prefix = prefix;
    }

    get() {
        try {
            return JSON.parse(global[this.storeType].getItem(this.prefix));
        } catch {
            return null;
        }
    }

    set(value) {
        try {
            return global[this.storeType].setItem(this.prefix, JSON.stringify(value));
        } catch {
            return {};
        }
    }
}
