import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { container } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import { ContentBoxEmpty, ContentBoxError } from '@hydrogen/elements.ui.components.content-box';

import { useNavigate } from 'react-router-dom';
import { ContentBox } from 'elements/ui/components/content-box';
import { LinkButton } from 'elements/ui/components/button';
import { useInvestmentTransactions } from 'elements/data/rest/use-investment-transactions';
import { NA } from '../../../../common/constants';

type TransactionsProps<TDataSource> = {
    dataSource?: TDataSource;
}

export const Transactions = ({
    dataSource = container.get<typeof useInvestmentTransactions>('useInvestmentTransactions'),
}: TransactionsProps<typeof useInvestmentTransactions>) => {
    const navigate = useNavigate();
    const { t, language } = useLanguage();

    const {
        data: { items = [] } = {},
        isLoading,
        isError,
    } = dataSource({
        skip: 0,
        take: 3,
        fields: ['SecurityName'],
        language,
    });

    const hasTransactions = useMemo(() => items.length > 0, [items]);

    return (
        <ContentBox as="section" isMainBox title={t('transactions.title')} variant="tabbed">
            <GridColumns
                columns={{ default: 1 }}
                className="gap-y-[1rem] pt-[1rem] pb-[1.75rem] px-[1.5rem] text-base text-primary-500"
            >
                <ContentBoxError isError={isError} errorMessage={t('common.something-wrong')}>
                    <SkeletonWrapper
                        isLoading={isLoading}
                        Skeleton={(
                            <SkeletonLine
                                height={44}
                                width="full"
                            />
                        )}
                    >
                        {hasTransactions ? items?.map((item) => (
                            <div key={uuidv4()}>
                                <p className="font-medium">
                                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                    {/* @ts-ignore */}
                                    {item.SecurityName || NA}
                                </p>
                            </div>
                        )) : (
                            <ContentBoxEmpty className="">
                                <>{t('common.data-empty')}</>
                            </ContentBoxEmpty>
                        )}
                    </SkeletonWrapper>
                    {hasTransactions && (
                        <div className="py-2 inline-block text-right">
                            <LinkButton onClick={() => navigate('transactions')} className="float-right">
                                <div className="flex text-[1.125rem] leading-[1.5rem]">
                                    {t('transactions.show-all')}
                                    <i className="ml-2 icon icon-long-arrow-right text-[1.2rem] decoration-none" />
                                </div>
                            </LinkButton>
                        </div>
                    )}
                </ContentBoxError>
            </GridColumns>
        </ContentBox>
    );
};
