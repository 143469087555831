import React, { useCallback } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';

import { Button } from 'elements/ui/components/button';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { useStepper } from 'elements/ui/components/stepper/components';
import { FirstLoginTopics, StepPageProps } from '../../../core/contracts';

export function FirstLoginWelcome({ links: { nextLink, prevLink } }: StepPageProps<any>) {
    const { t } = useLanguage();
    const { onStepComplete } = useStepper();

    const onGoToLoginButtonClick = useCallback(() => {
        global.sessionStorage.removeItem(FirstLoginTopics.FirstLoginClientDetails);
        onStepComplete({ nextLink, prevLink, isLast: true });
    }, []);

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.finish.name')}
                stepTitle={t('firstLogin.finish.title')}
                stepDesc={t('firstLogin.finish.desc')}
                stepImage="/static/img/check.svg"
                imgAlt={t('alt.roundCheck')}
            />
            <Button
                onClick={onGoToLoginButtonClick}
            >
                {t('firstLogin.finish.goToLogin')}
            </Button>
        </>
    );
}
