import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import Base from '@hydrogen/elements.ui.layouts.base';
import Loader, { LoaderSizes } from '@hydrogen/elements.ui.components.loader';
import { post } from '@hydrogen/elements.core.event-bus';

import { FirstLoginTopics } from 'elements/core/contracts';
import { Stepper } from 'elements/ui/components/stepper';
import { fakeApi } from './api-data';

export type FirstLoginProps = {
    parentLinkPattern: string;
};

export function FirstLogin({
    parentLinkPattern,
}: FirstLoginProps) {
    const { data, isLoading } = useQuery<any>(['first-login-mock'], fakeApi, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        const clientData = global.sessionStorage.getItem(FirstLoginTopics.FirstLoginClientDetails);

        if (clientData) {
            post(FirstLoginTopics.FirstLoginClientDetails, JSON.parse(clientData));
        }
    }, []);

    if (isLoading) {
        return (
            <Base>
                <div style={{ margin: 'auto' }}>
                    <Loader size={LoaderSizes.extraLarge} />
                </div>
            </Base>
        );
    }

    return (
        <Stepper
            name={FirstLogin.name}
            items={data}
            parentLinkPattern={parentLinkPattern}
            entryPointUrl="/registration"
        />
    );
}
