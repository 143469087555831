import React, {
    createContext, ReactNode, useContext, useCallback, useMemo, useEffect,
} from 'react';

import {
    useLocation, useNavigate,
} from '@hydrogen/elements.core.router';

import { bool } from 'yup';
import {
    Step, StepComplete, StepperAPI, StepsType,
} from '../types';

const StepperContext = createContext<StepperAPI>(null as any);

type StepperProviderProps = {
    name: string;
    items: StepsType[];
    parentLinkPattern: string;
    entryPointUrl: string;
    allowedToGoBack?: boolean;
    children: ReactNode;
    onCompleteUrl?: string;
    withCache: boolean;
}

export const StepperManager = ({
    name, items, parentLinkPattern, entryPointUrl, allowedToGoBack, children, onCompleteUrl, withCache,
}: StepperProviderProps) => {
    const location = useLocation();
    const navigate = useNavigate();

    const stepperName = useMemo<string>(() => `currentStep_${name}`, [name]);

    const fallbackUrl = useMemo<string>(() => {
        const firstStepUrl = items.reduce((minObj, currentObj) => {
            if (currentObj.mainStep < minObj.mainStep) {
                return currentObj;
            }

            return minObj;
        }, items[0]).url;

        return `/${parentLinkPattern}${firstStepUrl}`;
    }, [items]);

    const setupStepper = useCallback(() => {
        if (!withCache) {
            global.sessionStorage.removeItem(stepperName);
        }
        const currentStep = global.sessionStorage.getItem(stepperName);

        if (currentStep) {
            return;
        }

        const newStep: Step = {
            visitedLinks: [entryPointUrl],
            nextLink: fallbackUrl,
            tempLinks: [],
        };

        global.sessionStorage.setItem(stepperName, JSON.stringify(newStep));
    }, []);

    const getStepper = useCallback(() => {
        let currentStep = global.sessionStorage.getItem(stepperName);

        if (!currentStep) {
            setupStepper();
            currentStep = global.sessionStorage.getItem(stepperName);
        }

        return JSON.parse(currentStep!);
    }, []);

    const onStepComplete = ({ nextLink, prevLink, isLast }: StepComplete) => {
        const currentStep = getStepper();

        const newVisitedLinks = [...currentStep.visitedLinks, nextLink];

        if (prevLink) {
            newVisitedLinks.push(prevLink);
        }

        const visitedLinks = allowedToGoBack ? Array.from(new Set(newVisitedLinks)) : [];
        const newStep: Step = {
            visitedLinks,
            nextLink,
            tempLinks: [],
        };

        global.sessionStorage.setItem(stepperName, JSON.stringify(newStep));

        if (isLast) {
            global.sessionStorage.removeItem(stepperName);
            navigate(onCompleteUrl ?? '/');
        } else {
            navigate(nextLink);
        }
    };

    const onStepSkip = (nextLink: string) => {
        const currentStep = getStepper();

        const newStep: Step = {
            ...currentStep,
            tempLinks: [...currentStep.tempLinks, nextLink],
        };

        global.sessionStorage.setItem(stepperName, JSON.stringify(newStep));
        navigate(nextLink);
    };

    const exposedAPI = useMemo(() => ({
        onStepComplete, onStepSkip,
    }), []);

    useEffect(() => {
        setupStepper();
    }, []);

    useEffect(() => {
        const step = getStepper();

        const isStepVisited = step.visitedLinks.includes(location.pathname) || step.tempLinks.includes(location.pathname);

        if (!isStepVisited) {
            navigate(step.nextLink, { replace: true });
        }
    }, [location.pathname]);

    return (
        <StepperContext.Provider value={exposedAPI}>
            {children}
        </StepperContext.Provider>
    );
};

export const useStepper = (): StepperAPI => useContext(StepperContext);
