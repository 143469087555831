import { globalColorClasses } from '@hydrogen/elements.ui.utils';

export const theme = {
    shared: 'inline-flex flex-shrink-0 justify-center items-center relative transition border',
    types: {
        default: {
            layer: 'bg-button-default-background border-button-default-border rounded-button',
            text: 'font-bold text-button-default-text',
        },
        header: {
            layer: 'bg-button-header-background border-button-header-border shadow-button-header rounded-button',
            text: 'font-bold text-button-header-link',
        },
        secondary: {
            layer: 'bg-white border border-neutral-300 hover:ring-neutral-300',
            text: 'text-primary-500',
        },
        alternative: {
            layer: 'bg-button-alternative-background border-button-alternative-border rounded-button',
            text: 'font-bold text-button-alternative-text',
        },
        danger: {
            layer: 'bg-danger-500 border-danger-500 rounded-button',
            text: 'font-bold text-absolute-0',
        },
        outline: {
            layer: 'bg-white border-none',
            text: 'text-primary-500',
        },
        cta: {
            layer: 'bg-button-cta-background border-button-cta-border rounded-button',
            text: 'font-bold text-button-cta-text',
        },
    },
    sizes: {
        small: {
            layer: 'rounded-button h-7 w-7',
        },
        default: {
            layer: 'rounded-button h-8 w-8',
        },
        large: {
            layer: 'rounded-button h-9 w-9',
        },
    },
    states: {
        disabled: {
            layer: globalColorClasses.disabled,
        },
        focus: {
            layer: globalColorClasses.focus,
        },
    },
};
