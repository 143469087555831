import { StepPageProps } from 'elements/core/contracts';

export enum ChangePasswordStepsEnum {
    PasswordSet = 'PasswordSet',
    Success = 'Success',
}

export type ChangePasswordScreenType = {
    [key: string | number]: React.ComponentType<StepPageProps<any>>;
};
