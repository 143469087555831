import React from 'react';

import { SkeletonLine } from '@hydrogen/elements.ui.components.skeleton';

export const SkeletonCard = () => (
    <div className="flex justify-between items-center p-4 border-t-[0.0625rem] border-absolute-1000">
        <div>
            <p className="mb-2 h-[1.125rem]">
                <SkeletonLine height={6} width={24} />
            </p>
            <p className="mb-1 h-6">
                <SkeletonLine height={6} width={24} />
            </p>
            <p className="mb-2 h-[1.125rem]">
                <SkeletonLine height={6} width={24} />
            </p>
            <SkeletonLine height={6} width={24} />
        </div>
        <div className="flex flex-shrink-0 items-center">
            <SkeletonLine height={6} width={6} />
        </div>
    </div>
);
