import React from 'react';

import { Outlet } from '@hydrogen/elements.core.router';
import Grid from '@hydrogen/elements.ui.components.grid';

import { FirstLoginStepLayout } from 'elements/ui/layouts/first-login-step-layout';
import { ForgotPasswordClientNumberForm } from 'elements/ui/widgets/forgot-password-client-number-form';
import {
    ForgotPasswordIdentityVerificationForm,
} from '../../elements/ui/widgets/forgot-password-identity-verification-form';
import { ForgotPasswordCreateNewPasswordForm } from '../../elements/ui/widgets/forgot-password-create-new-password-form';
import { ForgotPasswordEmailSent } from '../../elements/ui/widgets/forgot-password-email-sent';
import { ForgotPasswordSuccess } from '../../elements/ui/widgets/forgot-password-success';

const parentRoute = '/forgot-password';

const createARoute = (Component: React.ComponentType<any>, currentUrl: string, nextLink: string, prevLink: string | undefined) => ({
    Component: FirstLoginStepLayout,
    url: currentUrl,
    props: {
        children: <Grid className="justify-items-center text-center text-darkBlue"><Outlet /></Grid>,
        prevLink,
        gridRowChildren: 'grid-rows-[1fr_auto]',
    },
    children: [
        {
            url: '',
            Component,
            props: {
                links: {
                    nextLink,
                },
            },
        },
    ],
});

export const forgotPassword = [
    createARoute(ForgotPasswordClientNumberForm, parentRoute, `${parentRoute}/mfa`, '/'),
    createARoute(ForgotPasswordIdentityVerificationForm, `${parentRoute}/mfa`, `${parentRoute}/check-your-email`, parentRoute),
    createARoute(ForgotPasswordEmailSent, `${parentRoute}/check-your-email`, '/', `${parentRoute}/mfa`),
    createARoute(ForgotPasswordCreateNewPasswordForm, `${parentRoute}/set-new-password/:key`, `${parentRoute}/success`, '/'),
    createARoute(ForgotPasswordSuccess, `${parentRoute}/success`, '/', '/'),
];
