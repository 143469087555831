import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { classNames, globalColorClasses } from '@hydrogen/elements.ui.utils';
import Badge, { BadgeTypes } from '@hydrogen/elements.ui.components.badge';
import Title from '@hydrogen/elements.ui.components.title';
import Separator from '@hydrogen/elements.ui.components.separator';
import { getLocales, useTranslate } from '@hydrogen/elements.ui.configs';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { CheckIcon } from '@heroicons/react/solid';
import { TCardOption } from '../types';

export const CardOption = ({
    item,
    disabled = false,
    variant = 'default',
    size = 'default',
    noRadio = false,
    error = false,
}: TCardOption) => {
    const { t } = useTranslate();
    const locales = getLocales();

    if (item.separator) {
        return (
            <div className="flex flex-col space-y-4">
                <Separator margin="-mx-4 sm:-mx-6 my-2" className="w-auto" />
                <Title as={size === 'large' ? 'h2' : 'h3'} type="section">{item.title}</Title>
            </div>
        );
    }

    return (
        <RadioGroup.Option
            value={item.value}
            as="div"
            disabled={item.disabled}
            className={({ checked, active }) => classNames(
                item.disabled ?? disabled ? globalColorClasses.disabled : 'cursor-pointer',
                !error && (active ? 'border-primary-500 ring-2 ring-primary-500' : ''),
                error && (active ? 'border-danger-200 ring-2 ring-danger-500' : 'border-danger-500'),
                !error && ((checked && !active) ? 'border-brand-300 bg-brand-200/25' : ''),
                error && ((checked && !active) ? 'border-danger-300' : 'border-danger-200'),
                !error && ((checked || active) ? 'border-primary-500 ring-2 ring-primary-500 bg-brand-200/25' : 'border-input-border-color'),
                error && ((checked || active) ? 'border-danger-200 ring-2 ring-danger-500 bg-danger-200/25' : 'border-danger-500'),
                'transition relative block   rounded-content  sm:flex sm:justify-between focus:outline-none',
                'border-width-input  p-4',
            )}
        >
            {({ checked }) => (
                <>
                    {item.suggested
                        && (
                            <div className="inline-flex sm:hidden mb-4">
                                <Badge
                                    type={BadgeTypes.accent}
                                    title={t(locales.SUGGESTED)}
                                />
                            </div>
                        )}
                    <div className={classNames(
                        'flex gap-3   items-start',
                        size === 'large' ? 'w-full sm:gap-4' : 'flex-1',
                    )}
                    >
                        {noRadio
                            ? (
                                <div className={classNames(
                                    !checked
                                        ? 'hidden'
                                        : 'absolute flex items-center justify-center',
                                    !error ? 'bg-primary-500 ring-content-background ' : 'bg-danger-200',
                                    (size === 'large') ? 'h-6 w-6 -top-3 -right-3' : 'h-5 w-5 -top-2.5 -right-2.5',
                                    'ring-2 flex-shrink-0 rounded-full text-on-brand',
                                )}
                                >
                                    <Icon
                                        icon={CheckIcon}
                                        size={IconsSizes.custom}
                                        className={classNames(
                                            (size === 'large') ? 'w-4 h-4' : 'w-3.5 h-3.5',
                                        )}
                                    />
                                </div>
                            )
                            : (
                                <span
                                    className={classNames(
                                        noRadio ? 'hidden' : 'flex',
                                        !error && (checked ? 'bg-primary-500 border-transparent' : 'bg-content-background border-input-border-color'),
                                        error && (checked ? 'bg-danger-200 border-transparent' : 'bg-content-background border-danger-500'),
                                        (size === 'large') ? 'h-5 w-5' : 'h-4 w-4',
                                        'transition mt-0.5 flex-shrink-0 h-4 w-4 rounded-full border items-center justify-center',
                                    )}
                                    aria-hidden="true"
                                >
                                    <span className={classNames(
                                        (size === 'large') ? 'w-2 h-2' : 'w-1.5 h-1.5',
                                        'rounded-full bg-white',
                                    )}
                                    />
                                </span>
                            )}
                        <div className={classNames(
                            'flex flex-col',
                            size === 'large' ? 'w-full  gap-2' : 'gap-1 text-sm flex-1',
                        )}
                        >
                            <RadioGroup.Label
                                as="div"
                                className={classNames(
                                    'font-medium text-primary-500 flex items-center gap-2',
                                )}
                            >
                                <div className={classNames(
                                    'w-auto',
                                    error && 'text-danger-200',
                                )}
                                >
                                    {item.name}
                                </div>
                                {item.suggested
                                    && (
                                        <Badge
                                            className="hidden sm:block"
                                            type={BadgeTypes.accent}
                                            title={t(locales.SUGGESTED)}
                                        />
                                    )}
                            </RadioGroup.Label>
                            {((variant === 'default' || (variant === 'accordion' && checked))
                                    && (!!item.desc || !!item.additional))
                                && (
                                    <RadioGroup.Description
                                        as="div"
                                        className="flex flex-col gap-4"
                                    >
                                        {item.desc
                                            && (
                                                <div className="text-secondary-500">
                                                    {item.desc}
                                                </div>
                                            )}
                                        {item.additional}
                                    </RadioGroup.Description>
                                )}
                        </div>
                    </div>
                </>
            )}
        </RadioGroup.Option>
    );
};
