import { useEffect, useState } from 'react';
import { post, subscribe } from '@hydrogen/elements.core.event-bus';

export const IS_AUTHED_TOPIC = 'IS_AUTHED_TOPIC';
export const dispatchIsAuthed = (value: boolean) => post(IS_AUTHED_TOPIC, { value });

export function useIsAuthed() {
    const userInfo = JSON.parse(global.sessionStorage.getItem('auth') || 'null');
    const [isAuthed, setIsAuthed] = useState<boolean | undefined>(userInfo?.isAuthed);

    useEffect(() => {
        const unsubscribe = subscribe(IS_AUTHED_TOPIC, ({ value }) => {
            setIsAuthed(value);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return isAuthed;
}
