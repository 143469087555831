import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useInvestmentTitle } from '@hydrogen/elements.data.wealth.rest.use-investment-title';
import { useParams } from '@hydrogen/elements.core.router';

export function usePortfolioName() {
    const { t } = useLanguage();
    const { investmentId } = useParams();
    const investmentTitle = useInvestmentTitle();

    const portfolioPrefix = t('positions.portfolio', 'Portfolio');
    const name = investmentTitle.data?.name || investmentId;

    return `${portfolioPrefix} ${name}`;
}
