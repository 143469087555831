import { useForm as useReactHookForm, UseFormProps as UserReactFormProps } from 'react-hook-form';
import { useCallback } from 'react';
import { useYupValidationResolver } from './yupValidationResolver';

export type UseFormProps<T extends { [x: string]: string | boolean | object | number }> = {
    validationSchema: unknown;
    options?: UserReactFormProps<T>;
};

export function useForm<T extends { [x: string]: string | boolean | object | number }>(args: UseFormProps<T>) {
    const resolver = useYupValidationResolver(args.validationSchema);

    const { reset, ...rest } = useReactHookForm<T>({
        resolver,
        ...args.options,
    });

    const resetForm = useCallback(() => {
        reset({} as T);
    }, [reset]);

    const updateForm = useCallback(
        (values: { [x: string]: any }) => {
            reset((formValues) => ({ ...formValues, ...values }));
        },
        [reset],
    );

    return { resetForm, updateForm, ...rest };
}
