import { BadgeTypes } from '@hydrogen/elements.ui.components.badge';

export enum StatusBadgeEnum {
    ToBeExecuted = BadgeTypes.warn,
    Executed = BadgeTypes.success,
    Cancelled= BadgeTypes.neutral,
    Failed = BadgeTypes.danger,
}

export enum StatusLangEnum {
    ToBeExecuted = 'investment-transactions.to-be-executed',
    Executed = 'investment-transactions.executed',
    Cancelled = 'investment-transactions.cancelled',
    Failed = 'investment-transactions.failed',
}
