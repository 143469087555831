import { StepPageProps } from 'elements/core/contracts';

export enum FirstLoginStepsEnum {
    AccountActivation = 'AccountActivation',
    AccountActivationExpired = 'AccountActivationExpired',
    EmailConfirmation = 'EmailConfirmation',
    EmailForm = 'EmailForm',
    PhoneConfirmation = 'PhoneConfirmation',
    PhoneForm = 'PhoneForm',
    TermsAndConditions = 'TermsAndConditions',
    PasswordSet = 'PasswordSet',
    Setup2FA1 = 'Setup2FA1',
    Setup2FA2 = 'Setup2FA2',
    Finish = 'Finish',
}

export type FirstLoginScreenType = {
    [key: string | number]: React.ComponentType<StepPageProps<any>>;
};
