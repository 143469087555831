import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';
import { useLanguage } from '@hydrogen/elements.core.i18n';

export type TransferObject = {
    amount: number;
    periodicity: number;
    startOfTransfer:string;
    targetPortfolio: {
        id: number;
        name: string;
        productName: string;
        rubrik: string;
    };
    nextTransfer:string;
}

export type TransferResponse = Array<TransferObject>;

export const useInvestmentTransfer = () => {
    const { language } = useLanguage();
    const portfolioId = useInvestmentId();

    const transferDataObject = useQuery<AxiosResponse<TransferResponse>, unknown, TransferResponse>(
        ['contact-investment-transfer', language, portfolioId],
        () => clientServiceApi().get(
            `/v1.0/Plan/${portfolioId}/standing-orders?language=${language}`,
        ),
        {
            enabled: !!portfolioId && !!language,
            keepPreviousData: true,
            select({ data }) {
                return data;
            },
        },
    );

    return {
        ...transferDataObject,
        isLoading:
        transferDataObject.isIdle
        || transferDataObject.isLoading
        || (transferDataObject.isPreviousData && transferDataObject.isFetching),
    };
};
