import ModalDialog, { ModalDialogSizes } from '@hydrogen/elements.ui.components.modal-dialog';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { subscribe } from '@hydrogen/elements.core.event-bus';
import { Button } from '../../components/button';
import { getDowntimeFlags } from '../../../../common/helper';
import { DownTime } from '../../../core/contracts';

export const DowntimeModal = () => {
    const { t } = useLanguage();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const { isBeforeDownTimeNow, isDownTimeNow } = getDowntimeFlags();
        let intervalId;

        if (isBeforeDownTimeNow || isDownTimeNow) {
            setShowModal(true);

            // Case for users that stay in chrome without page reload and waiting for downtime ending
            if (isDownTimeNow) {
                intervalId = setInterval(() => {
                    const {
                        isBeforeDownTimeNow: isBeforeDownTimeNowInterval,
                        isDownTimeNow: isDownTimeNowInterval,
                    } = getDowntimeFlags();

                    if (!(isBeforeDownTimeNowInterval || isDownTimeNowInterval)) {
                        window.location.reload();
                    }
                }, 5000);
            }
        }

        const unsubscribe = subscribe(DownTime.NavigationBlock, () => {
            setShowModal(true);
        });

        return () => {
            clearInterval(intervalId);
            unsubscribe();
        };
    }, []);

    const close = useCallback(() => {
        setShowModal(false);
    }, []);

    return (
        <ModalDialog
            size={ModalDialogSizes.large}
            withCloseIcon
            show={showModal}
            onClose={close}
            footer={(
                <div className="block mx-auto">
                    <div className="pb-2 w-[240px]">
                        <Button
                            isSubmit
                            onClick={close}
                        >
                            {t('downtime.dialog.btn')}
                        </Button>
                    </div>
                </div>
            )}
        >
            <div className="md:pt-14 md:pb-8 md:px-10 pt-[1.5rem]">
                <p>{t('downtime.message.line1')}</p>
                <p>{t('downtime.message.line2')}</p>
                <p>{t('downtime.message.line3')}</p>
                <p>{t('downtime.message.line4')}</p>
            </div>
        </ModalDialog>
    );
};
