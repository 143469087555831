import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';
import { useLanguage } from '@hydrogen/elements.core.i18n';

export type WithdrawalResponse = {
    bankIban: string;
    accountHolder: string;
    zipCode: string;
    city: string;
    bankName: string;
    withdrawalAmount: number;
    frequency: number;
    withdrawalType: string; // TODO: BE should add it for AZP
    durationInYears: string; // TODO: BE should add it for AZP
    plannedEndDate: string; // TODO: BE should add it for AZP
    startOfWithdrawal:string;
    targetPortfolio: {
        id: number;
        name: string;
    };
    nextTransfer:string;
    currentStrategy: string;
    nextReallocations: Array<{date: string; strategyName: string}>;
}

export const useInvestmentWithdrawal = () => {
    const { language } = useLanguage();
    const portfolioId = useInvestmentId();

    const withdrawalDataObject = useQuery<AxiosResponse<WithdrawalResponse>, unknown, WithdrawalResponse>(
        ['contact-investment-withdrawal', language, portfolioId],
        () => clientServiceApi().get(
            `/v1.0/Plan/${portfolioId}/withdrawal-data?language=${language}`,
        ),
        {
            enabled: !!portfolioId && !!language,
            keepPreviousData: true,
            select({ data }) {
                return data;
            },
        },
    );

    return {
        ...withdrawalDataObject,
        isLoading:
        withdrawalDataObject.isIdle
        || withdrawalDataObject.isLoading
        || (withdrawalDataObject.isPreviousData && withdrawalDataObject.isFetching),
    };
};
