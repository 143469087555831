import React from 'react';

import { Outlet } from '@hydrogen/elements.core.router';
import BaseLayout from '@hydrogen/elements.ui.layouts.base';
import { ChangePassword } from '../../elements/ui/widgets/change-password';

export const changePassword = {
    Component: BaseLayout,
    url: '/',
    index: true,
    props: {
        children: <Outlet />,
        footer: null,
    },
    children: [
        {
            url: 'change-password/*',
            Component: ChangePassword,
            props: {
                parentLinkPattern: 'change-password',
            },
        },
    ],
};
