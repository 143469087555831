import { StepsType } from 'elements/core/contracts';

import { ChangePasswordScreen } from './change-password-screen';
import { ChangePasswordStepsEnum } from './types';

const apiData: Array<StepsType> = [
    {
        url: '',
        links: {
            nextLink: '/success',
        },
        stepKey: ChangePasswordStepsEnum.PasswordSet,
        Component: ChangePasswordScreen[ChangePasswordStepsEnum.PasswordSet],
        mainStep: 1,
        isMain: true,
        mainStepTitle: (t) => t('first-login.account-activation'),
    },
    {
        url: '/success',
        links: {
            prevLink: '/',
        },
        stepKey: ChangePasswordStepsEnum.Success,
        Component: ChangePasswordScreen[ChangePasswordStepsEnum.Success],
        mainStep: 2,
        isMain: true,
        mainStepTitle: (t) => t('first-login.account-activation'),
    },
];

export const fakeApi = () => new Promise((resolve) => {
    resolve(apiData);
});
