import React from 'react';
import { ImageSize, ImageVariants } from './types';

export type ImageProps = {
    alt?: string;
    src: string;
    className?: string;
    variant?: ImageVariants;
    size?: ImageSize;
};

const variants = {
    round: 'rounded-full',
    roundWithBorder: 'rounded-full border border-solid',
    border: 'border border-solid',
    default: '',
};

const sizes = {
    xs: 'w-10 h-10 object-cover',
    sm: 'w-[72px] h-[72px] object-cover',
    md: 'w-24 h-24 object-cover',
    lg: 'w-32 h-32 object-cover',
    xl: 'w-64 h-64 object-cover',
    '2xl': 'w-[512px] h-[512px] object-cover',
};

export function Image({
    alt = 'img',
    src,
    className,
    variant = ImageVariants.default,
    size = ImageSize.md,
}: ImageProps) {
    return (
        <img
            src={src}
            alt={alt}
            className={`${variants[variant]} ${sizes[size]} ${className}`}
        />
    );
}
