import { useCallback, useEffect, useRef } from 'react';
import { parse } from 'date-fns';
import { post } from '@hydrogen/elements.core.event-bus';
import { DOWNTIME, envs } from './constants';
import { dateTimeSecondsFormat } from './dateformat';
import { DownTime } from '../elements/core/contracts';

type EmailProps = {
    to: string | string[];
    cc?: string | string[];
    bcc?: string | string[];
    subject: string;
    content: string;
}

/**
 * Generates a mailto link with specified email properties.
 *
 * @param {EmailProps} options - The options object containing email properties.
 * @param {string|string[]} options.to - The primary recipient(s) email address(es).
 * @param {string|string[]} [options.cc] - The email address(es) to be included in
 *                                         the CC (Carbon Copy) field.
 * @param {string|string[]} [options.bcc] - The email address(es) to be included in
 *                                          the BCC (Blind Carbon Copy) field.
 * @param {string} options.subject - The subject of the email.
 * @param {string} options.content - The content/body of the email.
 * @returns {string} The generated mailto link.
 *
 * @example
 * // Basic usage:
 * const emailConfig = {
 *   to: 'mail@example.com',
 *   cc: ['mail1@example.com', 'mail2@example.com'],
 *   bcc: 'mail3@example.com',
 *   subject: 'Hello from JavaScript!',
 *   content: 'This is the content of the email.'
 * };
 * const mailtoLink = sendEmail(emailConfig);
 * console.log(mailtoLink);
 * // Output:
 * "mailto:mail@example.com?cc=mail1@example.com,mail2@example.com&bcc=mail3@example.com
 * &subject=Hello%20from%20JavaScript!&body=This%20is%20the%20content%20of%20the%20email."
 */
export function sendEmail(options: EmailProps): string {
    const {
        to, cc, bcc, subject, content,
    } = options;

    let mailtoLink = `mailto:${encodeURIComponent(
        Array.isArray(to) ? to.join(',') : to,
    )}`;

    if (cc) {
        const ccEmails = Array.isArray(cc) ? cc.join(',') : cc;

        mailtoLink += `?cc=${encodeURIComponent(ccEmails)}`;
    }

    if (bcc) {
        const bccEmails = Array.isArray(bcc) ? bcc.join(',') : bcc;

        mailtoLink += `&bcc=${encodeURIComponent(bccEmails)}`;
    }

    if (subject) {
        mailtoLink += `&subject=${encodeURIComponent(subject)}`;
    }

    if (content) {
        mailtoLink += `&body=${encodeURIComponent(content)}`;
    }

    return mailtoLink;
}

export const useInterval = (callback, delay: number | null, delayExecution: number | null) => {
    const savedCallback = useRef<any>();
    const intervalId = useRef<any>();
    const timerId = useRef<any>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const clearCustomInterval = useCallback(() => {
        clearInterval(intervalId.current);
        clearTimeout(timerId.current);
    }, []);

    const tick = useCallback(() => {
        savedCallback.current(clearCustomInterval);
    }, [callback, clearCustomInterval]);

    useEffect(() => {
        if (delayExecution) {
            (async () => new Promise(() => {
                timerId.current = setTimeout(() => {
                }, delayExecution);
            }))();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);

            intervalId.current = id;
        }

        return () => {
            clearInterval(intervalId.current);
            clearTimeout(timerId.current);
        };
    }, [tick, delay]);
};

export const updateSessionStorageObject = (key: string, data: object) => {
    try {
        const cachedData = global.sessionStorage.getItem(key);

        if (!cachedData) {
            global.sessionStorage.setItem(key, JSON.stringify(data));
        } else {
            global.sessionStorage.setItem(key, JSON.stringify({ ...JSON.parse(cachedData), ...data }));
        }
    } catch (err) {
        console.error(err);
    }
};

export const setServiceWorkerAccessToken = (accessToken) => {
    window?.navigator?.serviceWorker?.controller?.postMessage({
        type: 'ACCESS_TOKEN',
        payload: accessToken,
    });
};

export const getCurrentEnv = () => {
    const suffix = global.serviceConfig.DMS_URL_SUFFIX;

    if (suffix.toLowerCase().includes('dev') && global.window.location.hostname === 'localhost') { return envs.LOCALHOST; }
    if (suffix.toLowerCase().includes('dev')) { return envs.DEV; }
    if (suffix.toLowerCase().includes('sandbox')) return envs.SIT;
    if (suffix.toLowerCase().includes('uat')) return envs.UAT;

    return envs.PROD;
};

export const getDowntimeFlags = () => {
    const nowTimestamp = +(new Date());
    const [begin, end] = [
        +(parse(DOWNTIME[0], dateTimeSecondsFormat, new Date())),
        +(parse(DOWNTIME[1], dateTimeSecondsFormat, new Date())),
    ];

    if (getCurrentEnv() !== envs.PROD) {
        return {
            isBeforeDownTimeNow: nowTimestamp <= end,
            isDownTimeNow: false,
        };
    }

    return {
        isBeforeDownTimeNow: nowTimestamp < begin,
        isDownTimeNow: nowTimestamp >= begin && nowTimestamp <= end,
    };
};

export const downtimeProtectedNavigation = (callback, withPrevent = false) => (...args) => {
    const { isDownTimeNow } = getDowntimeFlags();

    if (isDownTimeNow) {
        if (withPrevent && args?.[0]) {
            args[0].preventDefault();
        }
        post(DownTime.NavigationBlock);

        return false;
    }

    return callback(args);
};
