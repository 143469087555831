import React, { useMemo } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import Title from '@hydrogen/elements.ui.components.title';

import { container } from '@hydrogen/elements.core.di';
import { ContentBoxContent, ContentBoxEmpty } from '@hydrogen/elements.ui.components.content-box';
import ErrorBox from '@hydrogen/elements.ui.components.error-box';
import { SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import { InvestmentTile } from 'elements/ui/widgets/investment-tile';
import { SkeletonCard } from './skeleton-card';
import { InvestmentCardColors } from '../../../../common/constants';
import { useWithInvestmentsListWidget } from '../../../data/adapters/use-with-investments-list-widget';

export function InvestmentsList({
    showClosed = true,
    dataSource = container.get <typeof useWithInvestmentsListWidget>('useInvestmentsList'),
}) {
    const { t, language } = useLanguage();

    const {
        data = [],
        isLoading,
        isError,
    } = dataSource({
        showClosed,
    });

    let colorIndex = useMemo(() => 0, [data]);

    if (isError) {
        return (
            <>
                <ContentBoxContent>
                    <ErrorBox>
                        {t('common.something-wrong')}
                    </ErrorBox>
                </ContentBoxContent>
            </>
        );
    }

    if (!data?.length && !isLoading) {
        return (
            <></>
        );
    }

    return (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <>
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                </>
            )}
        >
            {data?.map((group) => (
                group.investments.length
                    ? (
                        <>
                            <Title as="h2" className="mb-[1rem] mt-[2rem] !text-[1.375rem] !leading-[2.0625rem]">
                                {group.groupName}
                            </Title>
                            <GridColumns gap={6} columns={{ md: 2 }}>

                                {
                                    group.investments?.map((item) => {
                                        const color = InvestmentCardColors?.[colorIndex] ?? InvestmentCardColors[3];

                                        colorIndex += 1;

                                        return (
                                            <InvestmentTile
                                                key={item.id}
                                                className={color}
                                                isImplemented={item.isImplemented}
                                                data={item}
                                            />
                                        );
                                    })
                                }

                            </GridColumns>

                        </>
                    ) : undefined
            ))}
        </SkeletonWrapper>
    );
}
