import { toUTCFormat } from '@hydrogen/elements.core.i18n';
import { TPerformanceItem } from '@hydrogen/elements.core.contracts';
import { TimeIntervalEnum } from '@hydrogen/elements.core.types';

export const getOptions = (
    // eslint-disable-next-line default-param-last
    data: TPerformanceItem[] = [],
    dateRange?,
): { x: number, y: number }[] => {
    if (data.length === 0) {
        return [
            {
                x: +new Date(toUTCFormat(new Date(dateRange?.from))),
                y: 0,
            },
            {
                x: +new Date(toUTCFormat(new Date(dateRange?.to))),
                y: 0,
            },
        ];
    }

    return (
        data.map(({ value, date }) => ({
            x: +new Date(toUTCFormat(date)),
            y: value * 100 || 0,
        })));
};

export const timeIntervalsDefault = [
    TimeIntervalEnum.YTD,
    TimeIntervalEnum.YEARS1,
    TimeIntervalEnum.YEARS3,
    TimeIntervalEnum.YEARS5,
    TimeIntervalEnum.SINCE_INCEPTION,
];
