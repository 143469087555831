import React from 'react';
import { TooltipFormatterContextObject, Point } from 'highcharts';

export declare type TooltipComponentProps = TooltipFormatterContextObject & {
    chartKey?: string;
};

type LegendPointType = Point & {
    currencyCode?: string
    amount?: string,
}

type PieChartTooltipProps = TooltipComponentProps & {
    formatValue?: (value?: string | number | null,
                   point?: LegendPointType
    ) => React.ReactNode | string | null,
    formatLabel?: (value?: string | number | null) => React.ReactNode | string | null,
}
export const PieChartTooltip = (props: PieChartTooltipProps) => (
    <div className="rounded-2xl rounded-bl-none !border-none shadow-none !py-2 !px-2.5 bg-accent-500 text-[0.75rem] leading-[1.125rem] !text-various-blue">
        <b>
            {props?.formatValue
                ? props?.formatValue(props.percentage, props.point)
                : props.percentage}
        </b>
        <div className="flex mt-1">
            {
                props.color && (
                    <div
                        style={{ backgroundColor: props.color as string }}
                        className="flex-shrink-0 self-start w-2.5 h-2.5 mt-1 mr-2 rounded-full"
                    />
                )
            }
            {props?.formatLabel ? props?.formatLabel(props?.chartKey) : props?.chartKey}
        </div>
    </div>
);
