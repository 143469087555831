import React from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';

import { FirstLoginHeader } from 'elements/ui/components/first-login-header';

export function AccountActivationExpired() {
    const { t } = useLanguage();

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.activationCode.name')}
                stepTitle={t('firstLogin.activationCode.title.error')}
                stepDesc={t('firstLogin.activationCode.desc.error')}
                stepImage="/static/img/code_expired.svg"
                imgAlt={t('alt.codeExpired')}
            />
        </>
    );
}
