import { useLanguage } from '@hydrogen/elements.core.i18n';
import React, { useMemo } from 'react';
import { useClientsOverviewInvestments } from '../../rest/use-clients-overview-investments';
import { AccessPolicyDescriptions, productExternalIds } from '../../../../common/constants';
import { isProxyAccessPolicy } from '../../../../common/utils';
import { formatCurrency, formatPercentage } from '../../../../common/formaters';
import { InvestmentStatus } from '../../../core/contracts';

export type CardType = {
    isJAIconVisible: boolean,
    title: string,
    subTitle: string[] | undefined,
    subSubtitle: string[] | undefined,
    href?: string,
    onClick?: React.MouseEventHandler<HTMLElement>,
    accountNumber?: number | string,
    performance?: string,
    value?: string,
    id: number,
    isImplemented: boolean,
}

export interface IInvestmentsList {
    groupName: string
    investments: CardType[]
}

export interface IInvestmentsListReqParams {
    showClosed?: boolean
}

export const investmentGroupsDefinition = [
    {
        groupName: 'overview.investmentList.pensionProvisionGroupName',
        rule: ({ externalId }) => [
            productExternalIds.aaa,
            productExternalIds.fz,
            productExternalIds.fzp,
        ].includes(externalId),
    },
    {
        groupName: 'overview.investmentList.flexiblePensionProvisionGroupName',
        rule: ({ externalId, accessPolicyId }) => [
            productExternalIds.zivv,
            productExternalIds.zifd,
            productExternalIds.azpNeu,
            productExternalIds.azpNeuSB,
            productExternalIds.azpAlt,
            productExternalIds.pk,
            productExternalIds.zic,
        ].includes(externalId) && !isProxyAccessPolicy(accessPolicyId),
    },
    {
        groupName: 'overview.investmentList.proxyAccountsGroupName',
        rule: ({ externalId, accessPolicyId }) => [
            productExternalIds.zivv,
            productExternalIds.zifd,
            productExternalIds.azpNeu,
            productExternalIds.azpNeuSB,
            productExternalIds.azpAlt,
            productExternalIds.pk,
            productExternalIds.zic,
        ].includes(externalId) && isProxyAccessPolicy(accessPolicyId),
    },
];

export function useWithInvestmentsListWidget({
    showClosed,
}: IInvestmentsListReqParams) {
    const { t, language } = useLanguage();
    const investmentsQuery = useClientsOverviewInvestments({
        language,
        showClosed,
    });

    const data = useMemo<IInvestmentsList[]>(
        () => {
            if (investmentsQuery?.data?.length) {
                const investmentGroups: IInvestmentsList[] = investmentGroupsDefinition.map((item) => ({
                    groupName: t(item.groupName),
                    investments: [],
                }));

                return investmentsQuery.data.reduce((akku, invest) => {
                    akku.forEach(
                        (investmentGroup, i) => {
                            if (investmentGroupsDefinition[i].rule({
                                externalId: invest.product.externalId,
                                accessPolicyId: invest.accessPolicyId,
                            })) {
                                const subTitle = [invest?.name || `${t('positions.portfolio', 'Portfolio')} ${invest?.id}`];
                                const subSubtitle: string[] = [];
                                const isImplemented = invest.status === InvestmentStatus.implemented;

                                if (invest?.rubrik) {
                                    subTitle.push(invest?.rubrik);
                                }
                                if (isProxyAccessPolicy(invest?.accessPolicyId)) {
                                    subSubtitle.push(t(AccessPolicyDescriptions?.[invest?.accessPolicyId]));
                                }
                                if (invest.isJoint) {
                                    subSubtitle.push(t('common.jointAccountName'));
                                }
                                investmentGroup.investments.push({
                                    title: invest?.product?.description,
                                    subTitle,
                                    subSubtitle,
                                    value: formatCurrency(invest?.value ?? 0, invest?.currency),
                                    performance: invest?.performanceTwr
                                        ? formatPercentage((invest?.performanceTwr ?? NaN) * 100, true)
                                        : '',
                                    accountNumber: invest?.name || `${t('positions.portfolio', 'Portfolio')} ${invest?.id}`,
                                    isJAIconVisible: invest.isJoint,
                                    id: invest.id,
                                    isImplemented,
                                    href: isImplemented ? `/investment/${invest.id}` : '',
                                });
                            }
                        },
                    );

                    return akku;
                }, investmentGroups);
            }

            return [];
        },
        [investmentsQuery.data, language],
    );

    return {
        ...investmentsQuery,
        data,
        isLoading: investmentsQuery.isLoading || investmentsQuery.isIdle,
    };
}
