import React, { useMemo } from 'react';

import { InformationCircleIcon } from '@heroicons/react/outline';
import { container } from '@hydrogen/elements.core.di';
import { formatDate, useLanguage } from '@hydrogen/elements.core.i18n';
import { ContentBoxEmpty, ContentBoxError } from '@hydrogen/elements.ui.components.content-box';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import Icon, { IconsSizes } from '@hydrogen/elements.ui.icon';
import { v4 as uuidv4 } from 'uuid';

import { ContentBox } from 'elements/ui/components/content-box';

import Tooltip from '@hydrogen/elements.ui.components.tooltip';
import { useInvestmentWithdrawal } from 'elements/data/rest/use-investment-withdrawal';

type ReallocationProps<TDataSource> = {
    dataSource?: TDataSource;
}

type ReallocationItemProps = {
    label: string,
    value?: string | number,
    labelClassName?: string,
    valueClassName?: string,
}

export const InvestmentReallocation = ({
    dataSource = container.get<typeof useInvestmentWithdrawal>('useInvestmentWithdrawal'),
}: ReallocationProps<typeof useInvestmentWithdrawal >) => {
    const { t, language } = useLanguage();

    const {
        data,
        isLoading,
        isError,
    } = dataSource();

    const ReallocationItem = ({
        label,
        value,
        labelClassName = 'font-medium mb-1 min-w-[7rem]',
        valueClassName = 'text-right text-secondary-300 mb-1',

    }: ReallocationItemProps) => (
        <div className="flex">
            <div className={labelClassName}>
                {label}
            </div>
            <div className={valueClassName}>
                {value}
            </div>
        </div>
    );

    const hasReallocation = useMemo(() => data?.nextReallocations && data?.nextReallocations?.length > 0, [data]);

    const expandedData = useMemo(() => {
        const currentStrategy = data?.currentStrategy ? [{ strategyName: data.currentStrategy, date: null }] : [];
        const nextReallocations = hasReallocation && data?.nextReallocations ? data.nextReallocations : [];

        return [...currentStrategy, ...nextReallocations];
    }, [data, hasReallocation, language]);

    const reallocationData = useMemo(() => expandedData?.map((reallocation, index) => (
        <ReallocationItem
            key={uuidv4()}
            label={index === 0 ? t('reallocation.current') : formatDate(new Date(reallocation?.date ? reallocation.date : ''), 'dd/MM/yyyy')}
            value={reallocation?.strategyName}
        />
    )), [expandedData, language]);

    return (
        <ContentBox
            as="section"
            isMainBox
            title={(
                <div>
                    {t('reallocation.title')}
                    <div className="float-right inline-block pt-1">
                        <Tooltip
                            tooltip={(
                                <div
                                    className="text-darkBlue bg-ziagWhite p-3 rounded-2xl rounded-br-none font-medium text-xs max-w-[350px] text-left break-words"
                                >
                                    {t('reallocation.tooltip.info')}
                                </div>
                            )}
                            placement="top-end"
                            variant="pure"
                        >
                            <Icon
                                className="w-7 h-7"
                                size={IconsSizes.custom}
                                icon={InformationCircleIcon}
                            />
                        </Tooltip>
                    </div>
                </div>
            )}
            variant="tabbed"
        >
            <GridColumns
                columns={{
                    lg: 1,
                    default: 1,
                }}
                className="gap-y-[1rem] pt-[1rem] pb-[1.75rem] px-[1.5rem] text-base text-primary-500"
            >
                <ContentBoxError isError={isError} errorMessage={t('common.something-wrong')}>
                    <SkeletonWrapper
                        isLoading={isLoading}
                        Skeleton={(
                            <SkeletonLine
                                height={44}
                                width="full"
                            />
                        )}
                    >
                        {hasReallocation
                            ? (
                                reallocationData
                            )
                            : (
                                <ContentBoxEmpty className="">
                                    <>{t('common.data-empty')}</>
                                </ContentBoxEmpty>
                            )}
                    </SkeletonWrapper>
                </ContentBoxError>
            </GridColumns>
        </ContentBox>
    );
};
