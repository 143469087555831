import { useParams } from '@hydrogen/elements.core.router';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';
import { useQuery } from 'react-query';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';

type InvestmentDetails = {
    issueCommission: number,
    pbNumber: string,
    provisioningRate: number,
    vstNumber: string,
    openingDate: Date,
    closingDate: Date,
    lpzPlanId: string,
    memberFullName: string,
    provisionReceiver: string,
    accountIban: string,
    portfolioName: string,
    generalAgency: any
}

export const useInvestmentDetails = () => {
    const investmentId = useInvestmentId();
    const { investmentId: fromUrl } = useParams();

    const portfolioId = investmentId ?? fromUrl;

    const query = useQuery<InvestmentDetails>(
        ['investment-details', portfolioId],
        () => clientServiceApi().get(`/v1.0/Plan/${portfolioId}/details`).then(({ data }) => data),
        {
            enabled: !!portfolioId,
            keepPreviousData: true,

        },
    );

    return {
        ...query,
        isLoading: query.isIdle || query.isLoading || (query.isPreviousData && query.isFetching),
    };
};
