import React, { ReactNode } from 'react';

import Grid from '@hydrogen/elements.ui.components.grid';
import { useLanguage } from '@hydrogen/elements.core.i18n';

import { ContactInfo } from 'elements/ui/widgets/contact-info';

type AuthLayoutProps = {
    children: ReactNode;
}

export function AuthLayout({ children }: AuthLayoutProps) {
    const { t } = useLanguage();

    return (
        <>
            <Grid gap={1} columns={{ md: 2 }} className="justify-items-center min-h-full flex-1 h-screen">
                <div className="h-full w-full md:w-8/12 md:max-w-[350px]">
                    {children}
                </div>
                <div className="h-full w-full hidden md:flex">
                    <div className="w-full h-full ml-8 flex flex-nowrap items-end">
                        <img
                            src="/static/img/bg-image.svg"
                            alt={t('alt.authBackgroundImage')}
                            className="w-6/12"
                        />
                    </div>
                </div>
            </Grid>
            <Grid className="px-[1.75rem] md:flex md:justify-center">
                <ContactInfo phoneNumber="+41 (0)44 628 08 00" className="mt-[84px] md:max-w-[350px]" />
            </Grid>
        </>
    );
}
