import React from 'react';

type PieChartSkeletonProps = {
    height?: string

}
const PieChartSkeleton = ({ height = '280px' }: PieChartSkeletonProps) => (
    <div style={{ height }}>
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 758 600"
            className="animate-pulse text-misc-200"
        >
            <rect
                fill="transparent"
                className="highcharts-background"
                x="0"
                y="0"
                rx="0"
                ry="0"
            />
            <g className="highcharts-pane-group" data-z-index="0" />
            <rect
                fill="none"
                className="highcharts-plot-border"
                data-z-index="1"
                x="10"
                y="0"
            />
            <g className="highcharts-series-group" data-z-index="3">
                <g
                    className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker"
                    data-z-index="0.1"
                    opacity="1"
                    transform="translate(10,0) scale(1 1)"
                    clipPath="none"
                >
                    <path
                        fill="currentColor"
                        d="M 368.9448045617986 10.000005620915942 A 271 271 0 0 1 590.5018491708103 124.86566420574994 L 512.9762019610266 179.51268173373745 A 176.15 176.15 0 0 0 368.9641229651691 104.85000365359534 Z"
                        transform="translate(0,0)"
                        stroke="#6E82FF"
                        strokeWidth="2"
                        opacity="1"
                        strokeLinejoin="round"
                        className="highcharts-point highcharts-color-0"
                    />
                    <path
                        fill="currentColor"
                        d="M 590.6578727296667 125.08724408516514 A 271 271 0 0 1 523.979603843889 503.31131863310793 L 469.7367424985278 425.50235711152015 A 176.15 176.15 0 0 0 513.0776172742834 179.65670865535733 Z"
                        transform="translate(0,0)"
                        stroke="#9D57F9"
                        strokeWidth="2"
                        opacity="1"
                        strokeLinejoin="round"
                        className="highcharts-point highcharts-color-1"
                    />
                    <path
                        fill="currentColor"
                        d="M 523.7572150725123 503.46618705547183 A 271 271 0 0 1 98.58276570007115 298.76286557899317 L 193.22879770504622 292.5458626263455 A 176.15 176.15 0 0 0 469.59218979713296 425.6030215860567 Z"
                        transform="translate(0,0)"
                        stroke="#4DB0D5"
                        strokeWidth="2"
                        opacity="1"
                        strokeLinejoin="round"
                        className="highcharts-point highcharts-color-2"
                    />
                    <path
                        fill="currentColor"
                        d="M 98.56513804605851 298.49243950833073 A 271 271 0 0 1 112.83467030912794 192.5674049664557 L 202.49253570093316 223.51881322819622 A 176.15 176.15 0 0 0 193.21733972993803 292.370085680415 Z"
                        transform="translate(0,0)"
                        stroke="#7ABF53"
                        strokeWidth="2"
                        opacity="1"
                        strokeLinejoin="round"
                        className="highcharts-point highcharts-color-3"
                    />
                    <path
                        fill="currentColor"
                        d="M 112.92323097207691 192.31128389575292 A 271 271 0 0 1 368.6235864367826 10.000261415569923 L 368.7553311839087 104.85016992012046 A 176.15 176.15 0 0 0 202.55010013184997 223.3523345322394 Z"
                        transform="translate(0,0)"
                        stroke="#FA6588"
                        strokeWidth="2"
                        opacity="1"
                        strokeLinejoin="round"
                        className="highcharts-point highcharts-color-4"
                    />
                </g>
                <g
                    className="highcharts-markers highcharts-series-0 highcharts-pie-series"
                    data-z-index="0.1"
                    opacity="1"
                    transform="translate(10,0) scale(1 1)"
                    clipPath="none"
                />
            </g>
        </svg>
    </div>
);

export default PieChartSkeleton;
