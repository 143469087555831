import React from 'react';

import { Outlet } from '@hydrogen/elements.core.router';
import BaseLayout from '@hydrogen/elements.ui.layouts.base';
import { ReEnrollDevice } from '../../elements/ui/widgets/re-enroll-device';

export const reEnrollDevice = {
    Component: BaseLayout,
    url: '/',
    index: true,
    props: {
        children: <Outlet />,
        footer: null,
    },
    children: [
        {
            url: 'access-app/*',
            Component: ReEnrollDevice,
            props: {
                parentLinkPattern: 'access-app',
            },
        },
    ],
};
