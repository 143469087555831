import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';

export type LinkContactProps = {
  className?: string;
  value: string,
  variant?:
    | 'email'
    | 'phone';
};

export const LinkContact = ({
    className,
    value,
    variant = 'phone',
}: LinkContactProps) => (
    <a href={`${variant === 'email' ? 'mailto' : 'tel'}:${value}`} className={classNames(className, 'block hover:underline text-secondary-300')}>
        <i className={classNames(variant === 'email' ? 'icon-mail-closed' : 'icon-phone', 'icon inline-block text-[1.2rem] mr-2')} />
        {value}
    </a>
);
