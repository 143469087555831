import React, { useCallback, useEffect, useMemo } from 'react';

import { formatDate, useLanguage } from '@hydrogen/elements.core.i18n';
import { Content } from '@hydrogen/elements.ui.layouts.base';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { TimeIntervalEnum } from '@hydrogen/elements.widgets.wealth.asset-development';
import { container } from '@hydrogen/elements.core.di';
import { useNavigate } from '@hydrogen/elements.core.router';

import { useAnalysisPerformance } from '@hydrogen/elements.data.wealth.rest.use-analysis-performance';
import { dispatchInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';
import { useParams } from 'react-router-dom';
import { classNames } from '@hydrogen/elements.ui.utils';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import { Secure } from 'hydrogen/elements.auth.client-portal';

import { AssetDevelopment } from 'elements/ui/widgets/asset-development';
import { Deposits } from 'elements/ui/widgets/deposits';
import { Performance } from 'elements/ui/widgets/performance';
import { Positions } from 'elements/ui/widgets/positions';
import { Allocations } from 'elements/ui/widgets/allocations';
import { AccountInfo } from 'elements/ui/widgets/account-info';
import { Transactions } from 'elements/ui/widgets/transactions';
import { useAnalysisAssetDevelopment } from 'elements/data/rest/use-analysis-asset-development';
import Title from '@hydrogen/elements.ui.components.title';
import { DateRangeType } from '@hydrogen/elements.ui.components.daterange-options';
import { dateRangesDefault } from '@hydrogen/elements.core.utils.base';
import { InvestmentRundown } from '../investment-rundown';
import { InvestmentContacts } from '../investment-contacts';
import { AccordionSection } from '../../components/accordion-section';
import { LinkButton } from '../../components/button';
import { InvestmentStatus } from '../../../core/contracts';
import { FIRST_OF_APR_24, FIRST_OF_JAN_23, productExternalIds } from '../../../../common/constants';
import { useClientsOverviewInvestments } from '../../../data/rest/use-clients-overview-investments';
import { InvestmentRisk } from '../investment-risk';
import { InvestmentTransfer } from '../investment-transfer';
import { InvestmentReallocation } from '../investment-reallocation';
import { InvestmentWithdrawalPlan } from '../investment-withdrawal-plan';
import { useWithInvestmentWithdrawalWidget } from '../../../data/adapters/use-with-investment-withdrawal-widget';
import { useProductType } from '../../../data/helpers/use-product-type';

enum WidgetIds {
    KeyFiguresWidget,
    PerformanceGraph,
    AssetDevelopmentGraph,
    DepositsWidget,
    InvestmentAllocation,
    InvestmentRiskWidget,
    PositionsWidget,
    TransactionsWidget,
    WantToDepositMore,
    YourPointOfContact,
    WithdrawalPlan,
    TransferPlan,
    NextReallocation,
}

const AccordionItem = ({
    title,
    subtitle,
}: { title?: string, subtitle?: string }) => (
    <>
        {title && <div className="font-medium">{title}</div>}
        {subtitle && <div>{subtitle}</div>}
    </>
);

export function Investment({
    secureLoading = true,
    dataSource = container.get<typeof useClientsOverviewInvestments>('useClientsOverviewInvestments'),
}: {secureLoading?: boolean; dataSource: typeof useClientsOverviewInvestments}) {
    const { t, language } = useLanguage();
    const navigate = useNavigate();
    const { investmentId } = useParams();
    const gridStyle = 'lg:gap-8 gap-6';

    const showClosed = true;

    useEffect(() => {
        if (investmentId) {
            dispatchInvestmentId(parseInt(investmentId, 10));
        }
    }, [investmentId]);

    const {
        data = [],
        isLoading,
    } = dataSource({
        showClosed,
        language,
    });

    const implementedPortfolios = useMemo(() => data?.filter((i) => i.status === InvestmentStatus.implemented), [data]);

    const selectedPortfolio = useMemo(() => implementedPortfolios.find((f) => f.id === Number(investmentId)), [investmentId, implementedPortfolios]);

    const { isThreeB } = useProductType({ externalId: selectedPortfolio?.product?.externalId });

    const rangesOptions: DateRangeType[] = useMemo(() => {
        if (isThreeB) {
            return [{
                from: formatDate(new Date(FIRST_OF_APR_24)),
                to: formatDate(new Date()),
            }];
        }

        return [TimeIntervalEnum.YTD, TimeIntervalEnum.YEARS1, TimeIntervalEnum.SINCE_INCEPTION, TimeIntervalEnum.CUSTOM_RANGE]
            .map((key) => (dateRangesDefault.find((range) => range.key === key)))
            .filter((range) => !!range)
            .map((range) => ({
                ...range,
                from: range?.key === TimeIntervalEnum.SINCE_INCEPTION ? formatDate(new Date(FIRST_OF_JAN_23)) : range?.from,
                label: t(`common.${range?.key}`),
            }));
    }, [t, isThreeB]);

    const widgets = useMemo(() => [
        {
            id: WidgetIds.KeyFiguresWidget,
            hideFor: [],
            Component: () => (<InvestmentRundown columnsNumber={2} />),
        },
        {
            id: WidgetIds.PerformanceGraph,
            hideFor: [productExternalIds.pk],
            Component: () => (
                <Performance
                    dateRanges={rangesOptions}
                    dataSource={
                        container.get<typeof useAnalysisPerformance>('useAnalysisPerformance')
                    }
                    isThreeB={isThreeB}
                />
            ),
        },
        {
            id: WidgetIds.AssetDevelopmentGraph,
            hideFor: [productExternalIds.pk],
            Component: () => (
                <AssetDevelopment
                    dataSource={
                        container.get<typeof useAnalysisAssetDevelopment>('useAnalysisAssetDevelopment')
                    }
                    isThreeB={isThreeB}
                    dateRanges={rangesOptions}
                />
            ),
        },
        {
            id: WidgetIds.TransferPlan,
            hideFor: [
                productExternalIds.aaa,
                productExternalIds.fz,
                productExternalIds.fzp,
                productExternalIds.pk,
                productExternalIds.zivv,
                productExternalIds.zifd,
                productExternalIds.zic,
                productExternalIds.azpNeu,
                productExternalIds.azpNeuSB,
                productExternalIds.azpAlt,
            ],
            Component: () => (
                <InvestmentTransfer />
            ),
            heightRatio: 2,
        },
        {
            id: WidgetIds.WithdrawalPlan,
            hideFor: [
                productExternalIds.aaa,
                productExternalIds.fz,
                productExternalIds.fzp,
                productExternalIds.pk,
                productExternalIds.zic,
                productExternalIds.azpAlt,
            ],
            Component: () => (
                <InvestmentWithdrawalPlan
                    dataSource={container.get<typeof useWithInvestmentWithdrawalWidget>('useWithInvestmentWithdrawalWidget')}
                />
            ),
            heightRatio: 2,
        },
        {
            id: WidgetIds.DepositsWidget,
            hideFor: [
                productExternalIds.fz,
                productExternalIds.fzp,
                productExternalIds.zifd,
                productExternalIds.zivv,
                productExternalIds.pk,
                productExternalIds.zic,
                productExternalIds.azpNeu,
                productExternalIds.azpNeuSB,
                productExternalIds.azpAlt,
            ],
            Component: () => (
                <Deposits />
            ),
            heightRatio: 1,
        },
        {
            id: WidgetIds.InvestmentAllocation,
            hideFor: [
                productExternalIds.pk,
                productExternalIds.zic,
            ],
            Component: () => (
                <Allocations />
            ),
            heightRatio: 2,
        },
        {
            id: WidgetIds.InvestmentRiskWidget,
            hideFor: [
                productExternalIds.pk,
                productExternalIds.zic,
            ],
            Component: () => (
                <InvestmentRisk />
            ),
            heightRatio: 1,
        },
        {
            id: WidgetIds.NextReallocation,
            hideFor: [
                productExternalIds.fz,
                productExternalIds.fzp,
                productExternalIds.zifd,
                productExternalIds.zivv,
                productExternalIds.pk,
                productExternalIds.zic,
                productExternalIds.aaa,
            ],
            Component: () => (
                <InvestmentReallocation />
            ),
            heightRatio: 1,
        },
        {
            id: WidgetIds.PositionsWidget,
            hideFor: [
                productExternalIds.pk,
            ],
            Component: () => (
                <Positions />
            ),
            heightRatio: 1,
        },
        {
            id: WidgetIds.TransactionsWidget,
            hideFor: [],
            Component: () => (
                <Transactions />
            ),
            heightRatio: 1,
        },
        {
            id: WidgetIds.WantToDepositMore,
            hideFor: [productExternalIds.zic],
            Component: () => (
                <AccountInfo />
            ),
            heightRatio: 1,
        },
        {
            id: WidgetIds.YourPointOfContact,
            hideFor: [],
            Component: () => (
                <InvestmentContacts />
            ),
            heightRatio: 1,
        },
    ], [isThreeB, rangesOptions]);

    const getWidgetIfNotHidden = useCallback(({ widgetId }) => {
        if (!selectedPortfolio) return undefined;
        const widgetToShow = widgets.find(({ id }) => (widgetId === id));

        if (!!widgetToShow && !widgetToShow?.hideFor.includes(selectedPortfolio.product.externalId)) {
            return <widgetToShow.Component />;
        }

        return undefined;
    }, [selectedPortfolio?.product?.externalId]);

    const distributedWidgetsBetweenColumns = useMemo(() => {
        if (!selectedPortfolio) return undefined;

        const allVisibleWidgets = widgets.reduce((akku: { widgetsIds, totalRatioSize: number }, item) => {
            if (item?.heightRatio && !item?.hideFor.includes(selectedPortfolio.product.externalId)) {
                akku.totalRatioSize += item.heightRatio;
                akku.widgetsIds.push(item);
            }

            return akku;
        }, { widgetsIds: [], totalRatioSize: 0 });

        let leftColumnRatio = 0;

        return allVisibleWidgets?.widgetsIds?.reduce((akku, item) => {
            if (allVisibleWidgets.totalRatioSize / 2 > leftColumnRatio) {
                akku.left.push(item);
                leftColumnRatio += item.heightRatio;
            } else {
                akku.right.push(item);
            }

            return akku;
        }, { left: [], right: [] });
    }, [widgets, selectedPortfolio?.product?.externalId]);

    return (
        <Secure loading={secureLoading}>
            <div className="py-4 border-b border-header-border text-center">
                <SkeletonWrapper
                    isLoading={isLoading}
                    Skeleton={(
                        <div className="flex justify-center">
                            <SkeletonLine
                                className="h-[1.85rem]"
                                width={28}
                            />
                        </div>
                    )}
                >
                    {implementedPortfolios.length > 1
                        ? (
                            <AccordionSection
                                className="[&_button]:justify-center [&_button]:items-center [&_h3]:font-normal"
                                title={(
                                    <AccordionItem
                                        title={selectedPortfolio?.product?.description}
                                        subtitle={selectedPortfolio?.name ?? `${t('positions.portfolio', 'Portfolio')} ${selectedPortfolio?.id}`}
                                    />
                                )}
                            >
                                <div className="max-h-[4.5rem] pt-3 gap-y-3 overflow-auto">
                                    {implementedPortfolios.map(({ id, name, product: { description } }) => (
                                        <>
                                            {
                                                id !== Number(investmentId) && (
                                                    <LinkButton
                                                        className="w-full text-center"
                                                        onClick={() => navigate(`/investment/${id}`)}
                                                    >
                                                        <AccordionItem
                                                            title={description}
                                                            subtitle={name ?? `${t('positions.portfolio', 'Portfolio')} ${id}`}
                                                        />
                                                    </LinkButton>
                                                )
                                            }
                                        </>
                                    ))}
                                </div>
                            </AccordionSection>
                        )
                        : (
                            <div className="leading-[1.84375rem] text-primary-500 text-center">
                                <AccordionItem
                                    title={implementedPortfolios?.[0]?.product?.description}
                                    subtitle={implementedPortfolios?.[0]?.name}
                                />
                            </div>
                        )}
                </SkeletonWrapper>
            </div>
            <GridColumns className="flex-1 bg-neutral-100">
                <Content className="max-w-[75rem] lg:pb-16 mx-auto mt-4 lg:mb-[1.5rem]">
                    {selectedPortfolio?.rubrik && (
                        <Title
                            className="pb-6 text-[1.875rem] leading-[2.6875rem] font-ogg"
                        >
                            {`<${selectedPortfolio?.rubrik}>`}
                        </Title>
                    )}
                    <GridColumns className={gridStyle} columns={1}>
                        {getWidgetIfNotHidden({ widgetId: WidgetIds.KeyFiguresWidget })}
                        {getWidgetIfNotHidden({ widgetId: WidgetIds.PerformanceGraph })}
                        {getWidgetIfNotHidden({ widgetId: WidgetIds.AssetDevelopmentGraph })}
                        <GridColumns className={classNames(gridStyle, 'items-start')} columns={{ md: 2 }}>
                            <GridColumns className={gridStyle} columns={1}>
                                {
                                    distributedWidgetsBetweenColumns?.left.map(({ id }) => (
                                        getWidgetIfNotHidden({ widgetId: id })
                                    ))
                                }
                            </GridColumns>
                            <GridColumns className={gridStyle} columns={1}>
                                {
                                    distributedWidgetsBetweenColumns?.right.map(({ id }) => (
                                        getWidgetIfNotHidden({ widgetId: id })
                                    ))
                                }
                            </GridColumns>
                        </GridColumns>
                    </GridColumns>
                </Content>
            </GridColumns>
        </Secure>
    );
}
