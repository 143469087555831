import React, { useCallback, useEffect, useState } from 'react';

import { container } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { subscribe } from '@hydrogen/elements.core.event-bus';

import { FirstLoginClientDetails, FirstLoginTopics, StepPageProps } from 'elements/core/contracts';
import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { useEmailConfirmation } from 'elements/data/rest/use-email-confirmation';
import { Button, LinkButton } from 'elements/ui/components/button';
import { OtpField } from 'elements/ui/components/otp-field';
import { Text } from 'elements/ui/components/text';
import { useStepper } from 'elements/ui/components/stepper/components';
import { useNotifications } from '../../components/notification';

export function EmailConfirmation({
    links: { nextLink, skipLink = '', prevLink },
    dataSource = container.get<typeof useEmailConfirmation>(
        'useEmailConfirmation',
    ),
}: StepPageProps<typeof useEmailConfirmation>) {
    const {
        inProgress, resendInProgress, confirmEmail, resendVerificationCode,
    } = dataSource();
    const [clientData, setClientData] = useState<FirstLoginClientDetails>();
    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { action, negative } = useNotifications();
    const { t } = useLanguage();
    const { onStepComplete, onStepSkip } = useStepper() || { onStepComplete: () => {} };

    useEffect(() => {
        const unsubscribe = subscribe(FirstLoginTopics.FirstLoginClientDetails, (payload) => {
            setClientData(payload);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        if (!!clientData && !clientData.email) {
            onStepSkip?.(skipLink);
        }
    }, [clientData, skipLink]);

    const onVerifyClick = useCallback(async () => {
        try {
            if (code?.length < 6) {
                setError(t('validation.mandatoryField'));

                return;
            }

            const result = await confirmEmail(code, clientData?.contactIdentityId ?? '');

            if (!result.emailVerified) {
                setError(t('validation.invalidEmailVerificationCode'));

                return;
            }

            if (typeof onStepComplete === 'function') {
                onStepComplete({
                    nextLink: result.phoneVerificationKeySent ? nextLink : '/signup/phone-form',
                    prevLink,
                });
            }
        } catch (err) {
            console.error(err);
            negative(t('notifications.somethingWentWrong'));
        }
    }, [nextLink, code]);

    const onResendClick = useCallback(async () => {
        try {
            await resendVerificationCode(clientData?.contactIdentityId ?? '');
            action(t('notifications.newCodeSent'));
        } catch (err: any) {
            negative(t('notifications.errorSendingNewCode'));
        }
    }, [resendVerificationCode, clientData]);

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.emailConfirmation.name')}
                stepTitle={t('firstLogin.emailConfirmation.title')}
                stepDesc={t('firstLogin.emailConfirmation.desc')}
                stepImage="/static/img/mail.svg"
                prevLink={prevLink}
                imgAlt={t('alt.accountActivation')}

            />
            <Text
                className="-mt-2 mb-[1.5rem]"
            >
                {t('firstLogin.emailConfirmation.emailCode', { email: `<strong>${clientData?.email ?? '-'}</strong>` })}
            </Text>
            <OtpField
                inputType="number"
                value={code}
                onChange={(val: string) => setCode(val)}
                onFullFill={() => {
                    setError('');
                }}
                length={6}
                error={error}
            />
            <Button
                loading={inProgress}
                disabled={inProgress || !code}
                onClick={onVerifyClick}
            >
                {t('firstLogin.emailConfirmation.verifyButton')}
            </Button>
            <LinkButton
                className="mt-[20px]"
                onClick={onResendClick}
                disabled={resendInProgress}
            >
                {t('firstLogin.emailConfirmation.resendCode')}
            </LinkButton>
            <div className="mt-[74px] pt-[1.5rem] border-t w-full text-start">
                <div>
                    <Text variant="label">
                        {t('firstLogin.emailConfirmation.notYourEmail')}
                    </Text>
                </div>
                <LinkButton
                    className="mt-[4px] text-[16px] leading-[24px]"
                    onClick={() => onStepSkip?.(skipLink)}
                >
                    {t('firstLogin.emailConfirmation.updateEmailButton')}
                </LinkButton>
            </div>
        </>
    );
}
