import React from 'react';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useNavigate } from '@hydrogen/elements.core.router';
import Grid from '@hydrogen/elements.ui.components.grid';
import Title, { TitleTypes } from '@hydrogen/elements.ui.components.title';

import { Button } from 'elements/ui/components/button';
import { Text } from 'elements/ui/components/text';
import { FirstLoginStepLayout } from 'elements/ui/layouts/first-login-step-layout';
import { TermsFormFields } from '../terms-and-conditions/terms-and-conditions';
import { useAuthenticationData } from '../../../../hydrogen/elements.auth.client-portal';

type TReadTermsDetails = {
termKey : TermsFormFields;
}

const termData = {
    [TermsFormFields.portalTerms]: {
        heading: 'firstLogin.terms.portal',
        data: 'firstLogin.terms.read.portal',
    },
};

export function ReadTermsDetails({
    termKey,
}: TReadTermsDetails) {
    const navigate = useNavigate();
    const { t } = useLanguage();
    const { authData } = useAuthenticationData();

    return (
        <div className="md:mt-0 mt-[1.5rem]">
            <FirstLoginStepLayout prevLink="/signup/terms" withMobileBackButton showBackButton={!authData?.accessToken}>
                <Grid className="justify-items-left text-left text-primary-500 md:w-[calc(100vw_-_330px)]">
                    <Title type={TitleTypes.cardMain} className="mt-2 md:mt-[-0.3125rem] mb-10 md:text-center">
                        {t(termData[termKey].heading)}
                    </Title>
                    <Text variant="desc" className="[&>b]:mb-[1rem] [&>b]:block [&>p]:mb-[3.3125rem] [&>p:last-child]:mb-0">
                        {t(termData[termKey].data)}
                    </Text>
                    <div className="flex justify-center lg:my-[6.5rem] my-12">
                        <Button
                            className="md:w-[315px] bg-white text-secondary-300 hover:text-white"
                            onClick={() => navigate(-1)}
                        >
                            {t('navigation.back')}
                        </Button>
                    </div>
                </Grid>
            </FirstLoginStepLayout>
        </div>
    );
}
