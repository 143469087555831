import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { object, string } from 'yup';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { InputTextSize } from '@hydrogen/elements.ui.components.text-input';

import { useNavigate } from '@hydrogen/elements.core.router';
import { useAuthenticationData } from 'hydrogen/elements.auth.client-portal';
import { Button } from 'elements/ui/components/button';
import { TextInput } from 'elements/ui/components/text-input';
import { useForm } from 'elements/ui/components/use-form';
import { FirstLoginHeader } from '../../components/first-login-header';
import { NUMBER_REGEX } from '../../../../common/validation';

type ForgotPasswordClientNumberForm = {
    clientId: string;
};

type ForgotPasswordClientNumberProps = {
    links: {
        nextLink: string
    }
};

export function ForgotPasswordClientNumberForm(
    { links: { nextLink } }: ForgotPasswordClientNumberProps,
) {
    const { t } = useLanguage();
    const [inProgress, setProgress] = useState(false);
    const navigate = useNavigate();
    const { forgotPassword } = useAuthenticationData();

    const validationSchema = useMemo(
        () => object().shape({
            clientId: string()
                .matches(NUMBER_REGEX, t('validation.numbersOnly'))
                .required(t('validation.mandatoryField')),
        }),
        [t],
    );

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<ForgotPasswordClientNumberForm>({
        validationSchema,
        options: {
            defaultValues: {
                clientId: '',
            },
        },
    });

    const clientId = watch('clientId');

    const isSubmitDisabled = !clientId || inProgress;

    const onFormSubmit = handleSubmit(async (values: ForgotPasswordClientNumberForm) => {
        try {
            setProgress(true);
            await forgotPassword(values.clientId);

            navigate(nextLink);
        } catch (error: unknown) {
            console.error('Error occurred: ', error);
        }
    });

    return (
        <>
            <FirstLoginHeader
                noImage
                stepTitle={t('forgotPassword.client.title')}
                stepDesc={t('forgotPassword.clientNumber.desc')}
                prevLink="/"
            />
            <form className="w-full mt-2 text-left" onSubmit={onFormSubmit}>
                <Controller
                    control={control}
                    name="clientId"
                    render={({ field }) => (
                        <TextInput
                            label={t('loginForm.clientId.label')}
                            labelInline
                            textSize={InputTextSize.lg}
                            error={errors.clientId?.message}
                            {...field}
                        />
                    )}
                />
                <Button
                    loading={inProgress}
                    onClick={onFormSubmit}
                    disabled={isSubmitDisabled}
                >
                    {t('forgotPassword.submitButton.label')}
                </Button>
            </form>
        </>
    );
}
