import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { classNames } from '@hydrogen/elements.ui.utils';
import IconButton, { IconButtonTypes, IconButtonSizes } from '@hydrogen/elements.ui.components.icon-button';

import { Text } from 'elements/ui/components/text';
import { theme } from './theme';

export type NotificationType = 'base' | 'action' | 'alert' | 'negative' | 'info';

const DEFAULT_AUTO_DURATION = 3000; // in ms

type NotificationProps = {
    message: string;
    type?: NotificationType;
    autoClose?: boolean;
    autoDuration?: number;
    onDelete?: () => void;
};

function findRootNotificationsContainer(): HTMLElement {
    const portalId = 'notifications-root';

    return document.getElementById(portalId) as HTMLElement;
}

export function Notification({
    type = 'base', message, autoClose, autoDuration = DEFAULT_AUTO_DURATION, onDelete,
}: NotificationProps) {
    const [isClosing, setIsClosing] = useState(false);

    const typeClasses = classNames(theme.types[type]);

    const baseClasses = classNames(
        theme.shared,
        typeClasses,
        isClosing ? 'translate-x-[150%] flex-initial' : 'animate-slide-in',
    );

    const close = () => setIsClosing(true);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (isClosing) {
            timeoutId = setTimeout(() => {
                onDelete?.();
            }, 300);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isClosing, onDelete]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        if (autoClose) {
            timeoutId = setTimeout(close, autoDuration);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [autoClose]);

    const container = findRootNotificationsContainer();

    return createPortal(
        <div className={classNames('overflow-hidden rounded-2xl min-h-12 transition-[min-height] duration-300 ease-out mb-4', isClosing && 'min-h-0')}>
            <div className={baseClasses}>
                <Text variant="alert" className="text-ziagWhite grow">{message}</Text>
                <div className="[&_button]:bg-transparent [&_button:hover]:ring-transparent flex items-center justify-center [&_svg]:h-6 [&_svg]:w-6 grow-0">
                    <IconButton
                        size={IconButtonSizes.small}
                        type={IconButtonTypes.outline}
                        onClick={close}
                    >
                        <i className="ml-2.5 icon icon-close text-[1.5rem] text-white" />
                    </IconButton>
                </div>
            </div>
        </div>,
        container,
    );
}
