import React, {
    createContext, ReactNode, useContext, useMemo, useState,
} from 'react';

import { Notification, NotificationType } from './notification';

type NotifyProps = {
    autoClose?: boolean;
    autoDuration?: number;
    onDelete?: () => void;
}

type NotificationSettings = NotifyProps & { type: NotificationType }

type Notifications = {
    id: number;
    message: string;
} & NotificationSettings;

type NotificationProviderProps = {
    children: ReactNode;
}

const NotificationContext = createContext<NotificationsAPI>(null as any);

type NotificationsAPI = {
    base: (message: string, props?: NotifyProps) => void;
    action: (message: string, props?: NotifyProps) => void;
    alert: (message: string, props?: NotifyProps) => void;
    negative: (message: string, props?: NotifyProps) => void;
    info: (message: string, props?: NotifyProps) => void;
}

export const NotificationManager = ({ children }: NotificationProviderProps) => {
    const [notifications, setNotifications] = useState<Notifications[]>([]);

    const createNotification = (message: string, props: NotificationSettings) => {
        setNotifications((prevNotifications) => [...prevNotifications,
            {
                id: prevNotifications.length,
                message,
                ...props,
                autoClose: props.autoClose ?? true,
                autoDuration: props.autoDuration ?? 3000,
            }]);
    };

    const deleteNotification = (id: number) => setNotifications(
        notifications.filter((notification) => notification.id !== id),
    );

    const base = (message: string, props?: NotifyProps) => {
        createNotification(message, { type: 'base', ...props });
    };

    const action = (message: string, props?: NotifyProps) => {
        createNotification(message, { type: 'action', ...props });
    };

    const alert = (message: string, props?: NotifyProps) => {
        createNotification(message, { type: 'alert', ...props });
    };

    const negative = (message: string, props?: NotifyProps) => {
        createNotification(message, { type: 'negative', ...props });
    };

    const info = (message: string, props?: NotifyProps) => {
        createNotification(message, { type: 'info', ...props });
    };

    const exposedAPI = useMemo(() => ({
        base, action, alert, negative, info,
    }), [notifications]);

    return (
        <NotificationContext.Provider value={exposedAPI}>
            {children}
            <>
                {notifications.map(({ id, onDelete, ...rest }) => (
                    <Notification
                        key={`${rest.type}_${id}`}
                        {...rest}
                        onDelete={() => {
                            onDelete?.();
                            deleteNotification(id);
                        }}
                    />
                ))}
            </>
        </NotificationContext.Provider>
    );
};

export const useNotifications = (): NotificationsAPI => useContext(NotificationContext);
