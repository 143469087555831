import { useCallback, useState } from 'react';

import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { AxiosResponse } from 'axios';
import { container } from '@hydrogen/elements.core.di';
import { FirstLoginClientDetails } from '../../../core/contracts';
import { useAppTokenValidate } from '../use-app-token-validate';

export type ConfirmEmailResponse = {
    emailVerified: boolean;
    phoneVerificationKeySent: boolean
}

export type UseEmailConfirmationResult = {
    confirmEmail: (email: string, contactIdentityId: string) => Promise<ConfirmEmailResponse>;
    resendVerificationCode: (contactIdentityId: string) => Promise<void>;
    updateEmail: (email: string, contactIdentityId: string) => Promise<FirstLoginClientDetails>;
    inProgress: boolean;
    resendInProgress: boolean
};

export function useEmailConfirmation(): UseEmailConfirmationResult {
    const dataSource = container.get<typeof useAppTokenValidate>('useAppTokenValidate');
    const { checkAppToken } = dataSource();
    const [inProgress, setInProgress] = useState(false);
    const [resendInProgress, setResendInProgress] = useState(false);

    const confirmEmail = useCallback(async (code: string, contactIdentityId: string) => {
        try {
            setInProgress(true);
            await checkAppToken();

            const result: AxiosResponse<ConfirmEmailResponse> = await clientServiceApi().get(`/v1.0/Onboarding/${contactIdentityId}/verify-email-by-code/${code}`);

            return result.data;
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            setInProgress(false);
        }
    }, []);

    const resendVerificationCode = useCallback(async (contactIdentityId: string): Promise<void> => {
        try {
            setResendInProgress(true);
            await checkAppToken();

            await clientServiceApi().post(`/v1.0/Onboarding/${contactIdentityId}/resend-email-verification-code`, {});
        } catch (err: any) {
            console.error(err);
            throw err;
        } finally {
            setResendInProgress(false);
        }
    }, []);

    const updateEmail = useCallback(async (newEmail: string, contactIdentityId: string) => {
        try {
            setInProgress(true);
            await checkAppToken();

            const response: AxiosResponse<FirstLoginClientDetails> = await clientServiceApi().patch(`/v1.1/Onboarding/${contactIdentityId}/update-contact-email`, { newEmail });

            return response.data;
        } finally {
            setInProgress(false);
        }
    }, []);

    return {
        confirmEmail,
        resendVerificationCode,
        updateEmail,
        inProgress,
        resendInProgress,
    };
}
