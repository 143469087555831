import { baseApi } from '@hydrogen/elements.core.rest.transport';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useParams } from 'react-router-dom';
import { serviceConfig } from 'configs/service.config';
import { IDeposit } from './types';

const { CLIENT_SERVICE_URL_SUFFIX } = serviceConfig;

export const useDeposits = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);
    const [data, setData] = useState<IDeposit>();
    const { investmentId } = useParams();
    const { language } = useLanguage();

    useEffect(() => {
        if (investmentId) {
            setError(false);
            baseApi(CLIENT_SERVICE_URL_SUFFIX)
                .get(
                    `/v1.0/Plan/${investmentId}/deposits`,
                ).then((response: AxiosResponse<IDeposit>) => {
                    if (response.data) {
                        setData(response.data);
                    }
                })
                .catch((e) => setError(true))
                .finally(() => setLoading(false));
        }
    }, [investmentId, language]);

    return {
        isLoading, isError, data,
    };
};
