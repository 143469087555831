import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';

import { post } from '@hydrogen/elements.core.event-bus';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';

import { LoginTopics } from 'elements/core/contracts/event-bus/login';

type UseLoginResult = {
    inProgress: boolean;
    isError: boolean | string;
    doLogin: (payload: LoginPayload) => Promise<LoginResponse>;
};

type LoginPayload = {
    userName: string;
    password: string;
    forceExpiringPreviousSession: boolean;
};

export enum LoginStatus {
    Auth = 'Auth',
    Allow = 'Allow',
}

export enum LoginResult {
    Success = 'Success',
    CredentialsInvalid = 'CredentialsInvalid',
}

export enum LoginFactor {
    Passcode = 'Passcode',
    Approve = 'Approce',
    QrCode = 'QrCode',
}

type LoginCampaignContact = {
    ContactId: number;
    UserName: string;
    ObfuscatedMobileNumber: string;
    IsActive: boolean;
    IsTwoFactorEnabled: boolean
}

type LoginSession = {
    SessionId: string;
    JwtAccessToken: string;
}

export type LoginResponse = {
    Result: LoginResult;
    Status: LoginStatus;
    Factor: LoginFactor;
    Provider: string;
    Length: number;
    CampaignContact: LoginCampaignContact;
    Session: LoginSession;
    PreAuthenticateSessionId: string;
    GeneratedKey: string;
}

export function useLogin(): UseLoginResult {
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean | string>(false);

    const doLogin = useCallback(async (payload: LoginPayload) => {
        try {
            setInProgress(true);
            setIsError(false);

            const result: AxiosResponse<LoginResponse> = await clientServiceApi().post('/v1.0/Onboarding/pre-authenticate', payload);

            post(LoginTopics.LoginPreAuthenticatedData, result.data);

            return result.data;
        } catch (error: any) {
            console.error(error);
            setIsError(error?.response?.body?.message || 'Error occured');
            throw error;
        } finally {
            setInProgress(false);
        }
    }, []);

    return { inProgress, isError, doLogin };
}
