import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useLanguageSwitcher } from '@hydrogen/elements.core.i18n';
import ModalDialog, { ModalDialogSizes } from '@hydrogen/elements.ui.components.modal-dialog';

import RadioButtonGroup from 'elements/ui/components/radio-button-group';
import { Button, LinkButton } from 'elements/ui/components/button';

import { useTranslation } from 'react-i18next';
import languageIcon from '../../../../assets/language/language-icon.svg';
import { availableLanguages } from '../../../core/i18n/constants';

type LanguageSwitcherProps = {
    initialModalDisplay?: boolean;
}

export function LanguageSwitcher({ initialModalDisplay = false }: LanguageSwitcherProps) {
    const { i18n: { language: isoCode }, t } = useTranslation();
    const { changeLanguage } = useLanguageSwitcher();
    const [newLang, setNewLang] = useState(isoCode);
    const [show, setShow] = useState(initialModalDisplay);
    const showLanguage = useCallback(() => {
        setShow(true);
    }, []);

    const preChangeLanguage = useCallback((lang: string) => {
        setNewLang(lang);
    }, [changeLanguage]);

    const languages = useMemo(() => availableLanguages.map((item) => ({
        value: item.value,
        name: t(item.label),
    })), [t]);

    const languageName = useMemo(
        () => languages?.find((item) => item?.value === isoCode)?.name,
        [isoCode, languages],
    );

    const onClose = () => {
        setShow(false);
        setNewLang(isoCode);
    };

    const onSubmit = () => {
        setShow(false);
        changeLanguage(newLang);

        const currentUrl = new URL(window.location.href);

        currentUrl.searchParams.set('lang', newLang);
        const newUrl = currentUrl.href;

        window.history.replaceState({}, document.title, newUrl);
    };

    const onSubmitOnEnter = (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (e.code === 'Enter') {
            onSubmit();
        }
    };

    useEffect(() => {
        setNewLang(isoCode);
    }, [isoCode]);

    return (
        <>
            <ModalDialog
                size={ModalDialogSizes.large}
                withCloseIcon
                show={show}
                onClose={onClose}
                footer={(
                    <div className="block mx-auto">
                        <div className="pb-2 w-[240px]">
                            <Button
                                isSubmit
                                onClick={onSubmit}
                                onKeyDown={onSubmitOnEnter}
                            >
                                {t('language.modal.setLanguage')}
                            </Button>
                        </div>
                    </div>
                )}
                title={(
                    <div
                        className="text-center pt-[1.1rem] md:text-[28px] text-[24px] md:leading-[39px] leading-[33px] font-normal"
                    >
                        {t('language.modal.title')}
                    </div>
                )}
            >
                <div className="md:pt-14 md:pb-8 md:px-28 pt-[1.5rem]">
                    <RadioButtonGroup
                        size="default"
                        type="card"
                        titleHidden
                        data={languages}
                        value={newLang}
                        cols={2}
                        onChange={preChangeLanguage}
                    />
                </div>
            </ModalDialog>
            <LinkButton onClick={showLanguage}>
                <span className="max-h-6 sm:max-h-8 flex items-center md:justify-center lg:justify-start">
                    <div className="flex items-center idle-link-idle">
                        <img src={languageIcon} alt={t('alt.languageIcon')} className="xl:w-5" />
                        <span className="ms-1 fs text-xs xl:text-base">{languageName}</span>
                    </div>
                </span>
            </LinkButton>
        </>
    );
}
