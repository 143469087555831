export const serviceConfig = {
    withBearerApplicationAccessToken: global.serviceConfig?.withBearerApplicationAccessToken,
    GATEWAY_API_URL: global.serviceConfig?.GATEWAY_API_URL,
    SERVICES_URL_SUFFIX: global.serviceConfig?.SERVICES_URL_SUFFIX,
    DMS_URL_SUFFIX: global.serviceConfig?.DMS_URL_SUFFIX,
    CUSTOM_URL_SUFFIX: global.serviceConfig?.CUSTOM_URL_SUFFIX,
    ENGAGEMENT_CONTENT_URL_SUFFIX: global.serviceConfig?.ENGAGEMENT_CONTENT_URL_SUFFIX,
    CLIENT_SERVICE_URL_SUFFIX: global.serviceConfig?.CLIENT_SERVICE_URL_SUFFIX,
    FIDENTITY_URL_SUFFIX: global.serviceConfig?.FIDENTITY_URL_SUFFIX,
    CLIENT_SERVICE_MEMBER_URL_SUFFIX: global.serviceConfig?.CLIENT_SERVICE_MEMBER_URL_SUFFIX,
    headers: global.serviceConfig?.headers,
    CLIENT_SERVICE_HEADERS: global.serviceConfig?.CLIENT_SERVICE_HEADERS,
    isVersionIncluded: global.serviceConfig?.isVersionIncluded,
    GQL_URL_SUFFIX: global.serviceConfig?.GQL_URL_SUFFIX,
};
