import React, { useMemo } from 'react';
import { ContentBoxEmpty, ContentBoxError } from '@hydrogen/elements.ui.components.content-box';
import DateRangeOptions, { DateRangeType } from '@hydrogen/elements.ui.components.daterange-options';
import AreaChart, { AreaChartData } from '@hydrogen/elements.ui.charts.area-chart';
import { TooltipComponentProps } from '@hydrogen/elements.ui.charts.core';
import { formatDate, useLanguage } from '@hydrogen/elements.core.i18n';
import { TimeIntervalEnum } from '@hydrogen/elements.core.types';
import { getContainer } from '@hydrogen/elements.core.di';
import { useAnalysisAssetDevelopment } from 'elements/data/rest/use-analysis-asset-development';
import { getColor } from '@hydrogen/elements.ui.theme';

import { ContentBox } from 'elements/ui/components/content-box';
import { Tooltip } from 'elements/ui/components/tooltip';
import { formatCurrency } from '../../../../common/formaters';
import { ChartInfo } from '../../components/chart-info';

export { TimeIntervalEnum };

const getOptions = (data: { x: number, y?: number | null }[] = []): AreaChartData[] => ([{
    marker: {
        enabled: false,
    },
    color: getColor('chart-color-12'),
    fillColor: 'transparent',
    data,
}]);

type DataItem = {
    value?: number | null
    date?: string | null
}

type AssetsManagementProps = {
    dateRanges?: DateRangeType[]
    withCustomDateRange?: boolean
    dataSource?: typeof useAnalysisAssetDevelopment
    isThreeB?: boolean
}

type AreaChartTooltipProps = TooltipComponentProps & {
    formatValue?: (value?: string | number | null) => string | null
    formatLabel?: (value?: string | number | null) => string | null
}

const AreaChartTooltip = (props: AreaChartTooltipProps) => (
    <Tooltip>
        <b className="mb-[0.25rem] text-various-blue">
            {props?.formatLabel ? props?.formatLabel(props?.x) : props?.x}
        </b>
        <p className="text-various-blue">{props?.formatValue ? props?.formatValue(props?.y) : props?.y}</p>
    </Tooltip>
);

export const AssetDevelopment = ({
    dateRanges,
    withCustomDateRange = true,
    dataSource = getContainer(useAnalysisAssetDevelopment),
    isThreeB,
}: AssetsManagementProps) => {
    const { t } = useLanguage();

    const [
        dateRange, setDateRange,
    ] = React.useState<DateRangeType | null>(dateRanges?.[0] || null);

    const onChange = (range: DateRangeType) => {
        setDateRange(range);
    };

    const { data: { items = [], currencyCode } = {}, isLoading, isError } = dataSource({
        period: dateRange?.from ? 'CustomRange' : 'SinceInception',
        dateFrom: dateRange?.from ? formatDate(new Date(dateRange?.from)) : null,
        dateTo: dateRange?.to ? formatDate(new Date(dateRange?.to)) : formatDate(new Date()),
    });

    const chartData = useMemo(() => (
        getOptions(
            (items?.map(({ value, date }: DataItem) => ({
                x: +(date ? new Date(date) : 0),
                y: value,
            }))),
        )
    ), [items]);

    return (
        <ContentBox as="section" isMainBox title={t('asset-development.title')} variant="tabbed">
            <div className="pt-4 pb-7 px-6">
                <ContentBoxError isError={isError} errorMessage={t('common.something-wrong')}>
                    <ChartInfo />
                    <div className="flex flex-wrap justify-between gap-4 mb-4 sm:mb-6">
                        <DateRangeOptions
                            withCustomDateRange={withCustomDateRange}
                            title={t('common.date-range')}
                            fromDateLabel={t('common.inception')}
                            onChange={onChange}
                            value={dateRange}
                            ranges={dateRanges}
                            formatDate={(date) => (date ? formatDate(new Date(date), 'dd.MM.yyyy') : '')}
                            maxDateTo={new Date()}
                            disabledFrom={isThreeB}
                            disabledTo={isThreeB}
                        />
                    </div>
                    <AreaChart
                        height="354px"
                        isLoading={isLoading}
                        TooltipComponent={(props: TooltipComponentProps) => (
                            <AreaChartTooltip
                                {...props}
                                formatLabel={(val) => (
                                    val
                                        ? formatDate(new Date(val), 'dd.MM.yyyy')
                                        : null
                                )}
                                formatValue={(val) => formatCurrency(+(val ?? 0), currencyCode)}
                            />
                        )}
                        min={items ? Math.min(
                            ...(items).map(({ value }: DataItem) => +(value || 0)),
                        ) : 0}
                        data={chartData}
                        EmptyDataComponent={(
                            <ContentBoxEmpty className="">
                                <>{t('common.data-empty')}</>
                            </ContentBoxEmpty>
                        )}
                    />
                </ContentBoxError>
            </div>
        </ContentBox>
    );
};
