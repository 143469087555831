import React, { useState } from 'react';

import { classNames } from '@hydrogen/elements.ui.utils';
import { getLink, getLocales, useTranslate } from '@hydrogen/elements.ui.configs';

import { InfoboxTypes } from './types';

const iconStyles = 'icon text-[1.5rem]';

type Infobox = {
    type?: InfoboxTypes,
    className?: string,
    link?: string,
    linkText?: React.ReactElement | string,
    icon?: string,
    noIcon?: boolean,
    dismissable?: boolean,
    compact?: boolean,
    title?: React.ReactElement | string,
    children?: React.ReactNode | React.ReactNode[] | null,
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void

}

export const Infobox = (props: Infobox) => {
    const [dismissed, setDismissed] = useState(false);
    const Link = getLink();
    const { t } = useTranslate();
    const { CONTINUE } = getLocales();

    const themes = {
        [InfoboxTypes.default]: {
            textClass: 'text-brand-700',
            bgClass: 'bg-brand-200',
            icon: <i className={classNames(iconStyles, props.icon || 'icon-info', 'text-brand-700')} />,
        },
        [InfoboxTypes.error]: {
            textClass: 'text-danger-700',
            bgClass: 'bg-danger-200',
            icon: <i className={classNames(iconStyles, props.icon || 'icon-info', 'text-danger-700')} />,
        },
        [InfoboxTypes.warn]: {
            textClass: 'text-warn-700',
            bgClass: 'bg-warn-200',
            icon: <i className={classNames(iconStyles, props.icon || 'icon-info', 'text-warn-700')} />,
        },
        [InfoboxTypes.success]: {
            textClass: 'text-success-700',
            bgClass: 'bg-success-200',
            icon: <i className={classNames(iconStyles, props.icon || 'icon-info', 'text-success-700')} />,
        },
    };
    const {
        icon, textClass, bgClass,
    } = themes[props.type || InfoboxTypes.default] || themes[InfoboxTypes.default];

    const handleDismiss = () => {
        setDismissed(true);
    };

    return (

        <div
            className={classNames(
                dismissed && 'hidden',
                props.className,
                bgClass,
                'relative px-4 py-3 rounded-2xl',
            )}
        >
            <div className="flex">
                {!props.noIcon
                    && (
                        <div className="-mt-2 flex-shrink-0">
                            {icon}
                        </div>
                    )}
                <div className={classNames(
                    (props.compact && !props.dismissable) && 'flex-1 sm:flex gap-4 sm:justify-between',
                    !props.noIcon && 'ms-3',
                    'w-full',
                )}
                >
                    <div>
                        {props.title && (
                            props.dismissable
                                ? (
                                    <div className="flex items-start justify-between">
                                        <p className={classNames(props.children ? 'mb-1' : '-mb-1', 'text-base -mt-1 font-bold', textClass)}>{props.title}</p>
                                        <div className={classNames('flex flex-shrink-0 -mt-2', textClass)}>
                                            <button
                                                type="button"
                                                onClick={() => handleDismiss()}
                                            >
                                                <i className={classNames(iconStyles, 'icon-close')} />
                                            </button>
                                        </div>
                                    </div>
                                )
                                : <p className={classNames(props.children ? 'mb-1' : '-mb-1', 'text-base -mt-1 font-bold', textClass)}>{props.title}</p>
                        )}
                        {(props.dismissable && !props.title)
                            ? (
                                <div className="flex items-start justify-between">
                                    <p className={classNames('text-sm font-medium', textClass)}>{props.children}</p>
                                    <div className={classNames('flex flex-shrink-0', textClass)}>
                                        <button
                                            type="button"
                                            className={classNames('')}
                                            onClick={() => handleDismiss()}
                                        >
                                            <i className={classNames(iconStyles, 'icon-close')} />
                                        </button>
                                    </div>
                                </div>
                            )
                            : <div className={classNames('text-sm font-medium', textClass)}>{props.children}</div>}
                    </div>
                    {props.link
                        && (
                            <p className={classNames((props.compact && !props.dismissable) ? 'mt-2 sm:mt-0' : 'mt-3', 'text-sm')}>
                                <Link
                                    to={props.link}
                                    className={classNames('underline whitespace-nowrap font-bold', textClass)}
                                >
                                    {props.linkText || t(CONTINUE)}
                                </Link>
                            </p>
                        )}
                    {props.onClick
                        && (
                            <button
                                type="button"
                                className={classNames(
                                    (props.compact && !props.dismissable) ? '' : 'mt-3',
                                    'text-sm underline whitespace-nowrap font-bold',
                                    textClass,
                                )}
                                onClick={props.onClick}
                            >
                                {props.linkText || t(CONTINUE)}
                            </button>
                        )}
                </div>
            </div>
        </div>
    );
};
