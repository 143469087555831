import React, { useMemo } from 'react';
import QRCode from 'react-qr-code';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import Grid from '@hydrogen/elements.ui.components.grid';

import { FirstLoginHeader } from 'elements/ui/components/first-login-header';
import { Button, LinkButton } from 'elements/ui/components/button';
import { Image } from 'elements/ui/components/image';
import { Text } from 'elements/ui/components/text';
import { StepPageProps } from 'elements/core/contracts';
import { useStepper } from 'elements/ui/components/stepper/components';
import Title, { TitleTypes } from '@hydrogen/elements.ui.components.title';

import personalPinStepEN from 'assets/mfa-setup/en/personal-pin-step.png';
import activateStepEN from 'assets/mfa-setup/en/activate-step.png';
import switchToQRStepEN from 'assets/mfa-setup/en/switch-qr-step.png';
import scanQRStepEN from 'assets/mfa-setup/en/scan-qr-step.png';

import personalPinStepDE from 'assets/mfa-setup/de/personal-pin-step.png';
import activateStepDE from 'assets/mfa-setup/de/activate-step.png';
import switchToQRStepDE from 'assets/mfa-setup/de/switch-qr-step.png';
import scanQRStepDE from 'assets/mfa-setup/de/scan-qr-step.png';

import personalPinStepFR from 'assets/mfa-setup/fr/personal-pin-step.png';
import activateStepFR from 'assets/mfa-setup/fr/activate-step.png';
import switchToQRStepFR from 'assets/mfa-setup/fr/switch-qr-step.png';
import scanQRStepFR from 'assets/mfa-setup/fr/scan-qr-step.png';

import personalPinStepIT from 'assets/mfa-setup/it/personal-pin-step.png';
import activateStepIT from 'assets/mfa-setup/it/activate-step.png';
import switchToQRStepIT from 'assets/mfa-setup/it/switch-qr-step.png';
import scanQRStepIT from 'assets/mfa-setup/it/scan-qr-step.png';

const IOS_APP = 'https://apps.apple.com/ch/app/myzurichinvest-access/id6458739750';
const ANDROID_APP = 'https://play.google.com/store/apps/details?id=ch.zurich.myinvest.access';

const personalPinStep = (lang) => {
    const imgLang = {
        en: personalPinStepEN,
        de: personalPinStepDE,
        fr: personalPinStepFR,
        it: personalPinStepIT,
    };

    return imgLang?.[lang] || imgLang.en;
};

const activateStep = (lang) => {
    const imgLang = {
        en: activateStepEN,
        de: activateStepDE,
        fr: activateStepFR,
        it: activateStepIT,
    };

    return imgLang?.[lang] || imgLang.en;
};

const switchToQRStep = (lang) => {
    const imgLang = {
        en: switchToQRStepEN,
        de: switchToQRStepDE,
        fr: switchToQRStepFR,
        it: switchToQRStepIT,
    };

    return imgLang?.[lang] || imgLang.en;
};

const scanQRStep = (lang) => {
    const imgLang = {
        en: scanQRStepEN,
        de: scanQRStepDE,
        fr: scanQRStepFR,
        it: scanQRStepIT,
    };

    return imgLang?.[lang] || imgLang.en;
};

const BoldTitle = ({ children }) => (
    <Title as="h2" type={TitleTypes.sub} className="col-span-1 justify-self-start font-medium !text-[14px]">{children}</Title>
);

export function MfaAppSetup({
    links: { nextLink, prevLink },
}: StepPageProps<null>) {
    const { t, language } = useLanguage();
    const { onStepComplete } = useStepper() || {};

    return (
        <>
            <FirstLoginHeader
                stepName={t('firstLogin.mfaAppSetup.name')}
                stepTitle={t('firstLogin.mfaAppSetup.title')}
                stepDesc={t('firstLogin.mfaAppSetup.desc')}
                noImage
                prevLink={prevLink}
            />

            <Grid
                className="grid-cols-[97px_auto] gap-y-[16px] md:grid !mb-4 text-left"
                gap={4}
            >
                <Image
                    src="/static/img/locked-ziag-icon.svg"
                    alt="Locked Logo"
                    className="rounded-tl-[17px] rounded-tr-[17px] w-[89px] h-[81px]"
                />
                <div className="flex flex-col">
                    <BoldTitle>{t('firstLogin.mfaAppSetup.guide.step', { stepNumber: 1 })}</BoldTitle>
                    <Text className="col-span-2 justify-self-start mb-4">{t('firstLogin.mfaAppSetup.guide.download-step.desc')}</Text>
                    <div className="col-span-2 grid grid-cols-[100px_auto] gap-x-2">
                        <Text className="col-span-2 items-start mb-1 text-base hidden md:block">{t('firstLogin.mfaAppSetup.apple')}</Text>
                        <QRCode
                            className="mb-3 h-[100px] w-[100px] hidden md:block"
                            value={IOS_APP}
                        />
                        <LinkButton
                            className="text-brand flex items-center pb-6 md:pb-0 col-span-2 md:col-span-1"
                            as="a"
                            href={IOS_APP}
                        >
                            {t('firstLogin.mfaAppSetup.appleLink')}
                            <Image src="/static/img/open-app-icon.svg" className="w-[20px] h-[12px] ml-2" />
                        </LinkButton>
                    </div>
                    <div className="col-span-2 grid grid-cols-[100px_auto] gap-x-2">
                        <Text className="col-span-2 items-start mb-1 text-base hidden md:block">{t('firstLogin.mfaAppSetup.android')}</Text>
                        <QRCode
                            className="h-[100px] w-[100px] hidden md:block"
                            value={ANDROID_APP}
                        />
                        <LinkButton
                            className="text-brand flex items-center col-span-2 md:col-span-1"
                            as="a"
                            href={ANDROID_APP}
                        >
                            {t('firstLogin.mfaAppSetup.androidLink')}
                            <Image src="/static/img/open-app-icon.svg" className="w-[20px] h-[12px] ml-2" />
                        </LinkButton>
                    </div>
                </div>
                <div className="col-span-3 w-full border-b h-1" />
                <img
                    src={personalPinStep(language)}
                    alt="Setup personal pin"
                    className="w-[97px] h-[201px] object-cover"
                />
                <div className="flex flex-col">
                    <BoldTitle>{t('firstLogin.mfaAppSetup.guide.step', { stepNumber: 2 })}</BoldTitle>
                    <Text className="col-span-2 justify-self-start">{t('firstLogin.mfaAppSetup.guide.personal-pin.desc')}</Text>
                </div>
                <div className="col-span-3 w-full border-b h-1" />
                <Image
                    src={activateStep(language)}
                    alt="Locked Logo"
                    className="w-[97px] h-[67px] object-cover"
                />
                <div className="flex flex-col">
                    <BoldTitle>{t('firstLogin.mfaAppSetup.guide.step', { stepNumber: 3 })}</BoldTitle>
                    <Text className="col-span-2 justify-self-start">{t('firstLogin.mfaAppSetup.guide.activate.desc')}</Text>
                </div>
                <div className="col-span-3 w-full border-b h-1" />
                <Image
                    src={switchToQRStep(language)}
                    alt="Locked Logo"
                    className="w-[97px] h-[51px] object-cover md:block hidden"
                />
                <div className="flex-col md:flex hidden">
                    <BoldTitle>{t('firstLogin.mfaAppSetup.guide.step', { stepNumber: 4 })}</BoldTitle>
                    <Text className="col-span-2 justify-self-start">{t('firstLogin.mfaAppSetup.guide.switchToQR.desc')}</Text>
                </div>
                <div className="col-span-3 w-full border-b h-1 hidden md:block" />
                <Image
                    src={scanQRStep(language)}
                    alt="Locked Logo"
                    className="w-[97px] h-[201px] object-cover md:block hidden"
                />
                <div className="flex-col md:flex hidden">
                    <BoldTitle>{t('firstLogin.mfaAppSetup.guide.step', { stepNumber: 5 })}</BoldTitle>
                    <Text className="col-span-2 justify-self-start">{t('firstLogin.mfaAppSetup.guide.scanQRStep.desc')}</Text>
                </div>
                <div className="md:hidden" />
                <div className="flex-col md:hidden">
                    <BoldTitle>{t('firstLogin.mfaAppSetup.guide.step', { stepNumber: 4 })}</BoldTitle>
                    <Text className="col-span-2 justify-self-start">{t('firstLogin.mfaAppSetup.guide.clickNext.desc')}</Text>
                </div>
            </Grid>
            <div className="w-full">
                <Button
                    onClick={() => typeof onStepComplete === 'function' && onStepComplete({ nextLink, prevLink })}
                >
                    {t('firstLogin.mfaAppSetup.next')}
                </Button>

            </div>
        </>
    );
}
