import { baseApi, serviceApi } from '@hydrogen/elements.core.rest.transport';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { serviceConfig } from 'configs/service.config';
import { downloadDmsDocument } from '../use-contact-details/downloadDmsDocument';
import { useNotifications } from '../../../ui/components/notification';
import { DOCUMENT_SOURCE, IDocument, UseDocumentsReturnType } from './types';

const { CLIENT_SERVICE_URL_SUFFIX } = serviceConfig;

const getMimeTypeFromBase64File = (file) => {
    switch (file.charAt(0)) {
        case '/':
            return 'image/jpeg';
        case 'i':
            return 'image/png';
        case 'R':
            return 'image/gif';
        case 'U':
            return 'image/webp';
        case 'J':
            return 'application/pdf';
        default:
            return 'unknown';
    }
};

export const useDocuments = (): UseDocumentsReturnType => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);
    const contactId = useContactId();
    const { t, language } = useLanguage();
    const [data, setData] = useState<IDocument[]>([]);
    const { negative } = useNotifications() || {};

    const handleDownloadFile = useCallback((documentData) => {
        const { filename } = documentData;

        const { newBlob } = downloadDmsDocument(
            documentData,
            {
                name: filename,
                saveFile: true,
            },
        );
        const url = URL.createObjectURL(newBlob);

        global.open(url, '_blank');
    }, []);

    const downloadDocument = useCallback(async ({
        id, documentSource, document, productId, lpzCustomerId,
    }) => {
        try {
            if (!documentSource || documentSource === DOCUMENT_SOURCE.dmsDocument) {
                const { data: documentData } = await serviceApi().get(`/v2.1/documents/${id}`);

                handleDownloadFile(documentData);
            } else if (documentSource === DOCUMENT_SOURCE.lpzDocument) {
                const { data: documentData } = await baseApi(CLIENT_SERVICE_URL_SUFFIX)
                    .get(
                        '/v1.0/Documents/DocDB/GetDocumentPdf',
                        {
                            params: {
                                DocumentId: id,
                                CustomerId: lpzCustomerId,
                                ProductId: productId,
                            },
                        },
                    );

                handleDownloadFile({
                    file: documentData.pdf,
                    mimeType: getMimeTypeFromBase64File(documentData.pdf),
                    originalFilename: document,
                    filename: document,
                });
            }
        } catch (err) {
            if (negative) {
                negative(t('document.download.fail'));
            }
        }
    }, [contactId]);

    useEffect(() => {
        if (contactId) {
            setLoading(true);
            baseApi(CLIENT_SERVICE_URL_SUFFIX)
                .get(
                    '/v1.0/Documents/DocDB/GetUnifiedDocuments',
                    {
                        params: {
                            language,
                            contactId,
                            isMember: false,
                        },
                    },
                ).then((response: AxiosResponse<IDocument[]>) => {
                    if (response.data) {
                        setData(response.data);
                    }
                })
                .catch((e) => setError(true))
                .finally(() => setLoading(false));
        }
    }, [contactId, language]);

    return {
        isLoading, isError, data, downloadDocument,
    };
};
