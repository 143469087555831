import { useMemo } from 'react';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';
import { useParams } from '@hydrogen/elements.core.router';
import { useClientsOverviewInvestments } from '../../rest/use-clients-overview-investments';

export function useSelectedPortfolio() {
    const { language } = useLanguage();
    const investmentId = useInvestmentId();
    const { investmentId: fromUrl } = useParams();
    const portfolioId = investmentId ?? fromUrl;

    const investmentsQuery = useClientsOverviewInvestments({
        language,
        showClosed: true,
    });

    const selectedPortfolio = useMemo(() => investmentsQuery?.data?.find((f) => f.id === Number(portfolioId)), [portfolioId, investmentsQuery.data]);

    return {
        ...investmentsQuery,
        data: selectedPortfolio,
        isLoading: investmentsQuery.isLoading || investmentsQuery.isIdle,
    };
}
