import React, { useCallback } from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
import Badge, { BadgeTypes } from '@hydrogen/elements.ui.components.badge';
import { formatDate } from '@hydrogen/elements.core.i18n';
import { LinkButton } from '../button';
import { NA } from '../../../../common/constants';
import { IDocument } from '../../../data/rest/use-documents/types';
import { Secure } from '../../../../hydrogen/elements.auth.client-portal';

export type DocumentProps = {
    secureLoading?: boolean,
    className?: string,
    downloadDocument?: ({
        id,
        documentSource,
        document,
        productId,
        lpzCustomerId,
    }) => void,
    document: IDocument,
};

export const Document = ({
    secureLoading = true,
    className,
    downloadDocument,
    document: {
        id,
        documentSource,
        productId,
        createDate,
        document,
        type,
        portfolioName,
        lpzCustomerId,
    },
}: DocumentProps) => {
    const onDownload = useCallback(async () => {
        if (!id || !downloadDocument) return null;

        return downloadDocument({
            id,
            documentSource,
            document,
            productId,
            lpzCustomerId,
        });
    }, [id,
        createDate,
        document,
        type,
        portfolioName,
        lpzCustomerId]);

    return (
        <Secure loading={secureLoading}>
            <div
                className={classNames(className, 'flex justify-between items-center px-6 py-4 border-t-[0.0625rem] border-absolute-1000 text-[0.75rem] leading-[1.125rem]')}
            >
                <div>
                    <p className="mb-2 text-fill-text">
                        {createDate ? formatDate(new Date(createDate), 'dd.MM.yyyy HH:mm') : NA}
                    </p>
                    <p className="mb-1 text-base text-primary-500">
                        {document || NA}
                    </p>
                    <p className="mb-2 text-secondary-300">
                        {type || NA}
                    </p>
                    <Badge
                        className="px-4 rounded-xl !text-sm font-medium"
                        title={portfolioName || NA}
                        type={BadgeTypes.neutral}
                    />
                </div>
                <LinkButton className="flex flex-shrink-0 hover:no-underline" onClick={onDownload}>
                    <i className="ml-3 icon icon-download inline-block text-[1.5rem] text-secondary-300" />
                </LinkButton>
            </div>
        </Secure>
    );
};
