import { OPTION_LIST } from 'common/constants';
import React, { useMemo } from 'react';
import { formatDate, useLanguage } from '@hydrogen/elements.core.i18n';
import { v4 as uuidv4 } from 'uuid';
import { formatCurrency } from '../../../../common/formaters';
import { useOptionList } from '../../rest/use-option-list';
import { useInvestmentTransfer } from '../../rest/use-investment-transfer';

export const useWithInvestmentTransferWidget = () => {
    const { t, language } = useLanguage();
    const {
        data: frequencyOptions,
        ...optionListQuery
    } = useOptionList({
        listKey: OPTION_LIST.WithdrawalPeriodicity,
    });

    const {
        data,
        isLoading,
        isError,
    } = useInvestmentTransfer();

    const transferDataArray = useMemo(() => data?.map((transfer) => {
        const transferData = [
            {
                label: t('withdrawal.portfolioTarget'),
                value: transfer?.targetPortfolio?.productName,
                labelClassName: 'font-medium pb-[0.5rem]',
                valueClassName: 'inline-block text-[1.125rem] text-secondary-300 mb-3',
                additionalData: (
                    <div className="text-[1.125rem] text-secondary-300 mb-1">
                        {transfer?.targetPortfolio?.rubrik}
                        <br />
                        {transfer?.targetPortfolio?.name}
                    </div>
                ),

            },
            {
                label: t('withdrawal.transferAmount'),
                value: transfer?.amount && formatCurrency(transfer?.amount, 'CHF'),
                valueClassName: 'float-right inline-block text-right font-medium text-secondary-300 mb-1',
            },
            {
                label: t('withdrawal.frequency'),
                value: transfer?.periodicity && frequencyOptions?.find((i) => i.id === transfer?.periodicity)?.label,
            },
            {
                label: t('withdrawal.transferStart'),
                value: transfer?.startOfTransfer && formatDate(new Date(transfer?.startOfTransfer), 'MM/yyyy'),
            },
            {
                label: t('withdrawal.nextTransfer'),
                value: transfer?.nextTransfer && formatDate(new Date(transfer?.nextTransfer), 'MM/yyyy'),
            },
        ];

        return {
            data: transferData,
            key: uuidv4(),
            isLoading,
            isError,
            hasWithdrawal: !!transfer?.targetPortfolio?.id,
            title: t('withdrawal.transferTitle'),
            tooltip: t('withdrawal.tooltip.info'),

        };
    }), [data, language]);

    return {
        data: transferDataArray,
        isLoading: isLoading || optionListQuery.isLoading,
        isError: isError || optionListQuery.isError,
    };
};
