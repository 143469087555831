import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
import LinkButton, { LinkButtonSizes } from '@hydrogen/elements.ui.components.link-button';

type LinkButtonProps = {
    as?: React.ElementType
    size?: LinkButtonSizes,
    disabled?: boolean,
    className?: string,
    htmlType?: string
    href?: string,
    onClick?: React.MouseEventHandler<HTMLElement>,
    children: React.ReactNode | React.ReactNode[] | null | boolean | React.ReactElement,
}
// The next classes are not configurable in hydrogen and needed to be overridden
// 1. font sizes and line height
// 2. text color
export const CLinkButton = ({
    className,
    ...props
}: LinkButtonProps) => (
    <LinkButton
        {...props}
        as={props.href ? 'a' : props.as}
        className={classNames(className, 'text-[14px] leading-[20px] hover:underline font-normal text-secondary-300')}
    />
);
