import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';

import { FirstLoginClientDetails } from 'elements/core/contracts';
import { container } from '@hydrogen/elements.core.di';
import { useAppTokenValidate } from '../use-app-token-validate';

export type UseAccountActivationResponse = {
    inProgress: boolean;
    validateActivationCode: (
        code: string
    ) => Promise<FirstLoginClientDetails | undefined>;
};

export function useAccountActivation(): UseAccountActivationResponse {
    const [inProgress, setInProgress] = useState(false);
    const dataSource = container.get<typeof useAppTokenValidate>('useAppTokenValidate');
    const { checkAppToken } = dataSource();

    const validateActivationCode = useCallback(async (code) => {
        if (!code) {
            return undefined;
        }
        try {
            setInProgress(true);

            await checkAppToken();

            const result: AxiosResponse<FirstLoginClientDetails> = await clientServiceApi().post(`/v1.0/Onboarding/${code}/validate`, {});

            return result.data;
        } finally {
            setInProgress(false);
        }
    }, []);

    return { inProgress, validateActivationCode };
}
