import { useReducer } from 'react';

type MiddleWaresFnType<T> = (data: T) => void

const middleWareHandler = <T>(
    middleWare: MiddleWaresFnType<T> | MiddleWaresFnType<T>[],
    data: T,
) => {
    if (Array.isArray(middleWare)) {
        middleWare.forEach((fn) => fn(data));

        return;
    }
    middleWare?.(data);
};

function reducer<T>(middleWare: MiddleWaresFnType<T> | MiddleWaresFnType<T>[]) {
    return (state: T, newState: Partial<T>) => {
        const nextState = ({
            ...state,
            ...newState,
        });

        middleWareHandler?.(middleWare, nextState);

        return nextState;
    };
}

export function useAuthenticationState<T>(
    initialState: T,
    middleWare: MiddleWaresFnType<T> | MiddleWaresFnType<T>[],
) {
    return useReducer(reducer<T>(middleWare), initialState);
}
