import React from 'react';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { Infobox } from '../info-box';
import { useProductType } from '../../../data/helpers/use-product-type';
import { useSelectedPortfolio } from '../../../data/helpers/use-selected-portfolio';

export const ChartInfo = () => {
    const { t } = useLanguage();
    const {
        data: selectedPortfolio,
    } = useSelectedPortfolio();
    const { isThreeB } = useProductType({ externalId: selectedPortfolio?.product?.externalId });

    return isThreeB ? (
        <Infobox
            className="mb-5"
            title={t('performanceAssetChart.info.message')}
        />
    ) : null;
};
