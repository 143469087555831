import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { classNames, globalColorClasses } from '@hydrogen/elements.ui.utils';
import { TBaseOption } from '../types';

export const DefaultOption = ({
    disabled = false,
    size = 'default',
    error = false,
    item,
}: TBaseOption) => (
    <RadioGroup.Option
        as="div"
        disabled={item.disabled}
        value={item.value}
        className={({ checked, active }) => classNames(
            item.disabled ?? disabled ? globalColorClasses.disabled : 'cursor-pointer',
            (checked && active) ? 'focus-visible:ring-offset-1 focus-visible:ring-4 focus-visible:ring-brand-300' : '',
            'transition relative focus:outline-none',
        )}
    >
        {({ checked }) => (
            <>
                <div className={classNames(
                    'flex gap-2 items-start transition relative focus:outline-none cursor-pointer',
                )}
                >
                    <span
                        className={classNames(
                            !error && (checked ? 'bg-primary-500 border-transparent' : 'border-input-border-color shadow-input bg-content-background'),
                            error && (checked ? 'bg-danger-200 border-transparent' : 'border-danger-500 shadow-input bg-content-background'),
                            'transition mt-0.5 border-width-input flex-shrink-0 rounded-full flex items-center justify-center',
                            size === 'large' ? 'h-5 w-5' : 'h-4 w-4',
                        )}
                        aria-hidden="true"
                    >
                        <span className={classNames(
                            'rounded-full bg-white',
                            size === 'large' ? ' w-2 h-2' : 'w-1.5 h-1.5',
                        )}
                        />
                    </span>
                    <div className={classNames(
                        'flex flex-col gap-1 text-sm mt-0.5',
                        size === 'large' ? 'text-base' : 'text-sm',
                    )}
                    >
                        <RadioGroup.Label
                            as="div"
                            className={classNames(
                                'flex gap-2 items-center cursor-pointer',
                                !error ? 'text-primary-500' : 'text-danger-200',
                            )}
                        >
                            {item.name}
                        </RadioGroup.Label>
                        {item.desc && (
                            <RadioGroup.Description
                                as="div"
                                className="text-secondary-500 cursor-pointer"
                            >
                                {item.desc}
                            </RadioGroup.Description>
                        )}
                    </div>
                </div>
            </>
        )}
    </RadioGroup.Option>
);
