import { useQuery } from 'react-query';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';

type UseAccountDetailsResponse = {
    iban: string,
    lpzPlanId: string,
    openingDate: string
    accountName: string
}

export const useAccountDetails = () => {
    const investmentId = useInvestmentId();

    const query = useQuery<UseAccountDetailsResponse>(
        ['account-details', investmentId],
        () => clientServiceApi()
            .get(
                `/v1.0/Plan/${investmentId}/account-details`,
            )
            .then(({ data }) => data),
        {
            keepPreviousData: true,
            enabled: !!investmentId,
            refetchOnMount: 'always',
        },
    );

    return { ...query, isLoading: query.isIdle || query.isLoading };
};
