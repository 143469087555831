import {
    baseApi, clientServiceApi, customApi, serviceApi,
} from '@hydrogen/elements.core.rest.transport';
import {
    AuthPayload,
    AuthStatusPayload,
    GetTokenPayload,
    LogoutPayload,
    PreAuthPayload,
    SmsAuthenticatePayload,
    UserDetailsPayload,
    ValidateTokenPayload,
    VerifySmsCodePayload,
} from '../types';

export const preAuthApi = (loginData: PreAuthPayload) => (
    clientServiceApi().post(
        '/v1.0/Onboarding/pre-authenticate',
        loginData,
        {
            Authorization: `Bearer ${loginData.trustedDeviceToken}`,
        },
    )
);
export const authenticateApi = (
    loginData: AuthPayload,
    contactId: number | null,
    accessToken?: string | null,
) => (
    (loginData && contactId && accessToken) ? clientServiceApi().post(
        `/v1.0/Onboarding/${contactId}/authenticate`,
        loginData,
        {
            Authorization: `Bearer ${accessToken}`,
        },
    ) : { data: null }
);

export const authenticateStatusApi = (
    loginData: AuthStatusPayload,
    accessToken?: string | null,
) => (
    clientServiceApi().post(
        '/v1.0/Onboarding/request-authentication-status',
        loginData,
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

export const getAuthenticateStatusApi = (
    loginData: AuthStatusPayload,
    accessToken?: string | null,
) => (
    clientServiceApi().post(
        '/v1.0/Onboarding/get-authentication-status',
        loginData,
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

export const sendSmsCodeApi = (
    loginData: SmsAuthenticatePayload,
    accessToken?: string | null,
) => (
    clientServiceApi().post(
        `/v1.0/Contact/${loginData.clientId}/send-otp-sms`,
        loginData,
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

export const verifySmsCodeAPi = (loginData: VerifySmsCodePayload, accessToken?: string | null) => (
    clientServiceApi().post(
        `/v1.0/Contact/${loginData.clientId}/verify-otp-code`,
        {
            key: loginData.key,
        },
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

export const getAppToken = ({ type = 1, accessToken }: GetTokenPayload) => (
    customApi().post(
        '/connect/token',
        { type, access_Token: accessToken },
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

export const validateAppToken = ({ accessToken, appToken }: ValidateTokenPayload) => (
    serviceApi().post(
        '/v1.0/authentication/tokens/validate',
        { accessToken },
        {
            Authorization: `Bearer ${appToken}`,
        },
    )
);

export const logoffApi = ({ contactsId, accessToken }: LogoutPayload) => (
    serviceApi().post(
        `/v1.0/contacts/${contactsId}/authentication/logoff`,
        {},
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

export const getUserDetailsApi = ({
    contactId,
    accessToken,
}: UserDetailsPayload) => baseApi('/cls').get(`/v1.0/contacts/${contactId}/details`, { params: {} }, { Authorization: `Bearer ${accessToken}` });

type PreliminaryKeyApiPayload = {
    username: string
    accessToken: string
}
export const sendPreliminaryKeyApi = ({ username, accessToken }: PreliminaryKeyApiPayload) => (
    clientServiceApi().post(
        '/v1.0/Onboarding/forgotten-password/send-verification-key',
        { username },
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

type ResetKeyApiPayload = {
    preliminaryKey: string
    sessionId: string
    redirectUrl: string
    accessToken: string
}
export const sendResetKeyApi = ({
    preliminaryKey,
    sessionId,
    redirectUrl,
    accessToken,
}: ResetKeyApiPayload) => (
    clientServiceApi().post(
        '/v1.0/Onboarding/forgotten-password/send-password-reset-email',
        {
            preliminaryKey,
            sessionId,
            redirectUrl,
        },
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

type ResetPasswordPayload = {
    password: string
    key: string
    accessToken: string
}
export const resetPasswordApi = ({
    password,
    key,
    accessToken,
}: ResetPasswordPayload) => (
    clientServiceApi().post(
        `/v1.0/Onboarding/forgotten-password/reset-password/${key}`,
        {
            password,
        },
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

export const getPasswordPolicies = (accessToken) => (
    serviceApi().get(
        '/v1.0/authentication/password/policies',
        undefined,
        {
            Authorization: `Bearer ${accessToken}`,
        },
    )
);

type ChangePasswordPayload = {
    accessToken: string,
    contactId: number,
    password: string,
}
export const changePasswordApi = ({
    accessToken, contactId, password,
}: ChangePasswordPayload) => (
    clientServiceApi().post(`/v1.0/Contact/${contactId}/change-password`, { password }, { Authorization: `Bearer ${accessToken}` })
    // serviceApi().patch(
    //     `/v1.0/contacts/${contactId}/password`,
    //     { oldPassword, newPassword },
    //     {
    //         Authorization: `Bearer ${accessToken}`,
    //     },
    // )
);
