import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { TRadioButtonGroupBaseProps } from '../types';

export const RadioGroupWrapper = ({
    value = null,
    onChange,
    children,
    disabled,
    type = 'default',
    title = '',
    titleHidden = true,
}: TRadioButtonGroupBaseProps & {
    children: string | number | React.ReactNode | null
}) => {
    const titleClass = titleHidden ? 'sr-only' : 'text-sm text-primary-500 font-medium';

    if (type === 'card') {
        return (
            <fieldset className="w-full">
                <RadioGroup value={value} onChange={onChange} disabled={disabled}>
                    <RadioGroup.Label
                        as="div"
                        className={titleClass}
                    >
                        {title}
                    </RadioGroup.Label>

                    {children}
                </RadioGroup>
            </fieldset>
        );
    }

    return (
        <RadioGroup value={value} onChange={onChange} disabled={disabled}>
            <RadioGroup.Label
                as="div"
                className={titleClass}
            >
                {title}
            </RadioGroup.Label>

            {children}
        </RadioGroup>
    );
};
