import React, { useMemo } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import GridColumns from '@hydrogen/elements.ui.components.grid';

import { container } from '@hydrogen/elements.core.di';
import { ContentBoxError } from '@hydrogen/elements.ui.components.content-box';
import { formatCurrency } from 'common/formaters';
import { ContentBox } from 'elements/ui/components/content-box';
import { ProgressBar } from 'elements/ui/components/progress-bar';
import { useDeposits } from '../../../data/rest/use-deposits';

type DepositsProps = {
    dataSource?: typeof useDeposits,
}

const fallbackCurrency = 'CHF';

export const Deposits = ({
    dataSource = container.get<typeof useDeposits>('useDeposits'),
}: DepositsProps) => {
    const { t } = useLanguage();
    const {
        data,
        isLoading,
        isError,
    } = dataSource();

    const stillPossible = data ? data.possibleAmount : 0;
    const progress = data ? (data.currentAmount * 100) / data.maximumAmount : 0;
    const currency = useMemo(() => data?.currency ?? fallbackCurrency, [data?.currency]);

    const layout = (amount2: number, amount1?: number, text?: string) => (
        <div className="flex items-start justify-between">
            <p className="text-left">
                {
                    amount1
                        ? (
                            <>
                                {currency}
                                &nbsp;
                                <b>{formatCurrency(amount1)}</b>
                            </>
                        )
                        : (<>{text}</>)
                }
            </p>
            <p className="text-right">
                {currency}
                &nbsp;
                <b>{formatCurrency(amount2)}</b>
            </p>
        </div>
    );

    return (
        <ContentBox
            as="section"
            isMainBox
            title={t('deposits.title', { year: new Date().getFullYear() })}
            variant="tabbed"
        >
            <GridColumns
                columns={{ lg: 1 }}
                className="gap-y-[0.25rem] pt-[1rem] pb-[1.75rem] px-[1.5rem] text-base text-primary-500"
            >
                <ContentBoxError isError={isError} errorMessage={t('common.something-wrong')}>
                    <SkeletonWrapper
                        isLoading={isLoading}
                        Skeleton={(
                            <SkeletonLine
                                className="h-[8.25rem]"
                                height={12}
                                width="full"
                            />
                        )}
                    >
                        <p className="text-right">
                            {t('deposits.maximumAmount')}
                        </p>
                        {layout(data?.maximumAmount || 0, data?.currentAmount || 0)}
                        <div className="py-1">
                            <ProgressBar progress={progress} />
                        </div>
                        {layout(stillPossible, undefined, t('deposits.stillPossible'))}
                    </SkeletonWrapper>
                </ContentBoxError>
            </GridColumns>
        </ContentBox>
    );
};
