import React, { forwardRef } from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
import { RadioGroupWrapper } from './components/radio-group-wrapper';
import { DefaultOption } from './components/radio-option';
import { CardOption } from './components/radio-card-option';
import { TRadioButtonGroupProps } from './types';

const columnClasses = {
    1: 'md:grid-cols-1',
    2: 'md:grid-cols-2',
    3: 'md:grid-cols-3',
    4: 'md:grid-cols-4',
    5: 'md:grid-cols-5',
    6: 'md:grid-cols-6',
};

const RadioButtonGroup = forwardRef((
    {
        data = [],
        error = false,
        value,
        ...props
    }: TRadioButtonGroupProps,
    outerRef?: React.Ref<HTMLDivElement>,
) => {
    const descriptionClass = props.titleHidden ? 'sr-only' : 'text-sm text-secondary-500';
    const type = props.type ? props.type : 'default';
    const size = props.size ? props.size : 'default';
    const cols = props.cols ? props.cols : 2;

    const onChange = (event) => {
        props.onChange?.(event);
    };

    const variant = props.variant || 'default';

    if (type === 'card') {
        return (
            <RadioGroupWrapper
                type="card"
                value={value}
                onChange={onChange}
                disabled={props.disabled}
                titleHidden={props.titleHidden}
                title={props.title}
            >
                <div
                    ref={outerRef}
                    className={
                        classNames(
                            '[&_span]:h-[24px] [&_span]:w-[24px] [&_span:last-child]:h-[10px] [&_span:last-child]:w-[10px] [&_div]:text-[1rem] [&_div]:leading-[1.5rem] [&_div]:font-normal',
                            props.customCols || columnClasses[cols],
                            size === 'large' ? 'space-y-6' : 'grid md:gap-y-[2rem] grid md:gap-x-[1.9rem] gap-[1rem]',
                            props.titleHidden ? '' : 'mt-2',
                        )
                    }
                >
                    {data.map((item) => (
                        <CardOption
                            key={item.value}
                            error={error}
                            item={item}
                            noRadio={props.noRadio}
                            disabled={props.disabled}
                            size={size}
                            variant={variant}
                        />
                    ))}
                </div>
            </RadioGroupWrapper>
        );
    }

    return (
        <RadioGroupWrapper
            value={value}
            onChange={onChange}
            disabled={props.disabled}
            titleHidden={props.titleHidden}
            title={props.title}
        >
            {props.description && (
                <div className={classNames(
                    descriptionClass,
                )}
                >
                    {props.description}
                </div>
            )}

            <div
                ref={outerRef}
                className={
                    classNames(
                        props.grid ? `grid gap-4 ${columnClasses[cols]}` : 'flex justify-start items-start',
                        (props.horizontal && !props.grid) ? 'gap-6' : `flex-col ${size === 'large' ? 'gap-4' : 'gap-2'}`,
                        props.titleHidden ? '' : 'mt-2',
                    )
                }
            >
                {data.map((item) => (
                    <DefaultOption
                        size={size}
                        key={item.value}
                        error={error}
                        item={item}
                        disabled={props.disabled}
                    />
                ))}
            </div>
        </RadioGroupWrapper>
    );
});

export default RadioButtonGroup;
