import React, { useMemo } from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { ContentBoxError } from '@hydrogen/elements.ui.components.content-box';

import { useAnalysisInvestmentAllocation } from '@hydrogen/elements.data.wealth.rest.use-analysis-investment-allocation';
import { container } from '@hydrogen/elements.core.di';
import { ContentBox } from 'elements/ui/components/content-box';
import { PieChart, PieChartTooltip } from 'elements/ui/components/pie-chart';
import { TooltipComponentProps } from 'elements/ui/components/pie-chart/pie-chart-tooltip';

type AllocationsProps = {
    dataSet?: typeof useAnalysisInvestmentAllocation,
};

export const Allocations = ({
    dataSet = container.get<typeof useAnalysisInvestmentAllocation>('useAnalysisInvestmentAllocation'),
}: AllocationsProps) => {
    const { t, language } = useLanguage();
    const { data: items, isLoading, isError } = dataSet({
        includeLookThrough: true,
        category: 'ParentAssetClass',
        language,
    });

    const chartPayload = [{
        name: 'Percentage',
        showInLegend: true,
        data: items?.map((i) => ({ ...i, y: i.percentage })),
    }];

    const hasAllocations = useMemo(() => items && items?.length > 0, [items]);

    const PieChartTooltipFormatter = (props: TooltipComponentProps) => (
        <PieChartTooltip
            {...props}
            formatValue={(val) => (val ? `${(+val)?.toFixed(2)}%` : null)}
        />
    );

    const formatLegendValue = (y) => `${(Number(y) * 100)?.toFixed(2)}%`;

    return (
        <ContentBox as="section" isMainBox title={t('allocations.title')} variant="tabbed">
            <GridColumns
                columns={{ lg: 1 }}
                className="gap-y-[0.25rem] pt-4 pb-7 px-6 text-base text-primary-500"
            >
                <ContentBoxError isError={isError} errorMessage={t('common.something-wrong')}>
                    <PieChart
                        isLoading={isLoading}
                        data={chartPayload}
                        withLegend
                        legendType="custom"
                        formatLegendValue={formatLegendValue}
                        TooltipComponent={PieChartTooltipFormatter}
                        hasAllocations={hasAllocations}
                    />
                </ContentBoxError>
            </GridColumns>
        </ContentBox>
    );
};
