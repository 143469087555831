import { useQuery } from 'react-query';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { AccessPolicy } from '../../../../common/constants';

export interface IClientsOverviewInvestment {
    id: number
    name: string
    currency: string
    value: number
    status: number,
    absoluteReturn: number | null,
    isJoint: boolean,
    rubrik?: string | null,
    accessPolicyId: AccessPolicy,
    performanceTwr: number | null,
    product: {
        externalId: string
        description: string
    }
}

export interface IClientsOverviewInvestmentReqParams {
    showClosed?: boolean
    language?: string
}

export function useClientsOverviewInvestments({
    showClosed,
    language,
}: IClientsOverviewInvestmentReqParams) {
    const contactId = useContactId();
    const query = useQuery<IClientsOverviewInvestment[]>(
        ['client-investments-overview', contactId, showClosed, language],
        () => clientServiceApi()
            .get(
                `/v1.0/Contact/${contactId}/overview/investments`,
                { params: { showClosed, language } },
            ).then(({ data }) => data.items),
        {
            enabled: !!contactId,
            keepPreviousData: true,
        },
    );

    return {
        ...query,
        isLoading: query.isLoading || query.isIdle,
    };
}
