import React from 'react';
import Table, {
    useTableControl,
    TableFilter,
    createTableState,
    TableEmpty,
} from '@hydrogen/elements.ui.components.table';
import { useFields } from '@hydrogen/elements.data.wealth.app.use-fields';
import { getTableOrders } from '@hydrogen/elements.core.utils.base';
import { getContainer } from '@hydrogen/elements.core.di';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import Pagination from '@hydrogen/elements.ui.components.pagination';
import { useInvestmentPositions } from '@hydrogen/elements.data.wealth.rest.use-investment-positions';
import { LinkListItem } from '@hydrogen/elements.ui.components.link-list';
import { ContentBoxFunctionBar } from '@hydrogen/elements.ui.components.content-box';

import { ContentBox } from 'elements/ui/components/content-box';
import { customCells, customMobileCells } from './custom-cells';

export type InvestmentsPositionsProps = {
    countOfPage?: number,
    dataSource?: typeof useInvestmentPositions,
    tableSourceKey?: string
};

// TODO: this is copied from hydrogen - rethink if it's needed
// TODO: when BE is fixed, uncomment all commented lines
export function InvestmentPositions({
    countOfPage = 10,
    tableSourceKey = 'contact-investment-positions',
    dataSource = getContainer(useInvestmentPositions),
}: InvestmentsPositionsProps) {
    const { t, language } = useLanguage();
    const { data = [], isLoading: isLoadingColumns } = useFields({
        sourceKey: tableSourceKey,
        language,
    });
    const {
        filters, onChangeFilter, page, setPage, sorting, onSort,
    } = useTableControl();

    const columns = ['Security.Name', 'Allocation', 'InvestmentValueSecurityCurrency', 'Security.AssetClass.Name', 'Quantity', 'BuyPrice', 'ValuationPrice', 'Security.Currency.CurrencyCode'];

    // TODO: when BE is fixed and sending the correct values for
    // isMobileView and mobileOrder, remove this code
    const newData = columns.reduce<any[]>((acc, key) => {
        const foundItem = data.find((item) => item.key === key);

        if (foundItem) {
            acc.push(foundItem);
        }

        return acc;
    }, []).map((item, index) => {
        if (item.key === 'Security.Currency.CurrencyCode') {
            return {
                ...item,
                isVisible: false,
            };
        }

        return {
            ...item,
            isMobileView: true,
            mobileOrder: index,
        };
    });

    const {
        data: positions = {
            totalCount: 0,
            items: [],
        },
        isLoading, isError,
    } = dataSource({
        skip: (page - 1) * countOfPage,
        take: countOfPage,
        fields: columns,
        filters,
        orders: getTableOrders(sorting),
        language,
    });

    const tableState = createTableState({
        isLoading,
        columns: newData,
        data: positions.items,
        isLoadingColumns,
        customCells,
        mobileCustomCells: customMobileCells,
        props: {
            skeleton: {
                className: 'min-h-[40px] sm:min-h-[24px]',
            },
        },
    });

    return (
        <ContentBox
            as="section"
            isMainBox
            title={t('investment-positions.title')}
            variant="tabbed"
        >
            <ContentBoxFunctionBar position="top">
                <TableFilter
                    title={t('investments-positions.search-title')}
                    filters={filters}
                    onChangeFilter={onChangeFilter}
                    fields={newData}
                    isLoading={isLoading}
                    isDisabled={isError && !filters.length}
                />
            </ContentBoxFunctionBar>
            <Table
                renderListItem={(listItem) => (
                    <LinkListItem
                        showIcon={false}
                    >
                        {tableState.mobileItem(listItem)}
                    </LinkListItem>
                )}
                EmptySlot={(
                    <TableEmpty
                        withFilter={!!filters.length}
                        isError={isError}
                    />
                )}
                sorting={sorting}
                onSort={onSort as any} // TODO: fix type, probably due to different versions of lib
                rowKey="threadId"
                isEmpty={!isLoading && !tableState.data.length}
                columns={tableState.columns}
                data={tableState.data}
            />
            <Pagination
                total={positions.totalCount}
                page={page}
                onChange={setPage}
                pageSize={countOfPage}
            />
        </ContentBox>
    );
}
