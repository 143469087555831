import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
import { getLink, getLocales, useTranslate } from '@hydrogen/elements.ui.configs';
import Tooltip from '@hydrogen/elements.ui.components.tooltip';
import { getSteps, parsePath } from './utils';
import { BaseProgressProps } from './types';

export const ProgressBars = (props: BaseProgressProps) => {
    const Link = getLink();
    const { t } = useTranslate();
    const locales = getLocales();

    return (
        <nav
            className="flex flex-col space-y-4 items-center justify-center w-full mb-12 text-primary-500 px-6"
            aria-label="Progress"
        >
            <div className="flex w-full items-baseline justify-between space-x-6">
                <p className={classNames(
                    props.inverted ? 'text-absolute-0' : 'text-primary-500',
                    'text-base font-bold',
                )}
                >
                    {props.mainStepTitle}
                </p>
                {props.remainingTime
                    && (
                        <div className={classNames(
                            props.inverted ? 'text-absolute-0/50' : 'text-secondary-500',
                            'text-sm font-medium text-end',
                        )}
                        >
                            <p>
                                {props.remainingTime === 0
                                    ? t(locales.LAST_STEP)
                                    : (`${props.remainingTime} ${t(locales.STEPS_TO_COMPLETE)}`)}
                            </p>
                        </div>
                    )}
            </div>
            <ol className="flex items-center justify-between gap-1 w-full">
                {getSteps(props.totalSteps).map((stepIndex) => (
                    <li key={stepIndex} className="flex-1">
                        <Tooltip
                            tooltip={props.stepsInfo?.[stepIndex]?.title}
                            placement="bottom"
                        >
                            {stepIndex <= props.mainStep
                                ? (

                                    <Link
                                        to={parsePath(props.stepsInfo?.[stepIndex]?.link)}
                                        className={classNames(
                                            props.className,
                                            'group relative block h-1 bg-primary-500 rounded-full transition hover:cursor-pointer',
                                        )}
                                    >
                                        <span className="sr-only">{props.mainStepTitle}</span>

                                    </Link>
                                )
                                : (
                                    <div className={classNames(
                                        'group relative block h-1 rounded-full hover:cursor-not-allowed',
                                        props.inverted ? 'bg-absolute-0/25' : 'bg-accent-500',
                                    )}
                                    >
                                        <span className="sr-only">{props.mainStepTitle}</span>
                                    </div>
                                )}
                        </Tooltip>
                    </li>
                ))}
            </ol>
        </nav>
    );
};
