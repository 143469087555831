import React, { useCallback, useMemo } from 'react';

import { classNames } from '@hydrogen/elements.ui.utils';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { Content } from '@hydrogen/elements.ui.layouts.base';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { ButtonVariants } from '@hydrogen/elements.ui.components.button';
import Title from '@hydrogen/elements.ui.components.title';

import { AvatarCircle } from '@hydrogen/elements.ui.components.avatar';
import { useAuthenticationData } from 'hydrogen/elements.auth.client-portal';
import { Button, LinkButton } from 'elements/ui/components/button';
import { ContentBox } from 'elements/ui/components/content-box';

export function Profile() {
    const { t } = useLanguage();
    const arrowStyle = 'float-right icon icon-arrow-right';
    const categoryStyle = 'mb-[1rem] text-[1.125rem] leading-[1.5rem] font-medium text-primary-500';
    const contentCenter = 'flex justify-center';
    const iconStyle = 'icon mr-[1rem] text-[1rem] text-primary-500 hover:none';
    const innerLinkStyle = 'px-[1.5rem] py-[1.2918rem]';
    const linkStyle = 'w-full inline-block !text-[1.125rem] !leading-[1.5rem] text-secondary-300 cursor-pointer hover:no-underline';

    const { authData } = useAuthenticationData();
    const nameInitialsWithSpace = useMemo(() => `${authData?.userInfo.firstName?.[0]} ${authData?.userInfo.lastName?.[0]}`, [authData?.userInfo]);
    const userFullName = authData?.userInfo?.fullName ?? '';

    const { logout } = useAuthenticationData();

    const signOut = useCallback(async () => {
        logout();
    }, [logout]);

    return (
        <div className="flex-1 bg-white">
            <Content className="max-w-[40rem] !lg:pb-16 mx-auto lg:mt-10 mt-[1.5rem] lg:mb-[1.5rem]">
                <div className={contentCenter}>
                    <AvatarCircle
                        alt={nameInitialsWithSpace}
                        className="!w-20 !h-20 !bg-accent-300 !text-[1.75rem] !leading-[2.4375rem] font-normal !text-primary-500"
                        size="large"
                    />
                </div>
                <Title as="h2" className="mt-3 mb-10 !text-2xl text-center">{userFullName}</Title>
                <GridColumns gap={12} columns={1}>
                    <div>
                        <p className={categoryStyle}>{t('profile.dashboard')}</p>
                        <ContentBox
                            as="section"
                            isMainBox
                            noHeader
                        >
                            <LinkButton href="/overview" className={classNames(linkStyle, 'my-[0.2293rem]')}>
                                {/* <i className={classNames(iconStyle, 'icon-user-male')} /> */}
                                <span className="hover:underline">{t('profile.overview')}</span>
                                <i className={arrowStyle} />
                            </LinkButton>
                        </ContentBox>
                    </div>
                    <div>
                        <p className={categoryStyle}>{t('profile.account')}</p>
                        <ContentBox
                            as="section"
                            isMainBox
                            title={(
                                <LinkButton href="/personal-data" className={linkStyle}>
                                    <i className={classNames(iconStyle, 'icon-user-male')} />
                                    <span className="hover:underline">{t('profile.personalData')}</span>
                                    <i className={arrowStyle} />
                                </LinkButton>
                            )}
                        >
                            <div className={innerLinkStyle}>
                                <LinkButton href="/documents" className={classNames(linkStyle)}>
                                    <i className={classNames(iconStyle, 'icon-folder')} />
                                    <span className="hover:underline">{t('profile.documents')}</span>
                                    <i className={arrowStyle} />
                                </LinkButton>
                            </div>
                        </ContentBox>
                    </div>
                    <div>
                        <p className={categoryStyle}>{t('profile.security')}</p>
                        <ContentBox
                            as="section"
                            isMainBox
                            title={(
                                <LinkButton href="/change-password" className={linkStyle}>
                                    <i className={classNames(iconStyle, 'icon-lock-closed')} />
                                    <span className="hover:underline">{t('profile.password')}</span>
                                    <i className={arrowStyle} />
                                </LinkButton>
                            )}
                        >
                            <div className={innerLinkStyle}>
                                <LinkButton href="/access-app" className={classNames(linkStyle)}>
                                    <i className={classNames(iconStyle, 'icon-shield-person')} />
                                    <span className="hover:underline">{t('profile.accessApp')}</span>
                                    <i className={arrowStyle} />
                                </LinkButton>
                            </div>
                        </ContentBox>
                    </div>
                    <div>
                        <p className={categoryStyle}>{t('profile.support')}</p>
                        <ContentBox
                            as="section"
                            isMainBox
                            noHeader
                        >
                            <LinkButton href="/support" className={classNames(linkStyle, 'my-[0.2293rem]')}>
                                <i className={classNames(iconStyle, 'icon-phone')} />
                                <span className="hover:underline">{t('profile.support')}</span>
                                <i className={arrowStyle} />
                            </LinkButton>
                        </ContentBox>
                    </div>
                </GridColumns>
                <div className={contentCenter}>
                    <Button
                        className="h-12 max-w-[15.625rem] mt-16"
                        variant={ButtonVariants.default}
                        onClick={signOut}
                    >
                        {t('profile.logout')}
                    </Button>
                </div>
            </Content>
        </div>
    );
}
