import { useNavigate } from '@hydrogen/elements.core.router';
import { LinksConfigType } from '../types';

export function useAuthenticationNavigation(links: LinksConfigType) {
    const navigate = useNavigate();
    const loginMtan = () => {
        navigate(links?.loginMtan || '/');
    };
    const login = () => {
        navigate(links?.login || '/');
    };
    const forgotPassword = () => {
        navigate(links?.forgotPassword || '/');
    };
    const forgotPasswordMtan = () => {
        navigate(links?.forgotPasswordMtan || '/');
    };
    const loginSms = () => {
        navigate(links.loginSms || '/');
    };

    return ({
        loginMtan,
        login,
        loginSms,
        forgotPassword,
        forgotPasswordMtan,
    });
}
