import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

export type UseCookiesAcceptResponse = {
  cookiesAccepted: boolean;
  acceptCookies: (data: AcceptCookiesPayload) => void;
};

export type AcceptCookiesPayload = {
  accepted: boolean;
};

export function useCookiesAccept(): UseCookiesAcceptResponse {
    const [cookies, setCookie] = useCookies(['client-cookies']);

    // Function to get the initial value from cookies
    const getInitialCookiesAccepted = (): boolean => (cookies['client-cookies'] ? cookies['client-cookies'] === 'true' : false);

    const cookiesAccepted = getInitialCookiesAccepted();

    const acceptCookies = useCallback(
        (data: AcceptCookiesPayload) => {
            const { accepted } = data;

            setCookie('client-cookies', accepted, { maxAge: 365 * 24 * 60 * 60 }); // Setting the cookie to expire in 1 year
        },
        [setCookie],
    );

    return { cookiesAccepted, acceptCookies };
}
