import React, { useState, ReactNode, forwardRef } from 'react';
import { classNames, globalColorClasses } from '@hydrogen/elements.ui.utils';
import { Label } from '@hydrogen/elements.ui.components.label';
import { v4 as uuidv4 } from 'uuid';
import { IconButtonTypes } from '@hydrogen/elements.ui.components.icon-button';
import Tooltip from '@hydrogen/elements.ui.components.tooltip';

import { IconButton } from 'elements/ui/components/button';
import { Tooltip as TooltipWrapper } from 'elements/ui/components/tooltip/tooltip';

export enum InputTextSize {
    xs = 'text-xs',
    sm = 'text-sm',
    base = 'text-base',
    lg = 'text-lg',
    xl = 'text-xl',
    xxl = 'text-2xl',
}

type InputIcons = React.ComponentType<{ className?: string }>

export type TextInputProps = Omit<React.InputHTMLAttributes<unknown>, 'autoComplete'> & {
    value?: string | number
    type?: string
    children?: ReactNode
    className?: string
    wrapperClassName?: string
    xPadding?: string
    error?: string | boolean
    addonTrailing?: ReactNode | string | number
    addonLeading?: ReactNode | string | number
    addonInline?: boolean
    addonErrorStyle?: 'default' | 'inner'
    iconLeading?: InputIcons | string
    iconTrailing?: string
    textSize?: InputTextSize
    optional?: boolean
    tooltip?: string
    name?: string
    autoComplete?: string | boolean
    label?: string | React.ReactElement
    placeholder?: string
    defaultValue?: string
    disabled?: boolean
    ariaLabel?: string
    labelInline?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    as?: React.ElementType
};

const getAutoComplete = (autoComplete?: string | boolean): string => {
    if (typeof autoComplete === 'string') return autoComplete;

    return autoComplete ? 'on' : 'off';
};

const getPasswordType = (isVisible = false) => (isVisible ? 'text' : 'password');

export const TextInput = forwardRef(({
    value = '',
    type = 'text',
    children,
    className,
    wrapperClassName,
    xPadding,
    error,
    addonTrailing,
    addonLeading,
    addonInline,
    addonErrorStyle = 'default',
    labelInline = false,
    iconLeading,
    iconTrailing,
    textSize = InputTextSize.sm,
    optional,
    name,
    label,
    placeholder,
    tooltip,
    defaultValue,
    disabled,
    ariaLabel,
    autoComplete = false,
    onChange,
    onKeyUp,
    onKeyDown,
    id = uuidv4(),
    as = 'input',
    ...inputProps
}: TextInputProps, ref) => {
    const ariaDescribedby: Array<string> = [];
    const [focused, setFocused] = useState(false);
    const [visiblePassword, setVisiblePassword] = useState(false);

    if (error) ariaDescribedby.push(`${name}-error`);
    if (addonTrailing) ariaDescribedby.push(`${name}-addonTrailing`);
    if (addonLeading) ariaDescribedby.push(`${name}-addonLeading`);
    if (children) ariaDescribedby.push(`${name}-desc`);

    // Allow for both versions of error state borders for text or number addons
    // default: UI as in https://product-hwm-prototype-dev.additiv.com/components/text-input/
    // inner: UI as in https://product-latest-prototype.additiv.com/components/text-input/
    const isAddonComponent = (addon: ReactNode | string | number) => !!addon && !['number', 'string'].includes(typeof addon);
    const isLeadingComponent = addonErrorStyle === 'default' || isAddonComponent(addonLeading);
    const isTrailingComponent = addonErrorStyle === 'default' || isAddonComponent(addonTrailing);
    const onBlur = () => {
        if (!value) {
            setFocused(false);
        }
    };
    const onFocus = () => {
        setFocused(true);
    };
    const addonLeadingContent = (): JSX.Element => {
        if (addonInline) {
            return <>{addonLeading}</>;
        }

        return (
            <span
                className={
                    classNames(
                        'inline-flex items-center ',
                        isLeadingComponent
                            ? 'border-width-input border-input-border-color border-r-0'
                            : 'border-r-width-input border-input-border-color',
                        'bg-neutral-50 text-secondary-500',
                        'px-3 rounded-l ',
                        'rtl:rounded-l-none rtl:rounded-r rtl:border-r rtl:border-l-0',
                    )
                }
                id={`${name}-addLeading`}
            >
                {addonLeading}
            </span>
        );
    };

    const addonTrailingContent = (): JSX.Element => {
        if (addonInline) {
            return <>{addonTrailing}</>;
        }

        return (
            <span
                className={classNames(
                    'px-3 inline-flex items-center',
                    isTrailingComponent
                        ? 'border-width-input border-input-border-color border-l-0'
                        : 'border-l-width-input border-input-border-color',
                    'bg-neutral-50 text-secondary-500',
                    'rounded-r',
                    'rtl:rounded-r-none rtl:rounded-l rtl:border-l rtl:border-r-0',
                )}
                id={`${name}-addonTrailing`}
            >
                {addonTrailing}
            </span>
        );
    };
    const InputElem = as || 'input';

    const isPasswordType = type === 'password';

    const inputType = isPasswordType
        ? getPasswordType(visiblePassword)
        : type;

    // TODO: replace icons with icon font as well as visibility on icon svg will be available
    const renderIcon = () => (
        <i
            className={classNames(
                iconTrailing && `icon-${iconTrailing}`,
                visiblePassword && 'icon-pass-show mr-3',
                !iconTrailing && !visiblePassword && 'icon-pass-hide mt-1 mr-3',
                tooltip && 'hover:cursor-pointer',
                error ? 'text-danger-200' : 'text-primary-500',
                'icon text-2xl bg-transparent z-20e',
            )}
        />
    );

    const renderTooltip = () => (
        <>
            {tooltip ? (
                <Tooltip
                    tooltip={(
                        <TooltipWrapper className="!rounded-bl-2xl !rounded-br-none">
                            {tooltip}
                        </TooltipWrapper>
                    )}
                    placement="top-end"
                    variant="pure"
                >
                    {renderIcon()}
                </Tooltip>
            )
                : renderIcon()}
        </>
    );

    return (
        <div className="md:min-h-[5.375rem] min-h-[4.875rem]">
            {!ariaLabel && !!label && !labelInline && (
                <Label htmlFor={id} optional={optional}>{label}</Label>
            )}
            <div className="relative flex w-full">
                {addonLeading && isLeadingComponent && addonLeadingContent()}
                <div className={classNames(
                    'relative flex h-12 md:h-[3.52rem]',
                    'block w-full shadow-input bg-accent-500 rounded-input',
                    'text-primary-500 text-base sm:text-sm border-width-input',
                    !error && 'border-input-border-color',
                    'focus-within:ring-1 focus-within:ring-secondary-300 focus-within:border-secondary-300',
                    !!error && 'text-danger-200 border-danger-200 placeholder-danger-500 focus-visible:outline-none focus:ring-1 focus:ring-danger-500 focus:border-danger-500',
                    !!addonTrailing && isTrailingComponent && 'rounded-r-none rounded-l rtl:rounded-r rtl:rounded-l-none',
                    !!addonLeading && isLeadingComponent && 'rounded-l-none rounded-r rtl:rounded-l rtl:rounded-r-none',
                    !!iconTrailing && 'text-left rtl:text-right',
                    disabled && globalColorClasses.disabled,
                    textSize,
                    wrapperClassName,
                )}
                >
                    {addonLeading && !isLeadingComponent && addonLeadingContent()}
                    {iconLeading
                        && (
                            <div className={
                                classNames(
                                    focused || value ? 'flex gap-2 items-center pointer-events-none pl-[1.5rem]' : 'hidden',
                                )
                            }
                            >
                                <i
                                    className={classNames(error ? 'text-danger-200' : 'text-neutral-300', 'icon z-20')}
                                />
                            </div>
                        )}
                    <div className={classNames(labelInline ? 'flex-col' : '', 'flex w-full')}>
                        <InputElem
                            {...inputProps}
                            ref={ref}
                            type={inputType}
                            name={name}
                            id={id}
                            className={
                                classNames(
                                    className,
                                    xPadding ?? 'px-[1.5rem]',
                                    'md:pt-[1.5rem] pt-[1.2rem] md:pb-[0.8rem] pb-0.5 block w-full h-12 md:h-[3.52rem] bg-transparent rounded-input text-base text-[1.2rem] leading-[2rem]',
                                    'border-0 focus:ring-0 placeholder-secondary-300 disabled:cursor-not-allowed',
                                    textSize,
                                    error && 'text-danger-200 placeholder-danger-500',
                                    '[&::-ms-reveal]:!hidden',
                                )
                            }
                            placeholder={labelInline ? '' : placeholder}
                            required={!!optional}
                            defaultValue={defaultValue}
                            aria-describedby={
                                (ariaDescribedby && ariaDescribedby.join(' ')) || undefined
                            }
                            disabled={disabled}
                            aria-invalid={!!error && 'true'}
                            aria-label={ariaLabel || undefined}
                            autoComplete={getAutoComplete(autoComplete)}
                            value={value}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onChange={onChange}
                            onKeyUp={onKeyUp}
                            onKeyDown={onKeyDown}
                        />
                        {labelInline
                            && (
                                <div
                                    className={classNames(
                                        focused || value ? 'md:top-[-3.25rem] top-[-2.375rem]' : 'md:top-[-2.45rem] top-[-1.75rem]',
                                        'block relative pl-[1.5rem] z-10',
                                    )}
                                >
                                    <Label
                                        className={classNames(
                                            focused || value ? '!text-[0.75rem] leading-[1.125rem] font-medium' : '!text-[1.125rem] leading-[1.5rem] font-normal',
                                            error ? '!text-danger-200' : '!text-secondary-300',
                                        )}
                                        fontSize="text-xs"
                                        labelInline={labelInline}
                                        htmlFor={id}
                                        optional={optional}
                                    >
                                        {label}
                                    </Label>
                                </div>
                            )}
                    </div>
                    {(iconTrailing || isPasswordType) && (
                        <div
                            className={classNames(
                                'flex gap-2 items-center',
                                addonLeading ? 'ps-16' : 'ps-2',
                                (iconTrailing && !!addonTrailing) && 'pe-16',
                                (iconTrailing && !addonTrailing) && 'pe-4',
                                iconTrailing && !tooltip && 'pointer-events-none',
                            )}
                        >

                            {iconTrailing && renderTooltip()}
                            {isPasswordType && (
                                <IconButton
                                    className="pr-2.5 !bg-transparent"
                                    onClick={
                                        () => setVisiblePassword((isVisible) => !isVisible)
                                    }
                                    type={IconButtonTypes.outline}
                                >
                                    {renderTooltip()}
                                </IconButton>
                            )}
                        </div>
                    )}

                    {addonTrailing && !isTrailingComponent && addonTrailingContent()}
                </div>
                {addonTrailing && isTrailingComponent && addonTrailingContent()}
            </div>
            {error
                && (
                    <p className="my-1.5 px-[1.5rem] text-[0.75rem] leading-[1.125rem] text-danger-200 font-bold" id={`${name}-error`}>
                        {error}
                    </p>
                )}
            {children
                && (
                    <p className="my-1 px-[1.5rem] text-[0.75rem] leading-[1.125rem] text-secondary-500" id={`${name}-desc`}>
                        {children}
                    </p>
                )}
        </div>
    );
});
