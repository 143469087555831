import React from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useNavigate } from '@hydrogen/elements.core.router';
import Title from '@hydrogen/elements.ui.components.title';

import { LinkButton } from 'elements/ui/components/button';

type SubHeaderProps = {
    title: string
    className?: string
    showBack?: boolean
}

export function SubHeader({ title, showBack = true, className = '' }: SubHeaderProps) {
    const { t } = useLanguage();
    const navigate = useNavigate();

    return (
        <div
            className={`lg:mb-12 mb-6 mx-[-1rem] sm:mx-[-1.5rem] md:mt-16 md:mb-[1.5rem] md:px-0 md:py-0 py-[1.125rem] md:bg-transparent bg-white px-[1.875rem] md:rounded-b-0 rounded-b-3xl md:shadow-none shadow-md ${className}`}
        >
            <div>
                {showBack && (
                    <LinkButton
                        className="flex align-middle text-[1rem] leading-6 text-secondary-300 hover:no-underline"
                        onClick={() => navigate(-1)}
                    >
                        <i className="icon icon-arrow-left text-[1.5rem] mr-3" />
                        <span className="md:inline-block mt-[0.2rem] hidden text-[1.125rem] leading-6 hover:underline">
                            {
                                t('subheader.back')
                            }
                        </span>
                    </LinkButton>
                )}
                <Title
                    className="align-middle md:mt-[-1.9rem] mt-[-1.4375rem] text-center md:text-[1.75rem] sm:text-[1.125rem] text-[1.125rem] md:leading-[2.4375rem] sm:leading-[1.5rem] leading-[1.5rem]"
                >
                    {title}
                </Title>
            </div>
        </div>
    );
}
