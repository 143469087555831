import React, { useMemo, useState } from 'react';
import { object, string } from 'yup';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useNavigate } from '@hydrogen/elements.core.router';
import { InputTextSize } from '@hydrogen/elements.ui.components.text-input';
import Title from '@hydrogen/elements.ui.components.title';
import { useAuthenticationData } from 'hydrogen/elements.auth.client-portal';
import { Button, LinkButton } from 'elements/ui/components/button';
import { NUMBER_REGEX } from 'common/validation';
import { Controller, useForm } from 'elements/ui/components/use-form';
import { LoginResult } from 'elements/data/rest/use-login/use-login';
import { TextInput } from 'elements/ui/components/text-input/text-input';
import { Infobox, InfoboxTypes } from 'elements/ui/components/info-box';

import { LOGIN_AUTH_ERRORS } from '../../../../common/constants';
import { downtimeProtectedNavigation } from '../../../../common/helper';

type LoginForm = {
    clientId: string;
    password: string;
};

export function LoginForm() {
    const [loginError, setLoginError] = useState<LoginResult | boolean | string>();
    const [inProgress, setInProgress] = useState(false);
    const { login, mfaAuthError } = useAuthenticationData();

    const navigate = useNavigate();
    const { t } = useLanguage();

    const validationSchema = useMemo(
        () => object().shape({
            clientId: string()
                .matches(NUMBER_REGEX, t('validation.numbersOnly'))
                .required(t('validation.mandatoryField')),
        }),
        [t],
    );

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<LoginForm>({
        validationSchema,
        options: {
            defaultValues: {
                clientId: '',
                password: '',
            },
        },
    });

    const password = watch('password');
    const clientId = watch('clientId');

    const isSubmitDisabled = !password || !clientId || inProgress;

    const onFormSubmit = handleSubmit(async (values: LoginForm) => {
        try {
            setInProgress(true);
            setLoginError(false);

            const data = await login({ password: values.password, username: values.clientId });

            if (data?.isAuthed) {
                navigate('/overview');
            }
        } catch (error: any) {
            if (error.Result && LOGIN_AUTH_ERRORS.includes(error?.Result)) {
                setLoginError(error.Result);
            } else {
                setLoginError('generic');
            }
            console.error('Error occurred: ', error);
        } finally {
            setInProgress(false);
        }
    });

    return (
        <div className="h-full w-full flex flex-col">
            <div
                className="bg-cover h-72 bg-no-repeat md:hidden mb-4 bg-bottom"
                style={{ backgroundImage: 'url(/static/img/cover-image.svg)', clipPath: 'ellipse(80% 70% at 70% 30%)' }}
            />
            <div className="w-full h-full flex flex-col items-center md:py-10 text-center md:justify-center">
                <Title>
                    {t('loginForm.header')}
                </Title>
                {(loginError || mfaAuthError) && (
                    <div className="justify-self-start w-full text-start mt-10 md:mt-12 px-7 md:px-0">
                        <Infobox
                            title={t(loginError ? `authError.${loginError}` : 'loginForm.info.error.mfaAuth')}
                            type={InfoboxTypes.error}
                        />
                    </div>
                )}
                <form className="w-full px-[1.75rem] md:px-0 mt-10 md:mt-12" onSubmit={onFormSubmit} autoComplete="off">
                    <div className="w-full text-start">
                        <Controller
                            name="clientId"
                            control={control}
                            render={({ field }) => (
                                <TextInput
                                    autoComplete="off"
                                    tooltip={t('loginForm.clientId.tooltip')}
                                    iconTrailing="info"
                                    label={t('loginForm.clientId.label')}
                                    labelInline
                                    textSize={InputTextSize.lg}
                                    error={errors.clientId?.message}
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className="w-full text-center mb-10">
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <div className="md:h-[4.5rem] h-16">
                                    <TextInput
                                        autoComplete="off"
                                        label={t('loginForm.password.label')}
                                        labelInline
                                        type="password"
                                        textSize={InputTextSize.lg}
                                        error={errors.password?.message}
                                        {...field}
                                    />
                                </div>
                            )}
                        />
                        <LinkButton
                            className="!text-[1.125rem] !leading-[1.5rem]"
                            onClick={
                                downtimeProtectedNavigation(() => {
                                    navigate('/forgot-password');
                                })
                            }
                        >
                            {t('loginForm.forgotPassword.label')}
                        </LinkButton>
                    </div>
                    <Button
                        isSubmit
                        loading={inProgress}
                        onClick={downtimeProtectedNavigation(onFormSubmit, true)}
                        disabled={isSubmitDisabled}
                    >
                        {t('loginForm.submitButton.label')}
                    </Button>
                    <LinkButton
                        className="!text-[1.125rem] !leading-[1.5rem] mt-16"
                        onClick={downtimeProtectedNavigation(() => {
                            navigate('/signup/code-verification');
                        })}
                    >
                        {t('loginForm.enterActivationCode.label')}
                    </LinkButton>
                </form>
            </div>
        </div>
    );
}
