import React from 'react';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';
import Badge from '@hydrogen/elements.ui.components.badge';
import { formatDate, t, toUTCFormat } from '@hydrogen/elements.core.i18n';
import { renderEmptyCell } from '@hydrogen/elements.ui.components.table';
import { StatusBadgeEnum, StatusLangEnum } from './types';
import { formatCurrency } from '../../../../common/formaters';

export const customCells = {
    Date: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width={20} />
                </div>
            )}
        >
            {item?.Date
                ? formatDate(new Date(toUTCFormat(item?.Date)), 'dd.MM.yyyy')
                : renderEmptyCell()}

        </SkeletonWrapper>
    ),
    'Status.Name': ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={<SkeletonLine width={32} />}
        >
            <Badge
                title={t(StatusLangEnum[item['Status.Name']])}
                type={StatusBadgeEnum[item['Status.Name']]}
            />
        </SkeletonWrapper>
    ),
    Value: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width={20} />
                </div>
            )}
        >
            {item?.Value
                ? `${item['Type.Name'] === 'Debit' ? '-' : '+'}${formatCurrency(item?.Value, item?.Currency)}`
                : renderEmptyCell()}
        </SkeletonWrapper>
    ),
};

export const customMobileCells = {
    Date: ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div>
                    <SkeletonLine width={20} />
                </div>
            )}
        >
            {item?.Date
                ? formatDate(new Date(toUTCFormat(item?.Date)), 'dd.MM.yyyy')
                : renderEmptyCell()}

        </SkeletonWrapper>
    ),
    'Status.Name': ({ item }, isLoading) => (
        <SkeletonWrapper
            isLoading={isLoading}
            Skeleton={(
                <div className="min-h-[24px] flex justify-end">
                    <SkeletonLine width="full" />
                </div>
            )}
        >
            <div className="font-medium truncate text-primary-500">
                {item['Status.Name']}
            </div>
        </SkeletonWrapper>
    ),
};
