export const availableLanguages = [
    {
        id: 'de',
        label: 'common.german',
        subLabel: 'common.de-de',
        value: 'de',
    },
    {
        id: 'en',
        label: 'common.english',
        subLabel: 'common.en-gb',
        value: 'en',
    },
    {
        id: 'fr',
        label: 'common.french',
        subLabel: 'common.fr-ch',
        value: 'fr',
    },
    {
        id: 'it',
        label: 'common.italian',
        subLabel: 'common.it-ch',
        value: 'it',
    },
];
