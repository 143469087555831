import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';
import { SkeletonLine, SkeletonWrapper } from '@hydrogen/elements.ui.components.skeleton';

import { LinkContact } from '../link-contact';

export type ContactBlockProps = {
    className?: string;
    isLoading?: boolean;
    name?: string;
    street?: string;
    city?: string;
    phone?: string;
    email?: string;
};

export const ContactBlock = ({
    className,
    isLoading = false,
    name,
    street,
    city,
    phone,
    email,
}: ContactBlockProps) => (
    <SkeletonWrapper
        isLoading={isLoading}
        Skeleton={(
            <SkeletonLine
                height={60}
                width="full"
            />
        )}
    >
        <div className={classNames(className, 'mb-4 text-primary-500')}>
            {name && <p>{name}</p>}
            {street && <p>{street}</p>}
            {city && <p>{city}</p>}
        </div>
        {phone && <LinkContact value={phone} />}
        {email && <LinkContact value={email} variant="email" />}
    </SkeletonWrapper>
);
