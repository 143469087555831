import { useCallback, useEffect } from 'react';
import { post } from '@hydrogen/elements.core.event-bus';
import { TIMEOUT_THRESHOLD } from '../../../common/constants';
import { AuthDataType } from '../types';
import { SessionTopics } from '../../../elements/core/contracts';

const activityEvents = ['click', 'dblclick', 'mousedown', 'mouseup', 'mouseenter', 'mouseover', 'mouseleave', 'mousemove', 'keydown', 'keyup', 'keypress', 'scroll'];

export const useIdleUserTime = (authData: AuthDataType) => {
    let timer: NodeJS.Timer | null = null;

    const resetTimer = useCallback(() => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            post(SessionTopics.SessionExpired, true);
        }, TIMEOUT_THRESHOLD);
    }, []);

    const clearEventsAndTimer = useCallback(() => {
        if (timer) {
            clearTimeout(timer);
        }
        activityEvents.forEach((f) => window.removeEventListener(f, resetTimer));
    }, []);

    useEffect(() => {
        if (authData.accessToken) {
            activityEvents.forEach((f) => window.addEventListener(f, resetTimer));
            resetTimer();
        } else {
            clearEventsAndTimer();
        }

        return () => {
            clearEventsAndTimer();
        };
    }, [resetTimer, authData, clearEventsAndTimer]);
};
