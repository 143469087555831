import React from 'react';

import { classNames } from '@hydrogen/elements.ui.utils';

export type TooltipProps = {
    className?: string;
    children: React.ReactNode | React.ReactNode[]
};

export const Tooltip = ({
    className,
    children,
}: TooltipProps) => (
    <div className={classNames(
        className,
        'max-w-[10.625rem] text-left break-words',
        'rounded-2xl rounded-bl-none !border-none shadow !py-[0.5rem] !px-[0.625rem] bg-accent-500 text-[0.75rem] leading-[1.125rem] text-various-blue',
    )}
    >
        {children}
    </div>
);
