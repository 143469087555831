import { useCallback, useState } from 'react';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { container } from '@hydrogen/elements.core.di';
import { useAppTokenValidate } from '../use-app-token-validate';

export type UsePasswordSetupResult = {
    inProgress: boolean;
    setPassword: (payload: SetPasswordPayload) => Promise<boolean>;
};

export type SetPasswordPayload = {
    clientId: string;
    password: string;
    contactIdentityId: string
};

export function usePasswordSetup(): UsePasswordSetupResult {
    const dataSource = container.get<typeof useAppTokenValidate>('useAppTokenValidate');
    const { checkAppToken } = dataSource();
    const [inProgress, setInProgress] = useState(false);

    const setPassword = useCallback(async (payload: SetPasswordPayload) => {
        try {
            setInProgress(true);
            await checkAppToken();

            await clientServiceApi().post(`/v1.0/Onboarding/${payload.contactIdentityId}/set-password`, {
                username: payload.clientId,
                password: payload.password,
            });

            return true;
        } finally {
            setInProgress(false);
        }
    }, []);

    return { inProgress, setPassword };
}
