import { AuthErrorResponseType, AuthErrors, UserInfoType } from './types';

export const initAuthData = global.initAuthData || {
    appToken: null,
    jwt: null,
    accessToken: null,
    preAuthenticateSessionId: null,
    isAuthed: false,
    userInfo: {
        contactId: null,
        userName: null,
        isActive: null,
        isTwoFactorEnabled: null,
        otpLength: null,
        firstName: null,
        lastName: null,
    },
};

type CampaignContact = {
    ContactId: number
    UserName: string
    IsActive: boolean
    IsTwoFactorEnabled: boolean
    PhoneNumber: string
}
export const getUserInfo = (campaignContact: CampaignContact, otpLength?: number): UserInfoType => ({
    contactId: campaignContact?.ContactId,
    userName: campaignContact?.UserName,
    isActive: campaignContact?.IsActive,
    isTwoFactorEnabled: campaignContact?.IsTwoFactorEnabled,
    phoneNumber: campaignContact?.PhoneNumber,
});

export const generateJWT = (sessionId?: string, jwtAccessToken?: string) => {
    if (!sessionId || !jwtAccessToken) return '';

    return (
        btoa(`${sessionId}:${jwtAccessToken}`)
    );
};

export const isAuthResponseError = (
    response: AuthErrorResponseType,
) => {
    if ((response?.Result || response?.result) && AuthErrors?.[response?.Result ?? response?.result]) throw response;
};

export const getAuthResponseError = (
    error: unknown,
    defaultErrorMessage = 'something-went-wrong',
): keyof typeof AuthErrors | string => (
    AuthErrors?.[(error as AuthErrorResponseType)?.Result || ''] || defaultErrorMessage
);
