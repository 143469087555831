import { useCallback, useEffect, useState } from 'react';
import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { subscribe } from '@hydrogen/elements.core.event-bus';
import { AxiosResponse } from 'axios';
import { container } from '@hydrogen/elements.core.di';
import { FirstLoginClientDetails, FirstLoginTopics } from '../../../core/contracts';
import { isAuthResponseError } from '../../../../hydrogen/elements.auth.client-portal/utils';
import { AuthErrorResponseType } from '../../../../hydrogen/elements.auth.client-portal/types';
import { useAppTokenValidate } from '../use-app-token-validate';

export enum EnrolmentStatus {
    Pending = 'Pending',
    Success = 'Success'
}

type AsyncParams = {
    ActivationQrCodeUrl: string
}

export type MfaAppDetails = {
    Result: string,
    UserName: string,
    EnrollmentSessionId: string,
    AsynchronousParameters: AsyncParams
}

type EnrolmentStatusResponse = {
    Result: string,
    Status: EnrolmentStatus
}

export type UseMfaSetupResult = {
    inProgress: boolean;
    appData: MfaAppDetails | undefined;
    getAppCode: () => Promise<void>;
    enrolmentStatus: EnrolmentStatus | undefined;
    getEnrolmentStatus: (clearInterval) => Promise<void>
};

export function useMfaSetup(): UseMfaSetupResult {
    const dataSource = container.get<typeof useAppTokenValidate>('useAppTokenValidate');
    const { checkAppToken } = dataSource();
    const [inProgress, setInProgress] = useState(false);
    const [appData, setAppData] = useState<MfaAppDetails>();
    const [clientData, setClientData] = useState<FirstLoginClientDetails>();
    const [enrolmentStatus, setEnrolmentStatus] = useState<EnrolmentStatus>();

    useEffect(() => {
        const unsubscribe = subscribe(FirstLoginTopics.FirstLoginClientDetails, (payload) => {
            setClientData(payload);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const getAppCode = useCallback(async () => {
        try {
            if (!clientData?.contactIdentityId) {
                return;
            }

            setInProgress(true);

            await checkAppToken();

            const response: AxiosResponse<MfaAppDetails> = await clientServiceApi().post(`/v1.0/Onboarding/${clientData?.contactIdentityId}/enroll`, {});

            setAppData(response.data);
        } finally {
            setInProgress(false);
        }
    }, [clientData]);

    const getEnrolmentStatus = useCallback(async (clearInterval) => {
        try {
            if (!clientData?.userName) {
                return;
            }

            const response: AxiosResponse<EnrolmentStatusResponse> = await clientServiceApi().post('/v1.0/Onboarding/enrollment-status', {
                userName: clientData?.userName,
                enrollmentSessionId: appData?.EnrollmentSessionId,
            });

            isAuthResponseError(response.data as AuthErrorResponseType);

            if (response.data.Status === EnrolmentStatus.Success) {
                clearInterval();
            }
            setEnrolmentStatus(response.data.Status);
        } catch (err: any) {
            clearInterval();
            setEnrolmentStatus(err.Result);
        }
    }, [clientData, appData]);

    return {
        inProgress, getAppCode, appData, getEnrolmentStatus, enrolmentStatus,
    };
}
