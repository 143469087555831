import { clientServiceApi } from '@hydrogen/elements.core.rest.transport';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { useEffect, useState } from 'react';
import { ContactDetailsData } from './types';

/* In case we start cacheing the contact details
const STORAGE_KEY = 'CONTACT_DETAILS';

export const getCache = () => JSON.parse(localStorage.getItem(STORAGE_KEY) || '{ "version": "", "contactDetails": [] }');
*/

export type UseContactDetailsResponse = {
    isLoading: boolean,
    data: any,
    error: string
};

export function useContactDetails(): UseContactDetailsResponse {
    const [isLoading, setIsLoading] = useState(true);
    const contactId = useContactId();
    const [error, setError] = useState('');

    const [data, setData] = useState<ContactDetailsData>();

    useEffect(() => {
        if (contactId) {
            clientServiceApi()
                .get(`v1.0/Contact/${contactId}/contact-information`)
                .then((response) => {
                    if (response.data) {
                        //  localStorage.setItem(STORAGE_KEY, JSON.stringify(response.data));
                        setData(response.data);
                    }
                })
                .catch((e) => setError(e.message))
                .finally(() => setIsLoading(false));
        }
    }, [contactId]);

    return { data, isLoading, error };
}
