import React, { ReactNode, useCallback } from 'react';

import Grid from '@hydrogen/elements.ui.components.grid';
import LinkButton from '@hydrogen/elements.ui.components.link-button';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useNavigate } from '@hydrogen/elements.core.router';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';

import { ContactInfo } from 'elements/ui/widgets/contact-info';

export type FirstLoginStepLayoutProps = {
    /**
     * a node to be rendered in the special component.
     */
    children: ReactNode;
    prevLink?: string;
    withMobileBackButton?: boolean;
    minContent?: string;
    showBackButton?: boolean;
    showContactInfo?: boolean;
    gridRowChildren?: string
};

export function FirstLoginStepLayout({
    prevLink = '',
    children,
    withMobileBackButton = false,
    minContent = 'min-content_min-content_1fr',
    showBackButton = true,
    showContactInfo = true,
    gridRowChildren = 'grid-rows-[auto_1fr_auto]',
}: FirstLoginStepLayoutProps) {
    const navigate = useNavigate();
    const { t } = useLanguage();

    const goBack = useCallback(() => {
        if (prevLink.includes('undefined')) {
            navigate('/registration');
        } else {
            navigate(prevLink);
        }
    }, [prevLink]);

    return (
        <Grid
            className="xs:justify-items-left md:justify-items-center grid-cols-1 px-[1.75rem] md:px-0 md:grid-cols-[1fr_350px_1fr] mt-0 md:mt-[3rem]"
        >
            <LinkButton
                className={`self-baseline ${withMobileBackButton ? 'flex' : 'hidden'} z-[1] md:flex text-primary-500 w-[100px] align-center`}
                href={prevLink}
                onClick={showBackButton ? goBack : undefined}
            >
                {showBackButton && (
                    <>
                        <ArrowNarrowLeftIcon className="text-primary-500 w-[1.5rem] mr-2.5" />
                        {` ${t('navigation.back')}`}
                    </>
                )}
            </LinkButton>
            <Grid
                className={`items-start [&>nav]:mb-0 [&>nav]:px-0 [&>nav>ol]:!mt-3 grid-rows-[${minContent}] min-h-[calc(100vh_-_162px)] md:min-h-[calc(100vh_-_235px)] ${gridRowChildren}`}
            >
                {children}
                {showContactInfo && <ContactInfo className="mt-[24px] grid-row-3" />}
            </Grid>
            <div />
        </Grid>
    );
}
