import React from 'react';

import { ChangePasswordNewPassword } from 'elements/ui/widgets/change-password-new-password';
import { ChangePasswordScreenType, ChangePasswordStepsEnum } from './types';
import { ChangePasswordSuccess } from '../change-password-success';

export const ChangePasswordScreen: ChangePasswordScreenType = {
    [ChangePasswordStepsEnum.PasswordSet]: (props) => (
        <ChangePasswordNewPassword {...props} />
    ),
    [ChangePasswordStepsEnum.Success]: (props) => (
        <ChangePasswordSuccess {...props} />
    ),
};
