import React from 'react';

import { t } from '@hydrogen/elements.core.i18n';
import { Outlet } from '@hydrogen/elements.core.router';
import BaseLayout from '@hydrogen/elements.ui.layouts.base';

import { ReadTermsDetails } from 'elements/ui/widgets/read-terms-details';
import { TermsAndConditions } from 'elements/ui/widgets/terms-and-conditions';
import { TermsFormFields } from 'elements/ui/widgets/terms-and-conditions/terms-and-conditions';

export const terms = {
    Component: BaseLayout,
    breadcrumb: () => t('nav.intro'),
    url: '/terms',
    props: {
        links: {
            nextLink: '/test',
        },
        children: <Outlet />,
    },
    children: [
        {
            url: 'portal-terms',
            Component: ReadTermsDetails,
            props: {
                termKey: TermsFormFields.portalTerms,
            },
        },
        {
            url: '',
            Component: TermsAndConditions,
            props: {
                links: {
                    nextLink: 'next-link',
                },
            },
        },
    ],
};
