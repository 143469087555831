import { useCallback } from 'react';
import { setAccessToken } from '@hydrogen/elements.core.rest.transport';
import { container } from '@hydrogen/elements.core.di';
import { TOKEN_TYPES } from '../../../../hydrogen/elements.auth.client-portal/types';
import { useAppToken } from '../use-app-token';
import { SessionStoreManager } from '../../../core/utils';

export const useAppTokenValidate = () => {
    const dataSource = container.get<typeof useAppToken>('useAppToken');
    const { getAppToken, validateAppToken } = dataSource();
    const authObject = SessionStoreManager('auth').load();

    const checkAppToken = useCallback(async () => {
        try {
            if (!authObject.appToken) {
                const {
                    data: { result: { accessToken } },
                } = await getAppToken({ type: TOKEN_TYPES.APP_TOKEN });

                SessionStoreManager('auth').save({ appToken: accessToken, userInfo: {} });
                setAccessToken(accessToken);

                return;
            }
            await validateAppToken({ accessToken: authObject.appToken, appToken: authObject.appToken });

            setAccessToken(authObject.appToken.toString());
        } catch {
            const {
                data: { result: { accessToken } },
            } = await getAppToken({ type: TOKEN_TYPES.APP_TOKEN });

            SessionStoreManager('auth').save({ appToken: accessToken, userInfo: {} });
            setAccessToken(accessToken);
        }
    }, []);

    return { checkAppToken };
};
