import React, { useCallback, useState } from 'react';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import Title from '@hydrogen/elements.ui.components.title';

import { useNavigate } from '@hydrogen/elements.core.router';
import { useAuthenticationData } from 'hydrogen/elements.auth.client-portal';
import { AuthErrors } from 'hydrogen/elements.auth.client-portal/types';
import { Button } from 'elements/ui/components/button';
import { OtpField } from 'elements/ui/components/otp-field';
import { Text } from 'elements/ui/components/text';
import { useNotifications } from '../../components/notification';

enum VerificationMethod {
    PushNotification,
    PassCode,
}

type ForgotPasswordClientNumberProps = {
    links: {
        nextLink: string,
        prevLink: string
    };
};

export function ForgotPasswordIdentityVerificationForm(
    {
        links: { nextLink },
    }: ForgotPasswordClientNumberProps,
) {
    const { t } = useLanguage();
    const navigate = useNavigate();
    const [confirmationInProgress, setConfirmationInProgress] = useState(false);
    const [passCode, setPassCode] = useState<string>('');
    const [error, setError] = useState('');
    const { negative } = useNotifications();

    const { forgotPasswordMtan } = useAuthenticationData();

    const onVerifyClick = useCallback(async () => {
        try {
            setConfirmationInProgress(true);
            await forgotPasswordMtan(passCode);
            navigate(nextLink);
        } catch (err: any) {
            if (err.Result === AuthErrors.MTanInvalid) {
                setError(t('validation.invalidCode'));
            } else {
                negative(t('notifications.somethingWentWrong'));
            }
        } finally {
            setConfirmationInProgress(false);
        }
    }, [forgotPasswordMtan, passCode]);

    const disableVerifyButton = confirmationInProgress || !passCode || passCode.length < 5;

    return (
        <div className="flex items-center justify-start lg:justify-center flex-col text-darkBlue h-full w-full">
            <Title
                className="mt-16 md:mt-0 mb-8"
            >
                {t('identityVerification.title')}
            </Title>
            <div className="text-left">
                <div className="flex flex-col">
                    <Text
                        variant="desc"
                    >
                        {t('identityVerification.passCode.description')}
                    </Text>
                </div>
                <div className="mt-8 text-center">
                    <OtpField
                        uppercase
                        value={passCode}
                        onChange={setPassCode}
                        length={5}
                        error={error}
                    />
                    <Button
                        loading={confirmationInProgress}
                        disabled={disableVerifyButton}
                        onClick={onVerifyClick}
                    >
                        {t('identityVerification.passCode.verifyButton')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
