import React, { ReactElement } from 'react';

import { classNames } from '@hydrogen/elements.ui.utils';
import { useNavigate } from '@hydrogen/elements.core.router';
import { SkeletonCircle, SkeletonLine } from '@hydrogen/elements.ui.components.skeleton';
import Grid from '@hydrogen/elements.ui.components.grid';
import IconButton, { IconButtonSizes, IconButtonTypes } from '@hydrogen/elements.ui.components.icon-button';
import Title, { TitleTypes } from '@hydrogen/elements.ui.components.title';
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline';

import { Image, ImageSize, ImageVariants } from 'elements/ui/components/image';
import { Text } from 'elements/ui/components/text';

export type FirstLoginHeaderProps = {
    stepName?: string;
    stepTitle: string | ReactElement;
    stepImage?: string;
    stepDesc?: string | ReactElement;
    noImage?: boolean;
    isLoading?: boolean;
    prevLink?: string;
    imageBelowTitle?: boolean;
    imageVariant?: ImageVariants;
    imageClassName?: string;
    imgAlt?: string
};

export function FirstLoginHeader({
    stepName,
    stepTitle,
    stepImage = '',
    stepDesc,
    noImage,
    isLoading = false,
    prevLink = '',
    imageBelowTitle = false,
    imageVariant = ImageVariants.roundWithBorder,
    imageClassName = '',
    imgAlt = '',

}: FirstLoginHeaderProps) {
    const navigate = useNavigate();
    const gridClasses = 'grid-cols-[1fr_auto_1fr] w-full items-center [&>button]:block md:[&>button]:hidden [&>button]:bg-transparent';

    if (isLoading) {
        return (
            <>
                <SkeletonLine
                    className={classNames(noImage && 'mb-[3.125rem]', 'mt-6')}
                    width="32"
                />
                {!noImage && (
                    <SkeletonCircle className="mt-[1.125rem] mb-[1rem]" size="24" />
                )}
                <SkeletonLine className="mb-4" height="7" width="full" />
                <SkeletonLine className="mb-8" width="full" />
            </>
        );
    }

    return (
        <>
            <Grid
                className={classNames(gridClasses, stepName ? 'md:grid-cols-1 mt-[1.625rem]' : 'md:mt-0 mt-[1.5rem] !mb-0', (noImage || imageBelowTitle) && 'mb-[1.125rem]')}
            >

                {
                    prevLink
                        ? (
                            <IconButton
                                size={IconButtonSizes.small}
                                type={IconButtonTypes.outline}
                                onClick={() => navigate(prevLink)}
                            >
                                <ArrowNarrowLeftIcon className="text-brand" />
                            </IconButton>
                        )
                        : <div />
                }
                {
                    stepName && (
                        <Text variant="stepTitle" className="ml-[-28px]">
                            {stepName}
                        </Text>
                    )
                }
                <div className="md:hidden" />
            </Grid>
            {
                !noImage && !imageBelowTitle && (
                    <Image
                        size={ImageSize.md}
                        variant={imageVariant}
                        src={stepImage}
                        alt={imgAlt}
                        className={classNames(imageClassName || '', 'mt-[1.125rem] mb-[1rem]')}
                    />
                )
            }
            <Title
                className={classNames(noImage ? 'mb-[2rem]' : 'mb-4', imageBelowTitle && 'mt-2', !stepName && prevLink && 'md:mt-0 -mt-[1.75rem]')}
                type={TitleTypes.cardMain}
            >
                {stepTitle}
            </Title>
            {
                !noImage && imageBelowTitle && (
                    <Image
                        size={ImageSize.md}
                        variant={imageVariant}
                        src={stepImage}
                        className={classNames(imageClassName || '', 'mb-[1rem]')}
                        alt={imgAlt}
                    />
                )
            }
            {stepDesc && (
                <Text variant="desc" className="mb-[1.5rem]">
                    {stepDesc}
                </Text>
            )}
        </>
    );
}
