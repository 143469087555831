import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';

type ProgressBarProps = {
    className?: string,
    progress: number,
}

export const ProgressBar = ({
    className,
    progress,
}: ProgressBarProps) => {
    const barClassNames = 'absolute h-10';

    return (
        <div className={classNames(className, 'relative h-10')}>
            <div className={classNames(barClassNames, 'z-[0] w-full rounded-[0.4375rem] bg-accent-700')} />
            <div className={classNames(barClassNames, 'rounded-l-[0.4375rem] z-[1] bg-secondary-300')} style={{ width: `${progress}%` }} />
        </div>
    );
};
