import React, { useEffect } from 'react';

import { useLanguage, useLanguageSwitcher } from '@hydrogen/elements.core.i18n';
import { classNames } from '@hydrogen/elements.ui.utils';
import Grid from '@hydrogen/elements.ui.components.grid';
import { useNavigate } from '@hydrogen/elements.core.router';

import { StepPageProps } from 'elements/core/contracts';
import { Button, LinkButton } from 'elements/ui/components/button';

import bgImage from '../../../../assets/intro/bg-image.png';
import { SessionStoreManager } from '../../../core/utils';

export function Intro({
    links: { nextLink },
}: StepPageProps<null>) {
    const navigate = useNavigate();
    const { t } = useLanguage();
    const { changeLanguage } = useLanguageSwitcher();
    const headerStyle = 'md:text-[44px] md:leading-[52px] text-[30px] leading-[43px] ';

    useEffect(() => {
        const soredCode = SessionStoreManager('code').load()?.code;
        const queryParameters = new URLSearchParams(window.location.search);
        const code = queryParameters.get('code');
        const lang = queryParameters.get('lang');

        if (lang) {
            changeLanguage(lang);
        }

        SessionStoreManager('code').save({ code: code || soredCode });
    }, []);

    return (
        <Grid gap={1} columns={{ sm: 2 }} className="justify-items-center min-h-full flex-1 bg-[#b6c6e8]">
            <div className="md:h-full h-[90%]">
                <div
                    className="w-full h-full flex flex-col-reverse flex-nowrap lg:items-end items-center justify-end overflow-hidden"
                >
                    <img
                        src={bgImage}
                        alt={t('alt.authBackgroundImage')}
                        className="max-h-[130%] sm:max-w-full sm:max-h-full max-w-[130%] rounded-full mt-[-30%] sm:mt-[-5rem] ml-[-15%] mr-[-15%] sm:mr-0"
                    />
                </div>
            </div>
            <div className="h-full">
                <div className="w-full h-full flex flex-col items-center justify-center">
                    <div className="text-center lg:text-left px-[1.75rem] sm:pt-[1.75rem] pb-11 max-w-md">
                        <div className="text-left text-darkBlue">
                            <h1 className={headerStyle}>
                                {t('firstLogin.welcome.name')}
                                <p className={classNames(headerStyle, 'font-ogg font-normal')}>
                                    {t('firstLogin.welcome.title')}
                                </p>
                            </h1>
                            <p className="mt-2 sm:my-[1.9rem] text-[1.25rem]">{t('firstLogin.welcome.desc')}</p>
                        </div>
                        <div className="mt-6 mb-[1.875rem] text-right md:text-left">
                            <div className="md:w-[20.875rem] w-[14.75rem] inline-flex">
                                <Button onClick={() => navigate(nextLink)}>
                                    {t('firstLogin.welcome.goToLogin')}
                                </Button>
                            </div>
                        </div>
                        <LinkButton
                            onClick={() => {
                                navigate('/');
                            }}
                            className="!text-primary-500"
                        >
                            {t('firstLogin.welcome.login')}
                        </LinkButton>
                    </div>
                </div>
            </div>
        </Grid>
    );
}
