import { formatDate, useLanguage } from '@hydrogen/elements.core.i18n';
import { useMemo } from 'react';
import {
    AccessPolicy, DEFAULT_CURRENCY, FIRST_OF_APR_24, FIRST_OF_JAN_23,
} from '../../../../common/constants';
import { useSelectedPortfolio } from '../../helpers/use-selected-portfolio';
import { useInvestmentKeyIndices } from '../../rest/use-investment-key-indices';
import { CardType } from '../../../ui/widgets/overview';
import { DAY_MONTH_YEAR_FORMAT } from '../../../../common/formaters';
import { useProductType } from '../../helpers/use-product-type';

export function useWithRundownWidget() {
    const {
        data: selectedPortfolio,
        isLoading: isSelectedPortfolioLoading,
        isError: isSelectedPortfolioError,
    } = useSelectedPortfolio();
    const { t } = useLanguage();

    const {
        isLoading,
        data,
        ...query
    } = useInvestmentKeyIndices();

    const { isPK, isThreeB, isThreeA } = useProductType({ externalId: selectedPortfolio?.product?.externalId });

    const migrationDate = useMemo<string | null>(() => {
        if (!data?.createDate || isPK) {
            return null;
        }

        if (isThreeA && new Date(data.createDate) < FIRST_OF_JAN_23) {
            return formatDate(FIRST_OF_JAN_23, DAY_MONTH_YEAR_FORMAT);
        }

        if (isThreeB && new Date(data.createDate) < FIRST_OF_APR_24) {
            return formatDate(FIRST_OF_APR_24, DAY_MONTH_YEAR_FORMAT);
        }

        return null;
    }, [data?.createDate, isThreeA, isThreeB, isPK]);

    const overviewRundownData: CardType[] | undefined = useMemo(
        () => {
            if (!data) {
                return undefined;
            }

            const items: CardType[] = [
                {
                    order: 1,
                    label: t('overview.amount'),
                    unit: DEFAULT_CURRENCY,
                    value: data?.currentValue,
                },

            ];

            if (!isPK) {
                items.push(
                    {
                        order: 2,
                        label: t('overview.return'),
                        showNegative: false,
                        unit: '%',
                        value: data?.performanceTwr ? data.performanceTwr * 100 : undefined,
                        date: !data?.returnStartDate ? null : formatDate(new Date(data?.returnStartDate), DAY_MONTH_YEAR_FORMAT),
                        tooltip: t('overview.returnDesc'),
                    },
                    {
                        order: 4,
                        label: t('overview.return'),
                        unit: DEFAULT_CURRENCY,
                        value: data?.absoluteReturn,
                        date: !data?.createDate ? null : formatDate(new Date(data?.createDate), DAY_MONTH_YEAR_FORMAT),
                    },
                );
            }

            if (migrationDate) {
                items.push({
                    order: 3,
                    label: t('overview.return'),
                    unit: DEFAULT_CURRENCY,
                    value: data?.absoluteReturnPostGoLive,
                    date: migrationDate,
                });
            }

            return items?.sort((a, b) => a.order! - b.order!);
        },
        [data, isPK, t, migrationDate],
    );

    const jaUsers = useMemo(() => (
        selectedPortfolio?.isJoint && data?.portfolioContacts && data?.portfolioContacts?.length > 1
            ? data.portfolioContacts.reduce((akku: string[], { displayName, accessPolicyId }) => {
                if (accessPolicyId === AccessPolicy.Owner) akku.push(displayName);

                return akku;
            }, [])
            : undefined
    ), [selectedPortfolio?.isJoint && data?.portfolioContacts]);

    return {
        ...query,
        data: {
            overviewRundownData,
            jaUsers,
        },
        isLoading: isLoading || isSelectedPortfolioLoading,
        isError: query.isError || isSelectedPortfolioError,
    };
}
