import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { classNames } from '@hydrogen/elements.ui.utils';
import GridColumns from '@hydrogen/elements.ui.components.grid';
import { Link } from '@hydrogen/elements.core.router';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useInvestmentId } from '@hydrogen/elements.data.wealth.app.use-investment-id';

import { LanguageSwitcher } from 'elements/ui/widgets/languge-switcher';
import { CookiesBanner } from 'elements/ui/widgets/cookies-banner';
import { useAuthenticationData } from '../../../../hydrogen/elements.auth.client-portal';

export function Footer() {
    const { t, language } = useLanguage();
    const location = useLocation();
    const investmentId = useInvestmentId();
    const { authData } = useAuthenticationData() || {};
    const hasBackground = [
        '/overview',
        `/investment/${investmentId}`,
        `/investment/${investmentId}/positions`,
        `/investment/${investmentId}/transactions`,
    ].includes(location.pathname);
    const linkStyle = 'text-secondary-300 hover:underline';

    const privacyPolicyLink = useMemo(() => {
        switch (language) {
            case 'de':
                return 'https://zurich.ch/de/datenschutz/';
            case 'fr':
                return 'https://zurich.ch/fr/protection-des-donnees/';
            case 'it':
                return 'https://zurich.ch/it/protezione-dei-dati/';
            default:
                return 'https://zurich.ch/en/data-protection/';
        }
    }, [language]);

    return (
        <footer
            className={classNames(authData?.accessToken ? 'border-none' : 'border-t', hasBackground && '!bg-neutral-100', 'bg-footer-background w-full mt-auto')}
        >
            {authData?.isAuthed && <CookiesBanner />}
            <nav className="max-w-page mx-auto px-4 sm:px-6">
                <div
                    className={classNames(authData?.accessToken ? 'lg:flex block text-center' : 'flex justify-between', 'py-10 items-center lg:justify-between')}
                >
                    <GridColumns
                        columns={{
                            lg: 3,
                            default: 1,
                        }}
                        className="gap-x-[1.5rem] gap-y-[1.5rem] fs text-xs font-medium"
                    >
                        <div className="text-footer-text">© Zurich 2023</div>
                        {(authData?.accessToken) && (
                            <>
                                <Link to={privacyPolicyLink} className={linkStyle} target="_blank">{t('footer.privacyPolicy')}</Link>
                                <Link to="terms/portal-terms" className={linkStyle}>{t('footer.terms')}</Link>
                            </>
                        )}
                    </GridColumns>
                    <div className={classNames(authData?.accessToken ? 'lg:mt-0 mt-[1.5rem]' : '')}>
                        <LanguageSwitcher />
                    </div>
                </div>
            </nav>
        </footer>
    );
}
