import {
    AccessPolicy, FZ, PRODUCT_TYPE, THREEA,
} from './constants';

export const getProductTypeByName = (name: string | undefined) => {
    if (!name) {
        return undefined;
    }

    const type = parseInt(name.split('.')[1], 10);

    if (type < THREEA) {
        return PRODUCT_TYPE.threeA;
    }
    if (type < FZ) {
        return PRODUCT_TYPE.VB;
    }

    return undefined;
};

export const isDev = () => {
    const suffix = global.serviceConfig.DMS_URL_SUFFIX;

    if (suffix.toLowerCase().includes('dev') || global.window.location.hostname === 'localhost') {
        return true;
    }

    return false;
};

export const isProxyAccessPolicy = (accessPolicy:number) => [AccessPolicy.Representative, AccessPolicy.PowerOfAttorney].includes(accessPolicy);
