import { useQuery } from 'react-query';
import { baseApi, customApi } from '@hydrogen/elements.core.rest.transport';
import { useContactId } from '@hydrogen/elements.data.wealth.app.use-contact-id';
import { useLanguage } from '@hydrogen/elements.core.i18n';
import { serviceConfig } from 'configs/service.config';
import { isProxyAccessPolicy } from 'common/utils';
import { IClientInvestOverview } from './types';
import { useClientsOverviewInvestments } from '../use-clients-overview-investments';

const { SERVICES_URL_SUFFIX } = serviceConfig;

export function useClientInvestmentOverview({
    showClosed,
}) {
    const contactId = useContactId();
    const { language } = useLanguage();
    const investmentsQuery = useClientsOverviewInvestments({
        language,
        showClosed,
    });

    const query = useQuery<IClientInvestOverview>(
        ['client-investment-overview', contactId, investmentsQuery.data],
        () => baseApi(SERVICES_URL_SUFFIX)
            .get(
                `/v1.1/contacts/${contactId}/investment-overview`,
                { params: { language } },
            ).then(({ data }) => ({
                ...data,
                ...investmentsQuery?.data?.reduce(
                    (accu, item) => {
                        if (!isProxyAccessPolicy(item?.accessPolicyId)) {
                            return {
                                AbsoluteReturn: accu.AbsoluteReturn + (item?.absoluteReturn ?? 0),
                                CurrentValue: accu.CurrentValue + (item?.value ?? 0),
                            };
                        }

                        return accu;
                    },
                    {
                        AbsoluteReturn: 0,
                        CurrentValue: 0,
                    },
                ),
            })),
        {
            enabled: !!contactId && !!investmentsQuery.data,
        },
    );

    return {
        ...query,
        error: query.error || investmentsQuery.error,
        isLoading: query.isLoading || query.isIdle || investmentsQuery.isLoading || investmentsQuery.isIdle,
    };
}
