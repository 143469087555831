import { useCallback, useEffect, useState } from 'react';
import { subscribe } from '@hydrogen/elements.core.event-bus';
import { AxiosResponse } from 'axios';
import { clientServiceApi, setAccessToken } from '@hydrogen/elements.core.rest.transport';
import { LoginTopics } from 'elements/core/contracts/event-bus/login';
import { container } from '@hydrogen/elements.core.di';
import { LoginResponse } from '../use-login/use-login';
import { setServiceWorkerAccessToken } from '../../../../common/helper';
import { SessionStoreManager } from '../../../core/utils';
import { useAppTokenValidate } from '../use-app-token-validate';

export type UsePassCodeConfirmationResult = {
    confirmPassCode: (code: string) => Promise<AuthResponse | null>;
    getAuthStatus: (authStatusSessionId: any, userName: string) => void;
    confirmationInProgress: boolean;
    preAuthData: LoginResponse | undefined;
};

type CampaignContact = {
    ContactId: number,
    UserName: string,
    IsActive: boolean,
    IsTwoFactorEnabled: boolean
}

export type AuthResponse = {
    Result: boolean,
    Status: string,
    Factor: string,
    TrustedDeviceToken: string,
    CampaignContact: CampaignContact,
    AuthStatusSessionId: string,
    Session: string,
    AsynchronousParameters: any
}

type Session = {
    SessionId: string,
    JwtAccessToken: string
}

type AuthStatusResponse = {
    Result: string,
    TrustedDeviceToken: string,
    Session: Session
}

export function usePassCodeConfirmation(): UsePassCodeConfirmationResult {
    const dataSource = container.get<typeof useAppTokenValidate>('useAppTokenValidate');
    const { checkAppToken } = dataSource();
    const [confirmationInProgress, setConfirmationInProgress] = useState(false);
    const [preAuthData, setPreAuthData] = useState<LoginResponse | undefined>();

    useEffect(() => {
        const unsubscribe = subscribe(LoginTopics.LoginPreAuthenticatedData, (payload) => {
            setPreAuthData(payload);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const confirmPassCode = useCallback(async (code: string) => {
        try {
            if (!preAuthData?.PreAuthenticateSessionId) {
                return null;
            }

            setConfirmationInProgress(true);
            await checkAppToken();

            const response: AxiosResponse<AuthResponse> = await clientServiceApi().post(`/v1.0/Onboarding/${preAuthData?.CampaignContact?.ContactId}/authenticate`, {
                passcode: code || '111111',
                preAuthenticateSessionId: preAuthData?.PreAuthenticateSessionId,
                setTrusted: true,
            });

            return response.data;
        } finally {
            setConfirmationInProgress(false);
        }
    }, [preAuthData, checkAppToken]);

    const getAuthStatus = useCallback(async (authStatusSessionId: string, userName: string) => {
        await checkAppToken();
        const response: AxiosResponse<AuthStatusResponse> = await clientServiceApi().post('/v1.0/Onboarding/authentication-status', {
            userName,
            authStatusSessionId,
            setTrusted: true,
        });

        if (response.data.Result === 'Success') {
            // TODO: we need to think of a better way of handling JWT
            SessionStoreManager('auth').save({
                accessToken: response.data.Session.JwtAccessToken,
            });
            setAccessToken(response.data.Session.JwtAccessToken);
            setServiceWorkerAccessToken(response.data.Session.JwtAccessToken);
        } else {
            throw new Error('Something went wrong!');
        }
    }, [checkAppToken]);

    return {
        confirmPassCode,
        confirmationInProgress,
        preAuthData,
        getAuthStatus,
    };
}
