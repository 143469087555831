import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { baseApi } from '@hydrogen/elements.core.rest.transport';

export type OptionListObject = {
  id: number;
  uid: string;
  label: string;
}

export type OptionListResponse = {
 items : Array<OptionListObject>;
}

type OptionListProps = {
    language? : string,
    listKey: string,
}

export const useOptionList = ({
    language = 'en',
    listKey,
}: OptionListProps) => {
    const optionListObject = useQuery<AxiosResponse<OptionListResponse>, unknown, OptionListResponse>(
        ['option-list', language],
        () => baseApi('/cls').get(
            `/v1.1/schemas/lists/${listKey}/items`,
        ),
        {
            enabled: !!listKey,
            keepPreviousData: true,
            select({ data }) {
                return data;
            },
        },
    );

    return {
        ...optionListObject,
        data: optionListObject?.data?.items,
        isLoading:
        optionListObject.isIdle
        || optionListObject.isLoading
        || (optionListObject.isPreviousData && optionListObject.isFetching),
    };
};
