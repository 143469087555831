import { t } from '@hydrogen/elements.core.i18n';
import { Intro } from '../../elements/ui/widgets/intro';

export const intro = {
    Component: Intro,
    breadcrumb: () => t('nav.intro'),
    url: '/registration',
    props: {
        links: {
            nextLink: '/signup/code-verification',
        },
    },
};
