import React from 'react';
import { classNames } from '@hydrogen/elements.ui.utils';

import { theme } from './theme';

export enum IconButtonStates {
    disabled = 'disabled',
    focus = 'focus',
}

export enum IconButtonTypes {
    default = 'default',
    alternative = 'alternative',
    secondary = 'secondary',
    danger = 'danger',
    outline = 'outline',
    header = 'header',
}

export enum IconButtonSizes {
    small = 'small',
    default = 'default',
    large = 'large',
}

type IconButtonProps = {
    type?: IconButtonTypes
    size?: IconButtonSizes | string
    disabled?: boolean
    visual?: boolean
    title?: string
    className?: string
    children?: React.ReactNode | React.ReactNode[] | null | React.ReactElement | boolean,
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void

}

export const IconButton = (props: IconButtonProps) => {
    const sharedClasses = theme.shared;

    const type = props.type || IconButtonTypes.default;
    const typeClasses = classNames(theme.types[type].layer, theme.types[type].text);

    const size = props.size || IconButtonSizes.default;
    const sizeClasses = classNames(theme.sizes[size].layer);

    const focusClasses = classNames(theme.states.focus.layer);
    const disabledClasses = props.disabled ? classNames(theme.states.disabled.layer) : '';

    const combinedClasses = classNames(
        props.className,
        sharedClasses,
        sizeClasses,
        typeClasses,
        focusClasses,
        disabledClasses,
    );

    if (props.visual) {
        return (<span className={combinedClasses}>{props.children}</span>);
    }

    return (
        <button {...props} onClick={props.onClick} className={combinedClasses} type="button">
            {props.children}
        </button>

    );
};
