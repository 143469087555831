import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';

import { useLanguage } from '@hydrogen/elements.core.i18n';
import { useNavigate } from '@hydrogen/elements.core.router';
import Title from '@hydrogen/elements.ui.components.title';

import { useAuthenticationData } from 'hydrogen/elements.auth.client-portal';
import { Text } from 'elements/ui/components/text';
import { Counter } from 'elements/ui/components/counter';
import { StepPageProps } from 'elements/core/contracts';
import { usePassCodeConfirmation } from 'elements/data/rest/use-passcode-confirmation/use-passcode-confirmation';
// import { useNotifications } from '../../components/notification';

enum VerificationMethod {
    PushNotification,
    PassCode,
}

export function IdentityVerificationForm({
    links: { nextLink },
}: StepPageProps<typeof usePassCodeConfirmation>) {
    const { t } = useLanguage();
    // const { negative } = useNotifications();
    const effectRan = useRef(false);
    const [verificationMethod, setVerificationMethod] = useState(
        VerificationMethod.PushNotification,
    );
    const { loginMtan, setMfaAuthError, authData } = useAuthenticationData();
    const navigate = useNavigate();

    const onVerifyClick = useCallback(async () => {
        try {
            await loginMtan('111111');
            // navigate(nextLink);
        } catch (err) {
            console.error(err);
            setMfaAuthError(true);
            navigate('/');
        }
    }, [nextLink]);

    useEffect(() => {
        if (!effectRan.current) {
            (async () => onVerifyClick())();
        }

        return () => {
            effectRan.current = true;
        };
    }, [onVerifyClick]);

    useEffect(() => {
        if (authData.isAuthed) {
            navigate('/overview');
        }
    }, [authData]);

    const onTimeUp = useCallback(() => {
        setMfaAuthError(true);
        navigate('/');
    }, [navigate]);

    // const disableVerifyButton = confirmationInProgress || !passCode || passCode.length < 6;

    return (
        <div
            className="flex items-center justify-start md:justify-center flex-col text-darkBlue px-[1.75rem] md:px-0 h-full w-full"
        >
            <Title
                className="mt-16 mb-8"
            >
                {t('identityVerification.title')}
            </Title>
            <div className="text-left">

                <div>
                    <Text
                        variant="desc"
                    >
                        {t('identityVerification.notification.intro')}
                    </Text>
                </div>
                <div className="flex flex-col mt-4">
                    <Text
                        variant="disclaimer"
                    >
                        {t('identityVerification.notification.firstStep.disclaimer')}
                    </Text>
                    <Text
                        variant="desc"
                    >
                        {t('identityVerification.notification.firstStep.description')}
                    </Text>
                </div>
                <div className="flex flex-col mt-4">
                    <Text
                        variant="disclaimer"
                    >
                        {t('identityVerification.notification.secondStep.disclaimer')}
                    </Text>
                    <Text
                        variant="desc"
                    >
                        {t('identityVerification.notification.secondStep.description')}
                    </Text>
                </div>
                <div className="flex flex-col justify-items-center mt-8 text-center">
                    <Counter seconds={60} onTimeUp={onTimeUp} />
                </div>
                {/* <div className="text-center my-8"> */}
                {/*    <LinkButton */}
                {/*        as="a" */}
                {/*        onClick={setPassCodeVerificationMethod} */}
                {/*    > */}
                {/*        {t('identityVerification.notification.verificationMethod')} */}
                {/*    </LinkButton> */}
                {/* </div> */}

                {/* {verificationMethod === VerificationMethod.PassCode && ( */}
                {/*    <> */}
                {/*        <div className="flex flex-col"> */}
                {/*            <Text variant="disclaimer">{t('identityVerification.passCode.firstStep.disclaimer')}</Text> */}
                {/*            <Text */}
                {/*                variant="desc" */}
                {/*            > */}
                {/*                {t('identityVerification.passCode.firstStep.description')} */}
                {/*            </Text> */}
                {/*        </div> */}
                {/*        <div className="flex flex-col mt-4"> */}
                {/*            <Text variant="disclaimer">{t('identityVerification.passCode.secondStep.disclaimer')}</Text> */}
                {/*            <Text */}
                {/*                variant="desc" */}
                {/*            > */}
                {/*                {t('identityVerification.passCode.secondStep.description')} */}
                {/*            </Text> */}
                {/*        </div> */}
                {/*        <div className="my-8 text-center"> */}
                {/*            <div */}
                {/*                className="mb-[40px] [&_p]:text-left [&_input]:bg-disabled [&_p]:mt-0 [&>div>div>div>div>div]:rounded-lg [&>div_input]:rounded-lg [&>div>div>div]:w-10 [&>div>div>div_input]:h-[48px] [&>div>div>div_input]:text-2xl [&>div>div>div_input]:bg-ziagWhite" */}
                {/*            > */}
                {/*                <OtpField length={6} inputType="number" value={passCode} onChange={setPassCode} /> */}
                {/*            </div> */}
                {/*            <Button */}
                {/*                loading={confirmationInProgress} */}
                {/*                disabled={disableVerifyButton} */}
                {/*                onClick={onVerifyClick} */}
                {/*            > */}
                {/*                {t('identityVerification.passCode.verifyButton')} */}
                {/*            </Button> */}
                {/*        </div> */}
                {/*    </> */}
                {/* )} */}
            </div>
        </div>
    );
}
